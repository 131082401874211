import React from 'react'
import styled from 'styled-components'
import { suomifiDesignTokens } from 'suomifi-ui-components'

type Spacing =
  | 'xxs'
  | 'xs'
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl'
  | '0'

const DividerLine: React.FC<{
  mt?: Spacing
  mb?: Spacing
  className?: string
}> = (props) => {
  return <HR {...props} aria-hidden="true" />
}

export default DividerLine

const HR: React.FC<{ mt?: Spacing; mb?: Spacing }> = styled.hr<{
  mt: Spacing
  mb: Spacing
}>`
  border: none;
  border-top: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  margin-block-start: ${({ mt = '0' }) =>
    mt === '0' ? 0 : suomifiDesignTokens.spacing[mt]};
  margin-block-end: ${({ mb = '0' }) =>
    mb === '0' ? 0 : suomifiDesignTokens.spacing[mb]};
`
