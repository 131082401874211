import axios from 'axios'
import to from 'common/src/vtj/to'

export const DEFAULT_TIMEOUT = 20000

export const DEFAULT_UPLOAD_TIMEOUT = 120000

// All options documented at https://github.com/axios/axios#request-config
const request = (instance, options) =>
  to(
    instance.request({
      headers: {
        Accept: 'application/json',
        // Do not set Content-Type here as Axios sets it based on data given
        'Csrf-Token': window.CSRF_TOKEN,
      },
      timeout: DEFAULT_TIMEOUT,
      ...options,
    })
  )

const getWithInstance = (instance) => (url, options) =>
  request(instance, {
    method: 'get',
    url,
    ...options,
  })

const postWithInstance = (instance) => (url, data, options) =>
  request(instance, {
    method: 'post',
    url,
    data,
    ...options,
  })

const putWithInstance = (instance) => (url, data, options) =>
  request(instance, {
    method: 'put',
    url,
    data,
    ...options,
  })

const patchWithInstance = (instance) => (url, data, options) =>
  request(instance, {
    method: 'patch',
    url,
    data,
    ...options,
  })

const delWithInstance = (instance) => (url, options) =>
  request(instance, {
    method: 'delete',
    url,
    ...options,
  })

export const enableCancel = (config) => {
  const source = axios.CancelToken.source()
  const configWithCancel = {
    ...config,
    cancelToken: source.token,
  }
  const cancelFunction = () => {
    source.cancel('CANCELLED')
  }
  return {
    configWithCancel,
    cancelFunction,
  }
}

export const isCancelError = (error) => {
  return axios.isCancel(error)
}

export const isTimeoutError = (error) => {
  return error.code === 'ECONNABORTED'
}

export const get = getWithInstance(axios)
export const post = postWithInstance(axios)
export const put = putWithInstance(axios)
export const patch = patchWithInstance(axios)
export const del = delWithInstance(axios)

export const createInstance = (options = undefined) => {
  const instance = axios.create(options)
  return createWithAxiosInstance(instance)
}

export const createWithAxiosInstance = (instance) => {
  return {
    get: getWithInstance(instance),
    post: postWithInstance(instance),
    put: putWithInstance(instance),
    patch: patchWithInstance(instance),
    del: delWithInstance(instance),
  }
}
