import LupaTypeSelectPage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-type-select/LupaTypeSelectPage'
import NeededAttachmentsPage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/needed-attachments/NeededAttachmentsPage'
import PersonInputPage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/persons/PersonInputPage'
import InfoInputPage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/info-input/InfoInputPage'
import SummaryPage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/summary/SummaryPage'
import { LUPA_ASIOINTI_UI_ROUTE_PREFIX } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-ui-route.util'
import DonePage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/done/DonePage'
import PrintableSummaryPage from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/printable-summary/PrintableSummaryPage'

export enum LupaApplicationStep {
  LUPA_TYPE_SELECT = 'LUPA_TYPE_SELECT',
  NEEDED_ATTACHMENTS = 'NEEDED_ATTACHMENTS',
  INPUT_PERSONS = 'INPUT_PERSONS',
  INPUT_INFO = 'INPUT_INFO',
  SUMMARY = 'SUMMARY',
}

export enum LupaApplicationOtherView {
  DONE = 'DONE',
  PRINTABLE_SUMMARY = 'PRINTABLE_SUMMARY',
}

export type LupaApplicationView = LupaApplicationStep | LupaApplicationOtherView

export const lupaApplicationStepOrder: LupaApplicationStep[] = Object.keys(
  LupaApplicationStep
) as LupaApplicationStep[]

export const mainPath = `${LUPA_ASIOINTI_UI_ROUTE_PREFIX}/hakemus`

export const pathByViewId: Record<LupaApplicationView, string> = {
  [LupaApplicationStep.LUPA_TYPE_SELECT]: `${mainPath}/hae`,
  [LupaApplicationStep.NEEDED_ATTACHMENTS]: `${mainPath}/tarvittavat-liitteet`,
  [LupaApplicationStep.INPUT_PERSONS]: `${mainPath}/henkilot`,
  [LupaApplicationStep.INPUT_INFO]: `${mainPath}/tiedot`,
  [LupaApplicationStep.SUMMARY]: `${mainPath}/yhteenveto`,
  [LupaApplicationOtherView.DONE]: `${mainPath}/valmis`,
  [LupaApplicationOtherView.PRINTABLE_SUMMARY]: `${mainPath}/valmis/yhteenveto`,
}

export const stepTranslationKeyByStepId: Record<LupaApplicationStep, string> = {
  [LupaApplicationStep.LUPA_TYPE_SELECT]: 'lupaAsioidenValinta',
  [LupaApplicationStep.NEEDED_ATTACHMENTS]: 'tarvittavatLiitteet',
  [LupaApplicationStep.INPUT_PERSONS]: 'hakijatJaPaamiehet',
  [LupaApplicationStep.INPUT_INFO]: 'hakemuksenTiedotJaLiitteet',
  [LupaApplicationStep.SUMMARY]: 'yhteenvetoJaLahetys',
}

export const viewComponentByViewId: Record<LupaApplicationView, React.FC> = {
  [LupaApplicationStep.LUPA_TYPE_SELECT]: LupaTypeSelectPage,
  [LupaApplicationStep.NEEDED_ATTACHMENTS]: NeededAttachmentsPage,
  [LupaApplicationStep.INPUT_PERSONS]: PersonInputPage,
  [LupaApplicationStep.INPUT_INFO]: InfoInputPage,
  [LupaApplicationStep.SUMMARY]: SummaryPage,
  [LupaApplicationOtherView.DONE]: DonePage,
  [LupaApplicationOtherView.PRINTABLE_SUMMARY]: PrintableSummaryPage,
}

export const resolveStepByPath = (pathname: string): LupaApplicationStep => {
  const found = lupaApplicationStepOrder.find(
    (step) => pathByViewId[step] === pathname
  )
  if (!found) {
    throw new Error(`Invalid application step pathname: ${pathname}`)
  }
  return found
}

export const isApplicationStep = (pathname: string): boolean => {
  try {
    resolveStepByPath(pathname)
    return true
  } catch (err) {
    return false
  }
}

export const getPrevStep = (
  from: LupaApplicationStep
): LupaApplicationStep | undefined => {
  const index = lupaApplicationStepOrder.indexOf(from)
  if (index > 0) {
    return lupaApplicationStepOrder[index - 1]
  }
  return undefined
}

export const getNextStep = (
  from: LupaApplicationStep
): LupaApplicationStep | undefined => {
  const index = lupaApplicationStepOrder.indexOf(from)
  if (index >= 0 && index < lupaApplicationStepOrder.length - 1) {
    return lupaApplicationStepOrder[index + 1]
  }
  return undefined
}

export function* getPreviousSteps(
  step: LupaApplicationStep
): Generator<LupaApplicationStep> {
  let prev: LupaApplicationStep | undefined = step
  do {
    prev = getPrevStep(prev)
    if (prev) {
      yield prev
    }
  } while (prev)
}
