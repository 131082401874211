import React from 'react'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Paragraph,
  Text,
} from 'suomifi-ui-components'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTallennaModalTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  useValittuEvtvVaatimus,
  IsoDatetimeToUiDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const SaveDialog = observer(
  ({
    visible,
    onSave,
    onClose,
  }: {
    visible: boolean
    onSave: () => void
    onClose: () => void
  }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet

    const vaatimus = useValittuEvtvVaatimus()

    const formattedMaaraPvm = IsoDatetimeToUiDate(vaatimus.dueDate)
    const { asiaType } = getTili()
    const translations =
      asiaType === 'OMAISUUSLUETTELO'
        ? {
            tallennaInfo: t('tallennaOmaisuusluetteloInfoText'),
            lahetaMennessa: t('lahetaOmaisuusluetteloMennessa', {
              paivamaara: formattedMaaraPvm,
            }),
          }
        : {
            tallennaInfo: t('tallennaModalInfoText'),
            lahetaMennessa: t('lahetaPaatostiliMennessa', {
              paivamaara: formattedMaaraPvm,
            }),
          }

    return (
      <Modal
        visible={visible}
        appElementId={ASIOINTI_ROOT_ELEMENT_ID}
        variant={isTablet ? 'default' : 'smallScreen'}
        onEscKeyDown={onClose}
      >
        <ModalContent>
          <ModalTitle>{t('tallennaJaPalaaEtusivulle')}</ModalTitle>
          <Paragraph>
            <Text>{translations.tallennaInfo}</Text>
          </Paragraph>
          <Block mt="s" />
          <Paragraph>
            <Text>{translations.lahetaMennessa}</Text>
          </Paragraph>
        </ModalContent>
        <ModalFooter>
          <Button
            data-test-id={mkTallennaModalTestId('save-button')}
            onClick={onSave}
          >
            {t('tallennaJaPalaaEtusivulle')}
          </Button>
          <Button
            data-test-id={mkTallennaModalTestId('cancel-saving-button')}
            onClick={onClose}
            variant="secondary"
          >
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

export default SaveDialog
