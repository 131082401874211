import React from 'react'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Paragraph,
  Text,
} from 'suomifi-ui-components'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { useTranslation } from 'react-i18next'
import { mkAbortModalTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const AbortDialog = observer(
  ({
    visible,
    onAbort,
    onClose,
  }: {
    visible: boolean
    onAbort: () => void
    onClose: () => void
  }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet

    return (
      <Modal
        visible={visible}
        appElementId={ASIOINTI_ROOT_ELEMENT_ID}
        variant={isTablet ? 'default' : 'smallScreen'}
        onEscKeyDown={onClose}
      >
        <ModalContent>
          <ModalTitle>{t('keskeytaHakemuksenTeko')}</ModalTitle>
          <Paragraph>
            <Text>{t('keskeytettyaHakemustaEiTallenneta')}</Text>
          </Paragraph>
          <Block mt="s" />
          <Paragraph>
            <Text>{t('haluatkoKeskeyttaaHakemuksen')}</Text>
          </Paragraph>
        </ModalContent>
        <ModalFooter>
          <Button
            data-test-id={mkAbortModalTestId('keskeyta-painike')}
            arial-label={t('vahvistaHakemuksenKeskeyttaminen')}
            onClick={onAbort}
          >
            {t('keskeyta')}
          </Button>
          <Button
            data-test-id={mkAbortModalTestId('peruuta-keskeytys-painike')}
            arial-label={t('peruutaHakemuksenKeskeyttaminen')}
            onClick={onClose}
            variant="secondary"
          >
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

export default AbortDialog
