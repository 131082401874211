import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getTilintarkastusStore,
  saveDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { InlineAlert, Link } from 'suomifi-ui-components'
import { mkUncompletedStepsSummaryTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { ErrorsBulletedList } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/components'
import {
  OMAISUUSLUETTELO_ROUTES,
  OmaisuusluetteloFormStates,
  PAATOSTILI_ROUTES,
  PaatostiliFormStates,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { resolveFormErrors } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-state.util'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

type Props = {
  forms: Partial<PaatostiliFormStates | OmaisuusluetteloFormStates>
}

const FormUncompletedStepsSummary = observer(
  forwardRef<HTMLDivElement, Props>(function FormUncompletedStepsSummary(
    { forms },
    ref
  ) {
    const [t] = useTranslation()
    const navigate = useNavigate()
    const isTablet = useDeviceContext().tablet
    const {
      isPrincipalUnderaged,
      lomake: {
        tili: { asiaType },
      },
    } = getTilintarkastusStore()
    const isOmaisuusluettelo = asiaType === AsiointiType.OMAISUUSLUETTELO

    const RouteErrorTranslations: Record<TilintarkastusRoutePath, string> = {
      // Strings are translation keys
      [PAATOSTILI_ROUTES.YLEISKATSAUS]: 'yleiskatsaus',
      [PAATOSTILI_ROUTES.PALKKIO]: 'palkkio',
      [PAATOSTILI_ROUTES.TULOT_JA_MENOT]: 'tulotJaMenot',
      [PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT]: 'omaisuusJaVelat',
      [PAATOSTILI_ROUTES.YHTEYSTIEDOT]: 'yhteystiedot',
      [PAATOSTILI_ROUTES.TEE_PAATOSTILI]: 'aloitus',
      [PAATOSTILI_ROUTES.YHTEENVETO]: 'yhteenveto',
      [PAATOSTILI_ROUTES.VALMIS]: 'valmis',
      [OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO]: 'aloitus',
      [OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]:
        isPrincipalUnderaged ? 'asuminenHeading' : 'toimintakykyJaAsuminen',
      [OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]:
        'kaytossaOlevaOmaisuusHeading2',
      [OMAISUUSLUETTELO_ROUTES.VARAT]: 'varat',
      [OMAISUUSLUETTELO_ROUTES.VELAT]: 'velat',
      [OMAISUUSLUETTELO_ROUTES.ELAKKEET_JA_ETUUDET]: 'elakkeetJaEtuudet',
      [OMAISUUSLUETTELO_ROUTES.KAYTTOVARAT]: 'kayttovarat',
      [OMAISUUSLUETTELO_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]:
        'sopimuksetJaVakuutukset',
      [OMAISUUSLUETTELO_ROUTES.MUUT_MERKITTAVAT_TOIMET]:
        'muutMerkittavatToimet',
      [OMAISUUSLUETTELO_ROUTES.VALTAKIRJA]: 'valtakirja',
      [OMAISUUSLUETTELO_ROUTES.YHTEYSTIEDOT]: 'yhteystiedot',
      [OMAISUUSLUETTELO_ROUTES.YHTEENVETO]: 'yhteenvetoJaLahetys',
      [OMAISUUSLUETTELO_ROUTES.VALMIS]: 'valmis',
    }

    const errors = Object.entries(
      resolveFormErrors(forms, RouteErrorTranslations)
    )

    return (
      <section data-test-id={mkUncompletedStepsSummaryTestId('container')}>
        {errors.length > 0 && (
          <FocusableDiv ref={ref} tabIndex={-1}>
            <InlineAlert
              mb={'s'}
              status="error"
              smallScreen={!isTablet}
              data-test-id={mkUncompletedStepsSummaryTestId('errors')}
              tabIndex={0}
              labelText={
                isOmaisuusluettelo
                  ? t('pakollisiaTietojaPuuttuuOmaisuusluettelo')
                  : t('pakollisiaTietojaPuuttuu')
              }
            >
              <ErrorsBulletedList>
                {errors.map(([path, error]) => (
                  <li key={path}>
                    <Link
                      smallScreen
                      href="#"
                      onClick={() => {
                        void saveDraft()
                        navigate(path)
                      }}
                    >
                      {t(error.toString()) || ''}
                    </Link>
                  </li>
                ))}
              </ErrorsBulletedList>
            </InlineAlert>
          </FocusableDiv>
        )}
      </section>
    )
  })
)

export default FormUncompletedStepsSummary
