import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

export const ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta31HenkilotietojaRestriction: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_24, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta31HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta32HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.SALAINEN,
    publicityClassIds: [PublicityClass.SALAINEN],
    publicityMetadata: {
      [PublicityClass.SALAINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_24, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const ulkomaalaisenRekisterointi4VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_4_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_4_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const ulkomaalaisenRekisterointi50VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_50_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_50_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const ulkomaalaisenRekisterointiPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
  retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
}

// FIXME: Remove this and use ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta31HenkilotietojaRestriction
// https://jira.dvv.fi/browse/OIVA-5034
export const ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta31HenkilotietojaRestrictionFIXME: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA, PublicityClass.SALAINEN],
    publicityMetadata: {
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
      },
      [PublicityClass.SALAINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_31],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

// FIXME: Remove this and use ulkomaalaisenRekisterointi4VuottaRetention
// https://jira.dvv.fi/browse/OIVA-5034
export const ulkomaalaisenRekisterointi4VuottaRetentionFIXME: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_4_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_4_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}
