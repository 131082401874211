import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
  AsiaPersonType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { InternalToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'

const perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

const perukirjaSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

const perukirja10VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_10_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_10_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const perukirjanOsakastietojenVahvistusMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUS_PERUKIRJAN_OSAKASTIETOJEN_VAHVISTAMISEKSI,
      restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5f720cb0-4732-4861-a7a3-99adb9c9efae'],
      defaultToivoAsiakirjaTypeId: '5f720cb0-4732-4861-a7a3-99adb9c9efae',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: perukirjaSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['874048f2-d2dd-4c81-ac4e-2d7ae0bf45e1'],
      defaultToivoAsiakirjaTypeId: '874048f2-d2dd-4c81-ac4e-2d7ae0bf45e1',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJAN_LIITE,
      restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5eb7fefb-c2e3-4876-b221-6da3adb10d18'],
      defaultToivoAsiakirjaTypeId: '5eb7fefb-c2e3-4876-b221-6da3adb10d18',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SAATE_TAI_LAHETE,
      restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['09edc5fc-40e1-452a-a3a7-3f13d0f479ce'],
      defaultToivoAsiakirjaTypeId: '09edc5fc-40e1-452a-a3a7-3f13d0f479ce',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['7b007bf5-4a12-413e-bea7-3e9974e6b141'],
      defaultToivoAsiakirjaTypeId: '7b007bf5-4a12-413e-bea7-3e9974e6b141',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['df05808d-7458-4a65-8333-2edd99b1847a'],
      defaultToivoAsiakirjaTypeId: 'df05808d-7458-4a65-8333-2edd99b1847a',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS,
      restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['53e128dd-5f2d-4233-b2d2-c454ed3f3032'],
      defaultToivoAsiakirjaTypeId: '53e128dd-5f2d-4233-b2d2-c454ed3f3032',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS,
      restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
      retentionMetadata: perukirja10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['99a15af2-653b-496f-aa7c-f2a69cca7c75'],
      defaultToivoAsiakirjaTypeId: '99a15af2-653b-496f-aa7c-f2a69cca7c75',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.PERITTAVA, AsiaPersonRole.HAKIJA_ASIAMIES],
  personTypeMetadata: [AsiaPersonType.IHMINEN, AsiaPersonType.YRITYS],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.RAUENNUT,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.AIHEETON_VIENTI,
      RatkaisuType.VAHVISTAMATTAJATTAMISPAATOS,
      RatkaisuType.MYONTEINEN_PAATOS,
      RatkaisuType.TUTKIMATTAJATTAMISPAATOS,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  retentionMetadata: perukirja10VuottaRetention,
  restrictionMetadata: perukirjaOsittainSalassaPidettava100VuottaK23K25K32HenkilotietojaRescrition,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
