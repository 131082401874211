import fp from 'lodash/fp'

export const validateHetu = (hetu: unknown): boolean => {
  return (
    typeof hetu === 'string' &&
    validateLength(hetu, 11, 11) &&
    validatePartialHetu(hetu)
  )
}

export const validatePartialHetu = (hetu: unknown): boolean => {
  return (
    typeof hetu === 'string' &&
    validateLength(hetu, 0, 11) &&
    (hetu.length === 0 || validateNumericHetuBlock(hetu, 0, 2, 1, 31)) &&
    (hetu.length <= 2 || validateNumericHetuBlock(hetu, 2, 4, 1, 12)) &&
    (hetu.length <= 4 || validateNumericHetuBlock(hetu, 4, 6, 0, 99)) &&
    (hetu.length <= 6 || validatePunctuation(hetu)) &&
    (hetu.length <= 6 || validateHetuDate(hetu)) &&
    (hetu.length <= 7 || validateNumericHetuBlock(hetu, 7, 10, 0, 999)) &&
    (hetu.length <= 10 || getValidHetuChecksum(hetu) === hetu[10])
  )
}

const hetuCheckSumDigitTable = '0123456789ABCDEFHJKLMNPRSTUVWXY'

export const getValidHetuChecksum = (hetu: string): string => {
  if (!validateLength(hetu, 10, 11)) {
    throw new Error('Hetu must have length of 10 or 11')
  }
  const numeralValue = toNumber(hetu.substring(0, 6) + hetu.substring(7, 10))
  if (isNaN(numeralValue)) {
    throw new Error('Invalid input. Must be in correct hetu format.')
  }
  return hetuCheckSumDigitTable[numeralValue % hetuCheckSumDigitTable.length]
}

const validateLength = (
  hetu: string,
  minLength: number,
  maxLength: number
): boolean => {
  return hetu.length >= minLength && hetu.length <= maxLength
}

const validateNumericHetuBlock = (
  hetu: string,
  indexStart: number,
  indexEnd: number,
  minValue: number,
  maxValue: number
): boolean => {
  const substring = hetu.substring(indexStart, indexEnd)
  const length = indexEnd - indexStart
  const minPossible = toNumber(fp.padCharsEnd('0', length, substring))
  const maxPossible = toNumber(fp.padCharsEnd('9', length, substring))
  return (
    !isNaN(toNumber(substring)) &&
    maxPossible >= minValue &&
    minPossible <= maxValue
  )
}

const validatePunctuation = (hetu: string): boolean => {
  return [
    '+',
    '-',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'Y',
    'X',
    'W',
    'V',
    'U',
  ].includes(hetu[6])
}

const validateHetuDate = (hetu: string): boolean => {
  const day = toNumber(hetu.substring(0, 2))
  const monthIndex = toNumber(hetu.substring(2, 4)) - 1
  const year = toNumber(punctuationToYearPrefix(hetu[6]) + hetu.substring(4, 6))
  const date = new Date(year, monthIndex, day, 0, 0, 0, 0)
  return (
    date.getDate() === day &&
    date.getMonth() === monthIndex &&
    date.getFullYear() === year
  )
}

const punctuationToYearPrefix = (punctuation: string): string => {
  switch (punctuation) {
    case '+':
      return '18'
    case '-':
    case 'Y':
    case 'X':
    case 'W':
    case 'V':
    case 'U':
      return '19'
    case 'A':
    case 'B':
    case 'C':
    case 'D':
    case 'E':
    case 'F':
      return '20'
    default:
      throw new Error(`Invalid punctuation ${punctuation}`)
  }
}

const toNumber = (string: string): number => {
  return /^\d+$/.test(string) ? Number(string) : NaN
}
