import keyMirror from 'keymirror'
import fp from 'lodash/fp'

export const LupaApplicationType = keyMirror({
  PERINNONJAKO: null,
  MYYNTI: null,
  MUU: null,
})
export type LupaApplicationTypeId =
  (typeof LupaApplicationType)[keyof typeof LupaApplicationType]

export const LupaSpecifierType = keyMirror({
  KIINTEISTON_HANKKIMINEN: null,
  ASUNNON_HANKKIMINEN: null,
  KIINTEISTON_VUOKRAAMINEN: null,
  ASUNNON_VUOKRAAMINEN: null,
  ASUMISOIKEUDEN_HANKKIMINEN: null,
  ASUMISOIKEUDEN_VUOKRAAMINEN: null,
  LAINAN_OTTAMINEN: null,
  PANTTAUS: null,
  TAKAUS: null,
  ELINKEINON_HARJOITTAMINEN: null,
  YHTIOMIEHEKSI_RYHTYMINEN: null,
  LAINAN_ANTAMINEN: null,
  SIJOITUSKOHTEIDEN_OSTAMINEN: null,
  METSAN_TAI_MAA_AINEKSEN_MYYNTI: null,
  PERINNOSTA_LUOPUMINEN: null,
  PERINTOOSUUDEN_LUOVUTUS: null,
  SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA: null,
  AVIOERO_OSITUS_TAI_EROTTELU: null,
  KV_ASIA: null,
})
export type LupaSpecifierTypeId =
  (typeof LupaSpecifierType)[keyof typeof LupaSpecifierType]

export const commonLupaSpecifiers = fp.pick(['KV_ASIA'], LupaSpecifierType)

export const muuLupaSpecifiers = {
  ...commonLupaSpecifiers,
  ...fp.pick(
    [
      'KIINTEISTON_HANKKIMINEN',
      'ASUNNON_HANKKIMINEN',
      'KIINTEISTON_VUOKRAAMINEN',
      'ASUNNON_VUOKRAAMINEN',
      'ASUMISOIKEUDEN_HANKKIMINEN',
      'ASUMISOIKEUDEN_VUOKRAAMINEN',
      'LAINAN_OTTAMINEN',
      'PANTTAUS',
      'TAKAUS',
      'ELINKEINON_HARJOITTAMINEN',
      'YHTIOMIEHEKSI_RYHTYMINEN',
      'LAINAN_ANTAMINEN',
      'SIJOITUSKOHTEIDEN_OSTAMINEN',
      'METSAN_TAI_MAA_AINEKSEN_MYYNTI',
      'PERINNOSTA_LUOPUMINEN',
      'PERINTOOSUUDEN_LUOVUTUS',
      'SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA',
      'AVIOERO_OSITUS_TAI_EROTTELU',
    ],
    LupaSpecifierType
  ),
}

export const myyntilupaSpecifiers = {
  ...commonLupaSpecifiers,
}
export const perinnonjakolupaSpecifiers = {
  ...commonLupaSpecifiers,
}

export const applicationTypeSpecifiers = {
  [LupaApplicationType.MUU]: muuLupaSpecifiers,
  [LupaApplicationType.MYYNTI]: myyntilupaSpecifiers,
  [LupaApplicationType.PERINNONJAKO]: perinnonjakolupaSpecifiers,
}

export const LupaVersion = keyMirror({
  V0: null,
  V1: null,
  V2: null,
  A1: null,
})
export type LupaVersionId = (typeof LupaVersion)[keyof typeof LupaVersion]

export const LupaStatus = keyMirror({
  SAAPUNUT_ODOTTAA_ASIANHALLINNAN_VASTAUSTA: null,
  AVOIN: null,
  RATKAISTU_ODOTTAA_ASIANHALLINNAN_VASTAUSTA: null,
  RATKAISTU: null,
})
export type LupaStatusId = (typeof LupaStatus)[keyof typeof LupaStatus]

export const PropertyType = keyMirror({
  ASUNTO: null,
  ASUMISOIKEUSASUNTO: null,
  KIINTEISTO: null,
})
export type PropertyTypeId = (typeof PropertyType)[keyof typeof PropertyType]

export const SaleProperty = {
  ...PropertyType,
  ...keyMirror({
    EI_VALITTU: null,
  }),
}

export type SalePropertyId = (typeof SaleProperty)[keyof typeof SaleProperty]

export const SendStatus = keyMirror({
  ODOTTAA_LAHETYSTA: null,
  LAHETETTY: null,
})

export type SendStatusId = (typeof SendStatus)[keyof typeof SendStatus]

export const ContainsEstate = keyMirror({
  LIITTYY_KUOLINPESA: null,
  EI_KUOLINPESAA: null,
  EI_VALITTU: null,
})
export type ContainsEstateId =
  (typeof ContainsEstate)[keyof typeof ContainsEstate]

export const LupaLinkType = keyMirror({
  YHDISTAMINEN: null,
  JAKAMINEN: null,
})
export type LupaLinkTypeId = (typeof LupaLinkType)[keyof typeof LupaLinkType]
