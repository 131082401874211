import React from 'react'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import {
  BulletList,
  CenteredWidthLimitedDiv,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const CookieInfoPage: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  return (
    <CenteredWidthLimitedDiv paddingX paddingY>
      <Heading variant="h1" smallScreen={!isTablet}>
        {t('evasteet')}
      </Heading>
      <Block mb={isTablet ? 'xl' : 'm'} />
      <ResponsiveBorderedContentBlock>
        <Heading variant="h2" smallScreen={!isTablet}>
          {t('mitenEvasteetToimivatEdunvalvonnanAsiointipalvelussa')}
        </Heading>
        <Block mb="m" />
        <Paragraph mb="m">
          <Text>{t('evasteInfoMitaEvasteetOvat')}</Text>
        </Paragraph>
        <Paragraph mb="m">
          <Text>
            {t('evasteInfoKaytammeAinoastaanValttamattomiaEvasteita')}
          </Text>
        </Paragraph>
        <Paragraph mb="m">
          <Text>{t('evasteInfoEvasteidenLukumaara')}</Text>
        </Paragraph>
        <BulletList>
          <li>
            <Text>{t('evasteInfoBulletKirjautuminen')}</Text>
          </li>
          <li>
            <Text>{t('evasteInfoBulletKieli')}</Text>
          </li>
          <li>
            <Text>{t('evasteInfoBulletBanner')}</Text>
          </li>
        </BulletList>
        <Block mt="m" />
        <Paragraph>
          <Text>{t('jatkamallaPalvelunKayttoaHyvaksytEvasteidenKayton')}</Text>
        </Paragraph>
      </ResponsiveBorderedContentBlock>
    </CenteredWidthLimitedDiv>
  )
}

export default CookieInfoPage
