import React, { useEffect, useReducer, useMemo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { throttle, get, noop } from 'lodash'
import ms from 'ms'
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from 'suomifi-ui-components'
import {
  useGetLoginUri,
  useGetLogoutUri,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/login-redirect'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkSessionTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import * as Api from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/session/ui-session-api'
import {
  ASIOINTI_ROOT_ELEMENT_ID,
  SESSION_EXPIRED_SEARCH_PARAM,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { ApiErrorCode } from 'common/src/vtj/ApiErrorCode.enum'
import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import {
  SessionInfoResponse,
  SessionRenewalResponse,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/session/session-api.type'

type SessionModalState = {
  isExpiredVisible: boolean
  isAboutToExpireVisible: boolean
  isNonRenewableVisible: boolean
  isNonRenewableClosed: boolean
}

declare global {
  interface Window {
    CSRF_TOKEN: string
  }
}

const SessionHandling: React.FC<{
  renewalThrottleTimeMs: number
  aboutToExpireTimeLeftMs: number
}> = observer((props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const loginUri = useGetLoginUri()
  const logoutUri = useGetLogoutUri()
  const onSessionAboutToExpire = useCallback((isRenewable: boolean) => {
    dispatch({ type: 'expiring', isRenewable })
  }, [])
  const onSessionExpired = useCallback(() => {
    window.location.assign(`/?${SESSION_EXPIRED_SEARCH_PARAM}`)
  }, [])
  const onSessionRestored = useCallback(() => {
    dispatch({ type: 'close' })
  }, [])

  const [sessionModals, dispatch] = useReducer(
    (
      prevState: SessionModalState,
      action:
        | { type: 'expiring'; isRenewable: boolean }
        | { type: 'close' }
        | { type: 'close-non-renewable' }
    ): SessionModalState => {
      switch (action.type) {
        case 'expiring':
          return {
            ...prevState,
            isAboutToExpireVisible: action.isRenewable,
            isNonRenewableVisible:
              !action.isRenewable && !prevState.isNonRenewableClosed,
          }
        case 'close':
          return {
            ...prevState,
            isExpiredVisible: false,
            isAboutToExpireVisible: false,
            isNonRenewableVisible: false,
          }
        case 'close-non-renewable':
          return {
            ...prevState,
            isExpiredVisible: false,
            isAboutToExpireVisible: false,
            isNonRenewableVisible: false,
            isNonRenewableClosed: true,
          }
      }
    },
    {
      isExpiredVisible: location.search.includes(SESSION_EXPIRED_SEARCH_PARAM),
      isAboutToExpireVisible: false,
      isNonRenewableVisible: false,
      isNonRenewableClosed: false,
    }
  )

  useSessionHandling({
    ...props,
    onSessionAboutToExpire,
    onSessionExpired,
    onSessionRestored,
  })

  if (sessionModals.isExpiredVisible) {
    const onLogIn = () => {
      window.location.assign(loginUri)
    }
    const removeSessionExpiredParamFromLocation = () => {
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete(SESSION_EXPIRED_SEARCH_PARAM)
      const search = searchParams.toString()
      const to = [
        location.pathname,
        search ? `?${search}` : '',
        location.hash,
      ].join('')
      navigate(to, { replace: true })
    }
    const onCancel = () => {
      dispatch({ type: 'close' })
      removeSessionExpiredParamFromLocation()
    }
    return <SessionExpiredModal onLogIn={onLogIn} onCancel={onCancel} />
  }

  if (sessionModals.isAboutToExpireVisible) {
    const onContinueSession = () => {
      dispatch({ type: 'close' })
    }
    const onLogOut = () => {
      window.location.assign(logoutUri)
    }
    return (
      <SessionAboutToExpireModal
        onContinueSession={onContinueSession}
        onLogOut={onLogOut}
      />
    )
  }

  if (sessionModals.isNonRenewableVisible) {
    const onClose = () => {
      dispatch({ type: 'close-non-renewable' })
    }
    const onLogOut = () => {
      window.location.assign(logoutUri)
    }
    return (
      <UnrenewableSessionAboutToExpireModal
        onClose={onClose}
        onLogOut={onLogOut}
      />
    )
  }

  return null
})

export default SessionHandling

export const sessionRenewalEvents = [
  'click',
  'keydown',
  'mousedown',
  'mousemove',
  'wheel',
  'touchstart',
  'touchmove',
] as const
const eventListenerOptions = {
  passive: true,
  capture: true,
} as const

const useSessionHandling = ({
  renewalThrottleTimeMs,
  aboutToExpireTimeLeftMs,
  onSessionExpired,
  onSessionAboutToExpire,
  onSessionRestored,
}: {
  renewalThrottleTimeMs: number
  aboutToExpireTimeLeftMs: number
  onSessionExpired: () => unknown
  onSessionAboutToExpire: (isSessionRenewable: boolean) => unknown
  onSessionRestored: () => unknown
}): void => {
  const { user } = useAsiointiUserStore()

  const { handleSessionRenewalEventThrottled, clearTimeouts } = useMemo(() => {
    // Initial values expiresAt, mustLogInAt doesn't matter much, as long now session is not expired right away,
    // and diff with "now" does exceed setTimeout max delay (https://developer.mozilla.org/en-US/docs/Web/API/setTimeout).
    let expiresAt = new Date(Date.now() + ms('10d'))
    let mustLogInAt = new Date(Date.now() + ms('11d'))
    let sessionExpirationTimeoutId = -1
    let sessionAboutToExpireTimeoutId = -1
    let currentSessionId: string | null = null

    const getTimeMsToSessionExpired = () => expiresAt.getTime() - Date.now()
    const getTimeMsToSessionAboutToExpire = () =>
      getTimeMsToSessionExpired() - aboutToExpireTimeLeftMs
    const getSessionRenewable = () =>
      mustLogInAt.getTime() > expiresAt.getTime()
    const isCsrfTokenError = (response: ApiResponse<SessionRenewalResponse>) =>
      response.status === 403 &&
      get(response, 'errorData.code') === ApiErrorCode.INVALID_CSRF_TOKEN

    const handleSessionRenewalEvent = async () => {
      const wasSessionAboutToExpire = getTimeMsToSessionAboutToExpire() <= 0
      const nextSessionId = crypto.randomUUID()

      const renewalResponse = await Api.renewSession(nextSessionId)

      if (isCsrfTokenError(renewalResponse)) {
        const sessionInfoResponse = await Api.getSessionInfo()
        handleSessionResponse(sessionInfoResponse, noop)
      } else {
        handleSessionResponse(renewalResponse, noop)
      }

      let wasRenewedByOtherClient: boolean
      if (renewalResponse.isOk) {
        wasRenewedByOtherClient =
          renewalResponse.data.previousExternalId !== currentSessionId
        currentSessionId = nextSessionId
      } else {
        wasRenewedByOtherClient = false
      }

      if (
        wasRenewedByOtherClient &&
        wasSessionAboutToExpire &&
        getTimeMsToSessionExpired() > 0
      ) {
        onSessionRestored()
      }
    }

    const handleSessionResponse = (
      response: ApiResponse<SessionRenewalResponse | SessionInfoResponse>,
      onError: () => unknown
    ) => {
      if (response.isOk) {
        expiresAt = response.data.expiresAt
        mustLogInAt = response.data.mustLogInAt
        window.CSRF_TOKEN = response.data.csrfToken
        setUpCallbackTimeouts()
      } else if (response.status === 401) {
        expiresAt = new Date()
        mustLogInAt = new Date()
        setUpCallbackTimeouts()
      } else {
        onError()
      }
    }

    const setUpCallbackTimeouts = () => {
      clearTimeouts()

      const timeMsToSessionExpired = getTimeMsToSessionExpired()
      if (timeMsToSessionExpired > 0) {
        sessionExpirationTimeoutId = window.setTimeout(async () => {
          // Check the session again. Another tab may have renewed the session.
          const response = await Api.getSessionInfo()
          handleSessionResponse(response, onSessionExpired)
          if (getTimeMsToSessionExpired() > 0) {
            onSessionRestored()
          }
        }, timeMsToSessionExpired)
      } else {
        return onSessionExpired()
      }

      const timeMsToSessionAboutToExpire = getTimeMsToSessionAboutToExpire()
      if (timeMsToSessionAboutToExpire > 0) {
        sessionAboutToExpireTimeoutId = window.setTimeout(async () => {
          // Check the session again. Another tab may have renewed or logged out the session.
          const response = await Api.getSessionInfo()
          handleSessionResponse(response, () =>
            onSessionAboutToExpire(getSessionRenewable())
          )
        }, timeMsToSessionAboutToExpire)
      } else {
        return onSessionAboutToExpire(getSessionRenewable())
      }
    }

    const clearTimeouts = () => {
      window.clearTimeout(sessionExpirationTimeoutId)
      window.clearTimeout(sessionAboutToExpireTimeoutId)
    }

    const handleSessionRenewalEventThrottled = throttle(
      handleSessionRenewalEvent,
      renewalThrottleTimeMs,
      {
        leading: true,
        trailing: false,
      }
    )

    return {
      handleSessionRenewalEventThrottled,
      clearTimeouts: clearTimeouts,
    }
  }, [
    aboutToExpireTimeLeftMs,
    renewalThrottleTimeMs,
    onSessionAboutToExpire,
    onSessionExpired,
    onSessionRestored,
  ])

  useEffect(() => {
    if (user) {
      void handleSessionRenewalEventThrottled()
      sessionRenewalEvents.forEach((event) =>
        window.document.addEventListener(
          event,
          handleSessionRenewalEventThrottled,
          eventListenerOptions
        )
      )
    }

    return () => {
      clearTimeouts()
      sessionRenewalEvents.forEach((event) =>
        window.document.removeEventListener(
          event,
          handleSessionRenewalEventThrottled,
          eventListenerOptions
        )
      )
    }
  }, [clearTimeouts, handleSessionRenewalEventThrottled, user])
}

// By default, suomifi-ui-components modal sets focus on its modal title, which a screen reader reads,
// and when closed, restores focus on the element that had the focus before opening.
// This is OK for these modals.

const SessionAboutToExpireModal: React.FC<{
  onContinueSession: () => unknown
  onLogOut: () => unknown
}> = ({ onContinueSession, onLogOut }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  return (
    <Modal
      visible
      appElementId={ASIOINTI_ROOT_ELEMENT_ID}
      scrollable={false}
      variant={isTablet ? 'default' : 'smallScreen'}
    >
      <ModalContent data-test-id={mkSessionTestId('vanhenee-pian-modaali')}>
        <ModalTitle>{t('istuntoVanheneePian')}</ModalTitle>
        {t('sinutKirjataanUlosPian')} {t('haluatkoJatkaaIstuntoa')}
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={onContinueSession}
          data-test-id={mkSessionTestId('jatka-painike')}
        >
          {t('jatka')}
        </Button>
        <Button
          onClick={onLogOut}
          variant="secondary"
          data-test-id={mkSessionTestId('kirjaudu-ulos-painike')}
          role="link"
        >
          {t('logoutLabel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const UnrenewableSessionAboutToExpireModal: React.FC<{
  onClose: () => unknown
  onLogOut: () => unknown
}> = ({ onClose, onLogOut }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  return (
    <Modal
      visible
      appElementId={ASIOINTI_ROOT_ELEMENT_ID}
      scrollable={false}
      variant={isTablet ? 'default' : 'smallScreen'}
    >
      <ModalContent
        data-test-id={mkSessionTestId('vanhenee-pian-lopullisesti-modaali')}
      >
        <ModalTitle>{t('etVoiEnaaJatkaaIstuntoa')}</ModalTitle>
        {t('sinutKirjataanUlosPian')}
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={onClose}
          data-test-id={mkSessionTestId('sulje-painike')}
        >
          {t('sulje')}
        </Button>
        <Button
          onClick={onLogOut}
          variant="secondary"
          data-test-id={mkSessionTestId('kirjaudu-ulos-painike')}
          role="link"
        >
          {t('logoutLabel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const SessionExpiredModal: React.FC<{
  onLogIn: () => unknown
  onCancel: () => unknown
}> = ({ onLogIn, onCancel }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  return (
    <Modal
      visible
      appElementId={ASIOINTI_ROOT_ELEMENT_ID}
      scrollable={false}
      variant={isTablet ? 'default' : 'smallScreen'}
    >
      <ModalContent data-test-id={mkSessionTestId('vanhentunut-modaali')}>
        <ModalTitle>{t('istuntoOnVanhentunut')}</ModalTitle>
        {t('jatkaPalvelunKayttoaKirjautumallaUudelleen')}
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={onLogIn}
          data-test-id={mkSessionTestId('tunnistaudu-painike')}
          role="link"
        >
          {t('loginButtonLabel')}
        </Button>
        <Button
          onClick={onCancel}
          variant="secondary"
          data-test-id={mkSessionTestId('sulje-painike')}
        >
          {t('sulje')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
