import {
  AsiakirjaValidationError,
  FieldValidationError,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { TFunction } from 'react-i18next'
import { LupaAsiointiAsiakirjaTypeId } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { resolveStepByPath } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { getStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'

interface FieldValidationErrorContainer {
  validationErrors: FieldValidationError[]
}

interface AsiakirjaValidationErrorContainer {
  validationErrors: AsiakirjaValidationError[]
}

const findFieldValidationError = <T extends FieldValidationErrorContainer>(
  { validationErrors }: T,
  field: keyof T
): FieldValidationError | undefined =>
  validationErrors.find((validationError) => validationError.field === field)

export const resolveFieldStatusProps = <
  T extends { validationErrors: FieldValidationError[] }
>(
  batch: T,
  field: keyof T,
  t: TFunction
): { status: 'default' | 'error'; statusText: string } => {
  const currentStep = resolveStepByPath(location.pathname)
  const { showValidationErrorsForSteps, validationFailedForSteps } = getStore()

  const fieldError =
    showValidationErrorsForSteps.has(currentStep) &&
    validationFailedForSteps.has(currentStep) &&
    findFieldValidationError(batch, field)
  if (fieldError) {
    return {
      status: 'error',
      statusText: resolveFieldErrorText(batch, field, t),
    }
  } else {
    return {
      status: 'default',
      statusText: '',
    }
  }
}

const resolveFieldErrorText = <T extends FieldValidationErrorContainer>(
  container: T,
  field: keyof T,
  t: TFunction
): string => {
  const validationError = findFieldValidationError(container, field)
  if (validationError) {
    if (validationError.translationParams) {
      return t(
        validationError.translationKey,
        validationError.translationParams
      )
    } else {
      return t(validationError.translationKey)
    }
  } else {
    return '' // no error
  }
}

export const resolveAsiakirjaValidationErrorText = <
  T extends AsiakirjaValidationErrorContainer
>(
  container: T,
  asiakirjaTypeId: LupaAsiointiAsiakirjaTypeId,
  t: TFunction
): string => {
  const currentStep = resolveStepByPath(location.pathname)
  const { showValidationErrorsForSteps, validationFailedForSteps } = getStore()

  const asiakirjaError =
    showValidationErrorsForSteps.has(currentStep) &&
    validationFailedForSteps.has(currentStep) &&
    container.validationErrors.find(
      (validationError) => validationError.asiakirjaTypeId === asiakirjaTypeId
    )
  if (asiakirjaError) {
    return t(asiakirjaError.translationKey)
  } else {
    return ''
  }
}
