import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTili,
  getTilirivit,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  AsiointiType,
  FrontIrtaimisto,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import {
  IsoDatetimeToUiDate,
  useValittuEvtvVaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const IrtaimistotForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontIrtaimisto =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountAssetsType.IRTAIMISTO,
      nimi: '',
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('irtaimistot-form')}>
      <Heading variant="h3">{t('irtaimistotHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaIrtaimistot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={IrtaimistoListItem}
        ModalContentComponent={IrtaimistoModal}
        state={omaisuus.varat.irtaimistot}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VARAT}
        dataTestIdPrefix={mkOmaisuusTestId('irtaimistot-form')}
        newEntry={mkNewEntry}
        translationPrefix="irtaimistot"
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
}

const translationsByType = {
  OMAISUUSLUETTELO: {
    arvoLabel: 'arvoEuroina',
    asiakirjaInfo: 'asiakirjaIrtaimistot',
  },
  TILI: {
    arvoLabel: 'arvoTilikaudenLopussaEuroina',
    asiakirjaInfo: 'irtaimistotAsiakirjaInfo',
  },
}

const IrtaimistoModal: FC<FormModalProps<FrontIrtaimisto>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const evtv = useValittuEvtvVaatimus()
    const { asiaType } = getTili()
    const isOmaisuusluettelo = asiaType === AsiointiType.OMAISUUSLUETTELO
    const alkupvm = IsoDatetimeToUiDate(evtv.accountingPeriodStartDate)
    const translations =
      translationsByType[
        asiaType === 'OMAISUUSLUETTELO' ? 'OMAISUUSLUETTELO' : 'TILI'
      ]
    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-irtaimistonTyyppi')}
          labelText={t('irtaimistonTyyppi')}
          hintText={t('irtaimistonTyyppiHintText')}
          value={entry.nimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, nimi: value })}
        />
        {!isOmaisuusluettelo && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLabel)}
          hintText={
            isOmaisuusluettelo
              ? t('ilmoitaArvoTehtavanAlussa', { alkupvm })
              : undefined
          }
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t(translations.asiakirjaInfo)}
          title={t('asiakirjat')}
        />
      </div>
    )
  }
)

const IrtaimistoListItem: FC<FormElementProps<FrontIrtaimisto>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const isOmaisuusluettelo =
      getTili().asiaType === AsiointiType.OMAISUUSLUETTELO

    const arvoLopussaEur = entry.arvoTilikaudenLopussaEur
      ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
      : ''

    const arvoAlussaEur = entry.arvoTilikaudenAlussaEur
      ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
      : ''

    return (
      <FormListInputExpander
        heading={entry.nimi}
        headingRight={arvoLopussaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        {!isOmaisuusluettelo && (
          <>
            <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
            <Text>{arvoAlussaEur}</Text>
            <Block mt={verticalMarginToken} />
          </>
        )}

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(IrtaimistotForm)
