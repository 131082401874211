import { TFunction } from 'react-i18next'
import fp from 'lodash/fp'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getExpenseForAccountingPeriod } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/yhteenveto.util'
import { YhteenvetoGroupProps } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'

export const getKayttovaratContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { kayttovarat },
    },
  } = account

  return kayttovarat.map((kv) => {
    return {
      heading: t(`expenseType_${kv.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, kv.yhteensa)],
      attachments: kv.tositteet,
    }
  })
}

export const getElinkustannuksetContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { elinkustannukset },
    },
  } = account

  return elinkustannukset.map((ek) => {
    return {
      heading: t(`expenseType_${ek.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, ek.yhteensa)],
    }
  })
}

export const getPalvelumaksutContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { palvelumaksut },
    },
  } = account

  return palvelumaksut.map((pm) => {
    return {
      heading: t(`expenseType_${pm.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, pm.yhteensa)],
    }
  })
}

export const getVuokratVastikkeetContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { vuokratVastikkeet },
    },
  } = account

  return vuokratVastikkeet.map((vv) => {
    return {
      heading: t(`expenseType_${vv.tyyppi}`),
      rows: [
        fp.flow(fp.compact, fp.join(', '))([vv.nimi, vv.tunnus]),
        getExpenseForAccountingPeriod(t, vv.yhteensa),
      ],
    }
  })
}

export const getElatusavutContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { elatusavut },
    },
  } = account

  return elatusavut.map((ea) => {
    return {
      heading: t(`expenseType_${ea.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, ea.yhteensa)],
    }
  })
}

export const getPankinPerimatMaksutContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { pankinPerimatMaksut },
    },
  } = account

  return pankinPerimatMaksut.map((ppm) => {
    return {
      heading: t(`expenseType_${ppm.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, ppm.yhteensa)],
    }
  })
}

export const getVerotContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { verot },
    },
  } = account

  return verot.map((v) => {
    return {
      heading: t(`expenseType_${v.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, v.yhteensa)],
    }
  })
}

export const getTyonantajakulutContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { tyonantajakulut },
    },
  } = account

  return tyonantajakulut.map((tak) => {
    return {
      heading: t(`expenseType_${tak.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, tak.yhteensa)],
    }
  })
}

export const getVelanhoitomenotContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { velanhoitomenot },
    },
  } = account

  return velanhoitomenot.map((vhm) => {
    return {
      heading: t(`expenseType_${vhm.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, vhm.yhteensa)],
    }
  })
}

export const getEdunvalvonnanKulutContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { edunvalvonnankulut },
    },
  } = account

  return edunvalvonnankulut.map((ek) => {
    return {
      heading: t(`expenseType_${ek.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, ek.yhteensa)],
      attachments: ek.tositteet,
    }
  })
}

export const getMuutMenotContent = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      menot: { muutMenot },
    },
  } = account

  return muutMenot.map((mm) => {
    return {
      heading: t(`expenseType_${mm.tyyppi}`),
      rows: [getExpenseForAccountingPeriod(t, mm.yhteensa)],
      attachments: mm.tositteet,
    }
  })
}
