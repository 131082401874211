import React, { ForwardedRef, HTMLProps } from 'react'
import { LoadingSpinner } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'

const PageLoading: React.FC<
  { text?: string; 'data-test-id'?: string } & Omit<
    HTMLProps<HTMLDivElement>,
    'as'
  > & { ref?: ForwardedRef<HTMLDivElement> }
> = ({ text, ...rest }) => {
  const [t] = useTranslation()

  return (
    <CenteredWidthLimitedDiv paddingX paddingY {...rest} aria-live="polite">
      <LoadingSpinner text={text ?? t('ladataan')} textAlign="right" />
    </CenteredWidthLimitedDiv>
  )
}

export default PageLoading
