import {
  Block,
  Button,
  IconArrowLeft,
  IconArrowRight,
  IconRemove,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import React, { useState } from 'react'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { observer } from 'mobx-react'
import { mkAlasivuNavigationTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  TilintarkastusRoutePath,
  currentNavigationSteps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { useFormContext } from 'react-hook-form'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import {
  getTilintarkastusStore,
  saveDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import styled from 'styled-components'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import AbortDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/AbortDialog'
import SaveDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/SaveDialog'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import { useValittuHenkilo } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'

const FormNavigationBar = observer(
  ({ onErrorRef }: { onErrorRef: React.RefObject<HTMLDivElement> }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const navigate = useNavigate()
    const { handleSubmit } = useFormContext()
    const henkilo = useValittuHenkilo()
    const openFrontPage = () =>
      navigate(getAsioiPathByPersonId(henkilo?.personId))
    const [isAbortDialogVisible, setAbortDialogVisible] = useState(false)
    const [isSaveDialogVisible, setSaveDialogVisible] = useState(false)
    const { asiaType } = getTili()
    const { excludeSteps } = getTilintarkastusStore()
    const location = useLocation()

    const { previous, next } = currentNavigationSteps(
      asiaType,
      excludeSteps,
      location.pathname as TilintarkastusRoutePath
    )

    const onAbort = () => {
      setAbortDialogVisible(false)
      openFrontPage()
    }

    const onSaveAndExit = () => {
      void saveDraft()
      setSaveDialogVisible(false)
      openFrontPage()
    }

    return (
      <Block
        mt={verticalMarginToken}
        data-test-id={mkAlasivuNavigationTestId('container')}
      >
        <AbortDialog
          visible={isAbortDialogVisible}
          onAbort={onAbort}
          onClose={() => {
            setAbortDialogVisible(false)
          }}
        />
        <SaveDialog
          visible={isSaveDialogVisible}
          onSave={onSaveAndExit}
          onClose={() => {
            setSaveDialogVisible(false)
          }}
        />
        <NavButtonContainer>
          {isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS) ? (
            <NavButton
              onClick={() => setSaveDialogVisible(true)}
              variant="secondary"
            >
              {t('tallennaJaPalaaEtusivulle')}
            </NavButton>
          ) : (
            <NavButton
              data-test-id={mkAlasivuNavigationTestId('abort-button')}
              variant="secondaryLight"
              icon={<IconRemove />}
              onClick={() => setAbortDialogVisible(true)}
            >
              {t('keskeyta')}
            </NavButton>
          )}

          <NavButtonGroupContainer>
            {previous && (
              <NavButton
                data-test-id={mkAlasivuNavigationTestId(
                  'previous-step-link-button'
                )}
                variant="secondary"
                icon={<IconArrowLeft />}
                onClick={() => {
                  void saveDraft()
                  navigate(previous)
                }}
              >
                {t('previous')}
              </NavButton>
            )}
            <Block ml="xs" />
            {next && (
              <NavButton
                data-test-id={mkAlasivuNavigationTestId(
                  'next-step-link-button'
                )}
                iconRight={<IconArrowRight />}
                onClick={async () =>
                  handleSubmit(
                    () => {
                      void saveDraft()
                      navigate(next)
                    },
                    () => {
                      setTimeout(() => onErrorRef?.current?.focus())
                    }
                  )()
                }
              >
                {t('next')}
              </NavButton>
            )}
          </NavButtonGroupContainer>
        </NavButtonContainer>
      </Block>
    )
  }
)

export default FormNavigationBar

export const NavButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${suomifiDesignTokens.spacing.xs};
`
export const NavButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const NavButton = styled(Button)`
  padding: ${suomifiDesignTokens.spacing.xs};
  min-width: 80px;

  @media screen and ${device.tablet} {
    padding-inline: ${suomifiDesignTokens.spacing.m};
  }
`
