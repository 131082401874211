import { Block, Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import {
  OMAISUUSLUETTELO_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import SaannollisetTulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/SaannollisetTulotForm'
import VerotiedotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/VerotiedotForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import AlaikainenElinkustannusForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/AlaikainenElinkustannusForm'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { AsiointiElaketyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const ElakkeetJaEtuudetPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const onErrorRef = useRef<HTMLDivElement>(null)

  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const { isPrincipalUnderaged } = getTilintarkastusStore()
  const { saannollisetTulot } = getTili()

  const showAlaikainenElinkustannuksetForm = !!(
    isPrincipalUnderaged &&
    saannollisetTulot.find(
      ({ tyyppi }) => tyyppi && AsiointiElaketyyppi.includes(tyyppi)
    )
  )

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading
          heading={t('elakkeetJaEtuudet')}
          steps={OMAISUUSLUETTELO_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <Paragraph>{t('elakkeetEtuudetPageInfo1')}</Paragraph>
        <Paragraph mt="s">{t('elakkeetEtuudetPageInfo2')}</Paragraph>
        <Paragraph mt="s">{t('elakkeetEtuudetPageInfo3')}</Paragraph>

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <SaannollisetTulotForm />
        <Block mt={verticalMarginToken} />
        {showAlaikainenElinkustannuksetForm && <AlaikainenElinkustannusForm />}

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <VerotiedotForm />

        <DividerLine mt={verticalMarginToken} />
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default ElakkeetJaEtuudetPage
