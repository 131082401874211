import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  AsiointiType,
  TiliData,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getHallintaoikeudet = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    isHallintaoikeuksia,
    mitenHallintaoikeuksistaOnSovittu,
    hallintaoikeusSopimukset,
  } = account

  const isOmaisuusluettelo = account.asiaType === AsiointiType.OMAISUUSLUETTELO

  const content: YhteenvetoGroupProps[] = [
    {
      heading: t(
        isOmaisuusluettelo
          ? 'onkoHallintaoikeusiaToisenOmaisuuteen'
          : 'onkoOllutSovittujaHallintaoikeuksia'
      ),
      rows: [
        isHallintaoikeuksia !== undefined
          ? isHallintaoikeuksia
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
    },
  ]

  if (isHallintaoikeuksia) {
    content.push({
      heading: t('mitenHallintaoikeuksistaOnSovittu'),
      rows: [mitenHallintaoikeuksistaOnSovittu],
      attachments: hallintaoikeusSopimukset,
    })
  }

  return content
}

const HallintaOikeudet: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section data-test-id={mkYhteenvetoTestId('hallintaoikeudet-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('hallintaoikeudet')}
      </Heading>
      <YhteenvetoGroups groups={getHallintaoikeudet(tili, t)} />
    </section>
  )
})

export default HallintaOikeudet
