/**
 * Please note before modifying this file:
 * one should follow suomifi guidelines for ui date formats:
 * https://wiki.dvv.fi/x/zqwsAQ
 */

import moment from 'moment'
import { tz } from 'moment-timezone'
export const DATE_UI_FORMAT = 'D.M.YYYY'
export const TIME_UI_FORMAT = 'HH:mm'
export const TIMESTAMP_FORMAT = `${DATE_UI_FORMAT} ${TIME_UI_FORMAT}`
export const TIMESTAMP_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
export const PV_FORMAT = 'YYYYMMDD'
export const PV_FORMAT_DATE_FNS = 'yyyyMMdd'
export const DATE_FORMAT = 'YYYY-MM-DD'

export const TIMEZONE = 'Europe/Helsinki'

export function formatTimestamp(timestamp) {
  return moment(timestamp).format(TIMESTAMP_FORMAT)
}

export function formatTimestampToUiDate(timestamp) {
  return moment(timestamp).format(DATE_UI_FORMAT)
}

export function formatTimestampToUiHour(timestamp) {
  return moment(timestamp).format(TIME_UI_FORMAT)
}

export function formatDate(timestamp) {
  return moment(timestamp).format(DATE_FORMAT)
}

export function nowAsDate() {
  return new Date(Date.now())
}

export function parseDate(dateString) {
  return tz(dateString, DATE_FORMAT, true, TIMEZONE)
}
