import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkMuutVaratTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import React from 'react'
import { runInAction } from 'mobx'
import { getOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/omaisuusluettelo.store'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'

export const MuutVaratForm = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const tili = getOmaisuusluettelo()

  return (
    <section data-test-id={mkMuutVaratTestId('container')}>
      <Heading mb="s" variant="h3">
        {t('muutOmassaKaytossaOlevatVarat')}
      </Heading>
      <Paragraph mb={verticalMarginToken}>{t('ohjeMuutOmatVarat')}</Paragraph>

      <FormRadioButtonGroup
        required
        value={tili?.paamiehenOmaisuus?.isMuutVarat?.toString()}
        data-test-id={mkMuutVaratTestId('saako-radio')}
        labelText={t('saakoHenkiloOmaanKayttoonsaMuitaVaroja')}
        updateValue={(value) => {
          runInAction(() => {
            tili.paamiehenOmaisuus.isMuutVarat = value === 'true'
          })
        }}
        radioButtons={[
          { labelText: t('ei'), value: 'false' },
          { labelText: t('kylla'), value: 'true' },
        ]}
      />

      {tili.paamiehenOmaisuus.isMuutVarat && (
        <Block mt={verticalMarginToken}>
          <FormTextArea
            data-test-id={mkMuutVaratTestId('input')}
            labelText={t('mitaMuitaVarojaHenkiloSaaOmaanKayttoon')}
            maxLength={1000}
            required={tili.paamiehenOmaisuus.isMuutVarat}
            value={tili.paamiehenOmaisuus.muutVarat}
            validate={commonXssValidator(t)}
            updateValue={(value) => {
              runInAction(() => {
                tili.paamiehenOmaisuus.muutVarat = value
              })
            }}
          />
        </Block>
      )}
      {tili.paamiehenOmaisuus.isMuutVarat === false &&
        !!tili.paamiehenOmaisuus.muutVarat && (
          <InlineAlert mt={'s'} status={'warning'}>
            {t('valittuEiInlineAlert')}
          </InlineAlert>
        )}
    </section>
  )
})

export default MuutVaratForm
