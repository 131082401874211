import React from 'react'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Paragraph,
  Text,
} from 'suomifi-ui-components'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkKeskeytaHakemusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const AbortDialog = observer(
  ({
    visible,
    onAbort,
    onClose,
  }: {
    visible: boolean
    onAbort: () => void
    onClose: () => void
  }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet

    return (
      <Modal
        visible={visible}
        appElementId={ASIOINTI_ROOT_ELEMENT_ID}
        variant={isTablet ? 'default' : 'smallScreen'}
        onEscKeyDown={onClose}
      >
        <ModalContent>
          <ModalTitle>{t('keskeytaPaatostilinTeko')}</ModalTitle>
          <Paragraph>
            <Text>{t('keskeytettyaPaatostiliaEiTallenneta')}</Text>
          </Paragraph>
          <Block mt="s" />
          <Paragraph>
            <Text>{t('haluatkoKeskeyttaaPaatostilinTeon')}</Text>
          </Paragraph>
        </ModalContent>
        <ModalFooter>
          <Button
            data-test-id={mkKeskeytaHakemusTestId('keskeyta-painike')}
            onClick={onAbort}
          >
            {t('keskeyta')}
          </Button>
          <Button
            data-test-id={mkKeskeytaHakemusTestId('peruuta-keskeytys-painike')}
            onClick={onClose}
            variant="secondary"
          >
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

export default AbortDialog
