import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

// FIXME: https://jira.dvv.fi/browse/OIVA-5161
//  * Remove PYSYVA
export const elamantapahtumat4VuottaRetentionFIXME: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_4_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_4_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

// FIXME: https://jira.dvv.fi/browse/OIVA-5161
//  * Remove PYSYVA
export const elamantapahtumat20VuottaRetentionFIXME: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_20_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_20_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const elamantapahtumat50VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_50_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_50_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

// FIXME: https://jira.dvv.fi/browse/OIVA-5161
//  * Remove PYSYVA
export const elamantapahtumat50VuottaRetentionFIXME: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_50_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_50_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const elamantapahtumatPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
  retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
}

export const elamantapahtumatJulkinenHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

// FIXME: https://jira.dvv.fi/browse/OIVA-5161
//  * Remove JULKINEN
export const elamantapahtumatJulkinenOsittainSalassaPidettava50VuottaK25HenkilotietojaRestrictionFIXME: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.JULKINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      },
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

// FIXME: https://jira.dvv.fi/browse/OIVA-5161
//  * Remove SALAINEN
export const elamantapahtumatJulkinenSalassaPidettava100VuottaK32HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
    },
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

// FIXME: https://jira.dvv.fi/browse/OIVA-5161
//  * Remove SALAINEN
export const elamantapahtumaOsittainSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestrictionFIXME: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
    publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA, PublicityClass.SALAINEN],
    publicityMetadata: {
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      },
      [PublicityClass.SALAINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const elamantapahtumatSalassaPidettava100VuottaK25K32HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}
