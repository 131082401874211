import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import { mkVarat } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import PankkiTalletuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/PankkiTalletuksetForm'
import ArvopaperitForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/ArvopaperitForm'
import KuolinpesaOsakkuudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/KuolinpesaOsakkuudetForm'
import OsuudetYhtioissaYrityksissaForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/OsuudetYhtioissaYrityksissaForm'
import HuoneistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/HuoneistotForm'
import KiinteistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/KiinteistotForm'
import IrtaimistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/IrtaimistotForm'
import SaatavatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/SaatavatForm'
import MuutVaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/MuutVaratForm'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { OMAISUUSLUETTELO_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const VaratPage: React.FC<{
  form: UseFormReturn<FieldValues, unknown>
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <section data-test-id={mkVarat()}>
          <PageStepHeading
            heading={t('varat')}
            steps={OMAISUUSLUETTELO_STEPS}
          />
          <FormErrorSummary ref={onErrorRef} />

          <Paragraph>{t('varatOlInfo1')}</Paragraph>
          <Paragraph mt="s">{t('varatOlInfo2')}</Paragraph>
          <Paragraph mt="s">{t('varatOlInfo3')}</Paragraph>
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <PankkiTalletuksetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <ArvopaperitForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <OsuudetYhtioissaYrityksissaForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <HuoneistotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <KiinteistotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <IrtaimistotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <SaatavatForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <MuutVaratForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <KuolinpesaOsakkuudetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        </section>
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default VaratPage
