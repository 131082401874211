import { AsiointiApplication } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { runAsiointiStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import { validateBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/validation-actions'

export const setApplicationValues = (
  lupaTypeId: AsiointiApplication['typeId'],
  values: Partial<Pick<AsiointiApplication, 'containsEstate'>>
): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    store.batch.applications.forEach((a) => {
      if (a.typeId === lupaTypeId) {
        Object.assign(a, { ...values })
      }
    })
    yield validateBatch()
  })
