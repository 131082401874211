import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Chip,
  suomifiDesignTokens,
  Text,
  Paragraph,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AsiointiLupaTypeId } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import { sortBy } from 'lodash'
import { asiointiLupaInfos } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import { mkLupaModalTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const LupaTypeModal = observer(
  ({
    visible,
    lupaTypeIds,
    onRemoveLupaType,
    onChangeLupaType,
    onClose,
  }: {
    visible: boolean
    lupaTypeIds: AsiointiLupaTypeId[]
    onRemoveLupaType: (lupaTypeId: AsiointiLupaTypeId) => unknown
    onChangeLupaType: () => unknown
    onClose: () => unknown
  }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const lupaTypeListRef = useRef<HTMLUListElement>(null)
    const changeLupaTypeBtnRef = useRef<HTMLButtonElement>(null)

    const infoEntries = Object.entries(asiointiLupaInfos)
    const sortedLupaTypeIds = sortBy(lupaTypeIds, (lupaTypeId) =>
      infoEntries.findIndex((entry) => entry[0] === lupaTypeId)
    )

    const handleRemove = (lupaTypeId: AsiointiLupaTypeId) => {
      onRemoveLupaType(lupaTypeId)
      if (lupaTypeIds.length === 1) {
        setTimeout(() => {
          changeLupaTypeBtnRef.current && changeLupaTypeBtnRef.current.focus()
        })
      }
    }

    return (
      <Modal
        visible={visible}
        appElementId={ASIOINTI_ROOT_ELEMENT_ID}
        onEscKeyDown={lupaTypeIds.length === 0 ? onChangeLupaType : onClose}
        scrollable={false}
        variant={isTablet ? 'default' : 'smallScreen'}
        focusOnOpenRef={lupaTypeListRef}
      >
        <ModalContent>
          <ModalTitle aria-live="polite" aria-atomic="true">
            {t('hakemukselleLisatytLupaAsiat')} ({lupaTypeIds.length})
          </ModalTitle>
          <LupaTypeList
            tabIndex={-1}
            ref={lupaTypeListRef}
            data-test-id={mkLupaModalTestId('lupatyyppilista')}
          >
            {sortedLupaTypeIds.map((lupaTypeId) => (
              <li key={lupaTypeId}>
                <Chip
                  actionLabel={t('poista')}
                  removable
                  onClick={() => handleRemove(lupaTypeId)}
                  data-test-id={mkLupaModalTestId(
                    'lupatyyppi',
                    lupaTypeId,
                    'chip'
                  )}
                >
                  {t(`asiointiLupaType-${lupaTypeId}`)}
                </Chip>
              </li>
            ))}
          </LupaTypeList>
          {lupaTypeIds.length === 0 && (
            <Paragraph mb="xxs">
              <Text
                data-test-id={mkLupaModalTestId('ei-valittuja-lupia-label')}
              >
                {t('eiValittujaLupaAsioita')}
              </Text>
            </Paragraph>
          )}
        </ModalContent>
        <ModalFooter>
          {lupaTypeIds.length === 0 && (
            <Button
              onClick={onChangeLupaType}
              ref={changeLupaTypeBtnRef}
              data-test-id={mkLupaModalTestId('vaihda-lupaa-painike')}
            >
              {t('siirryLupaValintaan')}
            </Button>
          )}
          {lupaTypeIds.length > 0 && (
            <Button
              onClick={onClose}
              variant="secondary"
              data-test-id={mkLupaModalTestId('sulje-painike')}
            >
              {t('sulje')}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }
)

export default LupaTypeModal

const LupaTypeList = styled.ul`
  > li {
    display: inline-block;
    margin: 0 ${suomifiDesignTokens.spacing.xs}
      ${suomifiDesignTokens.spacing.xs} 0;
  }
`
