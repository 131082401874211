import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  PAATOSTILI_ROUTES,
  PAATOSTILI_STEPS,
  PaatostiliFormStates,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { FormSubmitBar } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSubmitBar'
import { useForm, FormProvider } from 'react-hook-form'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/Perustiedot'
import FormUncompletedStepsSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormUncompletedStepsSummary'
import Yhteenveto from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/Yhteenveto'

const YhteenvetoPage: React.FC<{
  forms: PaatostiliFormStates
}> = observer(({ forms }) => {
  const [t] = useTranslation()

  const juuriForm = useForm()

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <FormProvider {...juuriForm}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkYhteenvetoTestId('page-container')}
        >
          <PageStepHeading
            heading={t('yhteenvetoJaLahetys')}
            steps={PAATOSTILI_STEPS}
          />
          <FormUncompletedStepsSummary forms={forms} ref={onErrorRef} />
          <DividerLine mt={verticalMarginToken} />
          <Perustiedot showValtakirja />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <Yhteenveto />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <FormSubmitBar
            forms={forms}
            next={PAATOSTILI_ROUTES.VALMIS}
            onErrorRef={onErrorRef}
          />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default YhteenvetoPage
