import React from 'react'
import {
  AsiointiApplication,
  AsiointiBatch,
  AsiointiPerson,
  FieldValidationError,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { LupaApplicationRole } from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { LupaAsiointiAsiakirjaType } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import {
  Block,
  InlineAlert,
  Paragraph,
  Text,
  Link,
} from 'suomifi-ui-components'
import styled from 'styled-components'
import {
  mkHakemusTestId,
  mkLupaAsiointiPersonFieldTestId,
  mkLupaAttachmentTestId,
  mkValidationErrorsSummaryTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  LupaApplicationStep,
  pathByViewId,
  resolveStepByPath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { runAsiointiStoreAction } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { useValidationErrorFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/validation-error-focus.util'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'

const ErrorsBulletedList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`

const FocusLink: React.FC<{
  focusStep: LupaApplicationStep
  focusToId: string
  displayText: string
  'data-test-id': string
}> = observer((props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const currentStep = resolveStepByPath(pathname)
  return (
    <Link
      data-test-id={props['data-test-id']}
      smallScreen
      href="#"
      onClick={(event) => {
        event.preventDefault()
        setTimeout(() => {
          runAsiointiStoreAction((store) => {
            if (currentStep !== props.focusStep) {
              navigate(pathByViewId[props.focusStep])
            }
            store.validationErrorFocus = props.focusToId
          })
        })
      }}
    >
      {props.displayText}
    </Link>
  )
})

const BatchErrors: React.FC<{ validationErrors: FieldValidationError[] }> =
  observer(({ validationErrors }) => {
    const [t] = useTranslation()
    return (
      <>
        {!!validationErrors.length && (
          <>
            <ErrorsBulletedList>
              {validationErrors.map((validationError) => (
                <li
                  key={`error-summary-for-batch-field-${validationError.field}`}
                >
                  {validationError.field === 'applications' && (
                    <FocusLink
                      focusStep={LupaApplicationStep.LUPA_TYPE_SELECT}
                      focusToId={mkHakemusTestId(
                        LupaApplicationStep.LUPA_TYPE_SELECT,
                        'current-step-container'
                      )}
                      displayText={t('lupaAsioidenValinta')}
                      data-test-id={mkValidationErrorsSummaryTestId(
                        'batch',
                        validationError.field
                      )}
                    />
                  )}
                  {validationError.field === 'description' && (
                    <FocusLink
                      focusStep={LupaApplicationStep.INPUT_INFO}
                      focusToId={mkHakemusTestId('description')}
                      displayText={t('kerroJaYksiloiToimenpide')}
                      data-test-id={mkValidationErrorsSummaryTestId(
                        'batch',
                        validationError.field
                      )}
                    />
                  )}
                  {validationError.field === 'arguments' && (
                    <FocusLink
                      focusStep={LupaApplicationStep.INPUT_INFO}
                      focusToId={mkHakemusTestId('arguments')}
                      displayText={t('kerroMiksiToimenpideOnEdunMukainen')}
                      data-test-id={mkValidationErrorsSummaryTestId(
                        'batch',
                        validationError.field
                      )}
                    />
                  )}
                </li>
              ))}
            </ErrorsBulletedList>
            <Block mt="m" />
          </>
        )}
      </>
    )
  })

const AsiointiPersonsErrors: React.FC<{ persons: AsiointiPerson[] }> = observer(
  ({ persons }) => {
    const [t] = useTranslation()
    return (
      <>
        {persons.map((person, index) => {
          const fieldToTranslation = (field: string) => {
            if (field === 'attachments') {
              if (person.applicationRoleId === LupaApplicationRole.HAKIJA) {
                return t(
                  `asiointiAsiakirjaType-${LupaAsiointiAsiakirjaType.VALTAKIRJA}`
                )
              } else if (
                person.applicationRoleId === LupaApplicationRole.PAAMIES &&
                person.opinionAttachmentAsiakirjaTypeId
              ) {
                return t(
                  `asiointiAsiakirjaType-${person.opinionAttachmentAsiakirjaTypeId}`
                )
              }
            } else {
              switch (field) {
                case 'firstnames':
                  return t('lupaPersonEtunimi')
                case 'lastname':
                  return t('lupaPersonSukunimi')
                case 'hetu':
                  return t('lupaPersonHetu')
                case 'phone':
                  return t('lupaPersonPuhelinnumero')
                case 'email':
                  return t('lupaPersonSahkoposti')
                case 'emailRepeated':
                  return t('sahkopostiUudelleen')
                case 'streetAddress':
                  return t('lupaPersonKatuosoite')
                case 'streetAddressExtra':
                  return t('lupaPersonOsoitteenLisatieto')
                case 'postalCode':
                  return t('lupaPersonPostinumero')
                case 'postOffice':
                  return t('lupaPersonPostitoimipaikka')
                case 'countryId':
                  return t('lupaPersonMaa')
                default:
                  // sensible default
                  return t('virheVirheellinenSyote')
              }
            }
          }

          if (person.validationErrors.length) {
            return (
              <React.Fragment key={`error-summary-for-${person.personId}`}>
                <Paragraph>
                  <Text smallScreen>{`${index + 1}. ${
                    person.applicationRoleId === LupaApplicationRole.HAKIJA
                      ? t('hakijanTiedot')
                      : t('paamiehenTiedot')
                  }`}</Text>
                </Paragraph>
                <ErrorsBulletedList>
                  {person.validationErrors.map((validationError) => {
                    const errorText = fieldToTranslation(validationError.field)
                    return (
                      errorText && (
                        <li
                          key={`error-summary-for-person-field-${validationError.field}`}
                        >
                          <FocusLink
                            focusStep={LupaApplicationStep.INPUT_PERSONS}
                            focusToId={mkLupaAsiointiPersonFieldTestId(
                              person.applicationRoleId,
                              index,
                              validationError.field
                            )}
                            displayText={errorText}
                            data-test-id={mkValidationErrorsSummaryTestId(
                              'person',
                              person.applicationRoleId,
                              index,
                              validationError.field
                            )}
                          />
                        </li>
                      )
                    )
                  })}
                </ErrorsBulletedList>
                <Block mt="m" />
              </React.Fragment>
            )
          }
        })}
      </>
    )
  }
)

const ApplicationsAsiakirjasErrors: React.FC<{
  applications: AsiointiApplication[]
}> = observer(({ applications }) => {
  const [t] = useTranslation()
  return (
    <>
      {applications.map((application) => {
        if (application.validationErrors.length) {
          return (
            <React.Fragment key={`error-summary-for-${application.typeId}`}>
              <Paragraph>
                <Text smallScreen>
                  {t(`asiointiLupaType-${application.typeId}`)}
                </Text>
              </Paragraph>
              <ErrorsBulletedList>
                {application.validationErrors.map(({ asiakirjaTypeId }) => {
                  return (
                    <li
                      key={`error-summary-for-${application.typeId}-${asiakirjaTypeId}`}
                    >
                      <FocusLink
                        focusStep={LupaApplicationStep.INPUT_INFO}
                        focusToId={mkLupaAttachmentTestId(
                          application.typeId,
                          asiakirjaTypeId
                        )}
                        displayText={t(
                          `asiointiAsiakirjaType-${asiakirjaTypeId}`
                        )}
                        data-test-id={mkValidationErrorsSummaryTestId(
                          'application',
                          application.typeId,
                          asiakirjaTypeId
                        )}
                      />
                    </li>
                  )
                })}
              </ErrorsBulletedList>
              <Block mt="m" />
            </React.Fragment>
          )
        }
      })}
    </>
  )
})

const ValidationErrorsSummary: React.FC<{
  batch: Partial<AsiointiBatch>
}> = observer(({ batch }) => {
  const [t] = useTranslation()
  const persons = batch.persons || []
  const hakijat = persons.filter(
    (person) => person.applicationRoleId === LupaApplicationRole.HAKIJA
  )
  const paamiehet = persons.filter(
    (person) => person.applicationRoleId === LupaApplicationRole.PAAMIES
  )

  return (
    <FocusableDiv
      tabIndex={-1}
      ref={useValidationErrorFocus<HTMLDivElement>(
        mkValidationErrorsSummaryTestId('container')
      )}
      data-test-id={mkValidationErrorsSummaryTestId('container')}
    >
      <InlineAlert status="error" labelText={t('tiedoissaPuutteita')}>
        <Paragraph>{t('tiedotPuuttuvatKohdista')}</Paragraph>
        <Block mt="m" />
        <BatchErrors validationErrors={batch.validationErrors || []} />
        <AsiointiPersonsErrors persons={hakijat} />
        <AsiointiPersonsErrors persons={paamiehet} />
        <ApplicationsAsiakirjasErrors applications={batch.applications || []} />
      </InlineAlert>
    </FocusableDiv>
  )
})

export default ValidationErrorsSummary
