import { flow, observable, when } from 'mobx'
import { MimeType } from 'common/src/vtj/MimeType.enum'
import { runAsiointiStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import { AsiointiLupaTypeId } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import {
  LupaAsiointiAttachment,
  AsiointiPerson,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { LupaAsiointiAsiakirjaTypeId } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import {
  pollFileChecks,
  stopPollingFileChecks,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/file/file-attachment-check'
import { validateBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/validation-actions'
import {
  AsiointiFileAttachment,
  AsiointiFileAttachmentError,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'
import { lupaAsiointiFileApi } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/file-api'
import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'

export function* handleNewAttachmentGenerator<T extends AsiointiFileAttachment>(
  observableAttachment: T,
  attachmentFile: File
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
): Generator<any, T, any> {
  const response: ApiResponse<void> = yield lupaAsiointiFileApi.uploadFile({
    sourceFileId: observableAttachment.sourceFileId,
    file: attachmentFile,
  })
  if (response.isOk) {
    observableAttachment.status = 'checking'
    pollFileChecks(lupaAsiointiFileApi, observableAttachment)
  } else {
    const status: AsiointiFileAttachment['status'] = 'failed'
    const error: AsiointiFileAttachmentError = 'upload_error'
    Object.assign(observableAttachment, { status, error })
  }
  return observableAttachment
}

type FileProps = {
  file: File
  asiakirjaTypeId: LupaAsiointiAsiakirjaTypeId
}

type FailedFileProps = FileProps & {
  status: 'failed'
  error: AsiointiFileAttachmentError
}

const addFailedFileInAttachments = (
  attachments: LupaAsiointiAttachment[],
  fileProps: FailedFileProps
): LupaAsiointiAttachment => {
  const observableAttachment = observable({
    sourceFileId: crypto.randomUUID(),
    mimeType: fileProps.file.type as MimeType,
    filename: fileProps.file.name,
    sizeBytes: fileProps.file.size,
    asiakirjaTypeId: fileProps.asiakirjaTypeId,
    status: fileProps.status,
    error: fileProps.error,
  })
  attachments.push(observableAttachment)
  return observableAttachment
}

export const uploadApplicationAttachment = (
  lupaTypeId: AsiointiLupaTypeId,
  { file, asiakirjaTypeId }: FileProps
): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    const application = store.batch.applications.find(
      (application) => application.typeId === lupaTypeId
    )
    if (!application) {
      throw new Error(`Application type "${lupaTypeId}" not found`)
    }

    const observableAttachment = observable({
      sourceFileId: crypto.randomUUID(),
      asiakirjaTypeId,
      mimeType: file.type as MimeType,
      filename: file.name,
      sizeBytes: file.size,
      status: 'loading',
    } as LupaAsiointiAttachment)
    application.attachments.push(observableAttachment)
    when(
      () =>
        observableAttachment.status === 'success' ||
        observableAttachment.status === 'failed',
      validateBatch
    )
    yield validateBatch()
    return yield flow(handleNewAttachmentGenerator)(observableAttachment, file)
  })

export const addFailedApplicationAttachment = (
  lupaTypeId: AsiointiLupaTypeId,
  fileProps: FileProps & {
    status: 'failed'
    error: AsiointiFileAttachmentError
  }
): Promise<void> =>
  runAsiointiStoreFlow(function* (store) {
    const application = store.batch.applications.find(
      (application) => application.typeId === lupaTypeId
    )
    if (!application) {
      throw new Error(`Application type "${lupaTypeId}" not found`)
    }
    addFailedFileInAttachments(application.attachments, fileProps)
    yield validateBatch()
  })

export const removeAttachment = (sourceFileId: string): Promise<void> =>
  runAsiointiStoreFlow(function* (store) {
    store.batch.applications.forEach((application) => {
      const index = application.attachments.findIndex(
        (attachment) => attachment.sourceFileId === sourceFileId
      )
      if (index > -1) {
        application.attachments.splice(index, 1)
      }
    })
    store.batch.persons.forEach((person) => {
      const index = person.attachments.findIndex(
        (attachment) => attachment.sourceFileId === sourceFileId
      )
      if (index > -1) {
        person.attachments.splice(index, 1)
      }
    })
    stopPollingFileChecks(sourceFileId)
    yield validateBatch()
  })

const findPersonOrError = (
  store: AsiointiStore,
  personId: AsiointiPerson['personId']
): AsiointiPerson => {
  const person = store.batch.persons.find(
    (person) => person.personId === personId
  )
  if (!person) {
    throw new Error(`Person with id "${personId}" not found`)
  }
  return person
}

export const uploadPersonAttachment = (
  personId: AsiointiPerson['personId'],
  { file, asiakirjaTypeId }: FileProps
): Promise<LupaAsiointiAttachment> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    const person = findPersonOrError(store, personId)
    const observableAttachment = observable({
      sourceFileId: crypto.randomUUID(),
      asiakirjaTypeId,
      mimeType: file.type as MimeType,
      filename: file.name,
      sizeBytes: file.size,
      status: 'loading',
    } as LupaAsiointiAttachment)
    person.attachments.push(observableAttachment)
    when(
      () =>
        observableAttachment.status === 'success' ||
        observableAttachment.status === 'failed',
      validateBatch
    )
    yield validateBatch()
    return yield flow(handleNewAttachmentGenerator)(observableAttachment, file)
  })

export const addFailedPersonAttachment = (
  personId: AsiointiPerson['personId'],
  fileProps: FileProps & {
    status: 'failed'
    error: AsiointiFileAttachmentError
  }
): Promise<void> =>
  runAsiointiStoreFlow(function* (store) {
    const person = findPersonOrError(store, personId)
    addFailedFileInAttachments(person.attachments, fileProps)
    yield validateBatch()
  })
