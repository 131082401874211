import { useEffect } from 'react'
import { throttle } from 'lodash'

export const useElementSizeObserver = (
  selector: string,
  callback: (dimensions: ResizeObserverEntry | null) => unknown,
  options: ResizeObserverOptions & { throttleWaitTime?: number } = {
    box: 'border-box',
    throttleWaitTime: 100,
  }
): void => {
  useEffect(() => {
    const element = window.document.querySelector(selector)

    const listener = throttle(callback, options.throttleWaitTime, {
      leading: true,
    })

    const resizeObserver = new ResizeObserver((entries) =>
      entries.forEach(listener)
    )

    if (element) {
      resizeObserver.observe(element, { box: options.box })
    } else {
      callback(null)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [selector, callback, options])
}
