import { AsiointiFileCheckResponse } from 'lupa-backend/src/vtj/asiointi/file/asiointi-file-api.type'

import { DEFAULT_UPLOAD_TIMEOUT } from 'common/src/vtj/browser/http'
import {
  ApiResponse,
  executeBackendClient,
} from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { AsiointiFileAttachment } from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'

export type AsiakirjaCreateData = { asiakirjaId: string }

export const uploadFile = async ({
  file,
  sourceFileId,
}: {
  file: File
  sourceFileId: string
}): Promise<ApiResponse<AsiakirjaCreateData>> => {
  return executeBackendClient((client) => {
    const form = new FormData()
    form.append('file', file)
    form.append('sourceFileId', sourceFileId)
    return client.post(`/asiointi/api/v1/file`, form, {
      timeout: DEFAULT_UPLOAD_TIMEOUT,
    })
  })
}

export const getValidationStatuses = async (
  attachments: AsiointiFileAttachment[]
): Promise<ApiResponse<AsiointiFileCheckResponse>> => {
  return executeBackendClient((client) =>
    client.post('/asiointi/api/v1/file/check', {
      asiakirjaIds: attachments
        .map(({ asiakirjaId }) => asiakirjaId)
        .filter(Boolean),
    })
  )
}

export const getDownloadUrl = (attachment: AsiointiFileAttachment): string => {
  return `/asiointi/api/v1/file/${
    attachment.asiakirjaId
  }/download/${encodeURIComponent(attachment.filename)}`
}
