import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import {
  DeviceContext,
  Devices,
  initialDeviceValues,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const DeviceContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const isTablet = useMediaQuery({ query: device.tablet })
  const isDesktop = useMediaQuery({ query: device.desktop })
  const [devices, setDevices] = useState<Devices>(initialDeviceValues)

  useEffect(
    () =>
      setDevices({
        tablet: isTablet,
        desktop: isDesktop,
      }),
    [isTablet, isDesktop]
  )

  return (
    <DeviceContext.Provider value={devices}>{children}</DeviceContext.Provider>
  )
}

export default DeviceContextProvider
