import { TFunction } from 'react-i18next'
import { AsiointiFileAttachmentError } from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'
import { ErrorCode, FileRejection } from 'react-dropzone'

export const getErrorMessage = (
  t: TFunction,
  error: AsiointiFileAttachmentError
): string => {
  switch (error) {
    case 'empty':
      return t('tiedostoOnTyhjaJaSitaEiLisatty')
    case 'too_large':
      return t('tiedostoOnLiianSuuriJaSitaEiLisatty')
    case 'invalid_type':
      return t('tiedostomuotoEiOleSallittu')
    case 'validation_not_passed':
      return t('tiedostoEiLapaissytTarkistusta')
    case 'upload_error':
    case 'validation_aborted':
      return t('liitettaEiLisattyHakemukselle')
  }
}

export const getError = (
  rejection: FileRejection
): AsiointiFileAttachmentError => {
  if (rejection.errors.length === 0) {
    return 'upload_error'
  }
  switch (rejection.errors[0].code) {
    case ErrorCode.FileInvalidType:
      return 'invalid_type'
    case ErrorCode.FileTooLarge:
      return 'too_large'
    case ErrorCode.FileTooSmall:
      return 'empty'
    default:
      return 'upload_error'
  }
}
