import { Tilirivi } from 'tilintarkastus-common/src/vtj/types/asiointi-account-rows.type'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountType,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { asiointiLedgerAccountMaps } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-all-maps'
import { AsiointiLedgerAccounts } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.type'

export type LedgerNumberInjectable = Pick<Tilirivi, 'tyyppi'> &
  Partial<Pick<Tilirivi, 'tiliointinumero'>>

export const injectLedgerNumbers = (
  category: AsiointiLedgerAccountCategory,
  items: LedgerNumberInjectable[]
): void => {
  if (items.length === 0) return
  const data = Object.values(asiointiLedgerAccountMaps[category])

  const getMeta = (type: AsiointiLedgerAccountType) => {
    const meta = data.find((i) => i.type === type)
    if (meta === undefined) {
      throw new Error(`Cannot find meta for ${type} in category ${category}`)
    }
    return meta
  }

  const reserved: Partial<Record<AsiointiLedgerAccountType, string[]>> =
    items.reduce(
      (map, current) =>
        !current.tiliointinumero
          ? map
          : {
              ...map,
              [current.tyyppi]: [
                ...(map[current.tyyppi] ?? []),
                current.tiliointinumero,
              ],
            },
      {} as Partial<Record<AsiointiLedgerAccountType, string[]>>
    )

  const getLedgerAccountNumber = (meta: AsiointiLedgerAccounts): string => {
    const { accounts, type } = meta
    const availableNumber =
      accounts.find((num) => !reserved[type]?.includes(num)) ??
      accounts[accounts.length - 1]
    reserved[type] = [...(reserved[type] ?? []), availableNumber]
    return availableNumber
  }

  for (const item of items) {
    item.tiliointinumero =
      item.tiliointinumero || getLedgerAccountNumber(getMeta(item.tyyppi))
  }
}
