import {
  ApiResponse,
  executeBackendClient,
} from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { DEFAULT_UPLOAD_TIMEOUT } from 'common/src/vtj/browser/http'
import { AsiointiFileCheckResponse } from 'lupa-backend/src/vtj/asiointi/file/asiointi-file-api.type'
import { AsiointiFileAttachment } from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'

export const lupaAsiointiFileApi = {
  uploadFile({
    file,
    sourceFileId,
  }: {
    file: File
    sourceFileId: string
  }): Promise<ApiResponse<void>> {
    return executeBackendClient((client) => {
      const form = new FormData()
      form.append('file', file)
      form.append('sourceFileId', sourceFileId)
      return client.post(`/luvat/asiointi/api/v1/file`, form, {
        timeout: DEFAULT_UPLOAD_TIMEOUT,
      })
    })
  },

  getValidationStatuses(
    attachments: AsiointiFileAttachment[]
  ): Promise<ApiResponse<AsiointiFileCheckResponse>> {
    return executeBackendClient((client) =>
      client.post('/luvat/asiointi/api/v1/file/check/query', {
        sourceFileIds: attachments.map(({ sourceFileId }) => sourceFileId),
      })
    )
  },

  getDownloadUrl(attachment: AsiointiFileAttachment): string {
    return `/luvat/asiointi/api/v1/file/${
      attachment.sourceFileId
    }/download/${encodeURIComponent(attachment.filename)}`
  },
}
