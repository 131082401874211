import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTili,
  getTilirivit,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontTakaus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountDebtType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import {
  useValittuEvtvVaatimus,
  IsoDatetimeToUiDate,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const TakauksetForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const omaisuus = getTilirivit()
  const {
    lomake: { lomakkeelleValitutTiedot },
    isPrincipalUnderaged,
  } = getTilintarkastusStore()

  const mkNewEntry = (): FrontTakaus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi:
        AsiointiLedgerAccountDebtType.TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA,
      lainanTyyppi: undefined,
      velallisenEtunimi: '',
      velallisenSukunimi: '',
      vakuus: '',
      onkoTullutHenkilonMaksettavaksi: undefined,
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('takaukset-form')}>
      <Heading
        variant="h3"
        data-test-id={mkOmaisuusTestId('takaukset-heading')}
      >
        {t(isPrincipalUnderaged ? 'panttaukset' : 'takauksetHeading')}
      </Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>
        {t(
          isPrincipalUnderaged
            ? 'ohjeIlmoitaPanttaukset'
            : 'ohjeIlmoitaTakaukset'
        )}
      </Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={TakausListItem}
        state={omaisuus.velat.takaukset}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VELAT}
        dataTestIdPrefix={mkOmaisuusTestId('takaukset-form')}
        ModalContentComponent={TakausModal}
        newEntry={mkNewEntry}
        translationPrefix={isPrincipalUnderaged ? 'panttaukset' : 'takaukset'}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
}

const TakausModal: FC<FormModalProps<FrontTakaus>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const { asiaType } = getTili()
    const vaatimus = useValittuEvtvVaatimus()
    const formattedAlkuPvm = IsoDatetimeToUiDate(
      vaatimus.accountingPeriodStartDate
    )

    const translations =
      takauksetTranslations[
        asiaType === 'OMAISUUSLUETTELO' ? 'OMAISUUSLUETTELO' : 'TILI'
      ]
    const { isPrincipalUnderaged } = getTilintarkastusStore()

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-velallisenEtunimi')}
          labelText={t('velallisenEtunimi')}
          value={entry.velallisenEtunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, velallisenEtunimi: value })
          }
        />
        <FormTextInput
          data-test-id={createDataTestId('modal-velallisenSukunimi')}
          labelText={t('velallisenSukunimi')}
          value={entry.velallisenSukunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, velallisenSukunimi: value })
          }
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-vakuus')}
          labelText={t('vakuus')}
          value={entry.vakuus}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, vakuus: value })}
        />
        {asiaType !== 'OMAISUUSLUETTELO' && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLopussa)}
          digitMode="two"
          hintText={
            asiaType === 'OMAISUUSLUETTELO'
              ? t('ilmoitaMaaraTehtavasiAlkamispaivalta', {
                  alkupvm: formattedAlkuPvm,
                })
              : undefined
          }
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        {!isPrincipalUnderaged && (
          <Block mb={verticalMarginToken}>
            <FormRadioButtonGroup
              required
              value={entry.onkoTullutHenkilonMaksettavaksi?.toString()}
              data-test-id={createDataTestId(
                'modal-onkoTullutHenkilonMaksettavaksi'
              )}
              labelText={t('onkoTullutHenkilonMaksettavaksi')}
              updateValue={(value) =>
                setEntry({
                  ...entry,
                  onkoTullutHenkilonMaksettavaksi: value === 'true',
                })
              }
              radioButtons={[
                {
                  value: 'false',
                  labelText: t('ei'),
                },
                { value: 'true', labelText: t('kylla') },
              ]}
            />
          </Block>
        )}

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t(
            isPrincipalUnderaged
              ? translations.asiakirjaInfo.panttaus
              : translations.asiakirjaInfo.takaus
          )}
          title={t('asiakirjat')}
        />
      </div>
    )
  }
)

const TakausListItem: FC<FormElementProps<FrontTakaus>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const { asiaType } = getTili()

    const arvoLopussaEur = entry.arvoTilikaudenLopussaEur
      ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
      : '-'
    const arvoAlussaEur = entry.arvoTilikaudenAlussaEur
      ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
      : ''

    const { isPrincipalUnderaged } = getTilintarkastusStore()

    return (
      <FormListInputExpander
        heading={`${entry.velallisenEtunimi} ${entry.velallisenSukunimi}`}
        headingRight={arvoLopussaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{t('vakuus')}</Heading>
        <Paragraph>{entry.vakuus}</Paragraph>

        {asiaType !== 'OMAISUUSLUETTELO' && (
          <Block mt={verticalMarginToken}>
            <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
            <Text>{arvoAlussaEur}</Text>
          </Block>
        )}

        {!isPrincipalUnderaged && (
          <Block mt={verticalMarginToken} mb={verticalMarginToken}>
            <Heading variant="h5">
              {t('onkoTullutHenkilonMaksettavaksi')}
            </Heading>
            <Text>
              {entry.onkoTullutHenkilonMaksettavaksi ? t('kylla') : t('ei')}
            </Text>
          </Block>
        )}

        {entry.asiakirjat.length > 0 && (
          <Block mb={verticalMarginToken}>
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
          </Block>
        )}
      </FormListInputExpander>
    )
  }
)

export default observer(TakauksetForm)

const takauksetTranslations = {
  OMAISUUSLUETTELO: {
    arvoLopussa: 'maaraEuroina',
    asiakirjaInfo: {
      takaus: 'takauksetsiakirjatAlkamispaivaltaInfo',
      panttaus: 'panttauksetasiakirjatAlkamispaivaltaInfo',
    },
  },
  TILI: {
    arvoLopussa: 'arvoTilikaudenLopussaEuroina',
    asiakirjaInfo: {
      takaus: 'takauksetAsiakirjaInfo',
      panttaus: 'panttauksetAsiakirjaInfo',
    },
  },
}
