import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import {
  Block,
  Heading,
  Text,
  suomifiDesignTokens,
  InlineAlert,
  Paragraph,
} from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  IsoDatetimeToUiDate,
  useValittuEvtvVaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

const PerustiedotOsiot = styled.div<{ isTablet: boolean }>`
  display: grid;
  grid-template-columns: ${({ isTablet }) => (isTablet ? '1fr 1fr' : 'auto')};
  gap: ${suomifiDesignTokens.spacing.m};
`

const getPerustiedotTranslations = (
  tiliType: AsiointiType,
  alkupvm: string,
  loppupvm: string,
  maarapvm: string,
  t: TFunction
) => {
  if (tiliType === 'OMAISUUSLUETTELO') {
    return {
      henkilo: t('henkilo'),
      tilikausi: t('tehtavanAlkamispaiva'),
      tilikausipvm: `${alkupvm}`,
      lahetaViimeistaan: t('lahetaOmaisuusluetteloMennessa', {
        paivamaara: maarapvm,
      }),
      valtakirjaHeading: t('omaisuusluetteloValtakirjaHeading'),
    }
  }

  return {
    henkilo: t('paamies'),
    tilikausi: t('tilikausi'),
    tilikausipvm: `${alkupvm} - ${loppupvm}`,
    lahetaViimeistaan: t('lahetaPaatostiliMennessa', { paivamaara: maarapvm }),
    valtakirjaHeading: t('paatostiliValtakirjaHeading'),
  }
}

const Perustiedot: React.FC<{ showValtakirja?: boolean }> = observer(
  ({ showValtakirja }) => {
    const [t] = useTranslation()

    const tili = getTili()
    const vaatimus = useValittuEvtvVaatimus()
    const formattedAlkuPvm = IsoDatetimeToUiDate(
      vaatimus.accountingPeriodStartDate
    )
    const formattedLoppuPvm = IsoDatetimeToUiDate(
      vaatimus.accountingPeriodEndDate
    )
    const formattedMaaraPvm = IsoDatetimeToUiDate(vaatimus.dueDate)
    const translations = getPerustiedotTranslations(
      tili.asiaType,
      formattedAlkuPvm,
      formattedLoppuPvm,
      formattedMaaraPvm,
      t
    )
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'

    return (
      <Block
        mt={verticalMarginToken}
        data-test-id={mkYhteenvetoTestId('perustiedot')}
      >
        <Heading variant="h3">{t('perustiedot')}</Heading>
        <Block mt={verticalMarginToken} />
        <PerustiedotOsiot isTablet={isTablet}>
          <Block
            data-test-id={mkYhteenvetoTestId('perustiedot-block-paamies-name')}
          >
            <Paragraph>
              <Text variant="bold">{translations.henkilo}</Text>
            </Paragraph>
            <Paragraph>
              {vaatimus.principalLastname}, {vaatimus.principalFirstnames}
            </Paragraph>
          </Block>
          <Block
            data-test-id={mkYhteenvetoTestId('perustiedot-block-tilikausi')}
          >
            <Paragraph>
              <Text variant="bold">{translations.tilikausi}</Text>
            </Paragraph>
            <Paragraph>{translations.tilikausipvm}</Paragraph>
          </Block>
          <Block
            data-test-id={mkYhteenvetoTestId(
              'perustiedot-block-tilivelvollinen'
            )}
          >
            <Paragraph>
              <Text variant="bold">{t('tilivelvollinen')}</Text>
            </Paragraph>
            <Paragraph>
              {vaatimus.guardianLastname}, {vaatimus.guardianFirstnames}
            </Paragraph>
          </Block>
          {!!tili.edunvalvojanValtakirja.length && !!showValtakirja && (
            <Block
              data-test-id={mkYhteenvetoTestId('perustiedot-block-valtakirja')}
            >
              <Paragraph>
                <Text variant="bold">{translations.valtakirjaHeading}</Text>
              </Paragraph>
              <AttachmentList attachments={tili.edunvalvojanValtakirja} />
            </Block>
          )}
        </PerustiedotOsiot>
        <Block mt={verticalMarginToken} />
        <InlineAlert
          data-test-id={mkYhteenvetoTestId('lahetys-maarapaiva-text')}
        >
          <Paragraph>{translations.lahetaViimeistaan}</Paragraph>

          {tili.asiaType === 'PAATOSTILI' && (
            <Paragraph>{t('tarkistaPaatostilinTiedotInfoText')}</Paragraph>
          )}
        </InlineAlert>
      </Block>
    )
  }
)

export default Perustiedot
