import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { FrontCaretaker } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getAddressString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/yhteenveto.util'
import { getOmatTiedot } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/omat-tiedot.store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getOmatTiedotContent = (
  omatTiedot: FrontCaretaker,
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const { lastname, firstnames, address, email, phone } = omatTiedot

  return [
    {
      heading: t('nimi'),
      rows: [`${firstnames} ${lastname}`],
    },
    {
      heading: t('puhelinnumero'),
      rows: [phone],
    },
    {
      heading: t('sahkoposti'),
      rows: [email],
    },
    { heading: t('katuosoite'), rows: [getAddressString(address, lang)] },
  ]
}

const Yhteystiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const omatTiedot = getOmatTiedot()
  const lang = useAsiointiUserStore().lang
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section data-test-id={mkYhteenvetoTestId('yhteystiedot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('yhteystiedot')}
      </Heading>
      <YhteenvetoGroups groups={getOmatTiedotContent(omatTiedot, t, lang)} />
    </section>
  )
})

export default Yhteystiedot
