import {
  SuomifiTheme,
  defaultSuomifiTheme,
  SuomifiThemeProvider,
} from 'suomifi-ui-components'
import React, { PropsWithChildren } from 'react'

const suomifiTheme: SuomifiTheme = defaultSuomifiTheme

// This must be used in all asiointi microfrontend apps because SuomifiThemeProvider only
// works on a React component tree, not DOM tree. Each microfrontend has its own React component tree.
export default function HolhousAsiointiTheme({
  children,
}: PropsWithChildren): React.ReactElement {
  return (
    <SuomifiThemeProvider theme={suomifiTheme}>{children}</SuomifiThemeProvider>
  )
}
