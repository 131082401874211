import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

export type AsiointiLedgerAccountDebtType = Extract<
  AsiointiLedgerAccountType,
  | AsiointiLedgerAccountType.ASUNTOLAINA
  | AsiointiLedgerAccountType.OPINTOLAINA
  | AsiointiLedgerAccountType.MUU_LAINA
  | AsiointiLedgerAccountType.ELATUSAPUVELKA
  | AsiointiLedgerAccountType.VELKAJARJESTELYVELKA
  | AsiointiLedgerAccountType.MUU_VELKA
  | AsiointiLedgerAccountType.TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA
>

export const AsiointiLedgerAccountDebtType = {
  [AsiointiLedgerAccountType.ASUNTOLAINA]:
    AsiointiLedgerAccountType.ASUNTOLAINA,
  [AsiointiLedgerAccountType.OPINTOLAINA]:
    AsiointiLedgerAccountType.OPINTOLAINA,
  [AsiointiLedgerAccountType.MUU_LAINA]: AsiointiLedgerAccountType.MUU_LAINA,
  [AsiointiLedgerAccountType.ELATUSAPUVELKA]:
    AsiointiLedgerAccountType.ELATUSAPUVELKA,
  [AsiointiLedgerAccountType.VELKAJARJESTELYVELKA]:
    AsiointiLedgerAccountType.VELKAJARJESTELYVELKA,
  [AsiointiLedgerAccountType.MUU_VELKA]: AsiointiLedgerAccountType.MUU_VELKA,
  [AsiointiLedgerAccountType.TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA]:
    AsiointiLedgerAccountType.TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA,
} as const
