export const scrollWindowToTop = (sourceStream) => {
  sourceStream.debounceImmediate(3000).onValue(() => {
    window.scrollTo(0, 0)
  })
}

export const isInsideBrowser = () => {
  return typeof window !== 'undefined'
}

export const changeUrl = (url) => {
  if (isInsideBrowser()) {
    window.location.href = url
  }
}
