import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import {
  tilintarkastus4VuottaRetention,
  tilintarkastus50VuottaRetention,
  tilintarkastusJulkinenHenkilotietojaRestriction,
  tilintarkastusPysyvaRetention,
  tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  tilintarkastusSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
  tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/common-tilintarkastus-metadata'

export const omaisuusluetteloMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.OMAISUUSLUETTELO,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastusPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'af0e2ae5-40ed-401d-aa28-16f04b617d30',
      toivoAsiakirjaTypeIds: ['af0e2ae5-40ed-401d-aa28-16f04b617d30'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.ARVIO_OMAISUUDESTA,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '9df05f2a-ec13-4e7e-a1f5-95d3bee15c0e',
      toivoAsiakirjaTypeIds: ['9df05f2a-ec13-4e7e-a1f5-95d3bee15c0e'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'b7646c18-923d-49da-88e4-97f2c59e2c9b',
      toivoAsiakirjaTypeIds: ['b7646c18-923d-49da-88e4-97f2c59e2c9b'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '222984ea-352b-458a-a81b-fede17676936',
      toivoAsiakirjaTypeIds: ['222984ea-352b-458a-a81b-fede17676936'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'c42613b8-f3ef-448b-9a3d-3f0ad68c28f0',
      toivoAsiakirjaTypeIds: ['c42613b8-f3ef-448b-9a3d-3f0ad68c28f0'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SALDOTODISTUS_TAI_OMAISUUSOTE,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'ae80e882-61d4-459b-bf67-894a9cd40653',
      toivoAsiakirjaTypeIds: ['ae80e882-61d4-459b-bf67-894a9cd40653'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '9240fce6-3066-490b-bf92-714c58e1bddb',
      toivoAsiakirjaTypeIds: ['9240fce6-3066-490b-bf92-714c58e1bddb'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SOPIMUS,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'a0e2a52d-0c62-4a4b-89dc-c85975819e03',
      toivoAsiakirjaTypeIds: ['a0e2a52d-0c62-4a4b-89dc-c85975819e03'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TESTAMENTTI_JA_TIEDOKSIANTOTODISTUS,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '9c2cd59e-ab24-4842-87c2-c21a8cad7f7f',
      toivoAsiakirjaTypeIds: ['9c2cd59e-ab24-4842-87c2-c21a8cad7f7f'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILIOTE,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '5b26a0e3-f344-4914-bb5e-461c02402013',
      toivoAsiakirjaTypeIds: ['5b26a0e3-f344-4914-bb5e-461c02402013'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALTAKIRJA,
      restrictionMetadata: tilintarkastusJulkinenHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '0a903978-babb-42e6-b141-058750e3106e',
      toivoAsiakirjaTypeIds: ['0a903978-babb-42e6-b141-058750e3106e'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVONTASUUNNITELMA,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'f4d00836-4c63-47da-ab0c-5ddc2526e8bf',
      toivoAsiakirjaTypeIds: ['f4d00836-4c63-47da-ab0c-5ddc2526e8bf'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TOSITE,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'd1374e13-f2ee-43aa-87aa-1bd6d72faf20',
      toivoAsiakirjaTypeIds: ['d1374e13-f2ee-43aa-87aa-1bd6d72faf20'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TULOSTE,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '23c6434f-57eb-4fb7-9eea-fee8696f3ae5',
      toivoAsiakirjaTypeIds: ['23c6434f-57eb-4fb7-9eea-fee8696f3ae5'],
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.HAKIJA],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN, AsiaPersonType.ORGANISAATIO],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.TARKASTETTU,
    manualRatkaisuTypeIds: [RatkaisuType.TARKASTETTU, RatkaisuType.PALAUTTETTU_TARKASTAMATTOMANA],
    automaticRatkaisuTypeIds: [RatkaisuType.AUTOMAATTISESTI_KASITELTY],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  // Omaisuusluettelo probably don't have tiedoksianto, only paatostili
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.EDVARD, Vireilletulokanava.ITSEPALVELU, Vireilletulokanava.TOIVO],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  retentionMetadata: tilintarkastusPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
