import { observer } from 'mobx-react'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { InlineAlert, Link } from 'suomifi-ui-components'
import React, { forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { hasErrors } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-state.util'
import { useTranslation } from 'react-i18next'
import { mkVirheyhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { ErrorsBulletedList } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/components'

const FormErrorSummary = observer(
  forwardRef<HTMLDivElement, object>(function FormErrorSummary(_props, ref) {
    const [t] = useTranslation()
    const form = useFormContext()
    return (
      <section data-test-id={mkVirheyhteenvetoTestId('container')}>
        {hasErrors(form) && (
          <FocusableDiv ref={ref} tabIndex={-1}>
            <InlineAlert
              mb={'s'}
              status="error"
              labelText={t('lomakkeellaHavaittiinSeuraavatOngelmat')}
              data-test-id={mkVirheyhteenvetoTestId('errors')}
            >
              <ErrorsBulletedList>
                {Object.entries(form.formState.errors).map(([name, error]) => {
                  return (
                    <li key={name}>
                      <Link
                        smallScreen
                        href="#"
                        onClick={() => {
                          setTimeout(
                            () => form.setFocus(name, { shouldSelect: true }),
                            0
                          )
                        }}
                      >
                        {error?.message?.toString() || ''}
                      </Link>
                    </li>
                  )
                })}
              </ErrorsBulletedList>
            </InlineAlert>
          </FocusableDiv>
        )}
      </section>
    )
  })
)

export default FormErrorSummary
