import { Block, Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import {
  mkAsuminenFormTestId,
  mkToimintakykyAsuminenTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  OMAISUUSLUETTELO_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import styled from 'styled-components'
import { AsuinpaikkaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/AsuinpaikkaForm'
import { HoitosuunnitelmaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/HoitosuunnitelmaForm'
import { ToimintakykyForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/ToimintakykyForm'
import AlaikainenAsuminenForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/AlaikainenAsuminenForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

const ToimintakykyAsuminenPage: React.FC<{ form: TilintarkastusForm }> =
  observer(({ form }) => {
    const [t] = useTranslation()
    const { isPrincipalUnderaged } = getTilintarkastusStore()
    const onErrorRef = useRef<HTMLDivElement>(null)

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const isOmaisuusluettelo =
      getTili().asiaType === AsiointiType.OMAISUUSLUETTELO
    return (
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkToimintakykyAsuminenTestId('container')}
        >
          <PageStepHeading
            heading={
              isPrincipalUnderaged
                ? t('asuminenHeading')
                : t('toimintakykyJaAsuminen')
            }
            steps={OMAISUUSLUETTELO_STEPS}
          />
          <FormErrorSummary ref={onErrorRef} />
          <ParagraphWithLineBreaks>
            {isPrincipalUnderaged
              ? t('ohjeOmaisuusluetteloAsuinpaikat_ALAIKAINEN')
              : t('ohjeOmaisuusluetteloAsuinpaikat')}
          </ParagraphWithLineBreaks>
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          {isPrincipalUnderaged ? (
            <AlaikainenAsuminenForm />
          ) : (
            <section data-test-id={mkAsuminenFormTestId('taysikainen')}>
              <AsuinpaikkaForm />
              <Block mt={verticalMarginToken} />
              {isOmaisuusluettelo && <HoitosuunnitelmaForm />}
              <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
              <ToimintakykyForm />
            </section>
          )}

          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    )
  })

const ParagraphWithLineBreaks = styled(Paragraph)`
  white-space: pre-line;
`
export default ToimintakykyAsuminenPage
