import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { runTilintarkastusStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TilintarkastusApiClient } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/api/tilintarkastusAsiointiClient'

export const submitTili = async (): Promise<ApiResponse<unknown>> =>
  runTilintarkastusStoreFlow(function* (store) {
    store.submitStatus = 'in-progress'
    store.submitError = undefined
    const submitFn =
      store.lomake.tili.asiaType === 'OMAISUUSLUETTELO'
        ? TilintarkastusApiClient.postOmaisuusluettelo
        : TilintarkastusApiClient.postPaatostili
    const response: ApiResponse<unknown> = yield submitFn(store.lomake)
    if (response.isOk) {
      store.submitStatus = 'submitted'
    } else {
      store.submitStatus = 'none'
      store.submitError = response.errorData ?? {} // On network error there is no error data
    }
    return response
  })
