export default {
  SUOMIFI_AUTH_FAILURE: 'suomifiAuthenticationFailure',
  SUOMIFI_AUTH_CANCELED: 'suomifiAuthenticationCanceled',
  INPUT_DEBOUNCE: 'inputDebounce',
  IS_SPEC: 'isSpec',
  TEST_MODE: 'testMode',
  WAIT_UNTIL_MAX_TIME: 'waitUntilMaxTime',
  MOBILE: 'mobile',
  GREP: 'grep',
}
