import { isInsideBrowser } from 'common/src/vtj/browser/browser-util'

export const getWindowNonceId = (): string | undefined =>
  isInsideBrowser()
    ? (
        window as unknown as {
          NONCE_ID: string
        }
      ).NONCE_ID
    : undefined
