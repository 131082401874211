export enum LanguageCode {
  AF = 'af',
  BG = 'bg',
  CS = 'cs',
  DA = 'da',
  DE = 'de',
  EL = 'el',
  EN = 'en',
  ES = 'es',
  ET = 'et',
  FI = 'fi',
  FR = 'fr',
  GA = 'ga',
  HR = 'hr',
  HU = 'hu',
  IT = 'it',
  LT = 'lt',
  LV = 'lv',
  MT = 'mt',
  NL = 'nl',
  PL = 'pl',
  PT = 'pt',
  RO = 'ro',
  SK = 'sk',
  SL = 'sl',
  SV = 'sv',
}

export const toLanguageCode = (language: string): LanguageCode => {
  return Object.values(LanguageCode).some((color) => color === language)
    ? <LanguageCode>language
    : LanguageCode.FI
}
