import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  Block,
  Heading,
  InlineAlert,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTili,
  getTilirivit,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonPercentageWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import {
  AsiointiType,
  FrontKiinteisto,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { Kayttotarkoitus } from 'tilintarkastus-common/src/vtj/types/asiointi-account-rows.type'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import styled from 'styled-components'
import {
  IsoDatetimeToUiDate,
  useValittuEvtvVaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const KiinteistotForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const omaisuus = getTilirivit()
  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  const mkNewEntry = (): FrontKiinteisto =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountAssetsType.KIINTEISTOT,
      kiinteistonNimi: '',
      kayttotarkoitus: undefined,
      tyhjillaanSelvitys: '',
      kiinteistonTunnus: '',
      omistusosuusProsentteina: undefined,
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
      vuokrasopimukset: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('kiinteistot-form')}>
      <Heading variant="h3">{t('kiinteistotHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaKiinteistot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={KiinteistoListItem}
        ModalContentComponent={KiinteistoModal}
        state={omaisuus.varat.kiinteistot}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VARAT}
        dataTestIdPrefix={mkOmaisuusTestId('kiinteistot-form')}
        newEntry={mkNewEntry}
        translationPrefix="kiinteistot"
        stateSubmitAdapter={({
          vuokrasopimukset,
          tyhjillaanSelvitys,
          ...rest
        }: FrontKiinteisto) => {
          if (rest.kayttotarkoitus === Kayttotarkoitus.TYHJILLAAN) {
            return { ...rest, vuokrasopimukset: [], tyhjillaanSelvitys }
          } else if (rest.kayttotarkoitus === Kayttotarkoitus.VUOKRATTUNA) {
            return { ...rest, vuokrasopimukset }
          } else {
            return { ...rest, vuokrasopimukset: [] }
          }
        }}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
}

const KiinteistoModal: FC<FormModalProps<FrontKiinteisto>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const { asiaType } = getTili()
    const isOmaisuusluettelo = asiaType === AsiointiType.OMAISUUSLUETTELO
    const evtv = useValittuEvtvVaatimus()
    const alkupvm = IsoDatetimeToUiDate(evtv.accountingPeriodStartDate)
    const translations =
      translationsByType[
        asiaType === 'OMAISUUSLUETTELO' ? 'OMAISUUSLUETTELO' : 'TILI'
      ]
    const kayttotarkoitustyypit = useMemo(
      () =>
        Object.values(Kayttotarkoitus).map((value) => ({
          name: t(`kayttotarkoitus_${value}`),
          value,
        })),
      [t]
    )

    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-kiinteistonNimi')}
          labelText={t('kiinteistonNimi')}
          value={entry.kiinteistonNimi}
          hintText={t('kiinteistonTyyppiHintText')}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, kiinteistonNimi: value })
          }
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-kiinteistonTunnus')}
          labelText={t('kiinteistonTunnus')}
          value={entry.kiinteistonTunnus}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, kiinteistonTunnus: value })
          }
        />
        <FormNumberInput
          data-test-id={createDataTestId('modal-omistusosuusProsentteina')}
          labelText={t('omistusOsuusProsentteina')}
          required
          digitMode="two"
          validate={commonPercentageWithDecimalsOrUndefinedValidator(t, 'two')}
          value={entry.omistusosuusProsentteina}
          updateValue={(value) =>
            setEntry({
              ...entry,
              omistusosuusProsentteina: value,
            })
          }
        />

        {!isOmaisuusluettelo && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLopussa)}
          hintText={
            isOmaisuusluettelo
              ? t(translations.kaypaArvo, { alkupvm })
              : t(translations.kaypaArvo)
          }
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormDropdown
          labelText={t('kayttotarkoitus')}
          data-test-id={createDataTestId('modal-kayttotarkoitus')}
          value={entry.kayttotarkoitus}
          items={kayttotarkoitustyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              kayttotarkoitus: value as Kayttotarkoitus,
            })
          }}
        />
        <Block mt={verticalMarginToken} />
        {entry.kayttotarkoitus === Kayttotarkoitus.TYHJILLAAN && (
          <>
            <FormTextInput
              data-test-id={createDataTestId('modal-tyhjana')}
              labelText={t('miksiTyhjillaanKiinteisto')}
              hintText={t('miksiTyhjillaanSelvitys')}
              required
              characterLimit={50}
              validate={commonXssValidator(t)}
              value={entry.tyhjillaanSelvitys}
              updateValue={(value) => {
                setEntry({
                  ...entry,
                  tyhjillaanSelvitys: value,
                })
              }}
            ></FormTextInput>
            <Block mt={verticalMarginToken} />
          </>
        )}

        {entry.kayttotarkoitus === Kayttotarkoitus.VUOKRATTUNA && (
          <>
            <FormAttachmentFileBox
              observableAttachments={entry.vuokrasopimukset}
              asiakirjaTypeId={TiliAsiakirjaType.SOPIMUS}
              data-test-id={createDataTestId('modal-vuokrasopimus')}
              text={t('kiinteistotVuokrasopimuksetInfo')}
              title={t('vuokrasopimukset')}
            />
            <Block mt={verticalMarginToken} />
          </>
        )}

        {entry.kayttotarkoitus === Kayttotarkoitus.OMASSA_KAYTOSSA && (
          <>
            <InlineAlert>
              <Text smallScreen>{t('omassaKaytossaInfoHeading')}</Text>
              <Block mt={'m'} />
              <List>
                <li>{t('omassaKaytossaInfoItem1')}</li>
                <li>{t('omassaKaytossaInfoItem2')}</li>
                <li>{t('omassaKaytossaInfoItem3')}</li>
              </List>
            </InlineAlert>
            <Block mt={verticalMarginToken} />
          </>
        )}

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t('asiakirjaKiinteistonArvosta')}
          title={t('asiakirjat')}
        />
      </div>
    )
  }
)

const KiinteistoListItem: FC<FormElementProps<FrontKiinteisto>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const isOmaisuusluettelo =
      getTili().asiaType === AsiointiType.OMAISUUSLUETTELO

    const omistusosuusProsentti = entry.omistusosuusProsentteina
      ? `${formatNumberWithComma(entry.omistusosuusProsentteina)} %`
      : ''

    const arvoLopussaEur = entry.arvoTilikaudenLopussaEur
      ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
      : ''

    const arvoAlussaEur = entry.arvoTilikaudenAlussaEur
      ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
      : ''

    return (
      <FormListInputExpander
        heading={entry.kiinteistonNimi}
        headingRight={arvoLopussaEur}
        subHeading={entry.kiinteistonTunnus}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{t('kayttotarkoitus')}</Heading>
        <Text>{t(`kayttotarkoitus_${entry.kayttotarkoitus}`)}</Text>
        {entry.tyhjillaanSelvitys &&
          entry.kayttotarkoitus === Kayttotarkoitus.TYHJILLAAN && (
            <>
              <Block />
              <Text>{entry.tyhjillaanSelvitys}</Text>
            </>
          )}
        <Block mt={verticalMarginToken} />

        <Heading variant="h5">{t('omistusOsuusProsentteina')}</Heading>
        <Text>{omistusosuusProsentti}</Text>
        <Block mt={verticalMarginToken} />

        {!isOmaisuusluettelo && (
          <>
            <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
            <Text>{arvoAlussaEur}</Text>
            <Block mt={verticalMarginToken} />
          </>
        )}

        {entry.asiakirjat.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        {entry.vuokrasopimukset.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('vuokrasopimukset')}</Heading>
            <AttachmentList attachments={entry.vuokrasopimukset} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

const translationsByType = {
  OMAISUUSLUETTELO: {
    arvoLopussa: 'arvoEuroina',
    asiakirjatInfo: 'asiakirjaKiinteistonArvosta',
    vuokrattunaInfo: 'asiakirjaVuokrasopimusKiinteisto',
    kaypaArvo: 'kaypaArvoTehtavanAlussa',
  },
  TILI: {
    arvoLopussa: 'arvoTilikaudenLopussaEuroina',
    asiakirjatInfo: 'huoneistotAsiakirjaInfo',
    vuokrattunaInfo: 'huoneistotVuokrasopimuksetInfo',
    kaypaArvo: 'arvoTilikaudenLopussaEuroina',
  },
}

const List = styled.ul`
  list-style: disc;
  margin-left: ${suomifiDesignTokens.spacing.m};

  li {
    padding-left: ${suomifiDesignTokens.spacing.s};
  }
`

export default observer(KiinteistotForm)
