import keyMirror from 'keymirror'

export const MarkingType = keyMirror({
  ALKUPERAINEN_ASIAKIRJA_ON_NAHTY: null,
  SAHKOISEN_ASIAKIRJAN_OIKEELLISUUS_ON_TARKASTETTU: null,
  ASIAKIRJA_EI_VAIKUTA_EHEALTA: null,
  ASIAKIRJA_EI_VAIKUTA_ALKUPERAISELTA: null,
  ASIAKIRJAT_ON_PALAUTETTU_ASIAKKAALLE: null,
})
export type MarkingTypeId = (typeof MarkingType)[keyof typeof MarkingType]
export const allMarkingTypeIds = Object.keys(MarkingType) as MarkingTypeId[]

export const MarkingComponentType = keyMirror({
  CHECKBOX: null,
  RADIOBUTTON: null,
})
export type MarkingComponentTypeId = (typeof MarkingComponentType)[keyof typeof MarkingComponentType]
export const allMarkingComponentTypeIds = Object.keys(MarkingComponentType) as MarkingComponentTypeId[]

export const MarkingComponentValue = keyMirror({
  KYLLA: null,
  EI: null,
  TIETOA_EI_ANNETTU: null,
})
export type MarkingComponentValueId = (typeof MarkingComponentValue)[keyof typeof MarkingComponentValue]
export const allMarkingComponentValueIds = Object.keys(MarkingComponentValue) as MarkingComponentValueId[]

export type MarkingComponentTypeValues = {
  [k in MarkingComponentTypeId]: MarkingComponentValueId[]
}
export const MarkingComponentTypeValues: MarkingComponentTypeValues = {
  [MarkingComponentType.CHECKBOX]: [MarkingComponentValue.EI, MarkingComponentValue.KYLLA],
  [MarkingComponentType.RADIOBUTTON]: [
    MarkingComponentValue.TIETOA_EI_ANNETTU,
    MarkingComponentValue.KYLLA,
    MarkingComponentValue.EI,
  ],
}

export const MarkingAlertType = keyMirror({
  ALERT: null,
  SUCCESS: null,
  WARNING: null,
  REQUIRED: null,
})
export type MarkingAlertTypeId = (typeof MarkingAlertType)[keyof typeof MarkingAlertType]
export const allMarkingAlertTypeIds = Object.keys(MarkingAlertType) as MarkingAlertTypeId[]

export type MarkingMetadata = {
  markingTypeId: MarkingTypeId
  markingComponentTypeId: MarkingComponentTypeId
  defaultMarkingComponentValueId: MarkingComponentValueId
  descriptionAllowed: boolean
  descriptionMandatory: boolean
  alerts: {
    markingAlertTypeId: MarkingAlertTypeId
    markingComponentValueId: MarkingComponentValueId
  }[]
}

export const defaultAsiakirjaMarkingMetadata: MarkingMetadata[] = [
  {
    markingTypeId: MarkingType.ALKUPERAINEN_ASIAKIRJA_ON_NAHTY,
    markingComponentTypeId: MarkingComponentType.RADIOBUTTON,
    defaultMarkingComponentValueId: MarkingComponentValue.TIETOA_EI_ANNETTU,
    descriptionAllowed: false,
    descriptionMandatory: false,
    alerts: [
      {
        markingAlertTypeId: MarkingAlertType.ALERT,
        markingComponentValueId: MarkingComponentValue.EI,
      },
      {
        markingAlertTypeId: MarkingAlertType.SUCCESS,
        markingComponentValueId: MarkingComponentValue.KYLLA,
      },
    ],
  },
  {
    markingTypeId: MarkingType.SAHKOISEN_ASIAKIRJAN_OIKEELLISUUS_ON_TARKASTETTU,
    markingComponentTypeId: MarkingComponentType.CHECKBOX,
    defaultMarkingComponentValueId: MarkingComponentValue.EI,
    descriptionAllowed: true,
    descriptionMandatory: false,
    alerts: [],
  },
  {
    markingTypeId: MarkingType.ASIAKIRJA_EI_VAIKUTA_EHEALTA,
    markingComponentTypeId: MarkingComponentType.CHECKBOX,
    defaultMarkingComponentValueId: MarkingComponentValue.EI,
    descriptionAllowed: true,
    descriptionMandatory: false,
    alerts: [
      {
        markingAlertTypeId: MarkingAlertType.WARNING,
        markingComponentValueId: MarkingComponentValue.KYLLA,
      },
    ],
  },
  {
    markingTypeId: MarkingType.ASIAKIRJA_EI_VAIKUTA_ALKUPERAISELTA,
    markingComponentTypeId: MarkingComponentType.CHECKBOX,
    defaultMarkingComponentValueId: MarkingComponentValue.EI,
    descriptionAllowed: true,
    descriptionMandatory: false,
    alerts: [
      {
        markingAlertTypeId: MarkingAlertType.WARNING,
        markingComponentValueId: MarkingComponentValue.KYLLA,
      },
    ],
  },
  {
    markingTypeId: MarkingType.ASIAKIRJAT_ON_PALAUTETTU_ASIAKKAALLE,
    markingComponentTypeId: MarkingComponentType.CHECKBOX,
    defaultMarkingComponentValueId: MarkingComponentValue.EI,
    descriptionAllowed: true,
    descriptionMandatory: false,
    alerts: [],
  },
]
