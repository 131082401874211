import keyMirror from 'keymirror'
import _ from 'lodash'

export const AsiakirjaType = keyMirror({
  ALKUPERAINEN_EDUNVALVONTAVALTAKIRJA: null,
  APOSTILLE: null,
  ARVIO_OMAISUUDESTA: null,
  ASIAN_SIIRTO_LASTENVALVOJALLE: null,
  ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA: null,
  AVIOEHTOSOPIMUS: null,
  EDUNVALVONTAVALTAKIRJAN_JALJENNOS: null,
  ERO_DVVN_ILMOITUS_REKISTEROINNISTA: null,
  ERO_DVVN_PAATOS_LIITTEINEEN: null,
  ERO_MUU_ASIAKIRJA: null,
  ERO_PAATOS_AVIOEROSTA: null,
  ERO_PERUSTEET_REKISTEROINNILLE: null,
  ERO_PYYNTO: null,
  ERO_SELVITYSASIAKIRJAT: null,
  ESISOPIMUS: null,
  ESITTELIJAN_ERIAVA_MIELIPIDE: null,
  EU_VAKIOLOMAKE: null,
  HALLINTOOIKEUDEN_PAATOS: null,
  HAKEMUS_EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMISEKSI: null,
  HAKEMUS_EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMISEKSI: null,
  HENKILON_NIMI_LAKI_VAATIMUS: null,
  HENKILOTUNNUSPYYNTO: null,
  HOITOTODISTUS: null,
  ILMOITUS_ASIAN_RAUKEAMISESTA: null,
  ILMOITUS_ETTEI_TIETOJA_VOIDA_LUOVUTTAA: null,
  ILMOITUS_REKISTEROINNISTA: null,
  KARAJAOIKEUDEN_PAATOS: null,
  KIINTEISTOREKISTERIN_OTE: null,
  KOPIO_HENKILOLLISYYSASIAKIRJASTA: null,
  KOPIO_HENKILOTODISTUKSESTA_TAI_PASSISTA: null,
  KOTIKUNTAPYYNTO: null,
  KUOLINTIEDON_KORJAUSPYYNTO: null,
  KUOLLEEKSIJULISTAMISPAATOS: null,
  KUULEMISKIRJE: null,
  KUULEMISKIRJE_VALTUUTTAJALLE: null,
  KUULEMISKIRJE_MUULLE_HENKILOLLE: null,
  ILMOITUS_KUOLEMASTA_VAESTOTIETOJARJESTELMAAN: null,
  ISANNOITSIJANTODISTUS: null,
  LAAKARINLAUSUNTO: null,
  LAAKARINLAUSUNTOPYYNTO: null,
  LAINHUUTOTODISTUS: null,
  LAUSUNTOPYYNTO: null,
  LAUSUNTOPYYNTO_NIMILAUTAKUNNALLE: null,
  LAUSUNTO_SOSIAALITOIMELTA: null,
  LISASELVITYS: null,
  LISASELVITYSPYYNTO: null,
  LUPAHAKEMUS: null,
  MAANVUOKRASOPIMUS: null,
  MUISTIO: null,
  MUISTUTUS: null,
  MUU_ASIAKIRJA: null,
  MUUTOKSENHAKUOHJE: null,
  MUU_LAUSUNTOPYYNTO: null,
  MUU_LAUSUNTO: null,
  MUU_LIITE: null,
  MUU_SELVITYS: null,
  MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA: null,
  MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA: null,
  MUU_VIRASTON_LAATIMA_ASIAKIRJA: null,
  MUU_VIRASTON_SAAMA_ASIAKIRJA: null,
  NIMENMUUTOSHAKEMUS: null,
  NIMILAUTAKUNNAN_LAUSUNTO: null,
  OIKAISUVAATIMUSOHJE: null,
  OIKEUSOHJEET: null,
  OMAISUUSLUETTELO: null,
  OMIEN_TIETOJEN_TARKASTUSOIKEUDEN_KAYTTAMINEN_TILAUS: null,
  PAAMIEHEN_LAUSUNTO: null,
  PAATOS: null,
  PAATOS_LIITTEINEEN: null,
  PAATOS_NIMENMUUTOKSESTA: null,
  PAATOSTILIN_TIEDOKSIANTO_LIITTEINEEN: null,
  PAATOSTILITULOSTE: null,
  PERUKIRJA: null,
  PERUSTEET_KOTIKUNNALLE_TAI_ASUMISEN_VAKINAISUUDEN_OSOITTAVA_SELVITYS: null,
  PERUSTEET_REKISTEROINNILLE: null,
  PYYNTO: null,
  RASITUSTODISTUS: null,
  RIKOSREKISTERIOTE: null,
  SAANTITODISTUS: null,
  SAATE_TAI_LAHETE: null,
  SALDOTODISTUS_TAI_OMAISUUSOTE: null,
  SELVITYS_LAINANHOITOKYVYSTA: null,
  SELVITYS_OMAISUUDEN_MYYNNISTA: null,
  SELVITYSASIAKIRJA: null,
  SELVITYSPYYNTO: null,
  SIVIILISAATYTODISTUS: null,
  SOPIMUS: null,
  SOPIMUSLUONNOS: null,
  SUKUSELVITYS: null,
  SUOSTUMUS: null,
  SYNTYMATODISTUS: null,
  TAYDENNYS: null,
  TAYDENNYSPYYNTO: null,
  TAYDENNYS_ASIAKKAALTA: null,
  TESTAMENTTI: null,
  TESTAMENTTI_JA_TIEDOKSIANTOTODISTUS: null,
  TILIEN_SALDOT_JA_TILITAPAHTUMAT: null,
  TILIOTE: null,
  TILITAPAHTUMAT: null,
  TODISTUS_ULOSOTTOREKISTERISTA: null,
  TODISTUS_VAESTOTIETOJARJESTELMASTA: null,
  TODISTUSTILAUS: null,
  VANHEMMUUDEN_TUNNUSTAMISASIAKIRJA_SAHKOINEN: null,
  VANHEMMUUDEN_TUNNUSTAMISASIAKIRJA_FYYSINEN: null,
  VANHEMMUUDEN_TUNNUSTAMISEN_KUULEMISASIAKIRJA: null,
  VANHEMMUUDEN_TUNNUSTAMISEN_KIISTAMINEN_TAI_PERUUTUS: null,
  VANHEMMUUDEN_SELVITTAMISPOYTAKIRJA_LIITTEINEEN: null,
  VALITUSOSOITUS: null,
  VALTAKIRJA: null,
  VALTUUTETUN_LAUSUMA: null,
  VALTUUTTAJAN_LAHEISEN_LAUSUMA: null,
  VALTUUTTAJAN_LAUSUMA: null,
  VASTAUS_KUULEMISEEN: null,
  VASTINEPYYNTO_ASIAKKAALLE: null,
  VASTINE_ASIAKKAALTA: null,
  VIHKIMINEN_DVVN_ILMOITUS_REKISTEROINNISTA: null,
  VIHKIMINEN_DVVN_PAATOS_DVVN_VIHKIJA: null,
  VIHKIMINEN_DVVN_PAATOS_MUU_VIHKIJA: null,
  VIHKIMINEN_DVVN_PAATOS_LIITTEINEEN: null,
  VIHKIMINEN_MUU_ASIAKIRJA: null,
  VIHKIMINEN_MUU_ASIAKIRJA_DVVN_VIHKIJA: null,
  VIHKIMINEN_MUU_ASIAKIRJA_MUU_VIHKIJA: null,
  VIHKIMINEN_PERUSTEET_REKISTEROINNILLE: null,
  VIHKIMINEN_PYYNTO: null,
  VIHKIMINEN_SELVITYSASIAKIRJAT: null,
  VIHKIMINEN_SELVITYSASIAKIRJAT_DVVN_VIHKIJA: null,
  VIHKIMINEN_SELVITYSASIAKIRJAT_MUU_VIHKIJA: null,
  VIHKIMINEN_SUKUNIMEN_ILMOITTAMINEN_DVVN_VIHKIJA: null,
  VIHKIMINEN_SUKUNIMEN_ILMOITTAMINEN_MUU_VIHKIJA: null,
  VIHKIMISILMOITUS_DVVN_VIHKIJA: null,
  VIHKIMISILMOITUS_MUU_VIHKIJA: null,
  VIRKATODISTUS: null,
  VUOKRAOIKEUSTODISTUS: null,
  ILMOITUS_EDUNVALVONNAN_TARPEESSA_OLEVASTA_HENKILOSTA: null,
  KARAJAOIKEUDEN_LAUSUMAPYYNTO_LIITTEINEEN_UUDESSA_ASIASSA: null,
  EDUNVALVONTAAN_ESITETYN_LAUSUMA: null,
  MUUN_HENKILON_LAUSUMA: null,
  SUOSTUMUS_EDUNVALVOJAN_TEHTAVAAN: null,
  KUULEMISKIRJE_EDUNVALVONTAAN_ESITETYLLE: null,
  HAKEMUS_KARAJAOIKEUDELLE: null,
  TAYDENNYS_KARAJAOIKEUDELLE: null,
  LAUSUMA_KARAJAOIKEUDELLE: null,
  HAKEMUKSEN_PERUUTUS_KARAJAOIKEUDELLE: null,
  ILMOITUS_KUOLEMASTA_KARAJAOIKEUDELLE: null,
  MUU_ASIAKIRJA_KARAJAOIKEUTEEN: null,
  KARAJAOIKEUDEN_TAYDENNYSPYYNTO: null,
  KARAJAOIKEUDEN_LAUSUMAPYYNTO: null,
  ISTUNTOKUTSU: null,
  ILMOITUS_RATKAISUN_ANTAMISESTA: null,
  MUU_TUOMIOISTUIMEN_LAATIMA_ASIAKIRJA: null,
  TUOMIOISTUIMEN_PAATOS: null,
  TUOMIOISTUIMEN_VALIAIKAISMAARAYS: null,
  OTE_HOLHOUSASIOIDEN_REKISTERISTA: null,
  ILMOITUS_ASIAN_JATTAMISESTA_SILLENSA: null,
  PYYNTO_AVIOLIITON_ESTEIDEN_TUTKINNASTA_LIITTEINEEN_VIHKIMINEN_SUOMESSA: null,
  TODISTUS_TOIMITETUSTA_AVIOLIITON_ESTEIDEN_TUTKINNASTA_VIHKIMISILMOITUS: null,
  KIELTEINEN_PAATOS_RAUKEAMINEN: null,
  PAATOSTILI_PAAKIRJA_PALKKIOLASKELMA: null,
  EDUNVALVONTASUUNNITELMA: null,
  TOSITE: null,
  PALKKIO_JA_KULULASKELMA: null,
  VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON: null,
  TARKASTETTU_PAATOSTILI_PAAKIRJA_JA_PALKKIOLASKELMA_TILIN_TARKASTUSMERKINNAT: null,
  VAATIMUS_VIRHEEN_KORJAAMISESTA: null,
  VUOSITILI_PAAKIRJA_PALKKIOLASKELMA: null,
  TARKASTETTU_VUOSITILI_JA_PALKKIOLASKELMA_TILIN_TARKASTUSMERKINNAT: null,
  TULOSTE: null,
  VERKKOLOMAKKEELLA_SAAPUNUT_TODISTUSTILAUS_TUNNISTAUTUNUT: null,
  VERKKOLOMAKKEELLA_SAAPUNUT_TODISTUSTILAUS_TUNNISTAUTUMATON: null,
  SELVITYS: null,
  KANSALAISUUDEN_TAI_ULKOMAATIEDON_REKISTEROINTI: null,
  KANSALAISUUDEN_MAARITTAMISPYYNTO_MIGRILLE: null,
  MUUTTOILMOITUS: null,
  LAUSUMAPYYNTO: null,
  LAUSUMA: null,
  ASIAKKAALTA_SAAPUNUT_TAYDENNYS: null,
  PAATOS_MUUTTOASIASSA: null,
  DVVN_ILMOITUS: null,
  HAKEMUS_EDUNVALVOJAN_MAARAAMISEKSI: null,
  POYTAKIRJA_HAKIJAN_HENKILOKOHTAISESTA_KUULEMISESTA: null,
  PYYNTO_AVIOLIITON_ESTEIDEN_TUTKINNASTA_LIITTEINEEN_VIHKIMINEN_ULKOMAILLA: null,
  TODISTUS_SUOMEN_LAIN_MUKAISESTA_OIKEUDESTA_MENNA_AVIOLIITTOON_ULKOMAAN_VIRANOMAISEN_EDESSA: null,
  HAKEMUS_PERUKIRJAN_OSAKASTIETOJEN_VAHVISTAMISEKSI: null,
  PERUKIRJAN_LIITE: null,
})
export type AsiakirjaTypeId = (typeof AsiakirjaType)[keyof typeof AsiakirjaType]
export const allAsiakirjaTypeIds = _.keys(AsiakirjaType) as AsiakirjaTypeId[]

export const AsiakirjaDelivererType = keyMirror({
  HENKILO: null,
  VIRKAILIJA: null,
  JARJESTELMA: null,
})
export type AsiakirjaDelivererTypeId = (typeof AsiakirjaDelivererType)[keyof typeof AsiakirjaDelivererType]

export const AsiakirjaDeliveryMethod = keyMirror({
  VERKKOSOVELLUS: null,
  INTEGRAATIO: null,
  KIRJE: null,
  SAHKOPOSTI: null,
  PUHELIN: null,
  TOIVO: null,
  TOIMITETTU: null,
  MUU: null,
  TOIMITETTU_TISKILLE: null,
  TOIMITETTU_POSTILAATIKKOON: null,
  TOIMITETTU_KURIIRILLA: null,
})
export type AsiakirjaDeliveryMethodId = (typeof AsiakirjaDeliveryMethod)[keyof typeof AsiakirjaDeliveryMethod]
export const allAsiakirjaDeliveryMethodIds = _.keys(AsiakirjaDeliveryMethod) as AsiakirjaDeliveryMethodId[]

export const AsiakirjaOriginType = keyMirror({
  SAAPUNUT: null,
  LAADITTU: null,
})
export type AsiakirjaOriginTypeId = (typeof AsiakirjaOriginType)[keyof typeof AsiakirjaOriginType]
export const allAsiakirjaOriginTypeIds = _.keys(AsiakirjaOriginType) as AsiakirjaOriginTypeId[]

export const ToivoBatchStatus = keyMirror({
  ALOITETTU: null,
  PERUUTETTU: null,
  LISATTY: null,
  VIIMEISTELTY: null,
  OSITTAIN_EPAONNISTUNUT: null,
  EPAONNISTUNUT: null,
})
export type ToivoBatchStatusId = (typeof ToivoBatchStatus)[keyof typeof ToivoBatchStatus]
export const AsiakirjaHashAlgorithm = keyMirror({
  SHA1: null,
  SHA256: null,
  SHA384: null,
  SHA512: null,
})
export type AsiakirjaHashAlgorithmId = (typeof AsiakirjaHashAlgorithm)[keyof typeof AsiakirjaHashAlgorithm]
export const AsiakirjaSignatureAlgorithm = keyMirror({
  SHA1withRSA: null,
  SHA256withRSA: null,
  SHA384withRSA: null,
  SHA512withRSA: null,
  SHA1withECDSA: null,
  SHA256withECDSA: null,
  SHA384withECDSA: null,
  SHA512withECDSA: null,
})
export type AsiakirjaSignatureAlgorithmId =
  (typeof AsiakirjaSignatureAlgorithm)[keyof typeof AsiakirjaSignatureAlgorithm]
export const AsiakirjaSignatureType = keyMirror({
  'cms-pades': null,
})
export type AsiakirjaSignatureTypeId = (typeof AsiakirjaSignatureType)[keyof typeof AsiakirjaSignatureType]
