import { executeBackendClient } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { GetUserEdunvalvontasuhteetResponse } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv/evtv-api.type'

export const getEvtvData =
  async (): Promise<GetUserEdunvalvontasuhteetResponse> => {
    const apiResponse = await executeBackendClient((client) =>
      client.get('/evtv-asiointi/api/v1/edunvalvontasuhteet')
    )

    if (apiResponse.isOk) {
      return apiResponse.data
    } else {
      throw new Error(
        'Failed loading Evtv data: ' + JSON.stringify(apiResponse.errorData)
      )
    }
  }
