/* eslint-disable promise/prefer-await-to-then */
import { action, runInAction } from 'mobx'
import {
  POLL_TIMEOUT_CURVE_LINEAR,
  pollUntilTrue,
} from 'asia-common/src/vtj/poll.util'
import {
  AsiointiFileCheckStatus,
  AsiointiFileCheckStatusId,
  AsiointiFileAttachment,
  AsiointiFileAttachmentError,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'
import {
  AsiointiFileCheckResponse,
  AsiointiFileCheckResult,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-api.type'
import settingsSingleton from 'asia-common/src/vtj/settings'
import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'

export const pollingMaxIter = 120

export type FileCheckPollingApi = {
  getValidationStatuses(
    attachments: AsiointiFileAttachment[]
  ): Promise<ApiResponse<AsiointiFileCheckResponse>>
}

// This function assumes that the input attachment is an observable (or that the function doesn't have to care).
export const pollFileChecks = (
  api: FileCheckPollingApi,
  attachment: AsiointiFileAttachment
): void => {
  const initialPollDelayMs = Number(
    settingsSingleton.get('asiointiServer.fileCheck.ui.initialPollDelayMs')
  )
  const pollMaxDurationMs = Number(
    settingsSingleton.get('asiointiServer.fileCheck.ui.pollMaxDurationMs')
  )

  stopFileCheckPolling()
  attachmentsToCheck.push(attachment)

  const startPolling = () => {
    let isStopped = false

    setTimeout(
      async () => {
        const isStoppedOrAllDone = await pollUntilTrue(
          async () => {
            if (attachmentsToCheck.length > 0 && !isStopped) {
              await updateFileCheckResults(api)
            }
            return attachmentsToCheck.length === 0 || isStopped
          },
          pollingMaxIter,
          pollMaxDurationMs,
          POLL_TIMEOUT_CURVE_LINEAR
        )

        if (!isStoppedOrAllDone) {
          runInAction(() => {
            attachmentsToCheck.forEach((attachment) => {
              Object.assign(attachment, {
                status: 'failed',
                error: 'validation_aborted',
              })
            })
          })
        }
      },
      // 1) The checks will take some on the server, so no need to start polling right away.
      // 2) Grace period for more files to be added to polling.
      initialPollDelayMs
    )

    return () => {
      isStopped = true
    }
  }

  stopFileCheckPolling = startPolling()
}

export const stopPollingFileChecks = (sourceFileId: string): void => {
  const index = attachmentsToCheck.findIndex(
    (attachment) => attachment.sourceFileId === sourceFileId
  )
  if (index > -1) {
    attachmentsToCheck.splice(index, 1)
  }
}

const attachmentsToCheck: AsiointiFileAttachment[] = []
let stopFileCheckPolling: () => void = () => {
  // noop
}

// Using Mobx actions and promises and here instead of Mobx flows and generator functions because the
// latter are not as testable. They don't seem to work with Sinon fake timers.
const updateFileCheckResults = (api: FileCheckPollingApi) =>
  api.getValidationStatuses(attachmentsToCheck).then(
    action((response) => {
      if (!response.isOk) return

      const { results } = response.data
      results.forEach((result) => {
        const reducedResult = reduceFileCheckResult(result)
        if (reducedResult.status === 'checking') return

        const index = attachmentsToCheck.findIndex((attachment) =>
          result.sourceFileId
            ? attachment.sourceFileId === result.sourceFileId
            : result.asiakirjaId
            ? attachment.asiakirjaId === result.asiakirjaId
            : false
        )
        if (index < 0) return

        const attachment = attachmentsToCheck[index]
        attachment.status = reducedResult.status
        if (
          // (checking both so that Typescript understands that attachment and reducedResult both have the error property)
          attachment.status === 'failed' &&
          reducedResult.status === 'failed'
        ) {
          attachment.error = reducedResult.error
        }
        attachmentsToCheck.splice(index, 1)
      })
    })
  )

export const reduceFileCheckResult = (
  fileCheckResult: Pick<
    AsiointiFileCheckResult,
    'virusCheckStatusId' | 'conversionCheckStatusId'
  >
):
  | {
      status: 'success' | 'checking'
    }
  | {
      status: 'failed'
      error: AsiointiFileAttachmentError
    } => {
  const reducedCheckStatusId = [
    fileCheckResult.conversionCheckStatusId,
    fileCheckResult.virusCheckStatusId,
  ].sort(
    (s1, s2) =>
      validationStatusPriorityMap[s1] - validationStatusPriorityMap[s2]
  )[0]
  switch (reducedCheckStatusId) {
    case AsiointiFileCheckStatus.LAPAISTY:
      return { status: 'success' }
    case AsiointiFileCheckStatus.KAYNNISSA:
      return { status: 'checking' }
    case AsiointiFileCheckStatus.EI_LAPAISTY:
      return { status: 'failed', error: 'validation_not_passed' }
    case AsiointiFileCheckStatus.KESKEYTETTY:
      return { status: 'failed', error: 'validation_aborted' }
  }
}

const validationStatusPriorityMap: Record<AsiointiFileCheckStatusId, number> = {
  EI_LAPAISTY: 0,
  KESKEYTETTY: 1,
  KAYNNISSA: 2,
  LAPAISTY: 3,
}
