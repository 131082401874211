import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import {
  tilintarkastus0VuottaRetention,
  tilintarkastus50VuottaRetention,
  tilintarkastusJulkinenHenkilotietojaRestriction,
  tilintarkastusPysyvaRetention,
  tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  tilintarkastusSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
  tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/common-tilintarkastus-metadata'

export const ensitiliMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ARVIO_OMAISUUDESTA,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '5f98363d-638b-4de8-8920-7d0313cad8f3',
      toivoAsiakirjaTypeIds: ['5f98363d-638b-4de8-8920-7d0313cad8f3'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'e29a8f82-b1ac-4aa3-9237-79023f9a1cf2',
      toivoAsiakirjaTypeIds: ['e29a8f82-b1ac-4aa3-9237-79023f9a1cf2'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'b43d0220-9b66-4fe9-add6-b7352ff5514f',
      toivoAsiakirjaTypeIds: ['b43d0220-9b66-4fe9-add6-b7352ff5514f'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: 'a249f840-076c-45e2-8569-97d758cf2f81',
      toivoAsiakirjaTypeIds: ['a249f840-076c-45e2-8569-97d758cf2f81'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '0b221231-0606-43a6-b4cf-723364633a19',
      toivoAsiakirjaTypeIds: ['0b221231-0606-43a6-b4cf-723364633a19'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SALDOTODISTUS_TAI_OMAISUUSOTE,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'dbbf668a-c200-4626-87d4-a9a6f3633b34',
      toivoAsiakirjaTypeIds: ['dbbf668a-c200-4626-87d4-a9a6f3633b34'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'cb7421cd-5325-49e2-82c9-aab48a4c0556',
      toivoAsiakirjaTypeIds: ['cb7421cd-5325-49e2-82c9-aab48a4c0556'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SOPIMUS,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '4b323aed-15ce-4e34-ac17-69d5ab87aa28',
      toivoAsiakirjaTypeIds: ['4b323aed-15ce-4e34-ac17-69d5ab87aa28'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TESTAMENTTI_JA_TIEDOKSIANTOTODISTUS,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '819904fb-3475-4ab6-a99a-321bc63456e4',
      toivoAsiakirjaTypeIds: ['819904fb-3475-4ab6-a99a-321bc63456e4'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILIOTE,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '9413c6a2-de49-4a24-8839-13836790a5d8',
      toivoAsiakirjaTypeIds: ['9413c6a2-de49-4a24-8839-13836790a5d8'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VUOSITILI_PAAKIRJA_PALKKIOLASKELMA,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus0VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '9b9a4408-dd94-457e-be1b-573ee9572cd2',
      toivoAsiakirjaTypeIds: ['9b9a4408-dd94-457e-be1b-573ee9572cd2'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALTAKIRJA,
      restrictionMetadata: tilintarkastusJulkinenHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '2af41619-bcfb-4384-b217-e4f162647686',
      toivoAsiakirjaTypeIds: ['2af41619-bcfb-4384-b217-e4f162647686'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVONTASUUNNITELMA,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '2f06333e-0728-4998-b6cd-49097a024cc8',
      toivoAsiakirjaTypeIds: ['2f06333e-0728-4998-b6cd-49097a024cc8'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TOSITE,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: 'be79f99a-31f2-43a0-99ec-9152ef54618d',
      toivoAsiakirjaTypeIds: ['be79f99a-31f2-43a0-99ec-9152ef54618d'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PALKKIO_JA_KULULASKELMA,
      restrictionMetadata: tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '446f1db9-62f5-48bc-ace8-d643b2325a16',
      toivoAsiakirjaTypeIds: ['446f1db9-62f5-48bc-ace8-d643b2325a16'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: '2d7c8d8c-729b-4849-8725-538df1358e5f',
      toivoAsiakirjaTypeIds: ['2d7c8d8c-729b-4849-8725-538df1358e5f'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastus50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '16f49aee-419b-4265-9ab2-26f1b64a86ad',
      toivoAsiakirjaTypeIds: ['16f49aee-419b-4265-9ab2-26f1b64a86ad'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.TARKASTETTU_VUOSITILI_JA_PALKKIOLASKELMA_TILIN_TARKASTUSMERKINNAT,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastusPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: 'aa16270f-3c94-47a3-8e77-3155595bef11',
      toivoAsiakirjaTypeIds: ['aa16270f-3c94-47a3-8e77-3155595bef11'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.VAATIMUS_VIRHEEN_KORJAAMISESTA,
      restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: tilintarkastusPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      defaultToivoAsiakirjaTypeId: '31243639-814e-483c-843e-3262a6be9cc7',
      toivoAsiakirjaTypeIds: ['31243639-814e-483c-843e-3262a6be9cc7'],
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA,
      postDueDateHuomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.HAKIJA],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN, AsiaPersonType.ORGANISAATIO],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.TARKASTETTU,
    manualRatkaisuTypeIds: [RatkaisuType.TARKASTETTU, RatkaisuType.PALAUTTETTU_TARKASTAMATTOMANA],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.EDVARD, ViestintaType.MANUAALINEN],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.TAYDENNYSPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    // Ensitili probably don't have tiedoksianto, only paatostili
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.EDVARD, Vireilletulokanava.ITSEPALVELU, Vireilletulokanava.TOIVO],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  retentionMetadata: tilintarkastusPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
