import keyMirror from 'keymirror'
import {
  LupaAsiakirjaType,
  LupaAsiakirjaTypeId,
} from 'lupa-backend/src/vtj/elsa/asiakirja/lupa-asiakirja-enums'

export const LupaAsiointiAsiakirjaType = keyMirror({
  PERUKIRJA_ILMAN_SUKUSELVITYSTA: null,
  TESTAMENTTI_JA_SEN_TIEDOKSIANTO_JA_LAINVOIMAISUUSTODISTUKSET: null,
  AVIOEHTOSOPIMUS_JA_TODISTUS_SEN_REKISTEROINNISTA: null,
  MUU_LIITE: null,

  KAUPPAKIRJA_TAI_SEN_LUONNOS: null,
  ARVIO_ASUNNON_ARVOSTA: null,
  ISANNOITSIJANTODISTUS: null,

  KAUPPAKIRJA_TAI_SEN_LUONNOS_TAI_ESISOPIMUS: null,
  ARVIO_KIINTEISTON_ARVOSTA: null,
  MAANVUOKRASOPIMUS: null,

  ASUMISOIKEUDEN_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA: null,
  ASUMISOIKEUDEN_LUOVUTUSHINTALASKELMA: null,
  ASUMISOIKEUSSOPIMUS: null,

  SOPIMUSASIAKIRJA_TAI_SEN_LUONNOS: null,
  ARVIO_METSAN_TAI_MAA_AINEKSEN_ARVOSTA: null,
  ARVIO_PELLON_ARVOSTA: null,
  METSAN_MYYNNIN_TOIMEKSIANTOSOPIMUS_PUUKAUPAN_MAARA_JA_HINTA_ARVIO: null,

  SELVITYS_HANKINNAN_RAHOITUKSESTA: null,
  SELVITYS_MYYJAN_OMISTUSOIKEUDESTA: null,

  ASUMISOIKEUSSOPIMUS_TAI_SEN_LUONNOS: null,

  VUOKRASOPIMUS_TAI_SEN_LUONNOS: null,
  SELVITYS_VUOKRAN_RIITTAVYYDESTA: null,

  OSITUS_EROTTELU_JA_TAI_PERINNONJAKOSOPIMUS_TAI_SEN_LUONNOS: null,
  SELVITYS_OMAISUUDEN_ARVOSTA: null,
  SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA: null,
  KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT: null,
  SELVITYS_EROTELTAVAN_OMAISUUDEN_OMISTAJUUDESTA: null,

  PERINTOOSUUDEN_LUOVUTUSSOPIMUS_TAI_SEN_LUONNOS: null,

  ILMOITUS_PERINNOSTA_LUOPUMISESTA_TAI_SEN_LUONNOS: null,

  KUOLINPESAN_YHTEISHALLINTOA_KOSKEVA_SOPIMUS_TAI_SEN_LUONNOS: null,

  OSITUS_JA_TAI_EROTTELUSOPIMUS_TAI_SEN_LUONNOS: null,
  SELVITYS_OMAISUUDEN_MYYNNISTA: null,
  SELVITYS_OMAISUUDESTA_JA_VELASTA_KUN_AVIOEROA_ON_HAETTU: null,

  VELKAKIRJA_TAI_SEN_LUONNOS: null,
  SELVITYS_VELALLISEN_TALOUDELLISESTA_TILANTEESTA_JA_LAINAN_TAKAISINMAKSUKYVYSTA:
    null,
  SELVITYS_PANTISTA_LAINAN_ANNOSSA: null,
  VELALLISEN_SUOSTUMUS_LUOTTOTIETOJEN_TARKASTAMISEEN: null,

  LAINAPAATOS_VELKAKIRJALUONNOS_TAI_MUU_LAINANANTAJAN_TODISTUS_LAINAN_EHDOISTA:
    null,
  SELVITYS_PAAMIEHEN_LAINANHOITOKYVYSTA: null,
  SELVITYS_ENTISISTEN_VELKAVASTUIDEN_VOIMAANJAANNISTA: null,

  ERITYISPANTTAUSSITOUMUS_SOPIMUSLUONNOS_TAI_MUU_SELVITYS_PANTTAUKSEN_EHDOISTA:
    null,
  SELVITYS_MUISTA_VAKUUKSISTA: null,
  LAINANSAAJAN_SELVITYS_LAINANHOITOKYVYSTAAN: null,
  LAINANSAAJAN_PALKKATODISTUS: null,

  SELVITYS_PANTATTAVASTA_OMAISUUDESTA_SEN_ARVOSTA_JA_PAAMIEHEN_OMISTUSOIKEUDESTA:
    null,
  SELVITYS_ENTISISTEN_PANTTAUSTEN_VOIMAANJAANNISTA: null,

  TAKAUSSITOUMUS_TAI_SEN_LUONNOS: null,
  SELVITYS_LAINANOTTAJAN_LAINANHOITOKYVYSTA: null,
  SELVITYS_PAAMIEHEN_KYVYSTA_SELVIYTYA_TAKAUSVASTUUSTA: null,

  SELVITYS_ELINKEINOTOIMINNASTA_JA_PAAMIEHEN_VASTUISTA: null,

  YHTIOSOPIMUS_TAI_SEN_LUONNOS: null,
  TILINPAATOS_YHTIOMIEHEKSI_RYHTYESSA: null,

  SIJOITUSRAHASTON_SAANNOT_SIJOITUSKOHTEIDEN_OSTOSSA: null,
  YHTIOJARJESTYS_SIJOITUSKOHTEIDEN_OSTOSSA: null,
  TILINPAATOS_SIJOITUSKOHTEIDEN_OSTOSSA: null,
  OSUUSKUNNAN_SAANNOT_SIJOITUSKOHTEIDEN_OSTOSSA: null,
  LAINAEHDOT_SIJOITUSKOHTEIDEN_OSTOSSA: null,
  JOHDANNAISSOPIMUKSEN_EHDOT_JA_OPTIONASETTAJAN_TALOUDELLINEN_ASEMA_SIJOITUSKOHTEIDEN_OSTOSSA:
    null,

  PAAMIEHEN_LAUSUNTO: null,
  MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA: null,
  LAAKARINLAUSUNTO: null,
  MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA: null,
  VALTAKIRJA: null,
})
export type LupaAsiointiAsiakirjaTypeId =
  (typeof LupaAsiointiAsiakirjaType)[keyof typeof LupaAsiointiAsiakirjaType]

export const estateAsiakirjaTypeIds: Readonly<LupaAsiointiAsiakirjaTypeId[]> = [
  LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
  LupaAsiointiAsiakirjaType.TESTAMENTTI_JA_SEN_TIEDOKSIANTO_JA_LAINVOIMAISUUSTODISTUKSET,
  LupaAsiointiAsiakirjaType.AVIOEHTOSOPIMUS_JA_TODISTUS_SEN_REKISTEROINNISTA,
]

export const lupaAsiointiAsiakirjaToElsaMap: Readonly<
  Record<LupaAsiointiAsiakirjaTypeId, LupaAsiakirjaTypeId>
> = {
  PERUKIRJA_ILMAN_SUKUSELVITYSTA: LupaAsiakirjaType.PERUKIRJA,
  TESTAMENTTI_JA_SEN_TIEDOKSIANTO_JA_LAINVOIMAISUUSTODISTUKSET:
    LupaAsiakirjaType.TESTAMENTTI,
  AVIOEHTOSOPIMUS_JA_TODISTUS_SEN_REKISTEROINNISTA:
    LupaAsiakirjaType.AVIOEHTOSOPIMUS,
  MUU_LIITE: LupaAsiakirjaType.MUU_LIITE,
  KAUPPAKIRJA_TAI_SEN_LUONNOS_TAI_ESISOPIMUS: LupaAsiakirjaType.SOPIMUSLUONNOS,
  ARVIO_KIINTEISTON_ARVOSTA: LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  MAANVUOKRASOPIMUS: LupaAsiakirjaType.MAANVUOKRASOPIMUS,
  ASUMISOIKEUDEN_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA:
    LupaAsiakirjaType.ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
  ASUMISOIKEUDEN_LUOVUTUSHINTALASKELMA:
    LupaAsiakirjaType.ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
  ASUMISOIKEUSSOPIMUS:
    LupaAsiakirjaType.ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
  SOPIMUSASIAKIRJA_TAI_SEN_LUONNOS: LupaAsiakirjaType.SOPIMUSLUONNOS,
  ARVIO_METSAN_TAI_MAA_AINEKSEN_ARVOSTA: LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  ARVIO_PELLON_ARVOSTA: LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  METSAN_MYYNNIN_TOIMEKSIANTOSOPIMUS_PUUKAUPAN_MAARA_JA_HINTA_ARVIO:
    LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  KAUPPAKIRJA_TAI_SEN_LUONNOS: LupaAsiakirjaType.SOPIMUSLUONNOS,
  ARVIO_ASUNNON_ARVOSTA: LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  ISANNOITSIJANTODISTUS: LupaAsiakirjaType.ISANNOITSIJANTODISTUS,
  SELVITYS_HANKINNAN_RAHOITUKSESTA: LupaAsiakirjaType.MUU_LIITE,
  SELVITYS_MYYJAN_OMISTUSOIKEUDESTA: LupaAsiakirjaType.MUU_LIITE,
  ASUMISOIKEUSSOPIMUS_TAI_SEN_LUONNOS:
    LupaAsiakirjaType.ASUMISOIKEUSSOPIMUS_LUOVUTUSHINTALASKELMA_JA_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
  VUOKRASOPIMUS_TAI_SEN_LUONNOS: LupaAsiakirjaType.SOPIMUSLUONNOS,
  SELVITYS_VUOKRAN_RIITTAVYYDESTA: LupaAsiakirjaType.MUU_LIITE,
  OSITUS_EROTTELU_JA_TAI_PERINNONJAKOSOPIMUS_TAI_SEN_LUONNOS:
    LupaAsiakirjaType.SOPIMUSLUONNOS,
  SELVITYS_OMAISUUDEN_ARVOSTA: LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA:
    LupaAsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
  KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT: LupaAsiakirjaType.TILITAPAHTUMAT,
  SELVITYS_EROTELTAVAN_OMAISUUDEN_OMISTAJUUDESTA: LupaAsiakirjaType.MUU_LIITE,
  PERINTOOSUUDEN_LUOVUTUSSOPIMUS_TAI_SEN_LUONNOS:
    LupaAsiakirjaType.SOPIMUSLUONNOS,
  ILMOITUS_PERINNOSTA_LUOPUMISESTA_TAI_SEN_LUONNOS:
    LupaAsiakirjaType.SOPIMUSLUONNOS,
  KUOLINPESAN_YHTEISHALLINTOA_KOSKEVA_SOPIMUS_TAI_SEN_LUONNOS:
    LupaAsiakirjaType.SOPIMUSLUONNOS,
  OSITUS_JA_TAI_EROTTELUSOPIMUS_TAI_SEN_LUONNOS:
    LupaAsiakirjaType.SOPIMUSLUONNOS,
  SELVITYS_OMAISUUDEN_MYYNNISTA:
    LupaAsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
  SELVITYS_OMAISUUDESTA_JA_VELASTA_KUN_AVIOEROA_ON_HAETTU:
    LupaAsiakirjaType.MUU_LIITE,
  VELKAKIRJA_TAI_SEN_LUONNOS: LupaAsiakirjaType.SOPIMUSLUONNOS,
  SELVITYS_VELALLISEN_TALOUDELLISESTA_TILANTEESTA_JA_LAINAN_TAKAISINMAKSUKYVYSTA:
    LupaAsiakirjaType.SELVITYS_LAINANHOITOKYVYSTA,
  SELVITYS_PANTISTA_LAINAN_ANNOSSA: LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  VELALLISEN_SUOSTUMUS_LUOTTOTIETOJEN_TARKASTAMISEEN:
    LupaAsiakirjaType.MUU_LIITE,
  LAINAPAATOS_VELKAKIRJALUONNOS_TAI_MUU_LAINANANTAJAN_TODISTUS_LAINAN_EHDOISTA:
    LupaAsiakirjaType.SOPIMUSLUONNOS,
  SELVITYS_PAAMIEHEN_LAINANHOITOKYVYSTA:
    LupaAsiakirjaType.SELVITYS_LAINANHOITOKYVYSTA,
  SELVITYS_ENTISISTEN_VELKAVASTUIDEN_VOIMAANJAANNISTA:
    LupaAsiakirjaType.MUU_LIITE,
  ERITYISPANTTAUSSITOUMUS_SOPIMUSLUONNOS_TAI_MUU_SELVITYS_PANTTAUKSEN_EHDOISTA:
    LupaAsiakirjaType.SOPIMUSLUONNOS,
  SELVITYS_MUISTA_VAKUUKSISTA: LupaAsiakirjaType.MUU_LIITE,
  LAINANSAAJAN_SELVITYS_LAINANHOITOKYVYSTAAN:
    LupaAsiakirjaType.SELVITYS_LAINANHOITOKYVYSTA,
  LAINANSAAJAN_PALKKATODISTUS: LupaAsiakirjaType.SELVITYS_LAINANHOITOKYVYSTA,
  SELVITYS_PANTATTAVASTA_OMAISUUDESTA_SEN_ARVOSTA_JA_PAAMIEHEN_OMISTUSOIKEUDESTA:
    LupaAsiakirjaType.ARVIO_OMAISUUDESTA,
  SELVITYS_ENTISISTEN_PANTTAUSTEN_VOIMAANJAANNISTA: LupaAsiakirjaType.MUU_LIITE,
  TAKAUSSITOUMUS_TAI_SEN_LUONNOS: LupaAsiakirjaType.SOPIMUSLUONNOS,
  SELVITYS_LAINANOTTAJAN_LAINANHOITOKYVYSTA:
    LupaAsiakirjaType.SELVITYS_LAINANHOITOKYVYSTA,
  SELVITYS_PAAMIEHEN_KYVYSTA_SELVIYTYA_TAKAUSVASTUUSTA:
    LupaAsiakirjaType.SELVITYS_LAINANHOITOKYVYSTA,
  SELVITYS_ELINKEINOTOIMINNASTA_JA_PAAMIEHEN_VASTUISTA:
    LupaAsiakirjaType.MUU_LIITE,
  YHTIOSOPIMUS_TAI_SEN_LUONNOS: LupaAsiakirjaType.SOPIMUSLUONNOS,
  TILINPAATOS_YHTIOMIEHEKSI_RYHTYESSA: LupaAsiakirjaType.MUU_LIITE,
  SIJOITUSRAHASTON_SAANNOT_SIJOITUSKOHTEIDEN_OSTOSSA:
    LupaAsiakirjaType.MUU_LIITE,
  YHTIOJARJESTYS_SIJOITUSKOHTEIDEN_OSTOSSA: LupaAsiakirjaType.MUU_LIITE,
  TILINPAATOS_SIJOITUSKOHTEIDEN_OSTOSSA: LupaAsiakirjaType.MUU_LIITE,
  OSUUSKUNNAN_SAANNOT_SIJOITUSKOHTEIDEN_OSTOSSA: LupaAsiakirjaType.MUU_LIITE,
  LAINAEHDOT_SIJOITUSKOHTEIDEN_OSTOSSA: LupaAsiakirjaType.MUU_LIITE,
  JOHDANNAISSOPIMUKSEN_EHDOT_JA_OPTIONASETTAJAN_TALOUDELLINEN_ASEMA_SIJOITUSKOHTEIDEN_OSTOSSA:
    LupaAsiakirjaType.MUU_LIITE,
  PAAMIEHEN_LAUSUNTO: LupaAsiakirjaType.PAAMIEHEN_LAUSUNTO,
  MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA:
    LupaAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA,
  LAAKARINLAUSUNTO: LupaAsiakirjaType.LAAKARINLAUSUNTO,
  MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA:
    LupaAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA,
  VALTAKIRJA: LupaAsiakirjaType.VALTAKIRJA,
}
