import { AsiakirjaMetadata } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { HuomioMetadata, HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { OrganizationMetadata } from 'asia-common/src/vtj/koodisto/organization-enums'
import {
  AsiaHandlerRole,
  AsiaPersonRole,
  AsiaPersonRoleId,
  AsiaPersonType,
  AsiaPersonTypeId,
  AsiaStatus,
  RatkaisuMetadata,
  RatkaisuType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { SpecifierMetadata, SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideMetadata,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Vireilletulokanava, VireilletulokanavaId } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { AsiaTypeAsiaStatusMetadata } from 'asia-common/src/vtj/koodisto/asia-status-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { EmailMetadata, ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

// All but AET and UAET todistukset use same the TOS
// Override possible future differences in asia type specific file

export const commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const commonTodistuksetJulkinenRestrictionMetadata: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const commonTodistuksetRetentionMetadata: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_5_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_5_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const commonTodistuksetAsiakirjaMetadata: AsiakirjaMetadata[] = [
  {
    asiakirjaTypeId: AsiakirjaType.TODISTUSTILAUS,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['d377299d-ee78-4cf6-bc07-12508817fb75'],
    defaultToivoAsiakirjaTypeId: 'd377299d-ee78-4cf6-bc07-12508817fb75',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VERKKOLOMAKKEELLA_SAAPUNUT_TODISTUSTILAUS_TUNNISTAUTUNUT,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['6544fc45-f1f5-4ab2-9733-59934603e30a'],
    defaultToivoAsiakirjaTypeId: '6544fc45-f1f5-4ab2-9733-59934603e30a',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VERKKOLOMAKKEELLA_SAAPUNUT_TODISTUSTILAUS_TUNNISTAUTUMATON,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['b992d69b-cae4-434e-a54e-5a020bd9d447'],
    defaultToivoAsiakirjaTypeId: 'b992d69b-cae4-434e-a54e-5a020bd9d447',
  },
  {
    asiakirjaTypeId: AsiakirjaType.OMIEN_TIETOJEN_TARKASTUSOIKEUDEN_KAYTTAMINEN_TILAUS,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['9524af2e-8874-4848-8b9b-70eb0f60bd31'],
    defaultToivoAsiakirjaTypeId: '9524af2e-8874-4848-8b9b-70eb0f60bd31',
  },
  {
    asiakirjaTypeId: AsiakirjaType.HALLINTOOIKEUDEN_PAATOS,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['35789b1b-b713-4977-a824-97a096e2e9ee'],
    defaultToivoAsiakirjaTypeId: '35789b1b-b713-4977-a824-97a096e2e9ee',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['1512dded-d5ab-4da7-8247-43c998b25626'],
    defaultToivoAsiakirjaTypeId: '1512dded-d5ab-4da7-8247-43c998b25626',
  },
  {
    asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['0a14ea35-000b-4754-8b47-7e3cc015a134'],
    defaultToivoAsiakirjaTypeId: '0a14ea35-000b-4754-8b47-7e3cc015a134',
  },
  {
    asiakirjaTypeId: AsiakirjaType.TODISTUS_VAESTOTIETOJARJESTELMASTA,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['c9e65eb7-df78-4d5e-9469-b03d609cd9f3'],
    defaultToivoAsiakirjaTypeId: 'c9e65eb7-df78-4d5e-9469-b03d609cd9f3',
  },
  {
    asiakirjaTypeId: AsiakirjaType.EU_VAKIOLOMAKE,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['673da113-978f-467f-99b3-c97f493bee95'],
    defaultToivoAsiakirjaTypeId: '673da113-978f-467f-99b3-c97f493bee95',
  },
  {
    asiakirjaTypeId: AsiakirjaType.APOSTILLE,
    restrictionMetadata: commonTodistuksetJulkinenRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['7a81dc88-e201-4aaf-a909-25551992ef83'],
    defaultToivoAsiakirjaTypeId: '7a81dc88-e201-4aaf-a909-25551992ef83',
  },
  {
    asiakirjaTypeId: AsiakirjaType.KIELTEINEN_PAATOS_RAUKEAMINEN,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['1ddb9507-6fe9-44d8-ba33-5e337c6663bf'],
    defaultToivoAsiakirjaTypeId: '1ddb9507-6fe9-44d8-ba33-5e337c6663bf',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUUTOKSENHAKUOHJE,
    restrictionMetadata: commonTodistuksetJulkinenRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['eba13857-3eb6-47b8-958b-b4b57703a06c'],
    defaultToivoAsiakirjaTypeId: 'eba13857-3eb6-47b8-958b-b4b57703a06c',
  },
  {
    asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOHJE,
    restrictionMetadata: commonTodistuksetJulkinenRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['beb63852-a952-442e-a718-162ba058cba6'],
    defaultToivoAsiakirjaTypeId: 'beb63852-a952-442e-a718-162ba058cba6',
  },
  {
    asiakirjaTypeId: AsiakirjaType.ILMOITUS_ETTEI_TIETOJA_VOIDA_LUOVUTTAA,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['a7ceac7e-46a7-46eb-bc1f-df9b243c4bf2'],
    defaultToivoAsiakirjaTypeId: 'a7ceac7e-46a7-46eb-bc1f-df9b243c4bf2',
  },
  {
    asiakirjaTypeId: AsiakirjaType.SAATE_TAI_LAHETE,
    restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
    retentionMetadata: commonTodistuksetRetentionMetadata,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    toivoAsiakirjaTypeIds: ['27c498b2-e6b1-4742-903c-ccd687267f9f'],
    defaultToivoAsiakirjaTypeId: '27c498b2-e6b1-4742-903c-ccd687267f9f',
  },
]

export const commonTodistuksetHuomioMetadata: HuomioMetadata[] = [
  {
    huomioTypeId: HuomioType.TURVAKIELTO,
    postDueDateHuomioTypeId: null,
  },
  {
    huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
    postDueDateHuomioTypeId: null,
  },
  {
    huomioTypeId: HuomioType.KIIREELLINEN,
    postDueDateHuomioTypeId: null,
  },
  {
    huomioTypeId: HuomioType.KIIREHDITTY,
    postDueDateHuomioTypeId: null,
  },
  {
    huomioTypeId: HuomioType.ODOTTAA_VAESTOTIEDON_REKISTEROITYMISTA,
    postDueDateHuomioTypeId: null,
  },
  {
    huomioTypeId: HuomioType.TODISTUS_PALAUTUNUT_VIRASTOON,
    postDueDateHuomioTypeId: null,
  },
]

export const commonTodistuksetOrganizationMetadata: OrganizationMetadata = {
  personRoleIds: [],
  organizationTypeIds: [],
}

export const commonTodistuksetPersonRoleMetadata: AsiaPersonRoleId[] = [AsiaPersonRole.ASIAKAS]

export const commonTodistuksetPersonTypeMetadata: AsiaPersonTypeId[] = [AsiaPersonType.IHMINEN]

export const commonTodistuksetRatkaisuMetadata: RatkaisuMetadata = {
  defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
  manualRatkaisuTypeIds: [
    RatkaisuType.RATKAISTU,
    RatkaisuType.HYLATTY,
    RatkaisuType.RAUENNUT,
    RatkaisuType.SILLENSA_JATETTY,
  ],
  automaticRatkaisuTypeIds: [],
}

export const commonTodistuksetSpecifierMetadata: SpecifierMetadata = {
  multipleSpecifiers: true,
  mandatorySpecifier: false,
  specifierTypeIds: [SpecifierType.APOSTILLE, SpecifierType.EIDAS, SpecifierType.EU_KAANNOSLOMAKE],
}

export const commonTodistuksetToimenpideMetadata: ToimenpideMetadata[] = [
  {
    toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
    internalViestintaTypeIds: [ViestintaType.MANUAALINEN, ViestintaType.TOIVO_POSTITUS, ViestintaType.TURVASAHKOPOSTI],
    externalViestintaTypeIds: [],
    prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.TODISTUS_VAESTOTIETOJARJESTELMASTA],
    prioritizedSaapunutAsiakirjaTypeIds: [],
    automationSaapunutAsiakirjaTypeId: null,
    huomioMetadata: null,
  },
  {
    toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
    internalViestintaTypeIds: [ViestintaType.MANUAALINEN, ViestintaType.TOIVO_POSTITUS, ViestintaType.TURVASAHKOPOSTI],
    externalViestintaTypeIds: [],
    prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
    prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
    automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
    huomioMetadata: {
      [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_SELVITYSTA,
      [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.SELVITYS_SAAPUNUT,
      [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT,
      showInHuomioFilter: true,
    },
  },
]

export const commonTodistuksetVireilletulokanavaMetadata: VireilletulokanavaId[] = [
  Vireilletulokanava.ELOMAKE_KANSALAINEN,
  Vireilletulokanava.ITSEPALVELU,
]

export const commonTodistuksetAsiaStatusMetadata: AsiaTypeAsiaStatusMetadata[] = [
  {
    asiaStatusId: AsiaStatus.ALOITTAMATTA,
    toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
    handlerRoleId: null,
    defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
    defaultMovableStatusId: null,
  },
  {
    asiaStatusId: AsiaStatus.KASITTELYSSA,
    toimenpideTypeId: InternalToimenpideType.VALMISTELU,
    handlerRoleId: AsiaHandlerRole.KASITTELIJA,
    defaultStartableStatusId: null,
    defaultMovableStatusId: null,
  },
  {
    asiaStatusId: AsiaStatus.VALMIS,
    toimenpideTypeId: null,
    handlerRoleId: null,
    defaultStartableStatusId: null,
    defaultMovableStatusId: null,
  },
]

export const commonTodistuksetEmailMetadata: EmailMetadata = {
  fromAddress: 'vtj-todistus@dvv.fi',
  useUserEmailIfPossible: false,
}
