import i18next, { i18n, Module } from 'i18next'
import fp from 'lodash/fp'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'

type TranslationResource = any // eslint-disable-line @typescript-eslint/no-explicit-any
export type NamespacedBundle = {
  namespace: string
  translations: Partial<Record<LanguageCode, TranslationResource>>
}

export type CreateInstanceOptions = {
  defaultBundle: NamespacedBundle
  additionalBundles?: NamespacedBundle[] // Order defines order of fallback namespaces
  language?: LanguageCode | 'cimode'
  modules?: Module[]
}

export function createI18nInstance({
  defaultBundle,
  additionalBundles = [],
  language = LanguageCode.FI,
  modules = [],
}: CreateInstanceOptions): i18n {
  const instance = i18next.createInstance()

  const lng = fp.any({ type: 'languageDetector' }, modules)
    ? undefined
    : language
  const defaultNS = defaultBundle.namespace
  const allBundles = [defaultBundle].concat(additionalBundles)
  const fallbackNS = getFallbackNamespaces(allBundles)
  const resources = combineBundles(allBundles)

  modules.forEach((module) => instance.use(module))

  void instance.init({
    defaultNS,
    fallbackNS,
    resources,
    interpolation: {
      escapeValue: false, // Not needed in React
    },
    lng,
    initImmediate: false, // Makes the init function synchronous. Otherwise .init will return a promise.
  })

  return instance
}

export function combineBundles(
  bundles: NamespacedBundle[]
): Record<LanguageCode, Record<string, TranslationResource>> {
  return fp.reduce(
    (resources, bundle) => {
      const translationLanguage = Object.getOwnPropertyNames(
        bundle.translations
      ) as LanguageCode[]
      translationLanguage.forEach((lang) => {
        if (!resources[lang]) {
          resources[lang] = {}
        }
        resources[lang][bundle.namespace] = bundle.translations[lang]
      })
      return resources
    },
    {} as Record<LanguageCode, Record<string, TranslationResource>>,
    bundles
  )
}

export function getFallbackNamespaces(bundles: NamespacedBundle[]): string[] {
  return bundles.map((bundle) => bundle.namespace)
}
