import Asuminen from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Asuminen'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import React from 'react'
import { observer } from 'mobx-react'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import OmassaKaytossaOlevaOmaisuus from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/OmassaKaytossaOlevaOmaisuus'
import Toimintakyky from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Toimintakyky'
import Varat from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Varat'
import Velat from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Velat'
import SaannollisetTulot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/SaannollisetTulot'
import Kayttovarat from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Kayttovarat'
import Verotiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Verotiedot'
import Tulot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Tulot'
import Menot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Menot'
import Hallintaoikeudet from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Hallintaoikeudet'
import Sopimukset from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Sopimukset'
import Vakuutukset from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Vakuutukset'
import Yhteystiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Yhteystiedot'
import Tiedoksianto from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Tiedoksianto'
import Testamentti from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Testamentti'
import MuutVarat from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/MuutVarat'
import Palkkio from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/Palkkio'
import { Block } from 'suomifi-ui-components'
import MuutMerkittavatToimet from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/MuutMerkittavatToimet'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import OmassaKaytossaPankkitilit from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/OmassaKaytossaPankkitilit'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const Yhteenveto = observer(() => {
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const {
    isPrincipalUnderaged,
    lomake: { tili },
  } = getTilintarkastusStore()

  return (
    <Block
      mt={verticalMarginToken}
      data-test-id={mkYhteenvetoTestId('container')}
    >
      <Asuminen />
      <DividerLine mb={verticalMarginToken} />

      {tili.asiaType === 'OMAISUUSLUETTELO' && !isPrincipalUnderaged && (
        <>
          <Toimintakyky />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}

      {tili.asiaType === 'OMAISUUSLUETTELO' && (
        <>
          <OmassaKaytossaOlevaOmaisuus />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}

      <Varat />
      <DividerLine mb={verticalMarginToken} />

      <Velat />
      <DividerLine mb={verticalMarginToken} />

      {tili.asiaType !== 'OMAISUUSLUETTELO' && (
        <>
          <Tulot />
          <DividerLine mb={verticalMarginToken} />

          <Menot />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}

      <SaannollisetTulot />
      <DividerLine mb={verticalMarginToken} />

      <Verotiedot />
      <DividerLine mb={verticalMarginToken} />

      {!isPrincipalUnderaged && (
        <>
          <Kayttovarat />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}

      {tili.asiaType === 'OMAISUUSLUETTELO' && (
        <>
          <OmassaKaytossaPankkitilit />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}
      {tili.asiaType === 'OMAISUUSLUETTELO' && (
        <>
          <MuutVarat />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}

      <Sopimukset />
      <DividerLine mb={verticalMarginToken} />

      <Vakuutukset />
      <DividerLine mb={verticalMarginToken} />

      <Hallintaoikeudet />
      <DividerLine mb={verticalMarginToken} />

      <MuutMerkittavatToimet />
      <DividerLine mb={verticalMarginToken} />

      {tili.asiaType === 'OMAISUUSLUETTELO' && !isPrincipalUnderaged && (
        <>
          <Testamentti />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}

      {tili.asiaType !== 'OMAISUUSLUETTELO' && (
        <>
          <Palkkio />
          <DividerLine mb={verticalMarginToken} />
        </>
      )}

      <Yhteystiedot />

      {tili.asiaType === 'PAATOSTILI' && (
        <>
          <DividerLine mb={verticalMarginToken} />
          <Tiedoksianto />
        </>
      )}
    </Block>
  )
})

export default Yhteenveto
