import { observer } from 'mobx-react'
import {
  RadioButton,
  RadioButtonGroup,
  StatusText,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import React, { ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { lowerFirst } from 'lodash'
import { createOnChangeEvent } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import styled from 'styled-components'

type FormRadioButtonGroupProps = {
  'data-test-id': string
  labelText: string
  labelNode?: ReactNode
  groupHintText?: string
  value?: string
  required?: boolean
  updateValue: (value: string) => void
  requiredLabel?: string
  radioButtons: { value: string; labelText: string }[]
}

const FormRadioButtonGroup: React.FC<FormRadioButtonGroupProps> = observer(
  ({
    'data-test-id': dataTestId,
    labelText,
    labelNode,
    groupHintText = '',
    value,
    required = false,
    updateValue,
    requiredLabel,
    radioButtons,
    ...rest
  }) => {
    const [t] = useTranslation()
    const {
      register,
      formState: { errors },
    } = useFormContext()

    const { onChange, ref, name } = register(dataTestId, {
      validate: (currentValue: string) => {
        if (
          required &&
          !radioButtons.map(({ value }) => value).includes(currentValue)
        ) {
          return requiredLabel ?? `${t('valitse')}, ${lowerFirst(labelText)}`
        }
      },
      onChange: (event) => updateValue(event.target.value),
      value: value,
    })

    return (
      <>
        <RadioButtonGroup
          data-test-id={dataTestId}
          data-test-error-message={
            (errors[dataTestId]?.message || '') as string
          }
          value={value}
          labelText={labelNode ?? labelText}
          groupHintText={groupHintText}
          required
          name={name}
          onChange={(value) => onChange(createOnChangeEvent(value, dataTestId))}
          ref={ref}
          {...rest}
        >
          {radioButtons.map(({ labelText, value }) => (
            <StyledRadioButton
              key={value}
              data-test-id={dataTestId + '-button-' + value}
              value={value}
              variant="small"
              $cssError={!!errors[dataTestId]}
            >
              {labelText}
            </StyledRadioButton>
          ))}
        </RadioButtonGroup>
        {errors[dataTestId] && (
          <StatusText status={'error'} mt={'xxs'} ariaLiveMode={'assertive'}>
            {(errors[dataTestId]?.message || '') as string}
          </StatusText>
        )}
      </>
    )
  }
)

export default FormRadioButtonGroup

// Todo remove this when design system implements error state for radio button
const radioAlertStroke = ` 
stroke: ${suomifiDesignTokens.colors.alertBase} !important;
stroke-width: 1.5px;
`

const StyledRadioButton = styled(RadioButton)<{ $cssError: boolean }>`
  &&& {
    .fi-icon-radio-base {
      ${({ $cssError }) => ($cssError ? radioAlertStroke : '')};
    }
  }
`
