import React, { ForwardedRef, HTMLProps, useEffect, useRef } from 'react'
import { Block, Button, InlineAlert, Link } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const UnexpectedError: React.FC<
  {
    alertLabelText?: string
    alertContentText?: string
    error?: Error
  } & Omit<HTMLProps<HTMLDivElement>, 'as'> & {
      ref?: ForwardedRef<HTMLDivElement>
    }
> = ({ alertLabelText, alertContentText, error, ...rest }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const reloadButtonRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    if (reloadButtonRef.current) {
      reloadButtonRef.current.focus()
    }
  }, [])
  useEffect(() => {
    // Log error instance once, not on every render
    if (error) {
      console.error(error)
    }
  }, [error])

  return (
    <CenteredWidthLimitedDiv paddingX paddingY {...rest}>
      <InlineAlert
        smallScreen={isTablet}
        status="error"
        labelText={alertLabelText ?? t('tapahtuiTuntematonVirhe')}
      >
        {alertContentText ?? t('yritaHetkenKuluttuaLadataSivuUudelleen')}
      </InlineAlert>
      <Block mb={isTablet ? 'l' : 'm'} />
      <Button
        variant="secondary"
        onClick={() => window.location.reload()}
        ref={reloadButtonRef}
      >
        {t('lataaSivuUudelleen')}
      </Button>
      <Block mb={isTablet ? 'l' : 'm'} />
      <Link href="/" variant="accent" smallScreen={!isTablet}>
        {t('siirryEdunvalvonnanAsiointipalvelunEtusivulle')}
      </Link>
    </CenteredWidthLimitedDiv>
  )
}

export default UnexpectedError
