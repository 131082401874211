import {
  AsiointiLupaKeyword,
  AsiointiLupaKeywordId,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import CurrentStepDisplay from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/common/CurrentStepDisplay'
import { mkLupaFilterTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  Block,
  Button,
  Chip,
  IconCheck,
  IconRemove,
  Text,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'

interface LupaKeyWordProps {
  selected?: boolean
}

const LupaFilter: React.FC<{
  selectedKeywords: AsiointiLupaKeywordId[]
  onChange: (keyword: AsiointiLupaKeywordId) => unknown
  clearFilters: () => unknown
}> = observer(({ selectedKeywords, onChange, clearFilters }) => {
  const asiointiLupaKeywords = Object.keys(
    AsiointiLupaKeyword
  ) as AsiointiLupaKeywordId[]
  const [t] = useTranslation()

  return (
    <ResponsiveBorderedContentBlock>
      <CurrentStepDisplay heading={t('hakuHeading')} />
      <Block mt="s" />
      <Text smallScreen variant="bold">
        {t('rajaaAiheita')}
      </Text>
      <Block mt="xs" />
      {asiointiLupaKeywords.map((asiointiKeyword) => {
        const isSelected = selectedKeywords.includes(asiointiKeyword)
        return (
          <LupaKeyWord
            selected={isSelected}
            key={asiointiKeyword}
            data-test-id={mkLupaFilterTestId(asiointiKeyword)}
            actionLabel={isSelected ? t('deselect') : t('select')}
            aria-pressed={isSelected}
            type="button"
            onClick={() => {
              onChange(asiointiKeyword)
            }}
          >
            {isSelected && <IconCheck />}
            {t(`asiointiLupaKeyword-${asiointiKeyword}`)}
          </LupaKeyWord>
        )
      })}
      <Block mt="s" />
      {selectedKeywords.length > 0 && (
        <Button
          variant="secondary"
          type="button"
          onClick={() => clearFilters()}
          data-test-id={mkLupaFilterTestId('clear')}
          icon={<IconRemove />}
        >
          {t('poistaRajaukset')}
        </Button>
      )}
    </ResponsiveBorderedContentBlock>
  )
})

export default LupaFilter

const LupaKeyWord = styled(Chip)<LupaKeyWordProps>`
  margin-right: ${suomifiDesignTokens.spacing.xs};
  margin-bottom: ${suomifiDesignTokens.spacing.xs};
  padding: ${suomifiDesignTokens.spacing.xs};
  border: 1px solid ${suomifiDesignTokens.colors.highlightBase};

  &&& {
    background-color: ${(props) =>
      props.selected
        ? suomifiDesignTokens.colors.highlightBase
        : suomifiDesignTokens.colors.whiteBase};
    color: ${(props) =>
      props.selected
        ? suomifiDesignTokens.colors.whiteBase
        : suomifiDesignTokens.colors.highlightBase};
  }
  .fi-icon {
    margin-right: ${suomifiDesignTokens.spacing.xxs};
    width: 10px;
    height: 10px;
  }
  cursor: pointer;
`
