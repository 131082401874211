import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

export type AsiointiLedgerAccountAssetsType = Extract<
  AsiointiLedgerAccountType,
  | AsiointiLedgerAccountType.EDUNVALVONTATILI
  | AsiointiLedgerAccountType.KAYTTOTILI
  | AsiointiLedgerAccountType.VUOKRAVAKUUSTILI
  | AsiointiLedgerAccountType.OMAISUUDEN_HOITOTILI
  | AsiointiLedgerAccountType.SIJOITUSTILI
  | AsiointiLedgerAccountType.ERAANTYVA_SIJOITUSTILI
  | AsiointiLedgerAccountType.VAKUUTUSSAASTOTILI
  | AsiointiLedgerAccountType.OSAKKEET
  | AsiointiLedgerAccountType.OSUUDET
  | AsiointiLedgerAccountType.JOUKKOVELKAKIRJALAINAT
  | AsiointiLedgerAccountType.RAHASTO_OSUUDET
  | AsiointiLedgerAccountType.ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT
  | AsiointiLedgerAccountType.KIINTEISTOT
  | AsiointiLedgerAccountType.OSUUDET_KUOLINPESISSA
  | AsiointiLedgerAccountType.SAATAVAT
  | AsiointiLedgerAccountType.IRTAIMISTO
  | AsiointiLedgerAccountType.MUUT_VARAT
>

export const AsiointiLedgerAccountAssetsType = {
  [AsiointiLedgerAccountType.EDUNVALVONTATILI]:
    AsiointiLedgerAccountType.EDUNVALVONTATILI,
  [AsiointiLedgerAccountType.KAYTTOTILI]: AsiointiLedgerAccountType.KAYTTOTILI,
  [AsiointiLedgerAccountType.VUOKRAVAKUUSTILI]:
    AsiointiLedgerAccountType.VUOKRAVAKUUSTILI,
  [AsiointiLedgerAccountType.OMAISUUDEN_HOITOTILI]:
    AsiointiLedgerAccountType.OMAISUUDEN_HOITOTILI,
  [AsiointiLedgerAccountType.SIJOITUSTILI]:
    AsiointiLedgerAccountType.SIJOITUSTILI,
  [AsiointiLedgerAccountType.ERAANTYVA_SIJOITUSTILI]:
    AsiointiLedgerAccountType.ERAANTYVA_SIJOITUSTILI,
  [AsiointiLedgerAccountType.VAKUUTUSSAASTOTILI]:
    AsiointiLedgerAccountType.VAKUUTUSSAASTOTILI,
  [AsiointiLedgerAccountType.OSAKKEET]: AsiointiLedgerAccountType.OSAKKEET,
  [AsiointiLedgerAccountType.OSUUDET]: AsiointiLedgerAccountType.OSUUDET,
  [AsiointiLedgerAccountType.JOUKKOVELKAKIRJALAINAT]:
    AsiointiLedgerAccountType.JOUKKOVELKAKIRJALAINAT,
  [AsiointiLedgerAccountType.RAHASTO_OSUUDET]:
    AsiointiLedgerAccountType.RAHASTO_OSUUDET,
  [AsiointiLedgerAccountType.ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT]:
    AsiointiLedgerAccountType.ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT,
  [AsiointiLedgerAccountType.KIINTEISTOT]:
    AsiointiLedgerAccountType.KIINTEISTOT,
  [AsiointiLedgerAccountType.OSUUDET_KUOLINPESISSA]:
    AsiointiLedgerAccountType.OSUUDET_KUOLINPESISSA,
  [AsiointiLedgerAccountType.SAATAVAT]: AsiointiLedgerAccountType.SAATAVAT,
  [AsiointiLedgerAccountType.IRTAIMISTO]: AsiointiLedgerAccountType.IRTAIMISTO,
  [AsiointiLedgerAccountType.MUUT_VARAT]: AsiointiLedgerAccountType.MUUT_VARAT,
} as const
