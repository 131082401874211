import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  getElakkeetContent,
  getEtuudetContent,
  getKorkotulotContent,
  getMuutTulotContent,
  getMyyntitulotContent,
  getOsingotContent,
  getPalkatContent,
  getTyottomyysturvaContent,
  getVuokratContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/tulot.util'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const Tulot: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getPaatostili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const valittuLomakkeelle =
    getTilintarkastusStore().lomake.lomakkeelleValitutTiedot

  return (
    <section data-test-id={mkYhteenvetoTestId('tulot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('tulotHeading')}
      </Heading>
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('elaketulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['elaketulot'] ? getElakkeetContent(tili, t) : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('palkkatulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['palkkatulot'] ? getPalkatContent(tili, t) : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('etuustulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['etuustulot'] ? getEtuudetContent(tili, t) : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('tyottomyysturvatulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['tyottomyysturvatulot']
            ? getTyottomyysturvaContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('vuokratulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['vuokratulot'] ? getVuokratContent(tili, t) : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('osinkotulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['osinkotulot'] ? getOsingotContent(tili, t) : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('korkotulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['korkotulot'] ? getKorkotulotContent(tili, t) : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('myyntitulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['myyntitulot']
            ? getMyyntitulotContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('muutTulotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          valittuLomakkeelle['muutTulot'] ? getMuutTulotContent(tili, t) : []
        }
      />
    </section>
  )
})

export default Tulot
