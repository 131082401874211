import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { observer } from 'mobx-react'
import { Checkbox } from 'suomifi-ui-components'
import { createOnChangeEvent } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'

export type FormCheckboxInputProps = {
  'data-test-id': string
  labelText?: string
  optionalText?: string
  hintText?: string
  checked?: boolean
  defaultValue?: boolean
  required?: boolean
  updateValue: (value: boolean) => void
  requiredLabel?: string
}

const FormCheckboxInput: React.FC<FormCheckboxInputProps> = observer(
  ({
    'data-test-id': dataTestId,
    labelText,
    hintText,
    defaultValue,
    required = false,
    updateValue,
    requiredLabel,
    ...rest
  }) => {
    const {
      formState: { errors },
    } = useFormContext()

    return (
      <Controller
        rules={{
          validate: (value: boolean) => {
            if (value) {
              return
            }
            if (required) {
              return requiredLabel
            }
          },
          onChange: (event) => {
            updateValue(event.target.value)
          },
        }}
        name={dataTestId}
        defaultValue={defaultValue}
        render={({ field: { ref, onChange, value } }) => (
          <Checkbox
            data-test-id={dataTestId}
            status={errors[dataTestId] ? 'error' : 'default'}
            aria-live="off"
            data-test-error-message={
              (errors[dataTestId]?.message || '') as string
            }
            statusText={(errors[dataTestId]?.message || '') as string}
            onClick={({ checkboxState }) => {
              onChange(createOnChangeEvent(checkboxState, dataTestId))
            }}
            ref={ref}
            checked={value}
            {...{ hintText, ...rest }}
          >
            {labelText}
          </Checkbox>
        )}
      />
    )
  }
)

export default FormCheckboxInput
