import styled from 'styled-components'
import { InlineAlert, suomifiDesignTokens } from 'suomifi-ui-components'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const ColoredInlineAlert = (color: string) => styled(InlineAlert)`
  &&& {
    background: ${suomifiDesignTokens.colors.whiteBase};
    // combines (with other styles) to 20 20 20 40
    padding: 5px 10px 5px 30px;
    border-top-color: ${suomifiDesignTokens.colors.depthBase};
    border-right-color: ${suomifiDesignTokens.colors.depthBase};
    border-bottom-color: ${suomifiDesignTokens.colors.depthBase};
    border-left-color: ${color};
  }
`
