export const alandMunicipalities = [
  '035', // Brändö
  '043', // Eckerö
  '060', // Finström
  '062', // Föglö
  '065', // Geta
  '076', // Hammarland
  '170', // Jomala
  '295', // Kumlinge
  '318', // Kökar
  '417', // Lemland
  '438', // Lumparland
  '478', // Maarianhamina
  '736', // Saltvik
  '766', // Sottunga
  '771', // Sund
  '941', // Vårdö
]
export const FOREIGN_MUNICIPALITY = '200' // Ulkomaat
export const UNKNOWN_MUNICIPALITY = '199' // Tuntematon
export const NO_MUNICIPALITY_IN_FINLAND = '198' // Ei kotikuntaa Suomessa
export const invalidMunicipalities = [
  UNKNOWN_MUNICIPALITY,
  NO_MUNICIPALITY_IN_FINLAND,
  FOREIGN_MUNICIPALITY,
]

export const isAlandMunicipality = (municipality) =>
  alandMunicipalities.includes(municipality)
export const isForeignMunicipality = (municipality) =>
  municipality === FOREIGN_MUNICIPALITY
export const isValidMunicipality = (municipality) =>
  !invalidMunicipalities.includes(municipality)
