import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Paragraph } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import KayttovaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/KayttovaratForm'
import { FormProvider } from 'react-hook-form'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import MuutToimetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/MuutToimetForm'
import VerotiedotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/VerotiedotForm'
import HallintaoikeudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/HallintaoikeudetForm'
import SaannollisetTulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/SaannollisetTulotForm'
import SopimuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/SopimuksetForm'
import {
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { AsuinpaikkaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/AsuinpaikkaForm'
import VakuutuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/VakuutuksetForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const YleiskatsausPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)
  const { isPrincipalUnderaged } = getTilintarkastusStore()

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkYleiskatsausTestId('container')}
        >
          <PageStepHeading
            heading={t('yleiskatsaus')}
            steps={PAATOSTILI_STEPS}
          />
          <FormErrorSummary ref={onErrorRef} />

          <Paragraph>
            {t('ohjeYleiskatsausLisaaTiedotTilanteesta')}{' '}
            {t('ohjeYleiskatsausLisaaLiittyvatAsiakirjat')}
          </Paragraph>
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <AsuinpaikkaForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          {!isPrincipalUnderaged && (
            <>
              <KayttovaratForm />
              <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            </>
          )}

          <SaannollisetTulotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <SopimuksetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <VakuutuksetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <VerotiedotForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <HallintaoikeudetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <MuutToimetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default YleiskatsausPage
