import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const NoShow = styled.div`
  display: none;
`

const RobotOnMountDetector: React.FunctionComponent = () => {
  const [isRendered, setIsRendered] = useState(false)
  const testId = isRendered
    ? 'robot-on-mount-detector-ready'
    : 'robot-on-mount-detector-not-ready'

  useEffect(() => setIsRendered(true), [])

  return <NoShow data-test-id={testId} />
}

RobotOnMountDetector.displayName = 'RobotOnMountDetector'
RobotOnMountDetector.propTypes = {}
RobotOnMountDetector.defaultProps = {}

export default RobotOnMountDetector
