import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import { Heading, Paragraph, Text } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import {
  lupaApplicationStepOrder,
  resolveStepByPath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { mkHakemusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import {
  getStore,
  runAsiointiStoreAction,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const CurrentStepDisplay: React.FC<{
  heading: string
}> = observer(({ heading }) => {
  const [t] = useTranslation()
  const { pathname } = useLocation()
  const isTablet = useDeviceContext().tablet
  const step = resolveStepByPath(pathname)
  const currentStep = lupaApplicationStepOrder.indexOf(step) + 1
  const numberOfSteps = lupaApplicationStepOrder.length
  const store = getStore()

  // useAutoFocus focuses on component mount. The effect below adds a visited step on mount.
  // => Autofocus on step number + header only when navigating to next step or back, without changing
  // the focus on initial load. The initial load's focus is handled elsewhere.
  const ref = useAutoFocus<HTMLDivElement>()
  useEffect(() => {
    runAsiointiStoreAction((store) => {
      store.visitedSteps.add(step)
    })
  }, [store, step])

  return (
    <FocusableDiv
      id={mkHakemusTestId('current-step-container')}
      tabIndex={-1}
      ref={store.visitedSteps.size > 0 ? ref : undefined}
      data-test-id={mkHakemusTestId(step, 'current-step-container')}
    >
      <Paragraph mb={isTablet ? 'm' : 'xs'}>
        <Text
          smallScreen
          data-test-id={mkHakemusTestId(step, 'current-step-display')}
        >
          {t('vaihe')} {currentStep}/{numberOfSteps}
        </Text>
      </Paragraph>
      <Heading variant="h2">{heading}</Heading>
    </FocusableDiv>
  )
})

export default CurrentStepDisplay
