import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'

export const getLandingLupaHelpUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/intressebevakaren-behover-tillstand'
    default:
      return 'https://dvv.fi/milloin-edunvalvoja-tarvitsee-luvan'
  }
}

export const getLandingTilinpaatosHelpUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/slutredovisning-nar-intressebevakningen-upphor'
    default:
      return 'https://dvv.fi/paatostili-edunvalvonnan-paattyessa'
  }
}

export const getOverviewAdultHelpUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/intressebevakning-for-vuxna'
    default:
      return 'https://dvv.fi/aikuisen-edunvalvonta'
  }
}

export const getOverviewMinorHelpUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/intressebevakning-for-en-minderarig'
    default:
      return 'https://dvv.fi/alaikaisen-edunvalvonta'
  }
}

export const getOverviewEvTasksHelpUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/intressebevakarens-uppgifter'
    default:
      return 'https://dvv.fi/edunvalvojan-tehtavat'
  }
}

export const getLupaFeedbackUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://laatutyokalut.suomi.fi/p/imA4?language=sv'
    default:
      return 'https://laatutyokalut.suomi.fi/p/imA4?language=fi'
  }
}

export const getTiliFeedbackUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://laatutyokalut.suomi.fi/p/a8aJ?language=sv'
    default:
      return 'https://laatutyokalut.suomi.fi/p/a8aJ?language=fi'
  }
}

export const getOverviewWhenLupaHelpUrl = getLandingLupaHelpUrl

export const dvvContactInfoUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/privatpersonernas-kundbetjaning'
    default:
      return 'https://dvv.fi/henkiloasiakkaiden-asiakaspalvelu'
  }
}

export const tietosuojaselosteUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/dataskyddsbeskrivining-e-tjansten-for-intressebevakning'
    default:
      return 'https://dvv.fi/tietosuojaseloste-edunvalvonnan-asiointipalvelu'
  }
}

export const saavutettavuusselosteUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/tillganglighet-e-tjansten-for-intressebevakning'
    default:
      return 'https://dvv.fi/saavutettavuusseloste-edunvalvonnan-asiointipalvelu'
  }
}

export const kasittelyAjatUrl = (lang: HolhousAsiointiLanguageCode): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/handlaggningstiderna-vid-kundtjansten-for-privatpersoner'
    default:
      return 'https://dvv.fi/henkiloasiakkaiden-kasittelyajat'
  }
}

export const cookiePageUrl = '/evasteet'

export const lomakkeetUrl = (lang: HolhousAsiointiLanguageCode): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/blanketter'
    default:
      return 'https://dvv.fi/lomakkeet'
  }
}

export const omaisuusluetteloOhjeUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/egendomsforteckning-nar-intressebevakningen-inleds-'
    default:
      return 'https://dvv.fi/omaisuusluettelo-edunvalvonnan-alkaessa'
  }
}

export const omaisuusluetteloOhjeAlaikaiselleUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/egendomsforteckning-nar-intressebevakningen-inleds'
    default:
      return 'https://dvv.fi/alaikaisen-omaisuusluettelo'
  }
}

export const getEdunvalvonnanAsiointiFeedbackUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://laatutyokalut.suomi.fi/p/BXFi?language=sv'
    default:
      return 'https://laatutyokalut.suomi.fi/p/BXFi?language=fi'
  }
}

export const vuositiliOhjeAlaikaiselleUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/arsredovisning-under-intressebevakningen1'
    default:
      return 'https://dvv.fi/alaikaisen-vuositilin-laadinta'
  }
}

export const vuositiliOhjeTaysikaiselleUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/arsredovisning-under-intressebevakningen'
    default:
      return 'https://dvv.fi/vuositili-edunvalvonnan-aikana'
  }
}

export const valtuuttajanTehtavatOhjeUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://dvv.fi/sv/sa-har-skoter-du-uppdraget-som-intressebevakningsfullmaktig'
    default:
      return 'https://dvv.fi/nain-hoidat-edunvalvontavaltuutetun-tehtavaa'
  }
}

export const suomifiViestitUrl = (
  lang: HolhousAsiointiLanguageCode
): string => {
  switch (lang) {
    case 'sv':
      return 'https://www.suomi.fi/meddelanden'
    default:
      return 'https://www.suomi.fi/viestit'
  }
}
