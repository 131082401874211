import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  elamantapahtumaOsittainSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestrictionFIXME,
  elamantapahtumat4VuottaRetentionFIXME,
  elamantapahtumatPysyvaRetention,
  elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
  elamantapahtumatSalassaPidettava100VuottaK25K32HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/common-elamantapahtumat-metadata'

// FIXME: https://jira.dvv.fi/browse/OIVA-5161
export const siviilisaadynRekisterointiUlkomainenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ERO_DVVN_PAATOS_LIITTEINEEN,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['4aaa4b37-09c1-45ab-b481-2a9301935033'],
      defaultToivoAsiakirjaTypeId: '4aaa4b37-09c1-45ab-b481-2a9301935033',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ERO_SELVITYSASIAKIRJAT,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['01ee0cf2-1675-45e0-b46d-2a5856bbde97'],
      defaultToivoAsiakirjaTypeId: '01ee0cf2-1675-45e0-b46d-2a5856bbde97',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ERO_MUU_ASIAKIRJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['95150025-9cf8-4e8b-a2d9-c710a9b39ee0'],
      defaultToivoAsiakirjaTypeId: '95150025-9cf8-4e8b-a2d9-c710a9b39ee0',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ERO_PYYNTO,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['79a5dcd2-5b46-4206-a5bf-fc7e15778cf2'],
      defaultToivoAsiakirjaTypeId: '79a5dcd2-5b46-4206-a5bf-fc7e15778cf2',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ERO_PERUSTEET_REKISTEROINNILLE,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['4db3f158-9480-4949-a796-d8e164c82d7a'],
      defaultToivoAsiakirjaTypeId: '4db3f158-9480-4949-a796-d8e164c82d7a',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ERO_DVVN_ILMOITUS_REKISTEROINNISTA,
      restrictionMetadata:
        elamantapahtumaOsittainSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestrictionFIXME,
      retentionMetadata: elamantapahtumat4VuottaRetentionFIXME,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['d21b7af0-d48f-4d21-9ea3-e72c4be7995b'],
      defaultToivoAsiakirjaTypeId: 'd21b7af0-d48f-4d21-9ea3-e72c4be7995b',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_PYYNTO,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['bb973126-e08a-4f72-ad2c-b119a6c3fbce'],
      defaultToivoAsiakirjaTypeId: 'bb973126-e08a-4f72-ad2c-b119a6c3fbce',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_PERUSTEET_REKISTEROINNILLE,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6b06ba97-b04c-4534-a6b6-110db487ba95'],
      defaultToivoAsiakirjaTypeId: '6b06ba97-b04c-4534-a6b6-110db487ba95',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_MUU_ASIAKIRJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['b7cae8cb-189b-431c-94c3-55a6c093f8e9'],
      defaultToivoAsiakirjaTypeId: 'b7cae8cb-189b-431c-94c3-55a6c093f8e9',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_SELVITYSASIAKIRJAT,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['316b0acc-ecfd-460a-b174-c7e7d0d27f9c'],
      defaultToivoAsiakirjaTypeId: '316b0acc-ecfd-460a-b174-c7e7d0d27f9c',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_DVVN_ILMOITUS_REKISTEROINNISTA,
      restrictionMetadata:
        elamantapahtumaOsittainSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestrictionFIXME,
      retentionMetadata: elamantapahtumat4VuottaRetentionFIXME,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['89b85405-e285-42e0-bec4-078614c05fe4'],
      defaultToivoAsiakirjaTypeId: '89b85405-e285-42e0-bec4-078614c05fe4',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMINEN_DVVN_PAATOS_LIITTEINEEN,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32ErityisiaHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['096c63d3-6501-45a7-82aa-e9395c622ff4'],
      defaultToivoAsiakirjaTypeId: '096c63d3-6501-45a7-82aa-e9395c622ff4',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.OSITTAIN_KIELTEINEN,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: true,
    specifierTypeIds: [
      SpecifierType.AVIOERO,
      SpecifierType.AVIOLIITTO,
      SpecifierType.REKISTEROIDUN_PARISUHTEEN_PURKAMINEN,
      SpecifierType.REKISTEROIDUN_PARISUHTEEN_ILMOITTAMINEN,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LISATIETOA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.LISATIETO_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LISATIEDON_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK25K32HenkilotietojaRestriction,
  retentionMetadata: elamantapahtumatPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
