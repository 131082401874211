import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTiedoksiantoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  commonEmailValidator,
  commonHetuValidator,
  commonPhoneValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { BlueBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import FormCountryDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCountryDropdown'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { validateHetu } from 'common/src/vtj/validation/validators-typed'
import {
  getTiedoksiantoForm,
  getTiedoksiantoFormCountryShortName,
  updateTiedoksiantoFormValues,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tiedoksianto.store'

const TiedoksiantoForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const { lang } = useAsiointiUserStore()
  const tiedoksianto = getTiedoksiantoForm()

  const etunimetTestId = mkTiedoksiantoTestId('etunimet')
  const sukunimiTestId = mkTiedoksiantoTestId('sukunimi')
  const hetuTestId = mkTiedoksiantoTestId('hetu')
  const phoneTestId = mkTiedoksiantoTestId('phone')
  const emailTestId = mkTiedoksiantoTestId('email')
  const katuosoiteTestId = mkTiedoksiantoTestId('katuosoite')
  const postinumeroTestId = mkTiedoksiantoTestId('postinumero')
  const postitoimipaikkaTestId = mkTiedoksiantoTestId('postitoimipaikka')
  const maaTestId = mkTiedoksiantoTestId('maa')

  return (
    <section data-test-id={mkTiedoksiantoTestId('form')}>
      <Heading variant="h3" as="h2">
        {t('tiedoksianto')}
      </Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>
        {t('ohjeIlmoitaOsakkaanYhteystiedotTiedoksiantoon')}
      </Paragraph>
      <Block mt={verticalMarginToken} />
      <BlueBorderedContentBlock padding="m">
        <FormTextInput
          data-test-id={etunimetTestId}
          labelText={t('etunimet')}
          value={tiedoksianto.firstnames}
          required
          validate={commonXssValidator(t)}
          updateValue={(firstnames) =>
            updateTiedoksiantoFormValues({
              firstnames,
            })
          }
        />
        <FormTextInput
          data-test-id={sukunimiTestId}
          labelText={t('sukunimi')}
          value={tiedoksianto.lastname}
          required
          validate={commonXssValidator(t)}
          updateValue={(lastname) =>
            updateTiedoksiantoFormValues({
              lastname,
            })
          }
        />
        <FormTextInput
          data-test-id={hetuTestId}
          labelText={t('henkilotunnus')}
          defaultValue={tiedoksianto.hetu || ''}
          validate={commonHetuValidator(t)}
          updateValue={(value) =>
            updateTiedoksiantoFormValues({
              hetu: validateHetu(value) ? value : undefined,
            })
          }
        />
        <FormTextInput
          data-test-id={phoneTestId}
          labelText={t('puhelinnumero')}
          value={tiedoksianto.phone}
          validate={commonPhoneValidator(t)}
          updateValue={(phone) =>
            updateTiedoksiantoFormValues({
              phone,
            })
          }
        />
        <FormTextInput
          data-test-id={emailTestId}
          labelText={t('sahkoposti')}
          value={tiedoksianto.email}
          validate={commonEmailValidator(t)}
          updateValue={(email) =>
            updateTiedoksiantoFormValues({
              email,
            })
          }
        />
        <FormTextInput
          data-test-id={katuosoiteTestId}
          labelText={t('katuosoite')}
          value={tiedoksianto.address.streetAddress}
          required
          validate={commonXssValidator(t)}
          updateValue={(streetAddress) =>
            updateTiedoksiantoFormValues({
              address: { ...tiedoksianto.address, streetAddress },
            })
          }
        />
        <FormTextInput
          data-test-id={postinumeroTestId}
          labelText={t('postinumero')}
          value={tiedoksianto.address.postalCode}
          required
          validate={commonXssValidator(t)}
          updateValue={(postalCode) =>
            updateTiedoksiantoFormValues({
              address: { ...tiedoksianto.address, postalCode },
            })
          }
        />
        <FormTextInput
          data-test-id={postitoimipaikkaTestId}
          labelText={t('postitoimipaikka')}
          value={tiedoksianto.address.postOffice}
          required
          validate={commonXssValidator(t)}
          updateValue={(postOffice) =>
            updateTiedoksiantoFormValues({
              address: { ...tiedoksianto.address, postOffice },
            })
          }
        />
        <FormCountryDropdown
          data-test-id={maaTestId}
          value={getTiedoksiantoFormCountryShortName(lang)}
          updateValue={(countryId) =>
            updateTiedoksiantoFormValues({
              address: { ...tiedoksianto.address, countryId },
            })
          }
        />
      </BlueBorderedContentBlock>
    </section>
  )
})

export default TiedoksiantoForm
