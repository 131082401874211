export const enum ASIOINTI_MAIN_ROUTES {
  JUURI = '/',
  ASIOI = '/asioi/:personId',
}

export const getAsioiPathByPersonId = (
  personId: string | undefined
): string => {
  if (personId) {
    return ASIOINTI_MAIN_ROUTES.ASIOI.replace(':personId', personId)
  } else {
    return ASIOINTI_MAIN_ROUTES.JUURI
  }
}
