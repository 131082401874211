import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  OMAISUUSLUETTELO_ROUTES,
  OMAISUUSLUETTELO_STEPS,
  OmaisuusluetteloFormStates,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { FormSubmitBar } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSubmitBar'
import { FormProvider } from 'react-hook-form'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/Perustiedot'
import FormCheckboxInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCheckboxInput'
import FormUncompletedStepsSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormUncompletedStepsSummary'
import Yhteenveto from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/Yhteenveto'

const OmaisuusluetteloYhteenvetoPage: React.FC<{
  forms: OmaisuusluetteloFormStates
  form: TilintarkastusForm
}> = observer(({ forms, form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const onErrorRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [OMAISUUSLUETTELO_ROUTES.YHTEENVETO]: _, ...formsWithoutYhteenveto } =
    forms

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkYhteenvetoTestId('page-container')}
        >
          <PageStepHeading
            heading={t('yhteenvetoJaLahetys')}
            steps={OMAISUUSLUETTELO_STEPS}
          />
          <FormUncompletedStepsSummary
            forms={formsWithoutYhteenveto}
            ref={onErrorRef}
          />
          <Paragraph>{t('yhteenvetoPageInfo1_OMAISUUSLUETTELO')}</Paragraph>
          <DividerLine mt={verticalMarginToken} />
          <Perustiedot showValtakirja />
          <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
          <Yhteenveto />
          <DividerLine mb={verticalMarginToken} />
          <Block mb={verticalMarginToken}>
            <Heading variant="h3" mb={verticalMarginToken}>
              {t('omaisuusluettelossaIlmoitettujenTietojenOikeellisuus')}
            </Heading>
            <FormCheckboxInput
              data-test-id={mkYhteenvetoTestId('vahvistan-tiedot-checkbox')}
              labelText={t('omaisuusluetteloIlmoitanTiedotOikeaksi')}
              requiredLabel={t('sinunOnHyvaksyttavaEhdotEnnenKuinVoitJatkaa')}
              updateValue={() => undefined}
              defaultValue={false}
              required
            />
          </Block>
          <DividerLine mb={verticalMarginToken} />
          <FormSubmitBar
            forms={formsWithoutYhteenveto}
            next={OMAISUUSLUETTELO_ROUTES.VALMIS}
            onErrorRef={onErrorRef}
          />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default OmaisuusluetteloYhteenvetoPage
