import { executeBackendClient } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { Country } from 'holhous-common/src/vtj/country/country.type'

export const getCountries: () => Promise<Country[]> = async () => {
  const apiResponse = await executeBackendClient<Country[]>((client) =>
    client.get(`/luvat/asiointi/api/v1/country`)
  )
  if (apiResponse.isOk) {
    return apiResponse.data
  } else {
    throw new Error(
      'Failed loading countries: ' + JSON.stringify(apiResponse.errorData)
    )
  }
}
