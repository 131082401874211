import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import {
  Button,
  ModalTitle,
  Modal,
  ModalFooter,
  ModalContent,
} from 'suomifi-ui-components'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { mkSubmitModalTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'

const SubmitBatchModal = observer(
  ({
    visible,
    onSubmit,
    onClose,
  }: {
    visible: boolean
    onSubmit: () => unknown
    onClose: () => unknown
  }) => {
    const [t] = useTranslation()
    const isTablet = useMediaQuery({ query: device.tablet })
    return (
      <Modal
        visible={visible}
        appElementId={ASIOINTI_ROOT_ELEMENT_ID}
        onEscKeyDown={onClose}
        scrollable={false}
        variant={isTablet ? 'default' : 'smallScreen'}
      >
        <ModalContent>
          <ModalTitle aria-live="polite" aria-atomic="true">
            {t('lahetaHakemus')}
          </ModalTitle>
          {t('haluatkoLahettaaHakemuksen')}
        </ModalContent>
        <ModalFooter>
          <Button
            onClick={onSubmit}
            data-test-id={mkSubmitModalTestId('laheta-painike')}
          >
            {t('send')}
          </Button>
          <Button
            onClick={onClose}
            variant="secondary"
            data-test-id={mkSubmitModalTestId('peruuta-painike')}
          >
            {t('cancel')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
)

export default SubmitBatchModal
