import { useRef, useEffect, RefObject } from 'react'

export const useAutoFocus = <E extends HTMLElement>(): RefObject<E> => {
  const inputRef = useRef<E>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return inputRef
}
