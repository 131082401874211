import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { FileRejection } from 'react-dropzone'
import FileBox, {
  FileBoxFile,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileBox'
import * as FileUtil from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/file/file.util'
import {
  addFailedPersonAttachment,
  removeAttachment,
  uploadPersonAttachment,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/file-actions'
import { LupaAsiointiAsiakirjaTypeId } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { AsiointiPerson } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { resolveFieldStatusProps } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/validation.util'
import {
  attachmentToFileBoxFile,
  lupaAllowedMimeTypes,
  lupaMaxSizeInBytes,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-file.util'

const PersonAttachmentFileBox: React.FC<{
  htmlId: string
  asiakirjaTypeId: LupaAsiointiAsiakirjaTypeId
  asiointiPerson: Pick<
    AsiointiPerson,
    'personId' | 'attachments' | 'validationErrors'
  >
  'data-test-id': string
}> = observer((props) => {
  const { attachments, personId } = props.asiointiPerson
  const [t] = useTranslation()
  const fileBoxFiles: FileBoxFile[] = attachments.map((ak) =>
    attachmentToFileBoxFile(t, ak)
  )

  const onAddFiles = (validFiles: File[], rejections: FileRejection[]) => {
    validFiles.forEach((file) => {
      void uploadPersonAttachment(personId, {
        file,
        asiakirjaTypeId: props.asiakirjaTypeId,
      })
    })
    rejections.forEach((rejection) =>
      addFailedPersonAttachment(personId, {
        asiakirjaTypeId: props.asiakirjaTypeId,
        status: 'failed',
        error: FileUtil.getError(rejection),
        file: rejection.file,
      })
    )
  }

  const onRemoveFile = (file: FileBoxFile) => {
    void removeAttachment(file.id)
  }

  const attachmentsStatus = resolveFieldStatusProps(
    props.asiointiPerson,
    'attachments',
    t
  )

  return (
    <FileBox
      htmlId={props.htmlId}
      files={fileBoxFiles}
      onAdd={onAddFiles}
      onRemove={onRemoveFile}
      errorMessage={
        attachmentsStatus.status === 'error' ? attachmentsStatus.statusText : ''
      }
      minFileSizeBytes={1}
      maxFileSizeBytes={lupaMaxSizeInBytes}
      allowedMimeTypes={lupaAllowedMimeTypes}
      data-test-id={props['data-test-id']}
    />
  )
})

export default PersonAttachmentFileBox
