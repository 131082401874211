import { action, flow, observable } from 'mobx'
import { TilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store.type'
import { AsiointiLocale } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { CancellablePromise } from 'mobx/dist/api/flow'
import {
  AsiointiType,
  FrontTilirivit,
  Lomake,
  TiliData,
  FrontPalkkio,
  FrontPaamiehenOmaisuus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiointiPrivatePersonRole } from 'tilintarkastus-common/src/vtj/tili-account-enums'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv/evtv-api.type'
import { AsiointiUser } from 'edunvalvonta-asiointi/src/vtj/asiointi/authentication/holhous-asiointi-user.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { TIEDOKSIANTO_PERSON_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tiedoksianto.store'
import { DeepPartial } from 'edunvalvonta-asiointi/src/vtj/asiointi/types'
import { DraftData } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import { saveTiliAsDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import { OMAISUUSLUETTELO_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'

let store: TilintarkastusStore | undefined

export const initTilintarkastusStore = (
  user: AsiointiUser,
  evtvSeurantaAsiavaatimus: AsiointiSeurantaAsiavaatimus,
  draftData: DraftData | undefined,
  lang: HolhousAsiointiLanguageCode
): TilintarkastusStore => {
  const tili = createEmptyTili(evtvSeurantaAsiavaatimus)
  const lomake = createLomake(user, tili, draftData?.lomake, lang)

  const excludeSteps = new Set<string>(
    evtvSeurantaAsiavaatimus.asiaType === 'OMAISUUSLUETTELO' &&
    evtvSeurantaAsiavaatimus.tilivelvollisuusType !==
      TilivelvollisuusType.OMAISUUSLUETTELO_YHDESSA
      ? [OMAISUUSLUETTELO_ROUTES.VALTAKIRJA]
      : []
  )

  store = observable({
    lomake,
    visitedSteps: new Set<string>(draftData?.visitedSteps || []),
    excludeSteps,
    submitStatus: 'none',
    submitError: undefined,
    accountingPeriodStartDate:
      evtvSeurantaAsiavaatimus.accountingPeriodStartDate,
    isPrincipalUnderaged: evtvSeurantaAsiavaatimus.isPrincipalUnderaged,
  } as TilintarkastusStore)
  return store
}

// For testing purposes only
export const initTilintarkastusStoreWithData = (
  data: TilintarkastusStore
): TilintarkastusStore => {
  store = observable(data)
  return store
}

export const getTilintarkastusStore = (): TilintarkastusStore => {
  if (store) {
    return store
  } else {
    throw new Error('TilintarkastusStore not initialized')
  }
}

export const isTilintarkastusStoreInitialized = (
  seurantaAsiavaatimusId: string
): boolean => {
  if (store && store.lomake) {
    return store.lomake.tili.seurantaAsiavaatimusId === seurantaAsiavaatimusId
  }
  return false
}

export const runTilintarkastusStoreFlow = <T>(
  fn: (store: TilintarkastusStore) => Generator<any, T, any> // eslint-disable-line @typescript-eslint/no-explicit-any
): CancellablePromise<T> => {
  const store = getTilintarkastusStore()
  return flow<T, [TilintarkastusStore]>(fn)(store)
}

export const runTilintarkastusStoreAction = <T>(
  fn: (store: TilintarkastusStore) => T
): T => {
  const store = getTilintarkastusStore()
  return action(fn)(store)
}

export const saveDraft = async (): Promise<void> => {
  const { submitStatus, lomake, visitedSteps } = getTilintarkastusStore()
  if (submitStatus === 'submitted') {
    return
  }
  await saveTiliAsDraft(lomake, Array.from(visitedSteps))
}

const createEmptyTili = (
  evtvVaatimus: AsiointiSeurantaAsiavaatimus
): TiliData => {
  return {
    asiaType: evtvVaatimus.asiaType as AsiointiType,
    asuinpaikat: [],
    edunvalvojanValtakirja: [],
    hallintaoikeusSopimukset: [],
    hoitosuunnitelmat: [],
    isAnnettuKayttovaroja: undefined,
    kayttovaraKaytanto: undefined,
    saannollisetTulot: [],
    sopimukset: [],
    vakuutukset: [],
    verotustiedot: [],
    isMuutMerkittavatToimet: undefined,
    tilirivit: {
      varat: {
        talletukset: [],
        saatavat: [],
        arvopaperit: [],
        huoneistot: [],
        irtaimistot: [],
        kiinteistot: [],
        muutVarat: [],
        osuudetHenkiloYhtiossaJaYrityksissa: [],
        osuudetKuolinpesissa: [],
      },
      velat: {
        pankkilainat: [],
        takaukset: [],
        muutVelat: [],
      },
      tulot: {
        elakkeet: [],
        palkat: [],
        etuudet: [],
        tyottomyysturva: [],
        vuokratulot: [],
        osinkotulot: [],
        korkotulot: [],
        myyntitulot: [],
        muutTulot: [],
      },
      menot: {
        kayttovarat: [],
        elinkustannukset: [],
        palvelumaksut: [],
        vuokratVastikkeet: [],
        elatusavut: [],
        pankinPerimatMaksut: [],
        verot: [],
        tyonantajakulut: [],
        velanhoitomenot: [],
        edunvalvonnankulut: [],
        muutMenot: [],
      },
    },
    kaytossaOlevaOmaisuus: [{ id: crypto.randomUUID(), value: '' }],
    elaketulotElinkustannuksiin: {
      elinkustannuksiinKaytettavatElaketulot: undefined,
      enKaytaElakettaElinkustannuksiin: false,
    },
    paamiehenOmaisuus: {
      muutVarat: undefined,
      isMuutVarat: undefined,
      omaisuusOmassaKaytossa: [],
      pankkitilit: [],
    },
    testamentti: { laadittu: undefined, rajoittaviaEhtoja: undefined },
    isHallintaoikeuksia: undefined,
    seurantaAsiavaatimusId: evtvVaatimus.seurantaAsiavaatimusId,
    palkkio: {
      veloitatkoKulukorvauksia: undefined,
      veloitatkoPalkkiota: undefined,
      kulukorvausMaara: undefined,
      kululaskelma: [],
      palkkioMaara: undefined,
    },
  }
}

export const createLomake = (
  user: AsiointiUser,
  tili: TiliData,
  previouslySavedLomake: DraftData['lomake'] | undefined,
  lang: HolhousAsiointiLanguageCode
): Lomake => {
  const [previousTiedoksiantoPerson] =
    previouslySavedLomake?.otherPersons?.privatePersons?.filter(
      (p) => p?.id === TIEDOKSIANTO_PERSON_ID
    ) || []

  return {
    locale: AsiointiLocale.fi, // TODO language pitäis olla dynaaminen? Aseta vasta ennen lähetystä?
    otherPersons: {
      privatePersons:
        tili.asiaType === 'PAATOSTILI'
          ? [
              {
                id: TIEDOKSIANTO_PERSON_ID,
                role: AsiointiPrivatePersonRole.YKSITYISHENKILO,
                email: previousTiedoksiantoPerson?.email || '',
                firstnames: previousTiedoksiantoPerson?.firstnames || '',
                lastname: previousTiedoksiantoPerson?.lastname || '',
                phone: previousTiedoksiantoPerson?.phone || '',
                address: {
                  streetAddress:
                    previousTiedoksiantoPerson?.address?.streetAddress || '',
                  postOffice:
                    previousTiedoksiantoPerson?.address?.postOffice || '',
                  postalCode:
                    previousTiedoksiantoPerson?.address?.postalCode || '',
                  countryId:
                    previousTiedoksiantoPerson?.address?.countryId ||
                    COUNTRY_CODE_FINLAND,
                },
                isAssetsRecipient: true,
              },
            ]
          : [],
      asiointiCaretakers: [],
      businesses: [],
    },
    caretaker: {
      firstnames:
        previouslySavedLomake?.caretaker?.firstnames ?? user.firstnames,
      lastname: previouslySavedLomake?.caretaker?.lastname ?? user.lastname,
      email: previouslySavedLomake?.caretaker?.email || user.email || '', // käyttäjä saa poistaa userin arvon
      phone: previouslySavedLomake?.caretaker?.phone || '',
      address: {
        streetAddress:
          previouslySavedLomake?.caretaker?.address?.streetAddress ??
          user.streetAddress?.[lang] ??
          '',
        postOffice:
          previouslySavedLomake?.caretaker?.address?.postOffice ??
          user.postOffice?.[lang] ??
          '',
        postalCode:
          previouslySavedLomake?.caretaker?.address?.postalCode ??
          user.postalCode ??
          '',
        countryId:
          previouslySavedLomake?.caretaker?.address?.countryId ??
          user.countryCode ??
          COUNTRY_CODE_FINLAND,
      },
    },
    tili: previouslySavedLomake?.tili
      ? mergeEmptyAndPreviousLomake(tili, previouslySavedLomake.tili)
      : tili,
    lomakkeelleValitutTiedot: Object.fromEntries(
      Object.entries(previouslySavedLomake?.lomakkeelleValitutTiedot || {}).map(
        ([key, value]) => [key, !!value]
      )
    ),
  }
}

const mergeEmptyAndPreviousLomake = (
  tyhjaTili: TiliData,
  draftTili: DeepPartial<TiliData>
): TiliData => {
  const { tilirivit, ...safePreviousValues } = draftTili

  // Yhteiset säännöt -->
  return {
    // turvalliset arvot pohjalle
    ...tyhjaTili,
    ...safePreviousValues,

    // Näitä ei saa ylikirjoittaa
    seurantaAsiavaatimusId: tyhjaTili.seurantaAsiavaatimusId,
    asiaType: tyhjaTili.asiaType,

    // Kun kaikki draft-tyypin arvot ovat partial,
    //  niin varmistetaan että object keys pysyvät ennallaan
    // esim jos draftilla on {key: 'kissa'}
    // ja tyhjässä tilissä on {key: undefined', key2: 'koira'}
    // niin ei voi korvata suoraan tyhjään draftilla, vaan pitää olla merge, että lopputulos:
    // {key: 'kissa', key2: 'koira'}
    tilirivit: tilirivit
      ? mergeTilirivit(tyhjaTili.tilirivit, tilirivit)
      : tyhjaTili.tilirivit,
    elaketulotElinkustannuksiin: {
      ...tyhjaTili.elaketulotElinkustannuksiin,
      ...draftTili.elaketulotElinkustannuksiin,
    },
    testamentti: {
      ...tyhjaTili.testamentti,
      ...draftTili.testamentti,
    },
    paamiehenOmaisuus: {
      ...tyhjaTili.paamiehenOmaisuus,
      ...draftTili.paamiehenOmaisuus,
    } as FrontPaamiehenOmaisuus,
    palkkio: {
      ...tyhjaTili.palkkio,
      ...draftTili.palkkio,
    } as FrontPalkkio,
  } as TiliData
}

const mergeTilirivit = (
  emptyTilirivit: FrontTilirivit,
  draftTilirivit: DeepPartial<FrontTilirivit>
): FrontTilirivit => {
  return {
    varat: {
      ...emptyTilirivit.varat,
      ...draftTilirivit.varat,
    },
    velat: {
      ...emptyTilirivit.velat,
      ...draftTilirivit.velat,
    },
    menot: {
      ...emptyTilirivit.menot,
      ...draftTilirivit.menot,
    },
    tulot: {
      ...emptyTilirivit.tulot,
      ...draftTilirivit.tulot,
    },
  } as FrontTilirivit
}
