import { runTilintarkastusStoreAction } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { getCountryStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/country.store'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import { FrontPrivatePerson } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

export const TIEDOKSIANTO_PERSON_ID = 'tiedoksianto'

export const getTiedoksiantoForm = (): FrontPrivatePerson =>
  runTilintarkastusStoreAction(({ lomake }) => {
    const p = lomake.otherPersons.privatePersons.find(
      ({ id }) => id === TIEDOKSIANTO_PERSON_ID
    )
    if (!p) {
      throw new Error('Missing tiedoksianto person ' + TIEDOKSIANTO_PERSON_ID)
    }
    return p
  })

export const updateTiedoksiantoFormValues = (
  values: Partial<Omit<FrontPrivatePerson, 'id'>>
): void =>
  runTilintarkastusStoreAction(({ lomake }) => {
    for (const person of lomake.otherPersons.privatePersons) {
      if (person.id === TIEDOKSIANTO_PERSON_ID) {
        Object.assign(person, values)
      }
    }
  })

export const getTiedoksiantoFormCountryShortName = (
  lang: HolhousAsiointiLanguageCode
): string => {
  const countryId =
    getTiedoksiantoForm().address.countryId || COUNTRY_CODE_FINLAND
  const found = getCountryStore().countries.find(
    (c) => c.countryId === countryId
  )?.shortName[lang]

  if (!found) {
    throw new Error('Unrecognized country ' + countryId)
  }
  return found
}
