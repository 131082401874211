import {
  AsiointiLedgerAccounts,
  PartialAsiointiLedgerAccounts,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.type'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
  AsiointiLedgerAccountType,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import {
  asiointiLedgerAccountRange,
  makeAsiointiLedgerAccountsMap,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.util'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'

const partialAsiointiLedgerAccountsIncomeMap: Record<
  AsiointiLedgerAccountIncomeType,
  PartialAsiointiLedgerAccounts
> = {
  [AsiointiLedgerAccountType.TYOELAKE]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELAKETULOT,
    accounts: ['300000'],
  },
  [AsiointiLedgerAccountType.KANSANELAKE]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELAKETULOT,
    accounts: ['300010'],
  },
  [AsiointiLedgerAccountType.ULKOMAAN_ELAKE]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELAKETULOT,
    accounts: ['300020'],
  },
  [AsiointiLedgerAccountType.PERHE_ELAKE]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELAKETULOT,
    accounts: ['300030'],
  },
  [AsiointiLedgerAccountType.PALKAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.PALKKATULOT,
    accounts: ['310000'],
  },
  [AsiointiLedgerAccountType.PALKKIOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.PALKKATULOT,
    accounts: ['310010'],
  },
  [AsiointiLedgerAccountType.TYOOSUUSRAHAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.PALKKATULOT,
    accounts: ['310020'],
  },
  [AsiointiLedgerAccountType.OMAISHOIDON_TUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.PALKKATULOT,
    accounts: ['310030'],
  },
  [AsiointiLedgerAccountType.ELAKKEENSAAJAN_ASUMISTUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320000'],
  },
  [AsiointiLedgerAccountType.YLEINEN_ASUMISTUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320001'],
  },
  [AsiointiLedgerAccountType.VAMMAISTUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320002'],
  },
  [AsiointiLedgerAccountType.KELAN_KUNTOUTUSRAHA]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320003'],
  },
  [AsiointiLedgerAccountType.KUNTOUTUSTUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320004'],
  },
  [AsiointiLedgerAccountType.SAIRAUSPAIVARAHA]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320005'],
  },
  [AsiointiLedgerAccountType.TOIMEENTULOTUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320006'],
  },
  [AsiointiLedgerAccountType.KOTIHOIDON_TUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320007'],
  },
  [AsiointiLedgerAccountType.ELATUSAPU]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320008'],
  },
  [AsiointiLedgerAccountType.ELATUSTUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320009'],
  },
  [AsiointiLedgerAccountType.VANHEMPAINRAHA]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320010'],
  },
  [AsiointiLedgerAccountType.LAPSILISA]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320011'],
  },
  [AsiointiLedgerAccountType.OPINTOTUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320012'],
  },
  [AsiointiLedgerAccountType.ULKOMAAN_ETUUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320014'],
  },
  [AsiointiLedgerAccountType.MUU_ETUUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.ETUUDET,
    accounts: ['320015'],
  },
  [AsiointiLedgerAccountType.TYOMARKKINATUKI]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA,
    accounts: ['330000'],
  },
  [AsiointiLedgerAccountType.PERUSPAIVARAHA]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA,
    accounts: ['330001'],
  },
  [AsiointiLedgerAccountType.ANSIOSIDONNAINEN_TYOTTOMYYSPAIVARAHA]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA,
    accounts: ['330002'],
  },
  [AsiointiLedgerAccountType.KOTOUTUMISTUKI_MAAHANMUUTTAJALLE]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA,
    accounts: ['330003'],
  },
  [AsiointiLedgerAccountType.MUU_TYOTTOMYYSTURVAETUUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYOTTOMYYSTURVA,
    accounts: ['330004'],
  },
  [AsiointiLedgerAccountType.KELAN_LAAKEKORVAUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340000'],
  },
  [AsiointiLedgerAccountType.LAAKEKORVAUKSET_MUU]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340001'],
  },
  [AsiointiLedgerAccountType.SAIRASVAKUUTUSKORVAUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340002'],
  },
  [AsiointiLedgerAccountType.HENKIVAKUUTUSKORVAUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340003'],
  },
  [AsiointiLedgerAccountType.VAHINKOVAKUUTUSKORVAUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340004'],
  },
  [AsiointiLedgerAccountType.VAHINGONKORVAUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340005'],
  },
  [AsiointiLedgerAccountType.MAKSUKATOSTA_JOHTUVAT_PALAUTUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340006'],
  },
  [AsiointiLedgerAccountType.MAKSUN_PALAUTUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340007'],
  },
  [AsiointiLedgerAccountType.VERONPALAUTUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340008'],
  },
  [AsiointiLedgerAccountType.KATEISVAROJA_HALTUUN]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340009'],
  },
  [AsiointiLedgerAccountType.LAHJAT_PERINNOT_TASINKO]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340010'],
  },
  [AsiointiLedgerAccountType.MUUT_TULOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340011'],
  },
  [AsiointiLedgerAccountType.VIRHETULO_KORJATAAN_MYOHEMMIN]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340098'],
  },
  [AsiointiLedgerAccountType.VIRHEMAKSUN_KORJAUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.SATUNNAISET_TULOT,
    accounts: ['340099'],
  },
  [AsiointiLedgerAccountType.VUOKRATULOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.VUOKRATULOT,
    accounts: [
      ...asiointiLedgerAccountRange(400000, 400101),
      ...asiointiLedgerAccountRange(401000, 401006),
      ...asiointiLedgerAccountRange(401100, 401199),
      ...asiointiLedgerAccountRange(401303, 401304),
      '401308',
      '401410',
      '401500',
      ...asiointiLedgerAccountRange(402200, 402299),
    ],
  },
  [AsiointiLedgerAccountType.OSINKOTULOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.OSINKOTULOT,
    accounts: ['410000'],
  },
  [AsiointiLedgerAccountType.KORKOTULOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.KORKOTULOT,
    accounts: ['420000'],
  },
  [AsiointiLedgerAccountType.METSANMYYNTI]: {
    subCategory: AsiointiLedgerAccountSubCategory.MYYNTITULOT,
    accounts: ['430000'],
  },
  [AsiointiLedgerAccountType.IRTAIMEN_MYYNTI]: {
    subCategory: AsiointiLedgerAccountSubCategory.MYYNTITULOT,
    accounts: ['430010'],
  },
  [AsiointiLedgerAccountType.ARVOPAPERIMYYNTI]: {
    subCategory: AsiointiLedgerAccountSubCategory.MYYNTITULOT,
    accounts: ['430020'],
  },
  [AsiointiLedgerAccountType.MERKINTAOSUUKSIEN_MYYNTI]: {
    subCategory: AsiointiLedgerAccountSubCategory.MYYNTITULOT,
    accounts: ['430030'],
  },
  [AsiointiLedgerAccountType.HUONEISTON_MYYNTI]: {
    subCategory: AsiointiLedgerAccountSubCategory.MYYNTITULOT,
    accounts: ['430040'],
  },
  [AsiointiLedgerAccountType.KIINTEISTON_MYYNTI]: {
    subCategory: AsiointiLedgerAccountSubCategory.MYYNTITULOT,
    accounts: ['430050'],
  },
  [AsiointiLedgerAccountType.MUUT_PAAOMATULOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_PAAOMATULOT,
    accounts: ['440000'],
  },
  [AsiointiLedgerAccountType.KIRJANPITOARVON_KOROTUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.KIRJANPITOARVON_KOROTUS,
    accounts: ['450000'],
  },
  [AsiointiLedgerAccountType.VAROJA_HALTUUN]: {
    subCategory: AsiointiLedgerAccountSubCategory.VAROJA_HALTUUN,
    accounts: ['500000'],
  },
}

export const asiointiLedgerAccountsIncomeMap: Record<
  AsiointiLedgerAccountIncomeType,
  AsiointiLedgerAccounts
> = makeAsiointiLedgerAccountsMap(AsiointiLedgerAccountCategory.TULOT)(
  partialAsiointiLedgerAccountsIncomeMap
)
