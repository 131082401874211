import React from 'react'
import { observer } from 'mobx-react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  LupaApplicationStep,
  pathByViewId,
  resolveStepByPath,
  stepTranslationKeyByStepId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import {
  RouterLink,
  suomifiDesignTokens,
  WizardNavigation,
  WizardNavigationItem,
} from 'suomifi-ui-components'
import {
  evaluateNavigationState,
  getStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { mkWizardStepTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { BorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

// values from suomifi-ui-components WizardNavigationItemProps
export type NavigationStepStatus =
  | 'default'
  | 'current'
  | 'current-completed'
  | 'completed'
  | 'coming'
  | 'disabled'

export type NavigationState = Record<LupaApplicationStep, NavigationStepStatus>

const LupaNavigation: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const { pathname } = useLocation()
  const navState = evaluateNavigationState(resolveStepByPath(pathname))

  return (
    <NavBlock>
      <WizardNavigation
        heading={t('vaiheet')}
        aria-label={t('vaiheet')}
        variant={isTablet ? 'default' : 'smallScreen'}
        initiallyExpanded={false}
      >
        {Object.values(LupaApplicationStep).map((step, index) => (
          <NavItem
            key={step}
            status={getStatusByStep(step)}
            href={pathByViewId[step]}
            text={`${index + 1}. ${t(stepTranslationKeyByStepId[step])}`}
            data-test-id={mkWizardStepTestId(step)}
          />
        ))}
      </WizardNavigation>
    </NavBlock>
  )

  function getStatusByStep(step: LupaApplicationStep): NavigationStepStatus {
    return navState[step]
  }
})

const NavBlock = styled(BorderedContentBlock)`
  @media ${device.tablet} {
    max-width: 366px;
    padding: ${suomifiDesignTokens.spacing.s} 0;
  }
`

const NavItem: React.FC<{
  status: NavigationStepStatus
  text: string
  href: string
  'data-test-id': string
}> = observer(({ status, text, href, 'data-test-id': dataTestId }) => {
  const [t] = useTranslation()
  const { batch } = getStore()
  const ariaLabel =
    status === 'current-completed' || status === 'completed'
      ? `${text}. ${t('vaiheValmis')}`
      : undefined

  return (
    <WizardNavigationItem
      status={status}
      aria-current={status === 'current' ? 'step' : undefined}
      data-test-id={dataTestId}
    >
      {status === 'coming' || batch.submitStatus !== 'none' ? (
        <RouterLink
          aria-disabled
          role="link"
          tabIndex={0}
          data-test-id={`${dataTestId}-link`}
        >
          {text}
        </RouterLink>
      ) : (
        <RouterLink
          to={href}
          asComponent={Link}
          aria-label={ariaLabel}
          data-test-id={`${dataTestId}-link`}
        >
          {text}
        </RouterLink>
      )}
    </WizardNavigationItem>
  )
})

export default LupaNavigation
