import fp from 'lodash/fp'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'
import { Country } from 'holhous-common/src/vtj/country/country.type'

/*
 * Don't specify more country codes if not absolutely necessary. It's better to
 * maintain these on db
 */
export const COUNTRY_CODE_FINLAND = '246'
export const COUNTRY_CODE_UNKNOWN = '999'

export const getIsValidCountry = (
  country: unknown
  // workaround for one-sided type guard using method mentioned on
  // https://github.com/microsoft/TypeScript/issues/15048#issuecomment-534376266
): country is Country & { countryId: 'string' } => {
  const countryId = fp.get('countryId', country)
  const countryCode2 = fp.get('countryCode2', country)
  const countryCode3 = fp.get('countryCode3', country)

  return !!(
    countryId &&
    countryId !== COUNTRY_CODE_UNKNOWN &&
    countryCode2 &&
    countryCode2 !== '99' &&
    countryCode3 &&
    countryCode3 !== '999'
  )
}

const getLangKey = (
  lang: string
): LanguageCode.FI | LanguageCode.SV | LanguageCode.EN => {
  switch (lang) {
    case 'SWE':
      return LanguageCode.SV
    case 'FIN':
      return LanguageCode.FI
    case LanguageCode.FI:
      return LanguageCode.FI
    case LanguageCode.SV:
      return LanguageCode.SV
    case LanguageCode.EN:
      return LanguageCode.EN
    default:
      return LanguageCode.FI
  }
}

export const getCountryShortName = (lang: string, item: Country): string => {
  return item.shortName[getLangKey(lang)]
}

export const getCountryOfficialName = (
  lang: string,
  item: Country
): string | null => {
  return item.officialName[getLangKey(lang)]
}
