import keyMirror from 'keymirror'

export const LupaPersonType = keyMirror({
  YKSITYISHENKILO: null,
  YLEINEN_EDUNVALVOJA: null,
  YRITYS: null,
})
export type LupaPersonTypeId =
  (typeof LupaPersonType)[keyof typeof LupaPersonType]

export const LupaApplicationRole = keyMirror({
  PAAMIES: null,
  HAKIJA: null,
  ASIAMIES: null,
  MUU_HENKILO: null,
})
export type LupaApplicationRoleId =
  (typeof LupaApplicationRole)[keyof typeof LupaApplicationRole]

export const OpinionType = keyMirror({
  HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN: null,
  PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN: null,
  PAAMIES_ALLE_15V_MIELIPIDETTA_EI_SELVITETTY: null,
  EI_TIEDOSSA: null,
})
export type OpinionTypeId = (typeof OpinionType)[keyof typeof OpinionType]

export const LupaValtuutusRepresentationTypes = keyMirror({
  EDUNVALVONTAVALTUUTUS: null,
  VARAVALTUUTUS: null,
  TOISSIJAINEN_VALTUUTUS: null,
})

export type LupaValtuutusRepresentationTypeId =
  (typeof LupaValtuutusRepresentationTypes)[keyof typeof LupaValtuutusRepresentationTypes]

export const LupaEdunvalvontaRepresentationTypes = keyMirror({
  VARSINAINEN_EDUNVALVONTA: null,
  EDUNVALVOJAN_SIJAISUUS: null,
  EDUNVALVOJA_EDUNVALVONTAVALTUUTETUN_SIJAISENA: null,
  ERITYISTEHTAVAAN_MAARATTY_EDUNVALVOJA: null,
})

export const LupaRepresentationType = {
  ...LupaValtuutusRepresentationTypes,
  ...LupaEdunvalvontaRepresentationTypes,
  ...keyMirror({
    ASIAMIESSUHDE: null,
  }),
}

export type LupaRepresentationTypeId =
  (typeof LupaRepresentationType)[keyof typeof LupaRepresentationType]
