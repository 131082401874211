import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

export type AsiointiLedgerAccountExpenseType = Extract<
  AsiointiLedgerAccountType,
  | AsiointiLedgerAccountType.KAYTTOVARAT
  | AsiointiLedgerAccountType.APTEEKKIOSTOT
  | AsiointiLedgerAccountType.ATERIAPALVELU
  | AsiointiLedgerAccountType.ENERGIA_JA_VESI
  | AsiointiLedgerAccountType.KODIN_HANKINNAT
  | AsiointiLedgerAccountType.KUNNALLINEN_KOTIHOITO_KOTISAIRAANHOITO
  | AsiointiLedgerAccountType.MATKAT
  | AsiointiLedgerAccountType.SUORATOISTOPALVELU
  | AsiointiLedgerAccountType.KIINTEISTON_KULUT
  | AsiointiLedgerAccountType.MUUT_OSTOKSET
  | AsiointiLedgerAccountType.PALVELUT
  | AsiointiLedgerAccountType.PUHELIN_INTERNET
  | AsiointiLedgerAccountType.RUOKAKAUPPA
  | AsiointiLedgerAccountType.SAIRAANKULJETUS
  | AsiointiLedgerAccountType.VAKUUTUKSET
  | AsiointiLedgerAccountType.VAPAA_AIKA_JA_HARRASTUSKULUT
  | AsiointiLedgerAccountType.KELA_KORVATTAVAT_MATKAKULUT
  | AsiointiLedgerAccountType.KELA_KORVATTAVAT_SAIRAANHOITOKULUT
  | AsiointiLedgerAccountType.MUUT_ELINKUSTANNUKSET
  | AsiointiLedgerAccountType.ASUMISPALVELU
  | AsiointiLedgerAccountType.MAKSUKATTOA_KERRYTTAVA_HOITO
  | AsiointiLedgerAccountType.MUUT_HOITOMAKSUT
  | AsiointiLedgerAccountType.PITKAAIKAISHOITO
  | AsiointiLedgerAccountType.ASUNTOVUOKRA
  | AsiointiLedgerAccountType.YHTIOVASTIKE
  | AsiointiLedgerAccountType.MUUT_VUOKRAT
  | AsiointiLedgerAccountType.KOTITALOUSVAHENNYSKELPOISET_MENOT
  | AsiointiLedgerAccountType.ELATUSAPU_LAPSELLE
  | AsiointiLedgerAccountType.ELATUSAPU_PUOLISOLLE
  | AsiointiLedgerAccountType.ARVO_OSUUSTILIN_SAILYTYSMAKSU
  | AsiointiLedgerAccountType.PALVELUMAKSUT
  | AsiointiLedgerAccountType.TALLELOKEROMAKSUT
  | AsiointiLedgerAccountType.ARVONLISAVERO
  | AsiointiLedgerAccountType.ENNAKKOVERO
  | AsiointiLedgerAccountType.JAANNOSVERO
  | AsiointiLedgerAccountType.KIINTEISTOVERO
  | AsiointiLedgerAccountType.LUOVUTUSVOITTOVERO
  | AsiointiLedgerAccountType.LAHDEVERO
  | AsiointiLedgerAccountType.METSANHOITOMAKSU
  | AsiointiLedgerAccountType.PERINTOVERO
  | AsiointiLedgerAccountType.MUUT_VEROT_KOIRAVERO_AJONEUVOVERO
  | AsiointiLedgerAccountType.PALKKA
  | AsiointiLedgerAccountType.VUOSILOMAKORVAUS
  | AsiointiLedgerAccountType.SOSIAALITURVAMAKSUT
  | AsiointiLedgerAccountType.ELAKETURVAMAKSUT
  | AsiointiLedgerAccountType.ENNAKKOPIDATYS
  | AsiointiLedgerAccountType.MUUT_TYONANTAJAKULUT
  | AsiointiLedgerAccountType.SOPIMUKSEN_MUKAISET_KOROT
  | AsiointiLedgerAccountType.VIIVASTYSKOROT_JA_PERINTAKULUT
  | AsiointiLedgerAccountType.EDUNVALVOJAN_PALKKIO
  | AsiointiLedgerAccountType.EDUNVALVOJAN_KULUKORVAUS
  | AsiointiLedgerAccountType.TILINTARKASTUS
  | AsiointiLedgerAccountType.PAATOKSET
  | AsiointiLedgerAccountType.MUUT_MAKSUT
  | AsiointiLedgerAccountType.ASIANTUNTIJAPALKKIOT
  | AsiointiLedgerAccountType.ETUUDEN_PALAUTUS
  | AsiointiLedgerAccountType.KORJAUSKUSTANNUKSET
  | AsiointiLedgerAccountType.MAKSUPALAUTUKSET
  | AsiointiLedgerAccountType.METSA_JA_MAATALOUSKIINTEISTON_KULUT
  | AsiointiLedgerAccountType.TIEMAKSU
  | AsiointiLedgerAccountType.VAHINGONKORVAUS
  | AsiointiLedgerAccountType.VALITYSPALKKIOT
  | AsiointiLedgerAccountType.MUUT_TILIKAUDEN_MENOT
  | AsiointiLedgerAccountType.HUONEISTON_OSTO
  | AsiointiLedgerAccountType.KIINTEISTON_OSTO
  | AsiointiLedgerAccountType.VIRHETULON_KORJAUS
  | AsiointiLedgerAccountType.VIRHEMAKSU_KORJATAAN_MYOHEMMIN
  | AsiointiLedgerAccountType.KIRJANPITOARVON_ALENNUS_MENOKOODI
  | AsiointiLedgerAccountType.PANKIN_VIRHE
  | AsiointiLedgerAccountType.VELKAA_HALTUUN
>

export const AsiointiLedgerAccountExpenseType = {
  [AsiointiLedgerAccountType.KAYTTOVARAT]:
    AsiointiLedgerAccountType.KAYTTOVARAT,
  [AsiointiLedgerAccountType.APTEEKKIOSTOT]:
    AsiointiLedgerAccountType.APTEEKKIOSTOT,
  [AsiointiLedgerAccountType.ATERIAPALVELU]:
    AsiointiLedgerAccountType.ATERIAPALVELU,
  [AsiointiLedgerAccountType.ENERGIA_JA_VESI]:
    AsiointiLedgerAccountType.ENERGIA_JA_VESI,
  [AsiointiLedgerAccountType.KODIN_HANKINNAT]:
    AsiointiLedgerAccountType.KODIN_HANKINNAT,
  [AsiointiLedgerAccountType.KUNNALLINEN_KOTIHOITO_KOTISAIRAANHOITO]:
    AsiointiLedgerAccountType.KUNNALLINEN_KOTIHOITO_KOTISAIRAANHOITO,
  [AsiointiLedgerAccountType.MATKAT]: AsiointiLedgerAccountType.MATKAT,
  [AsiointiLedgerAccountType.SUORATOISTOPALVELU]:
    AsiointiLedgerAccountType.SUORATOISTOPALVELU,
  [AsiointiLedgerAccountType.KIINTEISTON_KULUT]:
    AsiointiLedgerAccountType.KIINTEISTON_KULUT,
  [AsiointiLedgerAccountType.MUUT_OSTOKSET]:
    AsiointiLedgerAccountType.MUUT_OSTOKSET,
  [AsiointiLedgerAccountType.PALVELUT]: AsiointiLedgerAccountType.PALVELUT,
  [AsiointiLedgerAccountType.PUHELIN_INTERNET]:
    AsiointiLedgerAccountType.PUHELIN_INTERNET,
  [AsiointiLedgerAccountType.RUOKAKAUPPA]:
    AsiointiLedgerAccountType.RUOKAKAUPPA,
  [AsiointiLedgerAccountType.SAIRAANKULJETUS]:
    AsiointiLedgerAccountType.SAIRAANKULJETUS,
  [AsiointiLedgerAccountType.VAKUUTUKSET]:
    AsiointiLedgerAccountType.VAKUUTUKSET,
  [AsiointiLedgerAccountType.VAPAA_AIKA_JA_HARRASTUSKULUT]:
    AsiointiLedgerAccountType.VAPAA_AIKA_JA_HARRASTUSKULUT,
  [AsiointiLedgerAccountType.KELA_KORVATTAVAT_MATKAKULUT]:
    AsiointiLedgerAccountType.KELA_KORVATTAVAT_MATKAKULUT,
  [AsiointiLedgerAccountType.KELA_KORVATTAVAT_SAIRAANHOITOKULUT]:
    AsiointiLedgerAccountType.KELA_KORVATTAVAT_SAIRAANHOITOKULUT,
  [AsiointiLedgerAccountType.MUUT_ELINKUSTANNUKSET]:
    AsiointiLedgerAccountType.MUUT_ELINKUSTANNUKSET,
  [AsiointiLedgerAccountType.ASUMISPALVELU]:
    AsiointiLedgerAccountType.ASUMISPALVELU,
  [AsiointiLedgerAccountType.MAKSUKATTOA_KERRYTTAVA_HOITO]:
    AsiointiLedgerAccountType.MAKSUKATTOA_KERRYTTAVA_HOITO,
  [AsiointiLedgerAccountType.MUUT_HOITOMAKSUT]:
    AsiointiLedgerAccountType.MUUT_HOITOMAKSUT,
  [AsiointiLedgerAccountType.PITKAAIKAISHOITO]:
    AsiointiLedgerAccountType.PITKAAIKAISHOITO,
  [AsiointiLedgerAccountType.ASUNTOVUOKRA]:
    AsiointiLedgerAccountType.ASUNTOVUOKRA,
  [AsiointiLedgerAccountType.YHTIOVASTIKE]:
    AsiointiLedgerAccountType.YHTIOVASTIKE,
  [AsiointiLedgerAccountType.MUUT_VUOKRAT]:
    AsiointiLedgerAccountType.MUUT_VUOKRAT,
  [AsiointiLedgerAccountType.KOTITALOUSVAHENNYSKELPOISET_MENOT]:
    AsiointiLedgerAccountType.KOTITALOUSVAHENNYSKELPOISET_MENOT,
  [AsiointiLedgerAccountType.ELATUSAPU_LAPSELLE]:
    AsiointiLedgerAccountType.ELATUSAPU_LAPSELLE,
  [AsiointiLedgerAccountType.ELATUSAPU_PUOLISOLLE]:
    AsiointiLedgerAccountType.ELATUSAPU_PUOLISOLLE,
  [AsiointiLedgerAccountType.ARVO_OSUUSTILIN_SAILYTYSMAKSU]:
    AsiointiLedgerAccountType.ARVO_OSUUSTILIN_SAILYTYSMAKSU,
  [AsiointiLedgerAccountType.PALVELUMAKSUT]:
    AsiointiLedgerAccountType.PALVELUMAKSUT,
  [AsiointiLedgerAccountType.TALLELOKEROMAKSUT]:
    AsiointiLedgerAccountType.TALLELOKEROMAKSUT,
  [AsiointiLedgerAccountType.ARVONLISAVERO]:
    AsiointiLedgerAccountType.ARVONLISAVERO,
  [AsiointiLedgerAccountType.ENNAKKOVERO]:
    AsiointiLedgerAccountType.ENNAKKOVERO,
  [AsiointiLedgerAccountType.JAANNOSVERO]:
    AsiointiLedgerAccountType.JAANNOSVERO,
  [AsiointiLedgerAccountType.KIINTEISTOVERO]:
    AsiointiLedgerAccountType.KIINTEISTOVERO,
  [AsiointiLedgerAccountType.LUOVUTUSVOITTOVERO]:
    AsiointiLedgerAccountType.LUOVUTUSVOITTOVERO,
  [AsiointiLedgerAccountType.LAHDEVERO]: AsiointiLedgerAccountType.LAHDEVERO,
  [AsiointiLedgerAccountType.METSANHOITOMAKSU]:
    AsiointiLedgerAccountType.METSANHOITOMAKSU,
  [AsiointiLedgerAccountType.PERINTOVERO]:
    AsiointiLedgerAccountType.PERINTOVERO,
  [AsiointiLedgerAccountType.MUUT_VEROT_KOIRAVERO_AJONEUVOVERO]:
    AsiointiLedgerAccountType.MUUT_VEROT_KOIRAVERO_AJONEUVOVERO,
  [AsiointiLedgerAccountType.PALKKA]: AsiointiLedgerAccountType.PALKKA,
  [AsiointiLedgerAccountType.VUOSILOMAKORVAUS]:
    AsiointiLedgerAccountType.VUOSILOMAKORVAUS,
  [AsiointiLedgerAccountType.SOSIAALITURVAMAKSUT]:
    AsiointiLedgerAccountType.SOSIAALITURVAMAKSUT,
  [AsiointiLedgerAccountType.ELAKETURVAMAKSUT]:
    AsiointiLedgerAccountType.ELAKETURVAMAKSUT,
  [AsiointiLedgerAccountType.ENNAKKOPIDATYS]:
    AsiointiLedgerAccountType.ENNAKKOPIDATYS,
  [AsiointiLedgerAccountType.MUUT_TYONANTAJAKULUT]:
    AsiointiLedgerAccountType.MUUT_TYONANTAJAKULUT,
  [AsiointiLedgerAccountType.SOPIMUKSEN_MUKAISET_KOROT]:
    AsiointiLedgerAccountType.SOPIMUKSEN_MUKAISET_KOROT,
  [AsiointiLedgerAccountType.VIIVASTYSKOROT_JA_PERINTAKULUT]:
    AsiointiLedgerAccountType.VIIVASTYSKOROT_JA_PERINTAKULUT,
  [AsiointiLedgerAccountType.EDUNVALVOJAN_PALKKIO]:
    AsiointiLedgerAccountType.EDUNVALVOJAN_PALKKIO,
  [AsiointiLedgerAccountType.EDUNVALVOJAN_KULUKORVAUS]:
    AsiointiLedgerAccountType.EDUNVALVOJAN_KULUKORVAUS,
  [AsiointiLedgerAccountType.TILINTARKASTUS]:
    AsiointiLedgerAccountType.TILINTARKASTUS,
  [AsiointiLedgerAccountType.PAATOKSET]: AsiointiLedgerAccountType.PAATOKSET,
  [AsiointiLedgerAccountType.MUUT_MAKSUT]:
    AsiointiLedgerAccountType.MUUT_MAKSUT,
  [AsiointiLedgerAccountType.ASIANTUNTIJAPALKKIOT]:
    AsiointiLedgerAccountType.ASIANTUNTIJAPALKKIOT,
  [AsiointiLedgerAccountType.ETUUDEN_PALAUTUS]:
    AsiointiLedgerAccountType.ETUUDEN_PALAUTUS,
  [AsiointiLedgerAccountType.KORJAUSKUSTANNUKSET]:
    AsiointiLedgerAccountType.KORJAUSKUSTANNUKSET,
  [AsiointiLedgerAccountType.MAKSUPALAUTUKSET]:
    AsiointiLedgerAccountType.MAKSUPALAUTUKSET,
  [AsiointiLedgerAccountType.METSA_JA_MAATALOUSKIINTEISTON_KULUT]:
    AsiointiLedgerAccountType.METSA_JA_MAATALOUSKIINTEISTON_KULUT,
  [AsiointiLedgerAccountType.TIEMAKSU]: AsiointiLedgerAccountType.TIEMAKSU,
  [AsiointiLedgerAccountType.VAHINGONKORVAUS]:
    AsiointiLedgerAccountType.VAHINGONKORVAUS,
  [AsiointiLedgerAccountType.VALITYSPALKKIOT]:
    AsiointiLedgerAccountType.VALITYSPALKKIOT,
  [AsiointiLedgerAccountType.MUUT_TILIKAUDEN_MENOT]:
    AsiointiLedgerAccountType.MUUT_TILIKAUDEN_MENOT,
  [AsiointiLedgerAccountType.HUONEISTON_OSTO]:
    AsiointiLedgerAccountType.HUONEISTON_OSTO,
  [AsiointiLedgerAccountType.KIINTEISTON_OSTO]:
    AsiointiLedgerAccountType.KIINTEISTON_OSTO,
  [AsiointiLedgerAccountType.VIRHETULON_KORJAUS]:
    AsiointiLedgerAccountType.VIRHETULON_KORJAUS,
  [AsiointiLedgerAccountType.VIRHEMAKSU_KORJATAAN_MYOHEMMIN]:
    AsiointiLedgerAccountType.VIRHEMAKSU_KORJATAAN_MYOHEMMIN,
  [AsiointiLedgerAccountType.KIRJANPITOARVON_ALENNUS_MENOKOODI]:
    AsiointiLedgerAccountType.KIRJANPITOARVON_ALENNUS_MENOKOODI,
  [AsiointiLedgerAccountType.PANKIN_VIRHE]:
    AsiointiLedgerAccountType.PANKIN_VIRHE,
  [AsiointiLedgerAccountType.VELKAA_HALTUUN]:
    AsiointiLedgerAccountType.VELKAA_HALTUUN,
} as const
