import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  commonTodistuksetAsiakirjaMetadata,
  commonTodistuksetAsiaStatusMetadata,
  commonTodistuksetHuomioMetadata,
  commonTodistuksetOrganizationMetadata,
  commonTodistuksetPersonRoleMetadata,
  commonTodistuksetPersonTypeMetadata,
  commonTodistuksetRatkaisuMetadata,
  commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
  commonTodistuksetRetentionMetadata,
  commonTodistuksetSpecifierMetadata,
  commonTodistuksetToimenpideMetadata,
  commonTodistuksetVireilletulokanavaMetadata,
  commonTodistuksetEmailMetadata,
} from 'asia-common/src/vtj/koodisto/asia-type/todistukset/common-todistukset-metadata'

export const siviilisaatytodistusMetadata: AsiaMetadata = {
  asiakirjaMetadata: commonTodistuksetAsiakirjaMetadata,
  huomioMetadata: commonTodistuksetHuomioMetadata,
  organizationMetadata: commonTodistuksetOrganizationMetadata,
  personRoleMetadata: commonTodistuksetPersonRoleMetadata,
  personTypeMetadata: commonTodistuksetPersonTypeMetadata,
  ratkaisuMetadata: commonTodistuksetRatkaisuMetadata,
  specifierMetadata: commonTodistuksetSpecifierMetadata,
  toimenpideMetadata: commonTodistuksetToimenpideMetadata,
  vireilletulokanavaMetadata: commonTodistuksetVireilletulokanavaMetadata,
  asiaStatusMetadata: commonTodistuksetAsiaStatusMetadata,
  restrictionMetadata: commonTodistuksetOsittainSalassaPidettavaRestrictionMetadata,
  retentionMetadata: commonTodistuksetRetentionMetadata,
  emailMetadata: commonTodistuksetEmailMetadata,
}
