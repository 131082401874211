import React from 'react'
import { observer } from 'mobx-react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  RouterLink,
  suomifiDesignTokens,
  WizardNavigation,
  WizardNavigationItem,
} from 'suomifi-ui-components'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { BorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  PAATOSTILI_ROUTES,
  PaatostiliRoutePath,
  OMAISUUSLUETTELO_ROUTES,
  TilintarkastusFormState,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import {
  getTilintarkastusStore,
  runTilintarkastusStoreAction,
  saveDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { mkWizardNavigationTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'

// values from suomifi-ui-components WizardNavigationItemProps
export type NavigationStepStatus =
  | 'default'
  | 'current'
  | 'current-completed'
  | 'completed'
  | 'coming'
  | 'disabled'

export type NavigationState = Record<PaatostiliRoutePath, NavigationStepStatus>

interface FormWizardNavigationProps<
  R extends Partial<typeof PAATOSTILI_ROUTES | typeof OMAISUUSLUETTELO_ROUTES>, // i.e ROUTES
  P extends R[keyof R] & string // i.e. ROUTE PATH
> {
  routes: R
  steps: P[]
  forms: Partial<Record<P, TilintarkastusFormState>>
}

const FormNavigationWizard = observer(
  <
    T extends Partial<
      typeof PAATOSTILI_ROUTES | typeof OMAISUUSLUETTELO_ROUTES
    >,
    V extends T[keyof T] & string
  >({
    forms,
    routes,
    steps,
  }: FormWizardNavigationProps<T, V>) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const { excludeSteps, visitedSteps, submitStatus, isPrincipalUnderaged } =
      getTilintarkastusStore()
    const location = useLocation()
    const currentStep = location.pathname
    const activeSteps = steps.filter((step) => !excludeSteps.has(step))

    runTilintarkastusStoreAction((store) => {
      store.visitedSteps.add(currentStep)
    })

    const evaluateStep = (
      currentStep: V,
      evalStep: V
    ): NavigationStepStatus => {
      const currentIndex = activeSteps.indexOf(currentStep)
      const evalIndex = activeSteps.indexOf(evalStep)
      const completed = evaluateStepCompleted(evalStep)
      if (currentIndex === evalIndex) {
        return completed ? 'current-completed' : 'current'
      } else if (!!visitedSteps.has(evalStep) && submitStatus !== 'submitted') {
        return completed ? 'completed' : 'default'
      } else {
        return 'coming'
      }
    }

    const evaluateStepCompleted = (step: V): boolean => {
      const currentStepIndex = activeSteps.indexOf(step)
      const nextStep = activeSteps.at(
        currentStepIndex + 1
      ) as TilintarkastusRoutePath
      const stepFormState = (
        forms as Record<string, TilintarkastusFormState | undefined>
      )[step]

      return (
        visitedSteps.has(step) &&
        visitedSteps.has(nextStep) &&
        (!stepFormState || stepFormState.isComplete)
      )
    }

    const evaluateNavigationState = (currentStep: string): NavigationState =>
      activeSteps.reduce((navState, evalStep: V) => {
        return {
          ...navState,
          [evalStep]: evaluateStep(currentStep as V, evalStep),
        }
      }, {} as NavigationState)

    const navState = evaluateNavigationState(currentStep)

    const stepTranslationByKey: Record<string, string> = {
      TEE_PAATOSTILI: 'aloitus',
      YLEISKATSAUS: 'yleiskatsaus',
      OMAISUUS_JA_VELAT: 'omaisuusJaVelat',
      TULOT_JA_MENOT: 'tulotJaMenot',
      PALKKIO: 'palkkio',
      YHTEYSTIEDOT: 'yhteystiedot',
      YHTEENVETO: 'yhteenvetoJaLahetys',
      VALMIS: 'valmis',
      TEE_OMAISUUSLUETTELO: 'aloitus',
      TOIMINTAKYKY_JA_ASUMISTIEDOT: isPrincipalUnderaged
        ? 'asuminenHeading'
        : 'toimintakykyJaAsumistiedot',
      OMASSA_KAYTOSSA_OLEVA_OMAISUUS: 'omassaKaytossaOlevaOmaisuus',
      VARAT: 'varat',
      VELAT: 'velat',
      ELAKKEET_JA_ETUUDET: 'elakkeetJaEtuudet',
      KAYTTOVARAT: 'kayttovarat',
      SOPIMUKSET_JA_VAKUUTUKSET: 'sopimuksetJaVakuutukset',
      MUUT_MERKITTAVAT_TOIMET: 'muutMerkittavatToimet',
      VALTAKIRJA: 'valtakirja',
    }

    return (
      <NavBlock>
        <WizardNavigation
          data-test-id={mkWizardNavigationTestId('container')}
          heading={t('vaiheet')}
          aria-label={t('vaiheet')}
          variant={isTablet ? 'default' : 'smallScreen'}
          initiallyExpanded={false}
        >
          {activeSteps.map((path, index) => {
            const [[key, value]] = Object.entries(routes).filter(
              ([_, value]) => value === path
            )
            return (
              <NavItem
                key={key}
                status={navState[value as V]}
                href={value}
                text={`${index + 1}. ${t(stepTranslationByKey[key])}`}
              />
            )
          })}
        </WizardNavigation>
      </NavBlock>
    )
  }
)

const NavBlock = styled(BorderedContentBlock)`
  @media ${device.tablet} {
    max-width: 366px;
    padding: ${suomifiDesignTokens.spacing.s} 0;
  }
`

const NavItem: React.FC<{
  status: NavigationStepStatus
  text: string
  href: string
}> = observer(({ status, text, href }) => {
  const [t] = useTranslation()
  const { submitStatus } = getTilintarkastusStore()
  const ariaLabel =
    status === 'current-completed' || status === 'completed'
      ? `${text}. ${t('vaiheValmis')}`
      : undefined
  return (
    <WizardNavigationItem
      status={status}
      aria-current={status === 'current' ? 'step' : undefined}
      data-test-id={mkWizardNavigationTestId('item', href)}
    >
      {(!isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TABNAVIGATION) &&
        status === 'coming') ||
      submitStatus !== 'none' ? (
        <RouterLink
          onClick={() => {
            void saveDraft()
          }}
          aria-disabled
          tabIndex={0}
          data-test-id={mkWizardNavigationTestId('item-link', href)}
        >
          {text}
        </RouterLink>
      ) : (
        <RouterLink
          onClick={() => {
            void saveDraft()
          }}
          to={href}
          asComponent={Link}
          aria-label={ariaLabel}
          data-test-id={mkWizardNavigationTestId('item-link', href)}
        >
          {text}
        </RouterLink>
      )}
    </WizardNavigationItem>
  )
})

export default FormNavigationWizard
