import { LupaLanguage, LupaLanguageId } from 'lupa-backend/src/vtj/common-enums'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'

export const languageCodeToLupaLanguage = (
  languageCode: LanguageCode | string
): LupaLanguageId =>
  languageCode === LanguageCode.SV ? LupaLanguage.SWE : LupaLanguage.FIN

export const lupaLanguageToLanguageCode = (
  lupaLanguageId: LupaLanguageId
): LanguageCode => {
  switch (lupaLanguageId) {
    case LupaLanguage.FIN:
      return LanguageCode.FI
    case LupaLanguage.SWE:
      return LanguageCode.SV
  }
}
