import {
  AsiointiLupaKeyword,
  AsiointiLupaKeywordId,
  AsiointiLupaType,
  AsiointiLupaTypeId,
  ExternalLupaType,
  ExternalLupaTypeId,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import {
  ContainsEstate,
  LupaApplicationType,
  LupaSpecifierType,
  LupaSpecifierTypeId,
  SaleProperty,
  SalePropertyId,
} from 'lupa-backend/src/vtj/elsa/lupa/lupa-enums'
import {
  estateAsiakirjaTypeIds,
  LupaAsiointiAsiakirjaType,
  LupaAsiointiAsiakirjaTypeId,
} from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { without, uniq } from 'lodash'

export type AsiointiLupaInfo = {
  keywords: AsiointiLupaKeywordId[]
  mapping: AsiointiLupaMapping
  /** All possible asiakirja types */
  asiakirjaTypes: LupaAsiointiAsiakirjaTypeId[]
  /** Always required asiakirja types */
  requiredAsiakirjaTypes: LupaAsiointiAsiakirjaTypeId[]
  containsEstate: AsiointiLupaEstateSpecification
  /**
   * Asiakirja types that are enabled when lupa instance has containsEstate = LIITTYY_KUOLINPESA
   * and disabled when EI_KUOLINPESAA.
   */
  estateAsiakirjaTypes: LupaAsiointiAsiakirjaTypeId[]
  /**
   * Asiakirja types that are required when lupa instance has containsEstate = LIITTYY_KUOLINPESA.
   */
  requiredEstateAsiakirjaTypes: LupaAsiointiAsiakirjaTypeId[]
}

export type ExternalLupaInfo = {
  keywords: AsiointiLupaKeywordId[]
}

export enum AsiointiLupaEstateSpecification {
  OPTIONAL = 'OPTIONAL',
  ALWAYS = 'ALWAYS',
}

export type AsiointiLupaMapping =
  | {
      elsaLupaTypeId: typeof LupaApplicationType.MYYNTI
      salePropertyId: SalePropertyId
    }
  | {
      elsaLupaTypeId: typeof LupaApplicationType.MUU
      specifier: LupaSpecifierTypeId
    }
  | {
      elsaLupaTypeId: typeof LupaApplicationType.PERINNONJAKO
    }

// Ordered record
export const asiointiLupaInfos: Readonly<
  Record<AsiointiLupaTypeId, AsiointiLupaInfo>
> = {
  [AsiointiLupaType.ASUNNON_MYYNTI]: {
    keywords: [
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.MYYNTI,
      AsiointiLupaKeyword.ASUNNOT,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ARVIO_ASUNNON_ARVOSTA,
      LupaAsiointiAsiakirjaType.ISANNOITSIJANTODISTUS,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MYYNTI,
      salePropertyId: SaleProperty.ASUNTO,
    },
  },
  [AsiointiLupaType.KIINTEISTON_MYYNTI]: {
    keywords: [
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.MYYNTI,
      AsiointiLupaKeyword.KIINTEISTOT,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS_TAI_ESISOPIMUS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ARVIO_KIINTEISTON_ARVOSTA,
      LupaAsiointiAsiakirjaType.ARVIO_METSAN_TAI_MAA_AINEKSEN_ARVOSTA,
      LupaAsiointiAsiakirjaType.ARVIO_PELLON_ARVOSTA,
      LupaAsiointiAsiakirjaType.MAANVUOKRASOPIMUS,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS_TAI_ESISOPIMUS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MYYNTI,
      salePropertyId: SaleProperty.KIINTEISTO,
    },
  },
  [AsiointiLupaType.ASUMISOIKEUDEN_MYYNTI]: {
    keywords: [
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.MYYNTI,
      AsiointiLupaKeyword.ASUMISOIKEUS,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ASUMISOIKEUSSOPIMUS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ASUMISOIKEUDEN_LUOVUTUSHINTALASKELMA,
      LupaAsiointiAsiakirjaType.ASUMISOIKEUDEN_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ASUMISOIKEUDEN_LUOPUMISILMOITUS_TAI_KAUPPAKIRJA,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MYYNTI,
      salePropertyId: SaleProperty.KIINTEISTO,
    },
  },
  [AsiointiLupaType.METSAN_TAI_MAA_AINEKSEN_MYYNTI]: {
    keywords: [
      AsiointiLupaKeyword.MYYNTI,
      AsiointiLupaKeyword.METSA_TAI_MAA_AINES,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.SOPIMUSASIAKIRJA_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ARVIO_METSAN_TAI_MAA_AINEKSEN_ARVOSTA,
      LupaAsiointiAsiakirjaType.METSAN_MYYNNIN_TOIMEKSIANTOSOPIMUS_PUUKAUPAN_MAARA_JA_HINTA_ARVIO,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.SOPIMUSASIAKIRJA_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.METSAN_TAI_MAA_AINEKSEN_MYYNTI,
    },
  },
  [AsiointiLupaType.ASUNNON_HANKKIMINEN]: {
    keywords: [
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.OSTO,
      AsiointiLupaKeyword.ASUNNOT,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ARVIO_ASUNNON_ARVOSTA,
      LupaAsiointiAsiakirjaType.ISANNOITSIJANTODISTUS,
      LupaAsiointiAsiakirjaType.SELVITYS_HANKINNAN_RAHOITUKSESTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.ASUNNON_HANKKIMINEN,
    },
  },
  [AsiointiLupaType.KIINTEISTON_HANKKIMINEN]: {
    keywords: [
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.OSTO,
      AsiointiLupaKeyword.KIINTEISTOT,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS_TAI_ESISOPIMUS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ARVIO_KIINTEISTON_ARVOSTA,
      LupaAsiointiAsiakirjaType.ARVIO_METSAN_TAI_MAA_AINEKSEN_ARVOSTA,
      LupaAsiointiAsiakirjaType.ARVIO_PELLON_ARVOSTA,
      LupaAsiointiAsiakirjaType.MAANVUOKRASOPIMUS,
      LupaAsiointiAsiakirjaType.SELVITYS_HANKINNAN_RAHOITUKSESTA,
      LupaAsiointiAsiakirjaType.SELVITYS_MYYJAN_OMISTUSOIKEUDESTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KAUPPAKIRJA_TAI_SEN_LUONNOS_TAI_ESISOPIMUS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.KIINTEISTON_HANKKIMINEN,
    },
  },
  [AsiointiLupaType.ASUMISOIKEUDEN_HANKKIMINEN]: {
    keywords: [
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.OSTO,
      AsiointiLupaKeyword.ASUMISOIKEUS,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ASUMISOIKEUSSOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ASUMISOIKEUDEN_LUOVUTUSHINTALASKELMA,
      LupaAsiointiAsiakirjaType.SELVITYS_HANKINNAN_RAHOITUKSESTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ASUMISOIKEUSSOPIMUS_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.ASUMISOIKEUDEN_HANKKIMINEN,
    },
  },
  [AsiointiLupaType.ASUNNON_VUOKRAAMINEN]: {
    keywords: [
      AsiointiLupaKeyword.VUOKRAUS,
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.ASUNNOT,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VUOKRASOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ISANNOITSIJANTODISTUS,
      LupaAsiointiAsiakirjaType.SELVITYS_VUOKRAN_RIITTAVYYDESTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VUOKRASOPIMUS_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.ASUNNON_VUOKRAAMINEN,
    },
  },
  [AsiointiLupaType.KIINTEISTON_VUOKRAAMINEN]: {
    keywords: [
      AsiointiLupaKeyword.VUOKRAUS,
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.KIINTEISTOT,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VUOKRASOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.MAANVUOKRASOPIMUS,
      LupaAsiointiAsiakirjaType.SELVITYS_VUOKRAN_RIITTAVYYDESTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VUOKRASOPIMUS_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.KIINTEISTON_VUOKRAAMINEN,
    },
  },
  [AsiointiLupaType.ASUMISOIKEUDEN_VUOKRAAMINEN]: {
    keywords: [
      AsiointiLupaKeyword.VUOKRAUS,
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.ASUMISOIKEUS,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VUOKRASOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.ASUMISOIKEUSSOPIMUS,
      LupaAsiointiAsiakirjaType.SELVITYS_VUOKRAN_RIITTAVYYDESTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VUOKRASOPIMUS_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.ASUMISOIKEUDEN_VUOKRAAMINEN,
    },
  },
  [AsiointiLupaType.PERINNONJAKO]: {
    keywords: [AsiointiLupaKeyword.PERINTO, AsiointiLupaKeyword.KUOLINPESA],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.OSITUS_EROTTELU_JA_TAI_PERINNONJAKOSOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.SELVITYS_OMAISUUDEN_ARVOSTA,
      LupaAsiointiAsiakirjaType.SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA,
      LupaAsiointiAsiakirjaType.KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      LupaAsiointiAsiakirjaType.SELVITYS_EROTELTAVAN_OMAISUUDEN_OMISTAJUUDESTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.OSITUS_EROTTELU_JA_TAI_PERINNONJAKOSOPIMUS_TAI_SEN_LUONNOS,
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    containsEstate: AsiointiLupaEstateSpecification.ALWAYS,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.PERINNONJAKO,
    },
  },
  [AsiointiLupaType.PERINTOOSUUDEN_LUOVUTUS]: {
    keywords: [
      AsiointiLupaKeyword.KUOLINPESA,
      AsiointiLupaKeyword.MYYNTI,
      AsiointiLupaKeyword.PERINTO,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERINTOOSUUDEN_LUOVUTUSSOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.SELVITYS_OMAISUUDEN_ARVOSTA,
      LupaAsiointiAsiakirjaType.SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA,
      LupaAsiointiAsiakirjaType.KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERINTOOSUUDEN_LUOVUTUSSOPIMUS_TAI_SEN_LUONNOS,
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    containsEstate: AsiointiLupaEstateSpecification.ALWAYS,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.PERINTOOSUUDEN_LUOVUTUS,
    },
  },
  [AsiointiLupaType.PERINNOSTA_LUOPUMINEN]: {
    keywords: [AsiointiLupaKeyword.KUOLINPESA, AsiointiLupaKeyword.PERINTO],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ILMOITUS_PERINNOSTA_LUOPUMISESTA_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.SELVITYS_OMAISUUDEN_ARVOSTA,
      LupaAsiointiAsiakirjaType.SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA,
      LupaAsiointiAsiakirjaType.KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ILMOITUS_PERINNOSTA_LUOPUMISESTA_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.ALWAYS,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    // Nuutti Pennanen: "Laitetaan [perukirja] pakolliseksi muihin kuin perinnöstä luopumiseen [niissä luvissa, joihin aina liittyy kuolinpesä].
    // Siinä voi olla sellaisia kansainvälisiä erikoistilanteita, joissa aikataulutus ei välttämättä mene niin kuin Suomessa."
    requiredEstateAsiakirjaTypes: [],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.PERINNOSTA_LUOPUMINEN,
    },
  },
  [AsiointiLupaType.SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA]: {
    keywords: [AsiointiLupaKeyword.KUOLINPESA],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KUOLINPESAN_YHTEISHALLINTOA_KOSKEVA_SOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA,
      LupaAsiointiAsiakirjaType.KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.KUOLINPESAN_YHTEISHALLINTOA_KOSKEVA_SOPIMUS_TAI_SEN_LUONNOS,
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    containsEstate: AsiointiLupaEstateSpecification.ALWAYS,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA,
    },
  },
  [AsiointiLupaType.AVIOERO_OSITUS_TAI_EROTTELU]: {
    keywords: [AsiointiLupaKeyword.AVIOERO],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.OSITUS_JA_TAI_EROTTELUSOPIMUS_TAI_SEN_LUONNOS,
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
      LupaAsiointiAsiakirjaType.AVIOEHTOSOPIMUS_JA_TODISTUS_SEN_REKISTEROINNISTA,
      LupaAsiointiAsiakirjaType.TESTAMENTTI_JA_SEN_TIEDOKSIANTO_JA_LAINVOIMAISUUSTODISTUKSET,
      LupaAsiointiAsiakirjaType.SELVITYS_OMAISUUDEN_ARVOSTA,
      LupaAsiointiAsiakirjaType.SELVITYS_EROTELTAVAN_OMAISUUDEN_OMISTAJUUDESTA,
      LupaAsiointiAsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
      LupaAsiointiAsiakirjaType.SELVITYS_OMAISUUDESTA_JA_VELASTA_KUN_AVIOEROA_ON_HAETTU,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.OSITUS_JA_TAI_EROTTELUSOPIMUS_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    // Avioehtosopimus is relevant in this lupa type regardless of whether a kuolinpesä is involved or not.
    estateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
      LupaAsiointiAsiakirjaType.TESTAMENTTI_JA_SEN_TIEDOKSIANTO_JA_LAINVOIMAISUUSTODISTUKSET,
    ],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.AVIOERO_OSITUS_TAI_EROTTELU,
    },
  },
  [AsiointiLupaType.LAINAN_ANTAMINEN]: {
    keywords: [AsiointiLupaKeyword.LAINAT],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VELKAKIRJA_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.SELVITYS_VELALLISEN_TALOUDELLISESTA_TILANTEESTA_JA_LAINAN_TAKAISINMAKSUKYVYSTA,
      LupaAsiointiAsiakirjaType.SELVITYS_PANTISTA_LAINAN_ANNOSSA,
      LupaAsiointiAsiakirjaType.VELALLISEN_SUOSTUMUS_LUOTTOTIETOJEN_TARKASTAMISEEN,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.VELKAKIRJA_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.LAINAN_ANTAMINEN,
    },
  },
  [AsiointiLupaType.LAINAN_OTTAMINEN]: {
    keywords: [AsiointiLupaKeyword.LAINAT],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.LAINAPAATOS_VELKAKIRJALUONNOS_TAI_MUU_LAINANANTAJAN_TODISTUS_LAINAN_EHDOISTA,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.SELVITYS_ENTISISTEN_VELKAVASTUIDEN_VOIMAANJAANNISTA,
      LupaAsiointiAsiakirjaType.SELVITYS_PAAMIEHEN_LAINANHOITOKYVYSTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.LAINAPAATOS_VELKAKIRJALUONNOS_TAI_MUU_LAINANANTAJAN_TODISTUS_LAINAN_EHDOISTA,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.LAINAN_OTTAMINEN,
    },
  },
  [AsiointiLupaType.PANTTAUS]: {
    keywords: [
      AsiointiLupaKeyword.LAINAT,
      AsiointiLupaKeyword.MUUTTO,
      AsiointiLupaKeyword.PANTTAUS,
    ],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ERITYISPANTTAUSSITOUMUS_SOPIMUSLUONNOS_TAI_MUU_SELVITYS_PANTTAUKSEN_EHDOISTA,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.LAINAPAATOS_VELKAKIRJALUONNOS_TAI_MUU_LAINANANTAJAN_TODISTUS_LAINAN_EHDOISTA,
      LupaAsiointiAsiakirjaType.SELVITYS_MUISTA_VAKUUKSISTA,
      LupaAsiointiAsiakirjaType.LAINANSAAJAN_SELVITYS_LAINANHOITOKYVYSTAAN,
      LupaAsiointiAsiakirjaType.LAINANSAAJAN_PALKKATODISTUS,
      LupaAsiointiAsiakirjaType.SELVITYS_PANTATTAVASTA_OMAISUUDESTA_SEN_ARVOSTA_JA_PAAMIEHEN_OMISTUSOIKEUDESTA,
      LupaAsiointiAsiakirjaType.SELVITYS_ENTISISTEN_PANTTAUSTEN_VOIMAANJAANNISTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.ERITYISPANTTAUSSITOUMUS_SOPIMUSLUONNOS_TAI_MUU_SELVITYS_PANTTAUKSEN_EHDOISTA,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.PANTTAUS,
    },
  },
  [AsiointiLupaType.TAKAUS]: {
    keywords: [AsiointiLupaKeyword.LAINAT],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.TAKAUSSITOUMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.LAINAPAATOS_VELKAKIRJALUONNOS_TAI_MUU_LAINANANTAJAN_TODISTUS_LAINAN_EHDOISTA,
      LupaAsiointiAsiakirjaType.SELVITYS_LAINANOTTAJAN_LAINANHOITOKYVYSTA,
      LupaAsiointiAsiakirjaType.SELVITYS_PAAMIEHEN_KYVYSTA_SELVIYTYA_TAKAUSVASTUUSTA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.TAKAUSSITOUMUS_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.TAKAUS,
    },
  },
  [AsiointiLupaType.YHTIOMIEHEKSI_RYHTYMINEN]: {
    keywords: [AsiointiLupaKeyword.YRITYSTOIMINTA],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.YHTIOSOPIMUS_TAI_SEN_LUONNOS,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.SELVITYS_ELINKEINOTOIMINNASTA_JA_PAAMIEHEN_VASTUISTA,
      LupaAsiointiAsiakirjaType.TILINPAATOS_YHTIOMIEHEKSI_RYHTYESSA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.YHTIOSOPIMUS_TAI_SEN_LUONNOS,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.YHTIOMIEHEKSI_RYHTYMINEN,
    },
  },
  [AsiointiLupaType.ELINKEINON_HARJOITTAMINEN]: {
    keywords: [AsiointiLupaKeyword.YRITYSTOIMINTA],
    asiakirjaTypes: [
      LupaAsiointiAsiakirjaType.SELVITYS_ELINKEINOTOIMINNASTA_JA_PAAMIEHEN_VASTUISTA,
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.SELVITYS_ELINKEINOTOIMINNASTA_JA_PAAMIEHEN_VASTUISTA,
    ],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.ELINKEINON_HARJOITTAMINEN,
    },
  },
  [AsiointiLupaType.SIJOITUSKOHTEIDEN_OSTAMINEN]: {
    keywords: [AsiointiLupaKeyword.SIJOITTAMINEN, AsiointiLupaKeyword.OSTO],
    asiakirjaTypes: [
      ...estateAsiakirjaTypeIds,
      LupaAsiointiAsiakirjaType.TILINPAATOS_SIJOITUSKOHTEIDEN_OSTOSSA,
      LupaAsiointiAsiakirjaType.OSUUSKUNNAN_SAANNOT_SIJOITUSKOHTEIDEN_OSTOSSA,
      LupaAsiointiAsiakirjaType.YHTIOJARJESTYS_SIJOITUSKOHTEIDEN_OSTOSSA,
      LupaAsiointiAsiakirjaType.LAINAEHDOT_SIJOITUSKOHTEIDEN_OSTOSSA,
      LupaAsiointiAsiakirjaType.SIJOITUSRAHASTON_SAANNOT_SIJOITUSKOHTEIDEN_OSTOSSA,
      LupaAsiointiAsiakirjaType.JOHDANNAISSOPIMUKSEN_EHDOT_JA_OPTIONASETTAJAN_TALOUDELLINEN_ASEMA_SIJOITUSKOHTEIDEN_OSTOSSA,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
    ],
    requiredAsiakirjaTypes: [],
    containsEstate: AsiointiLupaEstateSpecification.OPTIONAL,
    estateAsiakirjaTypes: [...estateAsiakirjaTypeIds],
    requiredEstateAsiakirjaTypes: [
      LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
    ],
    mapping: {
      elsaLupaTypeId: LupaApplicationType.MUU,
      specifier: LupaSpecifierType.SIJOITUSKOHTEIDEN_OSTAMINEN,
    },
  },
}

export const externalLupaInfos: Readonly<
  Record<ExternalLupaTypeId, ExternalLupaInfo>
> = {
  [ExternalLupaType.OMAISUUDENHOITOSUUNNITELMA]: {
    keywords: [AsiointiLupaKeyword.OMAISUUDENHOITO],
  },
  [ExternalLupaType.VAJAAVALTAISEN_TYOANSIO]: {
    keywords: [AsiointiLupaKeyword.OMAISUUDENHOITO],
  },
  [ExternalLupaType.ESTOTILI]: {
    keywords: [AsiointiLupaKeyword.OMAISUUDENHOITO],
  },
}

export const lupaTypeSortOrders: Readonly<Record<AsiointiLupaTypeId, number>> =
  Object.keys(asiointiLupaInfos).reduce((sortOrders, lupaTypeId, index) => {
    sortOrders[lupaTypeId as AsiointiLupaTypeId] = index
    return sortOrders
  }, {} as Record<AsiointiLupaTypeId, number>)

export const applicationCompareFn = (
  a1: { typeId: AsiointiLupaTypeId },
  a2: { typeId: AsiointiLupaTypeId }
): number => lupaTypeSortOrders[a1.typeId] - lupaTypeSortOrders[a2.typeId]

export const getExpectedAsiakirjaTypes = ({
  typeId,
  containsEstate,
}: {
  typeId: AsiointiLupaTypeId
  containsEstate:
    | typeof ContainsEstate.LIITTYY_KUOLINPESA
    | typeof ContainsEstate.EI_KUOLINPESAA
}): LupaAsiointiAsiakirjaTypeId[] => {
  const info = asiointiLupaInfos[typeId]
  if (
    info.containsEstate === AsiointiLupaEstateSpecification.ALWAYS ||
    containsEstate === ContainsEstate.LIITTYY_KUOLINPESA
  ) {
    return uniq([...info.asiakirjaTypes, ...info.estateAsiakirjaTypes])
  } else {
    return without(info.asiakirjaTypes, ...info.estateAsiakirjaTypes)
  }
}

export const getRequiredAsiakirjaTypes = ({
  typeId,
  containsEstate,
}: {
  typeId: AsiointiLupaTypeId
  containsEstate:
    | typeof ContainsEstate.LIITTYY_KUOLINPESA
    | typeof ContainsEstate.EI_KUOLINPESAA
}): LupaAsiointiAsiakirjaTypeId[] => {
  const info = asiointiLupaInfos[typeId]
  if (
    info.containsEstate === AsiointiLupaEstateSpecification.ALWAYS ||
    containsEstate === ContainsEstate.LIITTYY_KUOLINPESA
  ) {
    return uniq([
      ...info.requiredAsiakirjaTypes,
      ...info.requiredEstateAsiakirjaTypes,
    ])
  } else {
    return [...info.requiredAsiakirjaTypes]
  }
}
