import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  Block,
  Button,
  ExternalLink,
  Heading,
  IconArrowRight,
  IconCheckCircleFilled,
  Paragraph,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import { getTiliFeedbackUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { mkValmisTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  ButtonBlock,
  FocusableHeading,
  ValmisBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/components'
import { useNavigate } from 'react-router-dom'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import { useValittuHenkilo } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'

const OmaisuusluetteloValmisPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const userStore = useAsiointiUserStore()
  const headingRef = useAutoFocus<HTMLHeadingElement>()
  const navigate = useNavigate()
  const henkilo = useValittuHenkilo()
  const openFrontPage = () =>
    navigate(getAsioiPathByPersonId(henkilo?.personId))
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <ResponsiveBorderedContentBlock data-test-id={mkValmisTestId('container')}>
      <ValmisBlock mb={verticalMarginToken}>
        <IconCheckCircleFilled fill={suomifiDesignTokens.colors.successDark1} />{' '}
        {t('valmis')}
      </ValmisBlock>
      <FocusableHeading
        variant="h2"
        smallScreen={!isTablet}
        ref={headingRef}
        tabIndex={-1}
      >
        {t('kiitosOmaisuusluetteloOnLahetetty')}
      </FocusableHeading>
      <DividerLine mt={verticalMarginToken} />
      <Paragraph mt={verticalMarginToken}>
        {t('omaisuusluetteloLahetettyInfo1')}
      </Paragraph>
      <DividerLine mt={verticalMarginToken} />
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('annaPalautetta')}
        </Heading>
        <Paragraph mt={verticalMarginToken}>
          {t('omaisuusluetteloAnnaPalautettaInfo1')}
        </Paragraph>
        <Block mt={verticalMarginToken}>
          <ExternalLink
            href={getTiliFeedbackUrl(userStore.lang)}
            data-test-id={mkValmisTestId('tili-feedback-link-button')}
            variant="accent"
            underline="initial"
            smallScreen={!isTablet}
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('annaPalautettaPalvelusta')}
          </ExternalLink>
        </Block>
      </Block>
      <DividerLine mt={verticalMarginToken} />
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('seuraavaksi')}
        </Heading>
        <Paragraph mt={verticalMarginToken}>
          {t('voitPalataEtusivulleTaiKirjautuaUlos')}
        </Paragraph>
        <ButtonBlock variant="section" mt={verticalMarginToken}>
          <Button
            onClick={openFrontPage}
            variant={'secondary'}
            iconRight={<IconArrowRight />}
            role="link"
            data-test-id={mkValmisTestId('asiointi-front-page-link-button')}
          >
            {t('siirryPalvelunEtusivulle')}
          </Button>
        </ButtonBlock>
      </Block>
    </ResponsiveBorderedContentBlock>
  )
})

export default OmaisuusluetteloValmisPage
