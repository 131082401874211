import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
  AsiaPersonType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

const uaetJulkinenRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

const uaetOsittainSalassaPidettavaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

const uaetSalassaPidettavaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25, SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

const uaet10VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_10_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_10_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

const uaet5VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_5_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_5_VUOTTA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const todistusOikeudestaMennaAvioliittoonUlkomaillaMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.PYYNTO_AVIOLIITON_ESTEIDEN_TUTKINNASTA_LIITTEINEEN_VIHKIMINEN_ULKOMAILLA,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['37248d81-c9de-4ad1-a0fa-150de36981b7'],
      defaultToivoAsiakirjaTypeId: '37248d81-c9de-4ad1-a0fa-150de36981b7',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c40a98b7-f354-4e15-95cd-1331248e1d4e'],
      defaultToivoAsiakirjaTypeId: 'c40a98b7-f354-4e15-95cd-1331248e1d4e',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['90ed8469-0211-4733-8395-c1ac53739c50'],
      defaultToivoAsiakirjaTypeId: '90ed8469-0211-4733-8395-c1ac53739c50',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['7db8f06a-93dd-4df0-bca2-7b59aff9dd6f'],
      defaultToivoAsiakirjaTypeId: '7db8f06a-93dd-4df0-bca2-7b59aff9dd6f',
    },
    {
      asiakirjaTypeId:
        AsiakirjaType.TODISTUS_SUOMEN_LAIN_MUKAISESTA_OIKEUDESTA_MENNA_AVIOLIITTOON_ULKOMAAN_VIRANOMAISEN_EDESSA,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['6fcd1561-5dc2-4ef8-a78a-9fe8b9b722fb'],
      defaultToivoAsiakirjaTypeId: '6fcd1561-5dc2-4ef8-a78a-9fe8b9b722fb',
    },
    {
      asiakirjaTypeId: AsiakirjaType.EU_VAKIOLOMAKE,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['efb6769f-aada-4852-a58e-a29a333c98d9'],
      defaultToivoAsiakirjaTypeId: 'efb6769f-aada-4852-a58e-a29a333c98d9',
    },
    {
      asiakirjaTypeId: AsiakirjaType.APOSTILLE,
      restrictionMetadata: uaetJulkinenRestriction,
      retentionMetadata: uaet5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['77e2ccdc-30fb-4ce7-a0db-88e13621c8a4'],
      defaultToivoAsiakirjaTypeId: '77e2ccdc-30fb-4ce7-a0db-88e13621c8a4',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SAATE_TAI_LAHETE,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['44a12a24-1a6d-465d-88e7-e08dc1b0c213'],
      defaultToivoAsiakirjaTypeId: '44a12a24-1a6d-465d-88e7-e08dc1b0c213',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KIELTEINEN_PAATOS_RAUKEAMINEN,
      restrictionMetadata: uaetSalassaPidettavaRestriction,
      retentionMetadata: uaet10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['fd359eef-8248-4918-a8d0-a32117a06264'],
      defaultToivoAsiakirjaTypeId: 'fd359eef-8248-4918-a8d0-a32117a06264',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KV_KORJAUS,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.SIVIILISAATY_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TODISTUS_PALAUTUNUT_VIRASTOON,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.PUOLISO_A, AsiaPersonRole.PUOLISO_B],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.RAUENNUT,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.HYLATTY,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [SpecifierType.APOSTILLE, SpecifierType.EU_KAANNOSLOMAKE],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [ViestintaType.MANUAALINEN, ViestintaType.TOIVO_POSTITUS],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.TODISTUS_SUOMEN_LAIN_MUKAISESTA_OIKEUDESTA_MENNA_AVIOLIITTOON_ULKOMAAN_VIRANOMAISEN_EDESSA,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
  retentionMetadata: uaet10VuottaRetention,
  restrictionMetadata: uaetOsittainSalassaPidettavaRestriction,
}
