import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonIbanValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { FrontPankkitili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { observable } from 'mobx'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import FormCheckboxInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCheckboxInput'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { formatTilinumeroInput } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const PankkitilitForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tili = getTili()
  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  return (
    <section data-test-id={mkYleiskatsausTestId('pankkitilit-form')}>
      <Heading variant="h3">{t('pankkitilitHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeOnkoPankkitileja')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={PankkitilitListItemContent}
        state={tili.paamiehenOmaisuus.pankkitilit}
        dataTestIdPrefix={mkYleiskatsausTestId('pankkitilit-form')}
        ModalContentComponent={PankkitilitModalContent}
        newEntry={() =>
          observable({
            id: crypto.randomUUID(),
            iban: '',
            kayttovaratili: false,
            pankinNimi: '',
            isKayttoOikeuksia: undefined,
            kayttooikeudet: '',
            sopimukset: [],
          } as FrontPankkitili)
        }
        stateSubmitAdapter={({
          kayttooikeudet,
          isKayttoOikeuksia,
          ...rest
        }: FrontPankkitili) => {
          if (isKayttoOikeuksia) {
            return { ...rest, isKayttoOikeuksia, kayttooikeudet }
          } else {
            return { ...rest, isKayttoOikeuksia: false }
          }
        }}
        translationPrefix="pankkitilit"
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

export default PankkitilitForm

const PankkitilitModalContent = ({
  entry,
  setEntry,
  createDataTestId,
}: FormModalProps<FrontPankkitili>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const { isPrincipalUnderaged } = getTilintarkastusStore()

  return (
    <>
      <FormTextInput
        data-test-id={createDataTestId('modal-iban')}
        labelText={t('tilinumero')}
        hintText={t('kirjoitaIbanMuodossa')}
        value={formatTilinumeroInput(entry.iban)}
        required
        validate={commonIbanValidator(t)}
        updateValue={(value) =>
          setEntry({ ...entry, iban: formatTilinumeroInput(value) })
        }
      />

      <FormCheckboxInput
        data-test-id={createDataTestId('kayttotili-checkbox')}
        labelText={t('tamaOnHenkilonKayttotili')}
        updateValue={(value) => setEntry({ ...entry, kayttovaratili: value })}
        defaultValue={entry.kayttovaratili}
        required
      />
      <Block mt={verticalMarginToken} />

      <FormTextInput
        data-test-id={createDataTestId('modal-pankinNimi')}
        labelText={t('pankinNimi')}
        value={entry.pankinNimi}
        required
        validate={commonXssValidator(t)}
        updateValue={(value) => setEntry({ ...entry, pankinNimi: value })}
      />

      <Block mb={verticalMarginToken}>
        <FormRadioButtonGroup
          required
          value={entry.isKayttoOikeuksia?.toString()}
          data-test-id={createDataTestId('modal-isKayttooikeuksia')}
          labelText={t('onkoTilinKayttooikeuksiaMuilla')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              isKayttoOikeuksia: value === 'true',
            })
          }}
          radioButtons={[
            {
              value: 'false',
              labelText: t('ei'),
            },
            { value: 'true', labelText: t('kylla') },
          ]}
        />
      </Block>

      {entry.isKayttoOikeuksia && (
        <Block mb={verticalMarginToken}>
          <FormTextArea
            data-test-id={createDataTestId('modal-kayttooikeudet')}
            labelText={t('kenellaOikeusKayttaaTilia')}
            hintText={t('ilmoitaHenkiloidenNimet')}
            maxLength={1000}
            required
            value={entry.kayttooikeudet}
            validate={commonXssValidator(t)}
            updateValue={(value) => {
              setEntry({
                ...entry,
                kayttooikeudet: value,
              })
            }}
          />
          {!isPrincipalUnderaged && (
            <InlineAlert>{t('kayttooikeusInlineAlert')}</InlineAlert>
          )}
        </Block>
      )}

      <FormAttachmentFileBox
        observableAttachments={entry.sopimukset}
        asiakirjaTypeId={TiliAsiakirjaType.SOPIMUS}
        data-test-id={createDataTestId('modal-asiakirja')}
        title={t('sopimukset')}
        text={t('ohjeTilisopimukset')}
      />
    </>
  )
}

const PankkitilitListItemContent = ({
  entry,
  createDataTestId,
  onEdit,
  onRemove,
}: FormElementProps<FrontPankkitili>) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  return (
    <FormListInputExpander
      heading={entry.pankinNimi}
      headingRight={entry.kayttovaratili ? t('paaasiallinenKayttotili') : ''}
      subHeading={entry.iban}
      showSubHeadingWhileOpen
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
    >
      {entry.isKayttoOikeuksia && (
        <Block mt={verticalMarginToken}>
          <Heading variant="h5">{t('kayttooikeusMuillaHenkiloilla')}</Heading>
          <Paragraph mb={verticalMarginToken}>{t('kylla')}</Paragraph>
          <Heading variant="h5">{t('kenellaKayttooikeus')}</Heading>
          <Paragraph mb={verticalMarginToken}>{entry.kayttooikeudet}</Paragraph>
        </Block>
      )}

      {entry.sopimukset.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('sopimukset')}</Heading>
          <AttachmentList attachments={entry.sopimukset} />
          <Block mt={verticalMarginToken} />
        </>
      )}
    </FormListInputExpander>
  )
}
