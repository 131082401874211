export const size = {
  tablet: '768px',
  desktop: '1280px',
} as const

export const device = {
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
} as const

export const contentMaxWidth = size.desktop
