import moment from 'moment'
import validator from 'validator'
import { DATE_FORMAT } from 'common/src/vtj/date.util'

export function validateDate(value, params) {
  if (!value) return false

  if (value.trim() !== value || value.replace(/[^0-9-:. ]/, '') !== value)
    return false

  params = { checkFormat: DATE_FORMAT, ...params }
  const formattedDate = moment(value, params.checkFormat, true)
  const comparedLength = Math.min(params.checkFormat.length, value.length)
  return (
    formattedDate.isValid() &&
    formattedDate.format(params.checkFormat).substr(0, comparedLength) ===
      value.substr(0, comparedLength)
  )
}

export function validateEmail(email) {
  if (!email || !validator.isEmail(email)) {
    return false
  }
  // unify top level domain validation between browsers:
  // when input[type=email], chrome changes foo@vrk.fivirhe-syö -> foo@vrk.xn--fivirhe-sy-ncb (valid)
  return !email
    .split('.')
    .pop()
    .match(/[^a-z-]|(xn--)/i)
}

export function validatePhoneNumber(phoneNumber) {
  if (phoneNumber.length < 5) {
    return false
  }
  return validator.isMobilePhone(phoneNumber, 'any', { strictMode: false })
}
