import { FileIcon } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileIcon'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link, Text, VisuallyHidden } from 'suomifi-ui-components'
import { getDownloadUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/file-api-client'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { AsiointiFileAttachment } from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'

const FileRow = styled.div`
  display: flex;
  flex-direction: row;
`
const FileIconContainer = styled.div`
  padding: 2px; // Artesanal, aesthetically pleasing alignment with file name
`

const FileList: React.FC<{ attachments: AsiointiFileAttachment[] }> = observer(
  ({ attachments }) => {
    const [t] = useTranslation()

    return (
      <>
        {attachments.map((liite) => {
          const downloadHref =
            liite.status === 'success' && liite.asiakirjaId
              ? getDownloadUrl(liite)
              : undefined
          return (
            <FileRow key={liite.sourceFileId}>
              <FileIconContainer>
                <FileIcon mimeType={liite.mimeType} />
              </FileIconContainer>
              {downloadHref ? (
                <Link
                  href={downloadHref}
                  target="_blank"
                  underline="hover"
                  data-test-id={mkTestId('file-list-linkki')}
                >
                  {liite.filename}
                  <VisuallyHidden>{t('opensInNewWindow')}</VisuallyHidden>
                </Link>
              ) : (
                <Text data-test-id={mkTestId('file-list-nimi')}>
                  {liite.filename}
                </Text>
              )}
            </FileRow>
          )
        })}
      </>
    )
  }
)

export default FileList
