import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { asiointiLedgerAccountsAssetsMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-map'
import { asiointiLedgerAccountsDebtMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-map'
import { asiointiLedgerAccountsIncomeMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-map'
import { asiointiLedgerAccountsExpenseMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-map'
import { asiointiLedgerAccountsOtherMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-other-map'

export const asiointiLedgerAccountMaps = {
  [AsiointiLedgerAccountCategory.VARAT]: asiointiLedgerAccountsAssetsMap,
  [AsiointiLedgerAccountCategory.VELAT]: asiointiLedgerAccountsDebtMap,
  [AsiointiLedgerAccountCategory.TULOT]: asiointiLedgerAccountsIncomeMap,
  [AsiointiLedgerAccountCategory.MENOT]: asiointiLedgerAccountsExpenseMap,
  [AsiointiLedgerAccountCategory.MUU]: asiointiLedgerAccountsOtherMap,
}
