import React, { PropsWithChildren, useEffect } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import PaamiesFrontPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/PaamiesFrontPage'
import LandingPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/LandingPage'
import MainFooter from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MainFooter'
import CookieInfoPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/cookies/CookieInfoPage'
import { cookiePageUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import PageNotFound from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/PageNotFound'
import lupaAsiointiRoutes from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/LupaAsiointiRoutes'
import { LUPA_ASIOINTI_UI_ROUTE_PREFIX } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-ui-route.util'
import { useForm } from 'react-hook-form'
import {
  omaisuusluetteloAsiointiRoutes,
  paatostiliAsiointiRoutes,
  TilintarkastusAsiointiApp,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/TilintarkastusAsiointiRoutes'
import {
  OMAISUUSLUETTELO_ROUTES,
  PAATOSTILI_ROUTES,
  TILINTARKASTUS_ASIOINTI_UI_ROUTE_PREFIX,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { ASIOINTI_MAIN_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import PaamiehenValintaPage from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/PaamiehenValintaPage'
import {
  getValittuHenkilo,
  initializeEvtvStore,
  useEvtvStore,
  valitseHenkiloByPersonId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { LoadingSpinner } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { fetchDraftsIntoStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'

const AuthenticatedPaamiesRoutes: React.FC<
  PropsWithChildren<{ redirect: string }>
> = observer(({ children, redirect = '/' }) => {
  const [t] = useTranslation()

  const { user } = useAsiointiUserStore()
  if (!user) {
    return <Navigate to={redirect} />
  }

  const { isLoading, isInitialized, vaatimukset } = useEvtvStore()
  useEffect(() => {
    if (user) {
      void initializeEvtvStore()
    }
  }, [user])

  const valittuHenkilo = getValittuHenkilo()
  if (isInitialized && !valittuHenkilo) {
    const { personId } = useParams()
    const found = personId && valitseHenkiloByPersonId(personId)
    if (!found) {
      return <Navigate to={redirect} />
    }
  }

  useEffect(() => {
    if (isInitialized) {
      void fetchDraftsIntoStore()
    }
  }, [vaatimukset, isInitialized])

  if (isLoading || !isInitialized) {
    return (
      <CenteredWidthLimitedDiv paddingX paddingY>
        <LoadingSpinner text={t('ladataan')} textAlign="right" />
      </CenteredWidthLimitedDiv>
    )
  }

  return children || <Outlet />
})

const AsiointiAppRoutes: React.FC = observer(() => {
  const { user } = useAsiointiUserStore()

  const paatostiliForms = {
    [PAATOSTILI_ROUTES.TEE_PAATOSTILI]: useForm({ shouldFocusError: false }),
    [PAATOSTILI_ROUTES.YLEISKATSAUS]: useForm({ shouldFocusError: false }),
    [PAATOSTILI_ROUTES.PALKKIO]: useForm({ shouldFocusError: false }),
    [PAATOSTILI_ROUTES.TULOT_JA_MENOT]: useForm({ shouldFocusError: false }),
    [PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT]: useForm({ shouldFocusError: false }),
    [PAATOSTILI_ROUTES.YHTEYSTIEDOT]: useForm({ shouldFocusError: false }),
  }

  const omaisuusluetteloForms = {
    [OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO]: useForm({
      shouldFocusError: false,
    }),
    [OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]: useForm({
      shouldFocusError: false,
    }),
    [OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]: useForm({
      shouldFocusError: false,
    }),
    [OMAISUUSLUETTELO_ROUTES.VARAT]: useForm({ shouldFocusError: false }),
    [OMAISUUSLUETTELO_ROUTES.VELAT]: useForm({ shouldFocusError: false }),
    [OMAISUUSLUETTELO_ROUTES.ELAKKEET_JA_ETUUDET]: useForm({
      shouldFocusError: false,
    }),
    [OMAISUUSLUETTELO_ROUTES.KAYTTOVARAT]: useForm({ shouldFocusError: false }),
    [OMAISUUSLUETTELO_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]: useForm({
      shouldFocusError: false,
    }),
    [OMAISUUSLUETTELO_ROUTES.MUUT_MERKITTAVAT_TOIMET]: useForm({
      shouldFocusError: false,
    }),
    [OMAISUUSLUETTELO_ROUTES.YHTEYSTIEDOT]: useForm({
      shouldFocusError: false,
    }),
    [OMAISUUSLUETTELO_ROUTES.VALTAKIRJA]: useForm({ shouldFocusError: false }),
    [OMAISUUSLUETTELO_ROUTES.YHTEENVETO]: useForm(), // sivulla ei käytetä virheyhteenvetoa, joten focus ok
  }

  return (
    <Routes>
      <Route Component={Layout}>
        <Route
          path={ASIOINTI_MAIN_ROUTES.JUURI}
          element={user ? <PaamiehenValintaPage /> : <LandingPage />}
        />
        <Route
          element={
            <AuthenticatedPaamiesRoutes redirect={ASIOINTI_MAIN_ROUTES.JUURI} />
          }
        >
          <Route
            path={ASIOINTI_MAIN_ROUTES.ASIOI}
            Component={PaamiesFrontPage}
          />
        </Route>
        <Route path={cookiePageUrl} Component={CookieInfoPage} />
        {lupaAsiointiRoutes}
        {
          <Route
            path={TILINTARKASTUS_ASIOINTI_UI_ROUTE_PREFIX}
            element={
              <TilintarkastusAsiointiApp
                forms={{ ...paatostiliForms, ...omaisuusluetteloForms }}
              />
            }
          >
            {paatostiliAsiointiRoutes(paatostiliForms)}
            {omaisuusluetteloAsiointiRoutes(omaisuusluetteloForms)}
          </Route>
        }
        <Route path="*" Component={PageNotFound} />
      </Route>
    </Routes>
  )
})

const Layout: React.FC = () => {
  const { pathname } = useLocation()
  const isFooterVisible = !pathname.startsWith(LUPA_ASIOINTI_UI_ROUTE_PREFIX)

  return (
    <LayoutGrid>
      <main>
        <Outlet />
      </main>
      {isFooterVisible && <MainFooter />}
    </LayoutGrid>
  )
}

const LayoutGrid = styled.div`
  grid-area: content;
  display: grid;
  grid-template-rows: 1fr auto;
`

export default AsiointiAppRoutes
