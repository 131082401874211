import keyMirror from 'keymirror'
import fp from 'lodash/fp'
import { AsiaLanguage } from 'asia-common/src/vtj/koodisto/asia-enums'

export const LupaSource = keyMirror({
  ASIA_UI: null,
  ASIA_BACKEND: null,
  EDVARD: null,
  VTJ: null,
  MAISTRAATTI_UI: null,
  ASIOINTI: null,
})
export type LupaSourceId = (typeof LupaSource)[keyof typeof LupaSource]

export const FileSystem = keyMirror({
  S3: null,
  TOIVO: null,
})
export type FileSystemId = (typeof FileSystem)[keyof typeof FileSystem]

export const LupaLanguage = fp.pick(['FIN', 'SWE'], AsiaLanguage)
export type LupaLanguageId = (typeof LupaLanguage)[keyof typeof LupaLanguage]

export const LupaHistoryReason = keyMirror({
  PAIVITETTY: null,
  POISTETTU: null,
})
export type LupaHistoryReasonId =
  (typeof LupaHistoryReason)[keyof typeof LupaHistoryReason]
