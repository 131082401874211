import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

export const getOmaisuusluettelo = (): TiliData => {
  const lomake = getTilintarkastusStore().lomake
  assertIsOmaisuusluettelo(lomake.tili)
  return lomake.tili
}

export const isOmaisuusluettelo = (tili: TiliData): tili is TiliData => {
  return tili.asiaType === 'OMAISUUSLUETTELO'
}

const assertIsOmaisuusluettelo = (tili: TiliData) => {
  if (!isOmaisuusluettelo(tili)) {
    throw new Error('Not a omaisuusluettelo')
  }
}
