import React from 'react'
import styled, { css } from 'styled-components'
import {
  contentMaxWidth,
  device,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import {
  Block,
  BlockProps,
  DesignTokens,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

export const centeredWidthLimited = css`
  width: 100%;
  max-width: ${contentMaxWidth};
  margin: 0 auto 0 auto;
`

export const CenteredWidthLimitedDiv = styled.div<{
  paddingX?: boolean | keyof DesignTokens['spacing']
  paddingY?: boolean | keyof DesignTokens['spacing']
}>`
  ${centeredWidthLimited};

  padding: ${({ paddingY, paddingX }) =>
    `${getPaddingY(paddingY, 'mobile')} ${getPaddingX(paddingX, 'mobile')}`};

  @media ${device.tablet} {
    padding: ${({ paddingY, paddingX }) =>
      `${getPaddingY(paddingY, 'tablet')} ${getPaddingX(paddingX, 'tablet')}`};
  }
`

const getPaddingX = (
  paddingX: undefined | boolean | keyof DesignTokens['spacing'],
  device: 'mobile' | 'tablet'
): string | number => {
  if (paddingX === true) {
    return device === 'mobile'
      ? suomifiDesignTokens.spacing.s
      : suomifiDesignTokens.spacing.xl
  } else if (paddingX) {
    return suomifiDesignTokens.spacing[paddingX]
  } else {
    return 0
  }
}
const getPaddingY = (
  paddingY: undefined | boolean | keyof DesignTokens['spacing'],
  device: 'mobile' | 'tablet'
): string | number => {
  if (paddingY === true) {
    return device === 'mobile'
      ? suomifiDesignTokens.spacing.l
      : suomifiDesignTokens.spacing.xl
  } else if (paddingY) {
    return suomifiDesignTokens.spacing[paddingY]
  } else {
    return 0
  }
}

export const ResponsiveBorderedContentBlock: React.FC<
  Omit<BlockProps, 'padding' | 'pt' | 'pr' | 'pb' | 'pl' | 'px' | 'py'>
> = (props) => {
  const isTablet = useDeviceContext().tablet

  return (
    <BorderedContentBlock
      {...props}
      py={isTablet ? 'xl' : 'm'}
      px={isTablet ? 'xl' : 's'}
    />
  )
}

export const BorderedContentBlock = styled(Block)`
  background-color: ${suomifiDesignTokens.colors.whiteBase};
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
`

export const BlueBorderedContentBlock = styled(Block)`
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  background: ${suomifiDesignTokens.colors.highlightLight4};
`

export const BulletList = styled.ul`
  list-style-type: disc;
  padding-left: 30px;
`

export const focusOutline = css`
  &:focus {
    ${suomifiDesignTokens.focuses.boxShadowFocus};
    ${suomifiDesignTokens.focuses.highContrastFocus};
  }
`
export const FocusableDiv = styled.div`
  ${focusOutline};
`
