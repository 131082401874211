import { createGlobalStyle } from 'styled-components'
import { suomifiDesignTokens } from 'suomifi-ui-components'

const reset = `
/* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/**
 * The Expander component breaks in Chrome version 129. The content of the expander overflows outside the box.
 * This is a temporary fix until the suomifi-library is fixed. The faulty library version is 14.0.0.
 * TODO: remove this after the problem is solved
 */
.fi-expander_content {
  animation: none !important;
 }
`

const fontFaces = `
  @font-face {
    font-family: 'Source Sans Pro';
    font-weight: 300;
    font-display: swap;
    src: url('/public/assets/fonts/SourceSansProLight.eot');
    src: url('/public/assets/fonts/SourceSansProLight.eot') format('embedded-opentype'),
        url('/public/assets/fonts/SourceSansProLight.woff2') format('woff2'),
        url('/public/assets/fonts/SourceSansProLight.woff') format('woff'),
        url('/public/assets/fonts/SourceSansProLight.ttf') format('truetype'),
        url('/public/assets/fonts/SourceSansProLight.svg#SourceSansProLight') format('svg');
  }
  
  @font-face {
    font-family: 'Source Sans Pro';
    font-weight: 400;
    font-display: swap;
    src: url('/public/assets/fonts/SourceSansProRegular.eot');
    src: url('/public/assets/fonts/SourceSansProRegular.eot') format('embedded-opentype'),
        url('/public/assets/fonts/SourceSansProRegular.woff2') format('woff2'),
        url('/public/assets/fonts/SourceSansProRegular.woff') format('woff'),
        url('/public/assets/fonts/SourceSansProRegular.ttf') format('truetype'),
        url('/public/assets/fonts/SourceSansProRegular.svg#SourceSansProRegular') format('svg');
  }
  
  @font-face {
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-display: swap;
    src: url('/public/assets/fonts/SourceSansProSemibold.eot');
    src: url('/public/assets/fonts/SourceSansProSemibold.eot') format('embedded-opentype'),
        url('/public/assets/fonts/SourceSansProSemibold.woff2') format('woff2'),
        url('/public/assets/fonts/SourceSansProSemibold.woff') format('woff'),
        url('/public/assets/fonts/SourceSansProSemibold.ttf') format('truetype'),
        url('/public/assets/fonts/SourceSansProSemibold.svg#SourceSansProSemibold') format('svg');
  }
`

// This sets styles across the asiointi system, the frame app and microfrontends.
// Use only in the frame app, and when a microfrontend sends an entire html page
// as a response.
export const GlobalStyle = createGlobalStyle`
${reset}
${fontFaces}

html {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}

body {
 ${suomifiDesignTokens.typography.bodyText}
 color: ${suomifiDesignTokens.colors.blackBase};
 background-color: ${suomifiDesignTokens.colors.depthLight3};
}
`
