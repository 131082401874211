import fp from 'lodash/fp'
import { createDomPurifyForEnv } from 'common/src/vtj/dom-purify.util'

const domPurify = createDomPurifyForEnv()

/*
 * This function must have at least as tight config as the xss middleware in server since document metadata is synced to asia
 * xss-middleware: common/src/vtj/server/middlewares/xss.middleware.js
 */
export const findPotentialXssCharacters = (value: string): string[] => {
  // brute force is used to return only violating chars since
  // domPurifier removes everything after sanitized characters in some cases (for.ex <<asdf => <<asdf)
  return Array.from(value).filter((c: string) => {
    const purifiedChar = domPurify.sanitize(c, {
      ALLOWED_TAGS: [],
    })
    return purifiedChar !== c
  })
}

const maybeDecodeUriComponent = (value: string) => {
  try {
    return decodeURIComponent(value)
  } catch {
    return value
  }
}

/*
 * Ui-validation helper function.
 * decodeURIComponent is done if possible since express does this in backend before request reaches xss-middleware
 * findPotentialXssCharacters is wrapped with fp.uniq to create better error messages
 */
export const findPotentialXssInUiTextInputValue = (value: string): string[] =>
  fp.uniq(findPotentialXssCharacters(maybeDecodeUriComponent(value)))
