import { AsiakirjaMetadata } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  PersonalDataClass,
  PublicityClass,
  PublicityClassId,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

///
// FIXME: New TOS required https://jira.dvv.fi/browse/OIVA-5106
//
// Retention and restriction metadata was taken from incomplete TOS Excel: TOS_Nimen muuttaminen_JHS191_V4_05112020xlsx.xlsx
// TOS has missing security reasons and some of the asiakirja types have different reasons in different rows
//
// Some of the asiakirja types are not in TOS: https://jira.dvv.fi/browse/VAAKA-7414
//
// Nimenmuutos requires a new TOS-document to fix all problems.
// Therefore KOHTA_24, KOHTA_25, KOHTA_31, KOHTA_32 is selected as a default
///

export const nimiasiatJulkinenEiHenkilotietoja: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatJulkinenHenkilotietoja: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32Henkilotietoja: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.JULKINEN,
    publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.JULKINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      },
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.JULKINEN,
    publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
    publicityMetadata: {
      [PublicityClass.JULKINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      },
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettavaSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja =
  (defaultPublicityClassId: PublicityClassId): RestrictionMetadata => ({
    publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA, PublicityClass.SALAINEN],
    defaultPublicityClassId,
    publicityMetadata: {
      [PublicityClass.JULKINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      },
      [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
      },
      [PublicityClass.SALAINEN]: {
        defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
        personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
        defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
        securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
        defaultSecurityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
        securityReasonIds: [
          SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
          SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
        ],
      },
    },
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  })

export const nimiasiatSalassaPidettava100VuottaK25ErityisiaHenkilotietoja: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
  retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
}

export const commonNimiasiatAsiakirjaMetadata: AsiakirjaMetadata[] = [
  {
    asiakirjaTypeId: AsiakirjaType.NIMENMUUTOSHAKEMUS,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: LISASELVITYS has different security reason rows
  {
    asiakirjaTypeId: AsiakirjaType.LISASELVITYS,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: VIRKATODISTUS defaultSecurityReasonIds missing from TOS
  {
    asiakirjaTypeId: AsiakirjaType.VIRKATODISTUS,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.SUOSTUMUS,
    restrictionMetadata: nimiasiatJulkinenHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
    restrictionMetadata: nimiasiatSalassaPidettava100VuottaK25ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: KARAJAOIKEUDEN_PAATOS defaultSecurityReasonIds missing from TOS
  {
    asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_PAATOS,
    restrictionMetadata: nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32Henkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTO_SOSIAALITOIMELTA,
    restrictionMetadata: nimiasiatSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: MUU_LIITE defaultSecurityReasonIds missing from TOS
  {
    asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: TAYDENNYSPYYNTO has different security reason rows
  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: LAUSUNTOPYYNTO has different rows in TOS
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettavaSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja(
        PublicityClass.JULKINEN
      ),
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: TAYDENNYS_ASIAKKAALTA is different from TAYDENNYSPYYNTO
  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYS_ASIAKKAALTA,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.HENKILON_NIMI_LAKI_VAATIMUS,
    restrictionMetadata: nimiasiatJulkinenHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO_NIMILAUTAKUNNALLE,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.NIMILAUTAKUNNAN_LAUSUNTO,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.VASTINEPYYNTO_ASIAKKAALLE,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.VASTINE_ASIAKKAALTA,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUISTUTUS,
    restrictionMetadata: nimiasiatJulkinenHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: PAATOS_NIMENMUUTOKSESTA defaultSecurityReasonIds missing from TOS
  {
    asiakirjaTypeId: AsiakirjaType.PAATOS_NIMENMUUTOKSESTA,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['c0420101-ce83-465f-ba15-8094bd9ee3e2', 'bb9f436a-6eb3-4dd2-af23-a71c6c5387db'],
    defaultToivoAsiakirjaTypeId: 'c0420101-ce83-465f-ba15-8094bd9ee3e2',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUUTOKSENHAKUOHJE,
    restrictionMetadata: nimiasiatJulkinenEiHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    // TODO VAAKA-5503 Change to LAADITTU
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['6dae0105-29ea-4bfa-bdbd-3e6ae54f304d'],
    defaultToivoAsiakirjaTypeId: '6dae0105-29ea-4bfa-bdbd-3e6ae54f304d',
  },
  // FIXME MUISTIO has different security reason rows
  {
    asiakirjaTypeId: AsiakirjaType.MUISTIO,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: SUKUSELVITYS is missing from TOS
  // Added in https://jira.dvv.fi/browse/VAAKA-7414
  {
    asiakirjaTypeId: AsiakirjaType.SUKUSELVITYS,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettavaSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja(
        PublicityClass.OSITTAIN_SALASSAPIDETTAVA
      ),
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: KOPIO_HENKILOTODISTUKSESTA_TAI_PASSISTA is missing from TOS
  // Added in https://jira.dvv.fi/browse/VAAKA-7414
  {
    asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOTODISTUKSESTA_TAI_PASSISTA,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettavaSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja(
        PublicityClass.OSITTAIN_SALASSAPIDETTAVA
      ),
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
  // FIXME: SYNTYMATODISTUS is missing from TOS
  // Added in https://jira.dvv.fi/browse/VAAKA-7414
  {
    asiakirjaTypeId: AsiakirjaType.SYNTYMATODISTUS,
    restrictionMetadata:
      nimiasiatJulkinenHenkilotietojaOsittainSalassaPidettavaSalassaPidettava100VuottaK24K25K31K32ErityisiaHenkilotietoja(
        PublicityClass.OSITTAIN_SALASSAPIDETTAVA
      ),
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
  },
]
