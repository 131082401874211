import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { mkTestamenttiTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { KyllaEiTuntematon } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { runInAction } from 'mobx'

const TestamenttiForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tili = getTili()
  if (tili.asiaType === 'OMAISUUSLUETTELO') {
    return (
      <section data-test-id={mkTestamenttiTestId('container')}>
        <Heading variant="h3" mb="s">
          {t('henkilonLaatimaTestamentti')}
        </Heading>
        <Paragraph mb={verticalMarginToken}>
          {t('ohjeHenkilonLaatimaTestamentti')}
        </Paragraph>

        <FormRadioButtonGroup
          data-test-id={mkTestamenttiTestId('laadittu-radio')}
          labelText={t('onkoHenkiloLaatinutTestamentin')}
          radioButtons={[
            {
              labelText: t('ei'),
              value: KyllaEiTuntematon.EI,
            },
            {
              labelText: t('kylla'),
              value: KyllaEiTuntematon.KYLLA,
            },
            {
              labelText: t('enTieda'),
              value: KyllaEiTuntematon.TUNTEMATON,
            },
          ]}
          value={tili.testamentti.laadittu}
          required
          updateValue={(value) => {
            runInAction(() => {
              if (
                Object.values(KyllaEiTuntematon).includes(
                  value as KyllaEiTuntematon
                )
              ) {
                tili.testamentti.laadittu = value as KyllaEiTuntematon
              } else {
                tili.testamentti.laadittu = undefined
              }
            })
          }}
        />

        {tili.testamentti.laadittu === KyllaEiTuntematon.KYLLA && (
          <Block mt={verticalMarginToken}>
            <FormRadioButtonGroup
              data-test-id={mkTestamenttiTestId('rajoittavia-ehtoja-radio')}
              labelText={t('testamentinSisalto')}
              groupHintText={t('ohjeTestamentinSisalto')}
              value={tili.testamentti.rajoittaviaEhtoja}
              required
              radioButtons={[
                {
                  labelText: t('eiSisallaRajoittaviaEhtoja'),
                  value: KyllaEiTuntematon.EI,
                },
                {
                  labelText: t('sisaltaaRajoittaviaEhtoja'),
                  value: KyllaEiTuntematon.KYLLA,
                },
                {
                  labelText: t('tuntematon'),
                  value: KyllaEiTuntematon.TUNTEMATON,
                },
              ]}
              updateValue={(value) => {
                runInAction(() => {
                  if (
                    Object.values(KyllaEiTuntematon).includes(
                      value as KyllaEiTuntematon
                    )
                  ) {
                    tili.testamentti.rajoittaviaEhtoja =
                      value as KyllaEiTuntematon
                  } else {
                    tili.testamentti.rajoittaviaEhtoja = undefined
                  }
                })
              }}
            />
          </Block>
        )}
      </section>
    )
  }
})

export default TestamenttiForm
