import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { action } from 'mobx'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'

const HallintaoikeudetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tili = getTili()

  return (
    <section data-test-id={mkYleiskatsausTestId('hallintaoikeudet-container')}>
      <Heading variant="h3">{t('hallintaoikeudet')}</Heading>
      <Paragraph mt="s">
        {tili.asiaType === 'OMAISUUSLUETTELO'
          ? t('ohjeOnkoHallintaoikeuksia')
          : t('ohjeIlmoitaMitaHallintaoikeuksia')}
      </Paragraph>

      <Block mt={verticalMarginToken}>
        <FormRadioButtonGroup
          required
          value={tili.isHallintaoikeuksia?.toString()}
          updateValue={action((value) => {
            tili.isHallintaoikeuksia = value === 'true'
          })}
          labelText={
            tili.asiaType === 'OMAISUUSLUETTELO'
              ? t('onkoHallintaoikeusiaToisenOmaisuuteen')
              : t('onkoOllutSovittujaHallintaoikeuksia')
          }
          data-test-id={mkYleiskatsausTestId('onko-hallintaoikeuksia-radio')}
          radioButtons={[
            { value: 'false', labelText: t('ei') },
            { value: 'true', labelText: t('kylla') },
          ]}
        />
      </Block>

      {tili.isHallintaoikeuksia && (
        <>
          <Block mt={verticalMarginToken}>
            <FormTextArea
              data-test-id={mkYleiskatsausTestId(
                'miten-hallintaoikeuksista-on-sovittu'
              )}
              labelText={t('mitenHallintaoikeuksistaOnSovittu')}
              maxLength={1000}
              required
              value={tili.mitenHallintaoikeuksistaOnSovittu}
              validate={commonXssValidator(t)}
              updateValue={action((value) => {
                tili.mitenHallintaoikeuksistaOnSovittu = value
              })}
            />
          </Block>
          <Block mt={verticalMarginToken}>
            <FormAttachmentFileBox
              observableAttachments={tili.hallintaoikeusSopimukset}
              asiakirjaTypeId={TiliAsiakirjaType.SOPIMUS}
              data-test-id={mkYleiskatsausTestId('hallintaoikeus-sopimukset')}
              title={t('sopimusHallintaoikeudesta')}
            />
          </Block>
        </>
      )}
      {tili.isHallintaoikeuksia === false &&
        (!!tili.mitenHallintaoikeuksistaOnSovittu ||
          tili.hallintaoikeusSopimukset.length > 0) && (
          <InlineAlert mt={'s'} status={'warning'}>
            {t('valittuEiInlineAlert')}
          </InlineAlert>
        )}
    </section>
  )
})

export default HallintaoikeudetForm
