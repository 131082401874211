import fp from 'lodash/fp'
import { JSONObject, JSONValue } from 'common/src/vtj/types/json.type'

let settings: JSONObject

export default {
  setAll: (newSettings: JSONObject): void => {
    settings = newSettings
  },
  get: (keyPath: string): JSONValue => {
    if (fp.has(keyPath, settings)) {
      return fp.get(keyPath, settings)
    } else {
      throw new Error(`Setting not found by key path '${keyPath}'`)
    }
  },
  getOrNull: (keyPath: string): JSONValue => {
    if (fp.has(keyPath, settings)) {
      return fp.get(keyPath, settings)
    } else {
      return null
    }
  },
}
