import React, { useRef } from 'react'
import {
  OMAISUUSLUETTELO_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkEdunvalvontaValtakirjaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { Paragraph } from 'suomifi-ui-components'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { FormProvider } from 'react-hook-form'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { ValtakirjaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/ValtakirjaForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const OmaisuusluetteloValtakirjaPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkEdunvalvontaValtakirjaTestId('page-container')}
        >
          <PageStepHeading
            heading={t('valtakirja')}
            steps={OMAISUUSLUETTELO_STEPS}
          />
          <FormErrorSummary ref={onErrorRef} />
          <Paragraph mt={verticalMarginToken}>
            {t('omaisuusluetteloValtakirjaInfo')}
          </Paragraph>
          <DividerLine mt={verticalMarginToken} />
          <ValtakirjaForm />
          <DividerLine mt={verticalMarginToken} />
          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default OmaisuusluetteloValtakirjaPage
