import {
  ApiResponse,
  executeBackendClient,
} from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { Lomake } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

interface TilintarkastusAsiointiClient {
  postPaatostili: (data: Lomake) => Promise<ApiResponse<unknown>>

  postOmaisuusluettelo: (data: Lomake) => Promise<ApiResponse<unknown>>
}

export const TilintarkastusApiClient: TilintarkastusAsiointiClient = {
  postPaatostili: (data: Lomake): Promise<ApiResponse<unknown>> =>
    executeBackendClient((client) => {
      return client.post('/tilintarkastus-asiointi/api/v1/paatostili', data)
    }),

  postOmaisuusluettelo: (data: Lomake): Promise<ApiResponse<unknown>> =>
    executeBackendClient((client) => {
      return client.post(
        '/tilintarkastus-asiointi/api/v1/omaisuusluettelo',
        data
      )
    }),
}
