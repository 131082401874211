import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import {
  OMAISUUSLUETTELO_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import SopimuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/SopimuksetForm'
import HallintaoikeudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/HallintaoikeudetForm'
import VakuutuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/VakuutuksetForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const SopimuksetJaVakuutuksetPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading
          heading={t('sopimuksetJaVakuutukset')}
          steps={OMAISUUSLUETTELO_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <Paragraph>{t('sopimuksetVakuutuksetPageInfo1')}</Paragraph>
        <Paragraph mt="s">{t('sopimuksetVakuutuksetPageInfo2')}</Paragraph>

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <SopimuksetForm />

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <VakuutuksetForm />

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <HallintaoikeudetForm />

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default SopimuksetJaVakuutuksetPage
