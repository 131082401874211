import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  vanhemmuusAsiakirjaMetadata,
  vanhemmuusAsiaRestriction,
  vanhemmuusPysyvaRetention,
} from 'asia-common/src/vtj/koodisto/asia-type/vanhemmuuden-vahvistaminen/common-vanhemmuus-metadata'

export const tunnustettuSyntymanJalkeenMetadata: AsiaMetadata = {
  asiakirjaMetadata: vanhemmuusAsiakirjaMetadata,
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.SIVIILISAATY_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.SYNTYMAILMOITUS_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [
    AsiaPersonRole.SYNNYTTAJA,
    AsiaPersonRole.VANHEMMUUDEN_TUNNUSTAJA,
    AsiaPersonRole.LAPSI,
    AsiaPersonRole.OIKEUDENOMISTAJA,
    AsiaPersonRole.LASTENVALVOJA,
    AsiaPersonRole.AVIOMIES,
    AsiaPersonRole.MUU,
  ],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.MYONTEINEN_PAATOS,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.SIIRRETTY_LASTENVALVOJALLE,
      RatkaisuType.RAUENNUT,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LISATIETOA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.LISATIETO_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LISATIEDON_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
  retentionMetadata: vanhemmuusPysyvaRetention,
  restrictionMetadata: vanhemmuusAsiaRestriction,
}
