/**
 * https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 */
export enum MimeType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  ODT = 'application/vnd.oasis.opendocument.text',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XML = 'application/xml',
  TXT = 'text/plain',
  TIFF = 'image/tiff',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
}

export enum Extension {
  'image/jpeg' = '.jpg',
  'image/png' = '.png',
  'application/pdf' = '.pdf',
  'application/msword' = '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = '.docx',
  'application/vnd.oasis.opendocument.spreadsheet' = '.ods',
  'application/vnd.oasis.opendocument.text' = '.odt',
  'application/vnd.ms-powerpoint' = '.ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' = '.pptx',
  'application/vnd.ms-excel' = '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = '.xslx',
  'application/xml' = '.xml',
  'text/plain' = '.txt',
  'image/tiff' = '.tiff',
  'image/heic' = '.heic',
  'image/heif' = '.heif',
}
