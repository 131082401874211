import { getCountries } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/country-api'
import { Country } from 'holhous-common/src/vtj/country/country.type'
import { observable } from 'mobx'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'

export interface CountryStore {
  countries: Country[]
}

let countryStore: CountryStore | undefined

export const initCountryStore = async ({
  countries,
}: {
  countries?: Country[]
} = {}): Promise<CountryStore> => {
  const data = countries || (await getCountries())
  countryStore = observable({ countries: data })
  return countryStore
}

export function getCountryStore(): CountryStore {
  if (!countryStore) {
    throw new Error('CountryStore not initialized')
  }
  return countryStore
}

export const getCountryNamesInDisplayOrder = (
  lang: HolhousAsiointiLanguageCode
): string[] =>
  getCountryStore()
    .countries.map((c) => c.shortName[lang])
    .sort()

export const getCountryByShortName = (
  lang: HolhousAsiointiLanguageCode,
  countryName: string
): Country => {
  const found = getCountryStore().countries.find(
    (c) => c.shortName[lang] === countryName
  )
  if (!found) {
    throw new Error('Country not found')
  }
  return found
}

export const getCountryById = (countryId: string): Country => {
  const found = getCountryStore().countries.find(
    (c) => c.countryId === countryId
  )

  if (!found) {
    throw new Error('Unrecognized country ' + countryId)
  }
  return found
}
