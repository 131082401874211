// Allows for awaiting promises without try-catch-blocks
// Inspired by https://blog.grossman.io/how-to-write-async-await-without-try-catch-blocks-in-javascript/
export default async function to(promise) {
  try {
    const data = await promise
    return [null, data]
  } catch (err) {
    return [err]
  }
}
