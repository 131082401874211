import { Block, Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import {
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkAloitussivuTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useTranslation } from 'react-i18next'
import { useValittuEvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { ValtakirjaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/ValtakirjaForm'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/Perustiedot'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const PaatostiliAloitusPage: React.FC<{ form: TilintarkastusForm }> = observer(
  ({ form }) => {
    const [t] = useTranslation()
    const evtvVaatimus = useValittuEvtvVaatimus()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const onErrorRef = useRef<HTMLDivElement>(null)

    return (
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkAloitussivuTestId('container')}
        >
          <PageStepHeading heading={t('aloitus')} steps={PAATOSTILI_STEPS} />
          <FormErrorSummary ref={onErrorRef} />
          <Paragraph>{t('paatostiliJuurisivuOhje')}</Paragraph>
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Perustiedot key="perustiedot" />
          <Block mt={verticalMarginToken} />
          {evtvVaatimus.tilivelvollisuusType ===
            TilivelvollisuusType.TILIVELVOLLISUUS_YHDESSA && <ValtakirjaForm />}
          <DividerLine mt={verticalMarginToken} />
          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
        <Block mt={verticalMarginToken} />
      </FormProvider>
    )
  }
)

export default PaatostiliAloitusPage
