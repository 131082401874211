import {
  AsiointiLupaType,
  AsiointiLupaTypeId,
  ExternalLupaTypeId,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import { LupaApplicationStep } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { LupaAsiointiAsiakirjaTypeId } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { AsiointiPerson } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'

export type ChildTestId = string | number

export const mkTestId = (...ids: ChildTestId[]): string => {
  return `${['lupa-asiointi', ...ids].join('-')}`
}

export const mkHakemusTestId = (...ids: ChildTestId[]): string =>
  mkTestId('hakemus', ...ids)

export const mkWizardStepTestId = (
  step: LupaApplicationStep,
  ...ids: ChildTestId[]
): string => mkHakemusTestId('wizard', 'step', step, ...ids)

export const mkNeededAttachmentsTestId = (
  lupaTypeId: AsiointiLupaTypeId,
  ...ids: ChildTestId[]
): string => mkHakemusTestId('tarvliitteet', lupaTypeId, ...ids)

export const mkAttachmentsBuyInstrTestId = (): string =>
  mkHakemusTestId('sijoitusinfo', AsiointiLupaType.SIJOITUSKOHTEIDEN_OSTAMINEN)

export const mkNeededAttachmentTestId = (
  lupaTypeId: AsiointiLupaTypeId,
  ...ids: ChildTestId[]
): string => mkHakemusTestId('tarvliitteet', lupaTypeId, 'liite', ...ids)

export const mkLupaCardTestId = (
  lupaTypeId: AsiointiLupaTypeId | ExternalLupaTypeId,
  ...ids: ChildTestId[]
): string => mkHakemusTestId('lupakortti', lupaTypeId, ...ids)

export const mkLupaFilterTestId = (...ids: ChildTestId[]): string =>
  mkHakemusTestId('filter', ...ids)

export const mkFooterTestId = (...ids: ChildTestId[]): string =>
  mkHakemusTestId('footer', ...ids)

export const mkLupaModalTestId = (...ids: ChildTestId[]): string =>
  mkHakemusTestId('lupamodaali', ...ids)

export const mkSubmitModalTestId = (...ids: ChildTestId[]): string =>
  mkHakemusTestId('lahetamodaali', ...ids)

export const mkAbortModalTestId = (...ids: ChildTestId[]): string =>
  mkHakemusTestId('keskeytamodaali', ...ids)

export const mkLupaTestId = (
  lupaTypeId: AsiointiLupaTypeId,
  ...ids: ChildTestId[]
): string => mkHakemusTestId('lupa', lupaTypeId, ...ids)

export const mkLupaAttachmentTestId = (
  lupaTypeId: AsiointiLupaTypeId,
  asiakirjaTypeId: LupaAsiointiAsiakirjaTypeId,
  ...ids: ChildTestId[]
): string => mkLupaTestId(lupaTypeId, 'liitteet', asiakirjaTypeId, ...ids)

export const mkLupaAsiointiPersonTestId = (
  applicationRoleId: AsiointiPerson['applicationRoleId'],
  ...ids: ChildTestId[]
): string => mkHakemusTestId('person-form', applicationRoleId, ...ids)

export const mkLupaAsiointiPersonHeadingTestId = (
  applicationRoleId: AsiointiPerson['applicationRoleId'],
  ...ids: ChildTestId[]
): string => mkHakemusTestId('person-heading', applicationRoleId, ...ids)

export const mkLupaAsiointiPersonFieldTestId = (
  applicationRoleId: AsiointiPerson['applicationRoleId'],
  ...ids: ChildTestId[]
): string => mkHakemusTestId('person-field', applicationRoleId, ...ids)

export const mkLupaAsiointiPersonActionTestId = (
  applicationRoleId: AsiointiPerson['applicationRoleId'],
  ...ids: ChildTestId[]
): string => mkHakemusTestId('person-action', applicationRoleId, ...ids)

export const mkYhteenvetoTestId = (...ids: ChildTestId[]): string =>
  mkHakemusTestId('yhteenveto', ...ids)

export const mkYhteenvetoHenkiloTestId = (
  type: 'hakija-tai-asiamies' | 'paamies',
  index: number,
  ...ids: ChildTestId[]
): string => mkYhteenvetoTestId(type, index, ...ids)

export const mkSummaryHakemusTestId = (
  index: number,
  ...ids: ChildTestId[]
): string => mkYhteenvetoTestId('hakemus', index, ...ids)

export const mkYhteenvetoLiiteryhmaTestId = (
  parentType: 'hakija-tai-asiamies' | 'paamies' | 'hakemus',
  parentIndex: number,
  groupIndex: number,
  ...ids: ChildTestId[]
): string =>
  mkYhteenvetoTestId(parentType, parentIndex, 'liiteryhma', groupIndex, ...ids)

export const mkYhteenvetoLiiteTestId = (
  parentType: 'hakija-tai-asiamies' | 'paamies' | 'hakemus',
  parentIndex: number,
  groupIndex: number,
  attachmentIndex: number,
  ...ids: ChildTestId[]
): string =>
  mkYhteenvetoLiiteryhmaTestId(
    parentType,
    parentIndex,
    groupIndex,
    'tiedosto',
    attachmentIndex,
    ...ids
  )

export const mkValidationErrorsSummaryTestId = (
  ...ids: ChildTestId[]
): string => mkHakemusTestId('validation-errors-summary', ...ids)
