import { AsiointiLedgerAccountOtherType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-other-enum'
import {
  AsiointiLedgerAccounts,
  PartialAsiointiLedgerAccounts,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.type'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
  AsiointiLedgerAccountType,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { makeAsiointiLedgerAccountsMap } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.util'

const partialAsiointiLedgerAccountsOtherMap: Record<
  AsiointiLedgerAccountOtherType,
  PartialAsiointiLedgerAccounts
> = {
  [AsiointiLedgerAccountType.TILIOIMATON_TAPAHTUMA]: {
    subCategory: AsiointiLedgerAccountSubCategory.SIIRTOTILIT,
    accounts: ['900020'],
  },
  [AsiointiLedgerAccountType.ALKUSALDON_KORJAUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.SIIRTOTILIT,
    accounts: ['900030'],
  },
}

export const asiointiLedgerAccountsOtherMap: Record<
  AsiointiLedgerAccountOtherType,
  AsiointiLedgerAccounts
> = makeAsiointiLedgerAccountsMap(AsiointiLedgerAccountCategory.MUU)(
  partialAsiointiLedgerAccountsOtherMap
)
