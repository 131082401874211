export enum ApiErrorCode {
  MISSING_API_TOKEN = 'MISSING_API_TOKEN',
  INVALID_API_TOKEN = 'INVALID_API_TOKEN',
  WRONG_CLIENT_API_TOKEN = 'WRONG_CLIENT_API_TOKEN',
  MISSING_PARAMETERS = 'MISSING_PARAMETERS',
  INVALID_PARAMETERS = 'INVALID_PARAMETERS',
  ILLEGAL_REDIRECT_PATH = 'ILLEGAL_REDIRECT_PATH',
  MISSING_CLIENT_CERTIFICATE = 'MISSING_CLIENT_CERTIFICATE',
  INVALID_CLIENT_CERTIFICATE = 'INVALID_CLIENT_CERTIFICATE',
  CONFIGURATION_ERROR = 'CONFIGURATION_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  FAILED_REQUEST = 'FAILED_REQUEST',
  MAINTENANCE_BREAK = 'MAINTENANCE_BREAK',
  ASIA_NOT_FOUND = 'ASIA_NOT_FOUND',
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  CONFLICT = 'CONFLICT',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  BAD_REQUEST = 'BAD_REQUEST',
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  INVALID_CSRF_TOKEN = 'INVALID_CSRF_TOKEN',
}
