import {
  AsiointiLedgerAccounts,
  PartialAsiointiLedgerAccounts,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.type'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

export const MAXIMUM_ACCOUNT_LEDGER_NUMBER_RANGE = 1000
export const MAXIMUM_ACCOUNT_LEDGER_NUMBER_RANGE_ERROR =
  'Too large account range at once'

export const asiointiLedgerAccountRange = (
  start: number,
  stop: number
): string[] => {
  const count = stop - start + 1
  if (count > MAXIMUM_ACCOUNT_LEDGER_NUMBER_RANGE) {
    throw new Error(MAXIMUM_ACCOUNT_LEDGER_NUMBER_RANGE_ERROR)
  }
  return Array.from(new Array(count))
    .map((_, i) => i + start)
    .map((i) => i.toString())
}

export const makeAsiointiLedgerAccountsMap =
  <K extends string, T extends Record<K, PartialAsiointiLedgerAccounts>>(
    category: AsiointiLedgerAccountCategory
  ) =>
  (map: T): Record<K, AsiointiLedgerAccounts> => {
    const keys = Object.keys(map) as K[]
    return keys.reduce(
      (result, key) => ({
        ...result,
        [key]: {
          ...map[key],
          type: key,
          category,
        },
      }),
      {} as Partial<Record<K, AsiointiLedgerAccounts>>
    ) as Record<K, AsiointiLedgerAccounts>
  }
