import { AsiointiLedgerAccountExpenseType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-enum'
import {
  AsiointiLedgerAccounts,
  PartialAsiointiLedgerAccounts,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.type'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
  AsiointiLedgerAccountType,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import {
  asiointiLedgerAccountRange,
  makeAsiointiLedgerAccountsMap,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.util'

const partialAsiointiLedgerAccountsExpenseMap: Record<
  keyof typeof AsiointiLedgerAccountExpenseType,
  PartialAsiointiLedgerAccounts
> = {
  [AsiointiLedgerAccountType.KAYTTOVARAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.KAYTTOVARAT,
    accounts: ['600000'],
  },
  [AsiointiLedgerAccountType.APTEEKKIOSTOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610000'],
  },
  [AsiointiLedgerAccountType.ATERIAPALVELU]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610001'],
  },
  [AsiointiLedgerAccountType.ENERGIA_JA_VESI]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610002'],
  },
  [AsiointiLedgerAccountType.KODIN_HANKINNAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610003'],
  },
  [AsiointiLedgerAccountType.KUNNALLINEN_KOTIHOITO_KOTISAIRAANHOITO]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610004'],
  },
  [AsiointiLedgerAccountType.MATKAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610005'],
  },
  [AsiointiLedgerAccountType.SUORATOISTOPALVELU]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610007'],
  },
  [AsiointiLedgerAccountType.KIINTEISTON_KULUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610008'],
  },
  [AsiointiLedgerAccountType.MUUT_OSTOKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610009'],
  },
  [AsiointiLedgerAccountType.PALVELUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610010'],
  },
  [AsiointiLedgerAccountType.PUHELIN_INTERNET]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610011'],
  },
  [AsiointiLedgerAccountType.RUOKAKAUPPA]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610012'],
  },
  [AsiointiLedgerAccountType.SAIRAANKULJETUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610013'],
  },
  [AsiointiLedgerAccountType.VAKUUTUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610014'],
  },
  [AsiointiLedgerAccountType.VAPAA_AIKA_JA_HARRASTUSKULUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610015'],
  },
  [AsiointiLedgerAccountType.KELA_KORVATTAVAT_MATKAKULUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610016'],
  },
  [AsiointiLedgerAccountType.KELA_KORVATTAVAT_SAIRAANHOITOKULUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610017'],
  },
  [AsiointiLedgerAccountType.MUUT_ELINKUSTANNUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELINKUSTANNUKSET,
    accounts: ['610018'],
  },
  [AsiointiLedgerAccountType.ASUMISPALVELU]: {
    subCategory: AsiointiLedgerAccountSubCategory.HOITO_JA_ASUMISPALVELUMAKSUT,
    accounts: ['620000'],
  },
  [AsiointiLedgerAccountType.MAKSUKATTOA_KERRYTTAVA_HOITO]: {
    subCategory: AsiointiLedgerAccountSubCategory.HOITO_JA_ASUMISPALVELUMAKSUT,
    accounts: ['620001'],
  },
  [AsiointiLedgerAccountType.MUUT_HOITOMAKSUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.HOITO_JA_ASUMISPALVELUMAKSUT,
    accounts: ['620002'],
  },
  [AsiointiLedgerAccountType.PITKAAIKAISHOITO]: {
    subCategory: AsiointiLedgerAccountSubCategory.HOITO_JA_ASUMISPALVELUMAKSUT,
    accounts: ['620003'],
  },
  [AsiointiLedgerAccountType.ASUNTOVUOKRA]: {
    subCategory: AsiointiLedgerAccountSubCategory.VUOKRAT_JA_VASTIKKEET,
    accounts: ['630000'],
  },
  [AsiointiLedgerAccountType.YHTIOVASTIKE]: {
    subCategory: AsiointiLedgerAccountSubCategory.VUOKRAT_JA_VASTIKKEET,
    accounts: asiointiLedgerAccountRange(631000, 631099),
  },
  [AsiointiLedgerAccountType.MUUT_VUOKRAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.VUOKRAT_JA_VASTIKKEET,
    accounts: ['632000'],
  },
  [AsiointiLedgerAccountType.KOTITALOUSVAHENNYSKELPOISET_MENOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.VUOKRAT_JA_VASTIKKEET,
    accounts: ['640000'],
  },
  [AsiointiLedgerAccountType.ELATUSAPU_LAPSELLE]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELATUSAPU,
    accounts: ['650000'],
  },
  [AsiointiLedgerAccountType.ELATUSAPU_PUOLISOLLE]: {
    subCategory: AsiointiLedgerAccountSubCategory.ELATUSAPU,
    accounts: ['650010'],
  },
  [AsiointiLedgerAccountType.ARVO_OSUUSTILIN_SAILYTYSMAKSU]: {
    subCategory: AsiointiLedgerAccountSubCategory.PANKIN_PERIMAT_MAKSUT,
    accounts: ['660000'],
  },
  [AsiointiLedgerAccountType.PALVELUMAKSUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.PANKIN_PERIMAT_MAKSUT,
    accounts: ['660010'],
  },
  [AsiointiLedgerAccountType.TALLELOKEROMAKSUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.PANKIN_PERIMAT_MAKSUT,
    accounts: ['660020'],
  },
  [AsiointiLedgerAccountType.ARVONLISAVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700000'],
  },
  [AsiointiLedgerAccountType.ENNAKKOVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700010'],
  },
  [AsiointiLedgerAccountType.JAANNOSVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700020'],
  },
  [AsiointiLedgerAccountType.KIINTEISTOVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700030'],
  },
  [AsiointiLedgerAccountType.LUOVUTUSVOITTOVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700040'],
  },
  [AsiointiLedgerAccountType.LAHDEVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700050'],
  },
  [AsiointiLedgerAccountType.METSANHOITOMAKSU]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700060'],
  },
  [AsiointiLedgerAccountType.PERINTOVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700070'],
  },
  [AsiointiLedgerAccountType.MUUT_VEROT_KOIRAVERO_AJONEUVOVERO]: {
    subCategory: AsiointiLedgerAccountSubCategory.VEROT,
    accounts: ['700080'],
  },
  [AsiointiLedgerAccountType.PALKKA]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYONANTAJAKULUT,
    accounts: ['710000'],
  },
  [AsiointiLedgerAccountType.VUOSILOMAKORVAUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYONANTAJAKULUT,
    accounts: ['710010'],
  },
  [AsiointiLedgerAccountType.SOSIAALITURVAMAKSUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYONANTAJAKULUT,
    accounts: ['710020'],
  },
  [AsiointiLedgerAccountType.ELAKETURVAMAKSUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYONANTAJAKULUT,
    accounts: ['710030'],
  },
  [AsiointiLedgerAccountType.ENNAKKOPIDATYS]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYONANTAJAKULUT,
    accounts: ['710040'],
  },
  [AsiointiLedgerAccountType.MUUT_TYONANTAJAKULUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.TYONANTAJAKULUT,
    accounts: ['710050'],
  },
  [AsiointiLedgerAccountType.SOPIMUKSEN_MUKAISET_KOROT]: {
    subCategory: AsiointiLedgerAccountSubCategory.VELANHOITOMENOT,
    accounts: ['720100'],
  },
  [AsiointiLedgerAccountType.VIIVASTYSKOROT_JA_PERINTAKULUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.VELANHOITOMENOT,
    accounts: ['721000'],
  },
  [AsiointiLedgerAccountType.EDUNVALVOJAN_PALKKIO]: {
    subCategory: AsiointiLedgerAccountSubCategory.EDUNVALVONNAN_KULUT,
    accounts: ['730000'],
  },
  [AsiointiLedgerAccountType.EDUNVALVOJAN_KULUKORVAUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.EDUNVALVONNAN_KULUT,
    accounts: ['730010'],
  },
  [AsiointiLedgerAccountType.TILINTARKASTUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.EDUNVALVONNAN_KULUT,
    accounts: ['730020'],
  },
  [AsiointiLedgerAccountType.PAATOKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.EDUNVALVONNAN_KULUT,
    accounts: ['730030'],
  },
  [AsiointiLedgerAccountType.MUUT_MAKSUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.EDUNVALVONNAN_KULUT,
    accounts: ['730040'],
  },
  [AsiointiLedgerAccountType.ASIANTUNTIJAPALKKIOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['740000'],
  },
  [AsiointiLedgerAccountType.ETUUDEN_PALAUTUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['741000'],
  },
  [AsiointiLedgerAccountType.KORJAUSKUSTANNUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: asiointiLedgerAccountRange(742000, 742199),
  },
  [AsiointiLedgerAccountType.MAKSUPALAUTUKSET]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['743000'],
  },
  [AsiointiLedgerAccountType.METSA_JA_MAATALOUSKIINTEISTON_KULUT]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: asiointiLedgerAccountRange(744100, 744199),
  },
  [AsiointiLedgerAccountType.TIEMAKSU]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: asiointiLedgerAccountRange(745100, 745199),
  },
  [AsiointiLedgerAccountType.VAHINGONKORVAUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['746000'],
  },
  [AsiointiLedgerAccountType.VALITYSPALKKIOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: asiointiLedgerAccountRange(747000, 747199),
  },
  [AsiointiLedgerAccountType.MUUT_TILIKAUDEN_MENOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['748000'],
  },
  [AsiointiLedgerAccountType.HUONEISTON_OSTO]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['748010'],
  },
  [AsiointiLedgerAccountType.KIINTEISTON_OSTO]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['748020'],
  },
  [AsiointiLedgerAccountType.VIRHETULON_KORJAUS]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['749098'],
  },
  [AsiointiLedgerAccountType.VIRHEMAKSU_KORJATAAN_MYOHEMMIN]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['749099'],
  },
  [AsiointiLedgerAccountType.KIRJANPITOARVON_ALENNUS_MENOKOODI]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['750000'],
  },
  [AsiointiLedgerAccountType.PANKIN_VIRHE]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_MENOT_TILIKAUDELLA,
    accounts: ['900010'],
  },
  [AsiointiLedgerAccountType.VELKAA_HALTUUN]: {
    subCategory: AsiointiLedgerAccountSubCategory.VELKAA_HALTUUN,
    accounts: ['800000'],
  },
}

export const asiointiLedgerAccountsExpenseMap: Record<
  keyof typeof AsiointiLedgerAccountExpenseType,
  AsiointiLedgerAccounts
> = makeAsiointiLedgerAccountsMap(AsiointiLedgerAccountCategory.MENOT)(
  partialAsiointiLedgerAccountsExpenseMap
)
