import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'
import {
  BulletList,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  Block,
  Button,
  ExternalLink,
  Heading,
  IconCheckCircleFilled,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import {
  getTiliFeedbackUrl,
  kasittelyAjatUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { mkValmisTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useNavigate } from 'react-router-dom'
import {
  ButtonBlock,
  FocusableHeading,
  ValmisBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/components'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import { useValittuHenkilo } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'

const PaatostiliValmisPage: React.FC = observer(() => {
  const [t, i18n] = useTranslation()
  const userStore = useAsiointiUserStore()
  const headingRef = useAutoFocus<HTMLHeadingElement>()
  const navigate = useNavigate()
  const henkilo = useValittuHenkilo()
  const openFrontPage = () =>
    navigate(getAsioiPathByPersonId(henkilo?.personId))

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  return (
    <ResponsiveBorderedContentBlock data-test-id={mkValmisTestId('container')}>
      <ValmisBlock mb={verticalMarginToken}>
        <IconCheckCircleFilled fill={suomifiDesignTokens.colors.successDark1} />{' '}
        {t('valmis')}
      </ValmisBlock>
      <FocusableHeading
        variant="h2"
        smallScreen={!isTablet}
        ref={headingRef}
        tabIndex={-1}
      >
        {t('kiitosPaatostiliOnLahetetty')}
      </FocusableHeading>
      <Block variant="section" mt={verticalMarginToken}>
        <Text smallScreen={!isTablet} variant="bold">
          {t('muistaTositteetInfo')}
        </Text>
        <Block mt={verticalMarginToken} />
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('mitaSeuraavaksiTapahtuu')}
        </Heading>
        <Block mt="xs" />

        <BulletList>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaOttaaPaatostilinKasittelyyn')}
            </Text>
          </li>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaOnSinuunYhteydessaJosTarvitaanLisatietoja')}{' '}
            </Text>
          </li>
          <li>
            <Text smallScreen={!isTablet}>
              {t('virkailijaTarkastaaPaatostilin')}{' '}
            </Text>
          </li>
        </BulletList>
      </Block>
      <Block variant="section" mt={verticalMarginToken}>
        <Heading variant="h3" smallScreen={!isTablet}>
          {t('kasittelyaika')}
        </Heading>
        <Block mt="xs">
          <ExternalLink
            href={kasittelyAjatUrl(
              i18n.language as HolhousAsiointiLanguageCode
            )}
            variant="accent"
            underline="initial"
            smallScreen={!isTablet}
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('tarkistaHenkiloasiakkaidenKasittelyajatDvvnSivuilta')}
          </ExternalLink>
        </Block>
      </Block>
      <ButtonBlock variant="section" mt={verticalMarginToken}>
        <Button
          onClick={openFrontPage}
          role="link"
          data-test-id={mkValmisTestId('asiointi-front-page-link-button')}
        >
          {t('siirryEtusivulle')}
        </Button>
      </ButtonBlock>
      <DividerLine mt={verticalMarginToken} />
      <Block mt={verticalMarginToken}>
        <ExternalLink
          href={getTiliFeedbackUrl(userStore.lang)}
          variant="accent"
          underline="initial"
          smallScreen={!isTablet}
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('annaPalautettaPalvelusta')}
        </ExternalLink>
      </Block>
    </ResponsiveBorderedContentBlock>
  )
})

export default PaatostiliValmisPage
