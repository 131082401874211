import keyMirror from 'keymirror'

export const HuomioType = keyMirror({
  SIVIILISAATY_PUUTTUU: null,
  SYNTYMAILMOITUS_PUUTTUU: null,
  ODOTTAA_TAYDENNYSTA: null,
  TAYDENNYS_SAAPUNUT: null,
  TAYDENNYKSEN_MAARAAIKA_YLITTYNYT: null,
  ODOTTAA_KUULEMISTA: null,
  KUULEMINEN_SAAPUNUT: null,
  KUULEMISEN_MAARAAIKA_YLITTYNYT: null,
  ODOTTAA_SELVITYSTA: null,
  SELVITYS_SAAPUNUT: null,
  SELVITYKSEN_MAARAAIKA_YLITTYNYT: null,
  ODOTTAA_LAUSUNTOA: null,
  LAUSUNTO_SAAPUNUT: null,
  LAUSUNNON_MAARAAIKA_YLITTYNYT: null,
  ODOTTAA_LYKKAYSTA: null,
  LYKKAYS_SAAPUNUT: null,
  LYKKAYKSEN_MAARAAIKA_YLITTYNYT: null,
  PAKOLLISIA_ASIAKIRJOJA_PUUTTUU: null,
  ODOTTAA_TUNNISTAUTUMISTA: null,
  TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT: null,
  KIIREELLINEN: null,
  KIIREHDITTY: null,
  TURVAKIELTO: null,
  ASIANOSAINEN_KUOLLUT: null,
  ODOTTAA_NIMILAUTAKUNTAA: null,
  ODOTTAA_MAKSUA: null,
  ODOTTAA_SUOSTUMUSTA: null,
  ODOTTAA_TIEDOTETTAVANA: null,
  ODOTTAA_MUU_SYY: null,
  SYNKRONOINTI_KESKEN: null, // special value, resolved programmatically, not part of db-koodisto
  ODOTTAA_LISATIETOA: null,
  LISATIETO_SAAPUNUT: null,
  LISATIEDON_MAARAAIKA_YLITTYNYT: null,
  ODOTTAA_AUTOMAATTISTA_PAATOKSENTEKOA: null,
  AUTOMAATTINEN_PAATOKSENTEKO_EPAONNISTUNUT: null,
  ODOTTAA_TAYDENNYSTA_MANUAALINEN: null,
  TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN: null,
  ODOTTAA_KUULEMISTA_MANUAALINEN: null,
  KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN: null,
  ODOTTAA_LAUSUNTOA_MANUAALINEN: null,
  LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN: null,
  ODOTTAA_SELVITYSTA_MANUAALINEN: null,
  SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN: null,
  MAARAAIKA: null,
  MAARAAIKA_YLITTYNYT: null,
  KV_KORJAUS: null,
  VAALIT: null,
  MUU_TALLENNUS: null,
  SAHKOISEN_ASIAKIRJAN_VARMENNUS: null,
  ODOTTAA_VAESTOTIEDON_REKISTEROITYMISTA: null,
  TODISTUS_PALAUTUNUT_VIRASTOON: null,
  ASIAKKAALLA_ON_MUUTTOESTO: null,
  KASITTELY_VAATII_LISASELVITYKSEN: null,
})
export type HuomioTypeId = (typeof HuomioType)[keyof typeof HuomioType]
export const allHuomioTypeIds = Object.keys(HuomioType) as HuomioTypeId[]
export const personRelatedHuomioTypeIds: HuomioTypeId[] = [HuomioType.TURVAKIELTO, HuomioType.ASIANOSAINEN_KUOLLUT]
export const automaticHuomioTypeIds: HuomioTypeId[] = [
  HuomioType.ODOTTAA_AUTOMAATTISTA_PAATOKSENTEKOA,
  HuomioType.AUTOMAATTINEN_PAATOKSENTEKO_EPAONNISTUNUT,
]

export interface HuomioMetadata {
  huomioTypeId: HuomioTypeId
  postDueDateHuomioTypeId: HuomioTypeId | null
}

export const HuomioClass = keyMirror({
  ASIAKIRJA: null,
  AUTOMAATTINEN_PAATOKSENTEKO: null,
  HENKILO: null,
  KIIREELLISYYS: null,
  KUULEMINEN: null,
  LAUSUNTOPYYNTO: null,
  LISATIETOPYYNTO: null,
  LYKKAYS: null,
  ODOTTAA: null,
  SELVITYSPYYNTO: null,
  TUNNISTAUTUMINEN: null,
  TAYDENTAMINEN: null,
})
export type HuomioClassId = (typeof HuomioClass)[keyof typeof HuomioClass]
export const allHuomioClassIds = Object.keys(HuomioClass) as HuomioClassId[]

export type HuomioClassTypes = {
  [k in HuomioClassId]: HuomioTypeId[]
}

// defines the order which the huomio types are shown in Elsa tehtavalista filters
export const huomioClassTypes: HuomioClassTypes = {
  [HuomioClass.ASIAKIRJA]: [
    HuomioType.PAKOLLISIA_ASIAKIRJOJA_PUUTTUU,
    HuomioType.SAHKOISEN_ASIAKIRJAN_VARMENNUS,
    HuomioType.TODISTUS_PALAUTUNUT_VIRASTOON,
    HuomioType.KASITTELY_VAATII_LISASELVITYKSEN,
  ],
  [HuomioClass.AUTOMAATTINEN_PAATOKSENTEKO]: [
    HuomioType.ODOTTAA_AUTOMAATTISTA_PAATOKSENTEKOA,
    HuomioType.AUTOMAATTINEN_PAATOKSENTEKO_EPAONNISTUNUT,
  ],
  [HuomioClass.HENKILO]: [
    HuomioType.ASIANOSAINEN_KUOLLUT,
    HuomioType.KV_KORJAUS,
    HuomioType.SIVIILISAATY_PUUTTUU,
    HuomioType.SYNTYMAILMOITUS_PUUTTUU,
    HuomioType.TURVAKIELTO,
    HuomioType.MUU_TALLENNUS,
    HuomioType.ASIAKKAALLA_ON_MUUTTOESTO,
  ],
  [HuomioClass.KIIREELLISYYS]: [
    HuomioType.KIIREHDITTY,
    HuomioType.KIIREELLINEN,
    HuomioType.MAARAAIKA,
    HuomioType.MAARAAIKA_YLITTYNYT,
    HuomioType.VAALIT,
  ],
  [HuomioClass.KUULEMINEN]: [
    HuomioType.ODOTTAA_KUULEMISTA,
    HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
    HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT,
    HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    HuomioType.KUULEMINEN_SAAPUNUT,
  ],
  [HuomioClass.LAUSUNTOPYYNTO]: [
    HuomioType.ODOTTAA_LAUSUNTOA,
    HuomioType.ODOTTAA_LAUSUNTOA_MANUAALINEN,
    HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT,
    HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    HuomioType.LAUSUNTO_SAAPUNUT,
  ],
  [HuomioClass.LISATIETOPYYNTO]: [
    HuomioType.ODOTTAA_LISATIETOA,
    HuomioType.LISATIEDON_MAARAAIKA_YLITTYNYT,
    HuomioType.LISATIETO_SAAPUNUT,
  ],
  [HuomioClass.LYKKAYS]: [
    HuomioType.ODOTTAA_LYKKAYSTA,
    HuomioType.LYKKAYKSEN_MAARAAIKA_YLITTYNYT,
    HuomioType.LYKKAYS_SAAPUNUT,
  ],
  [HuomioClass.ODOTTAA]: [
    HuomioType.ODOTTAA_NIMILAUTAKUNTAA,
    HuomioType.ODOTTAA_MAKSUA,
    HuomioType.ODOTTAA_SUOSTUMUSTA,
    HuomioType.ODOTTAA_TIEDOTETTAVANA,
    HuomioType.ODOTTAA_MUU_SYY,
    HuomioType.ODOTTAA_VAESTOTIEDON_REKISTEROITYMISTA,
  ],
  [HuomioClass.SELVITYSPYYNTO]: [
    HuomioType.ODOTTAA_SELVITYSTA,
    HuomioType.ODOTTAA_SELVITYSTA_MANUAALINEN,
    HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT,
    HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    HuomioType.SELVITYS_SAAPUNUT,
  ],
  [HuomioClass.TUNNISTAUTUMINEN]: [
    HuomioType.ODOTTAA_TUNNISTAUTUMISTA,
    HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
  ],
  [HuomioClass.TAYDENTAMINEN]: [
    HuomioType.ODOTTAA_TAYDENNYSTA,
    HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
    HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
    HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    HuomioType.TAYDENNYS_SAAPUNUT,
  ],
}
