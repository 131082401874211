import React from 'react'

export const trimEventTargetValue = <
  T extends HTMLInputElement | HTMLTextAreaElement
>(
  event: React.ChangeEvent<T>
): string => {
  const value = event.target?.value.trim()
  event.target.value = value
  return value
}
