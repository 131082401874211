import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Block, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { mkPalkkioTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  getPaatostili,
  updatePalkkioValues,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { useValittuEvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { EdunvalvontasuhdeType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import {
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { action } from 'mobx'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const PaatostiliPalkkioPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)

  const veloitatkoPalkkiotaTestId = mkPalkkioTestId(
    'veloitatko-palkkiota-radio-group'
  )
  const veloitatkoKulukorvauksiaTestId = mkPalkkioTestId(
    'veloitatko-kulukorvauksia-radio-group'
  )
  const palkkioMaaraTestId = mkPalkkioTestId('palkkio-maara')
  const kulukorvausMaaraTestId = mkPalkkioTestId('kulukorvaus-maara')
  const { palkkio } = getPaatostili()

  const lakimaarainenEliHolhoaja =
    EdunvalvontasuhdeType.EDUNVALVONTA_LAKIMAARAINEN ===
    useValittuEvtvVaatimus().guardianshipType

  if (lakimaarainenEliHolhoaja) {
    updatePalkkioValues({ veloitatkoPalkkiota: false })
  }

  // Unregister form fields so form isValid is tracked correctly
  const { unregister, getFieldState } = form
  useEffect(() => {
    if (
      palkkio.veloitatkoKulukorvauksia === false &&
      getFieldState(kulukorvausMaaraTestId) !== undefined
    ) {
      unregister(kulukorvausMaaraTestId)
    }
    if (
      palkkio.veloitatkoPalkkiota === false &&
      getFieldState(palkkioMaaraTestId) !== undefined
    ) {
      unregister(palkkioMaaraTestId)
    }
  }, [
    palkkio,
    palkkioMaaraTestId,
    kulukorvausMaaraTestId,
    unregister,
    getFieldState,
  ])

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkPalkkioTestId('container')}
        >
          <PageStepHeading heading={t('palkkio')} steps={PAATOSTILI_STEPS} />
          <FormErrorSummary ref={onErrorRef} />
          {lakimaarainenEliHolhoaja && (
            <>
              <InlineAlert
                status="warning"
                data-test-id={mkPalkkioTestId('huoltaja-warning')}
              >
                {t('ohjeLakimaarainenEdunvalvojaEliHuoltaja')}
              </InlineAlert>
              <Block mt={verticalMarginToken} />
            </>
          )}

          <Paragraph>{t('ohjeIlmoitaVeloitatko')}</Paragraph>
          <Block mt={verticalMarginToken} />
          <Paragraph>{t('ohjeVoitMaksaaSumma')}</Paragraph>
          <Block mt={verticalMarginToken} />
          <Paragraph>{t('ohjeIlmoitaPalkkioTulorekisteriin')}</Paragraph>

          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          {!lakimaarainenEliHolhoaja && (
            <>
              <Block mb={verticalMarginToken}>
                <FormRadioButtonGroup
                  required
                  value={palkkio.veloitatkoPalkkiota?.toString()}
                  data-test-id={veloitatkoPalkkiotaTestId}
                  labelText={t('veloitatkoPalkkiotaTaltaTilikaudelta')}
                  updateValue={action(
                    (value) => (palkkio.veloitatkoPalkkiota = value === 'true')
                  )}
                  radioButtons={[
                    {
                      value: 'true',
                      labelText: t('kylla'),
                    },
                    {
                      value: 'false',
                      labelText: t('eiLuovunPalkkiostaTilikaudella'),
                    },
                  ]}
                />
              </Block>

              {palkkio.veloitatkoPalkkiota ? (
                <FormNumberInput
                  data-test-id={palkkioMaaraTestId}
                  labelText={t('palkkioEuroina')}
                  value={palkkio.palkkioMaara}
                  validate={commonEuroWithDecimalsOrUndefinedValidator(
                    t,
                    'two'
                  )}
                  required={palkkio.veloitatkoPalkkiota}
                  updateValue={(value) =>
                    updatePalkkioValues({
                      palkkioMaara: value,
                    })
                  }
                />
              ) : (
                palkkio.veloitatkoPalkkiota === false && (
                  <Block mb={verticalMarginToken}>
                    <InlineAlert
                      data-test-id={mkPalkkioTestId('luovut-palkkiosta-info')}
                    >
                      {t('ohjeLuovutPalkkiosta')}
                    </InlineAlert>
                  </Block>
                )
              )}
            </>
          )}

          <Block mb={verticalMarginToken}>
            <FormRadioButtonGroup
              required
              value={palkkio.veloitatkoKulukorvauksia?.toString()}
              data-test-id={veloitatkoKulukorvauksiaTestId}
              labelText={t('veloitatkoKulukorvauksiaTaltaTilikaudelta')}
              updateValue={action(
                (value) => (palkkio.veloitatkoKulukorvauksia = value === 'true')
              )}
              radioButtons={[
                {
                  value: 'true',
                  labelText: t('kylla'),
                },
                {
                  value: 'false',
                  labelText: t('eiLuovunkorvauksistaTilikaudella'),
                },
              ]}
            />
          </Block>

          {palkkio.veloitatkoKulukorvauksia ? (
            <FormNumberInput
              data-test-id={kulukorvausMaaraTestId}
              labelText={t('kulutEuroina')}
              value={palkkio.kulukorvausMaara}
              validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
              required={palkkio.veloitatkoKulukorvauksia}
              updateValue={(value) =>
                updatePalkkioValues({
                  kulukorvausMaara: value,
                })
              }
            />
          ) : (
            palkkio.veloitatkoKulukorvauksia === false && (
              <Block mb={verticalMarginToken}>
                <InlineAlert>{t('ohjeLuovutKulukorvauksesta')}</InlineAlert>
              </Block>
            )
          )}
          {(palkkio.veloitatkoPalkkiota ||
            palkkio.veloitatkoKulukorvauksia) && (
            <>
              <FormAttachmentFileBox
                observableAttachments={palkkio.kululaskelma}
                asiakirjaTypeId={TiliAsiakirjaType.PALKKIO_JA_KULULASKELMA}
                data-test-id={mkPalkkioTestId('palkkio-laskelma')}
                title={t('palkkioJaKululaskelma')}
                text={t('ohjePalkkioJaKululaskelmaLomakepohja')}
              />
            </>
          )}
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default PaatostiliPalkkioPage
