import keyMirror from 'keymirror'
import { MimeType } from 'common/src/vtj/MimeType.enum'

export type AsiointiFileAttachmentError =
  | 'empty'
  | 'too_large'
  | 'invalid_type'
  | 'validation_not_passed'
  | 'validation_aborted'
  | 'upload_error'

export type AsiointiFileAttachment = {
  asiakirjaId?: string
  sourceFileId: string
  filename: string
  sizeBytes: number
  mimeType: MimeType
} & (
  | {
      status: 'success' | 'loading' | 'checking'
    }
  | {
      status: 'failed'
      error: AsiointiFileAttachmentError
    }
)

export const AsiointiFileCheckStatus = keyMirror({
  KAYNNISSA: null,
  LAPAISTY: null,
  EI_LAPAISTY: null,
  KESKEYTETTY: null,
})
export type AsiointiFileCheckStatusId =
  (typeof AsiointiFileCheckStatus)[keyof typeof AsiointiFileCheckStatus]
