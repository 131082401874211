import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FormProvider } from 'react-hook-form'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Block, Heading, Text } from 'suomifi-ui-components'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { useTranslation } from 'react-i18next'
import ElakeForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/ElakkeetForm'
import PalkkaForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/PalkkatulotForm'
import EtuudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/EtuudetForm'
import TyottomyysturvaForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/TyottomyysturvaForm'
import MyyntitulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/MyyntitulotForm'
import KorkotulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/KorkotulotForm'
import OsinkotulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/OsinkotulotForm'
import VuokratulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/VuokratulotForm'
import MuutTulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/tulot/MuutTulotForm'
import KayttovaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/KayttovaratForm'
import ElinkustannuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/ElinkustannuksetForm'
import PalvelumaksutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/PalvelumaksutForm'
import VuokratVastikkeetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/VuokratVastikkeetForm'
import ElatusavutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/ElatusavutForm'
import PankinPerimatMaksutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/PankinPerimatMaksutForm'
import VerotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/VerotForm'
import TyonantajakulutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/TyonantajakulutForm'
import VelanhoitomenotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/VelanhoitomenotForm'
import EdunvalvonnanKulutForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/EdunvalvonnanKulutForm'
import MuutMenotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/menot/MuutMenotForm'
import {
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const TulotMenotPage: React.FC<{ form: TilintarkastusForm }> = observer(
  ({ form }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const onErrorRef = useRef<HTMLDivElement>(null)

    return (
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkTulotMenotTestId('container')}
        >
          <PageStepHeading
            heading={t('tulotJaMenotHeading')}
            steps={PAATOSTILI_STEPS}
          />
          <FormErrorSummary ref={onErrorRef} />
          <Block mt={verticalMarginToken} />
          <Text>{t('tulotMenotInfoText')}</Text>
          <Block mt={verticalMarginToken} />
          <Text>{t('tulotMenotAdditionalInfoText')}</Text>
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />

          <section data-test-id={mkTulotMenotTestId('tulot')}>
            <Heading variant="h2">{t('tulotHeading')}</Heading>
            <Block mt={verticalMarginToken} />
            <ElakeForm />
            <Block mt={verticalMarginToken} />
            <PalkkaForm />
            <Block mt={verticalMarginToken} />
            <EtuudetForm />
            <Block mt={verticalMarginToken} />
            <TyottomyysturvaForm />
            <Block mt={verticalMarginToken} />
            <VuokratulotForm />
            <Block mt={verticalMarginToken} />
            <OsinkotulotForm />
            <Block mt={verticalMarginToken} />
            <KorkotulotForm />
            <Block mt={verticalMarginToken} />
            <MyyntitulotForm />
            <Block mt={verticalMarginToken} />
            <MuutTulotForm />
            <DividerLine mt={verticalMarginToken} />
            <Block mt={verticalMarginToken} />
          </section>

          <section data-test-id={mkTulotMenotTestId('menot')}>
            <Block mt={verticalMarginToken} />
            <Heading variant="h2">{t('menotHeading')}</Heading>
            <Block mt={verticalMarginToken} />
            <KayttovaratForm />
            <Block mt={verticalMarginToken} />
            <ElinkustannuksetForm />
            <Block mt={verticalMarginToken} />
            <PalvelumaksutForm />
            <Block mt={verticalMarginToken} />
            <VuokratVastikkeetForm />
            <Block mt={verticalMarginToken} />
            <ElatusavutForm />
            <Block mt={verticalMarginToken} />
            <PankinPerimatMaksutForm />
            <Block mt={verticalMarginToken} />
            <VerotForm />
            <Block mt={verticalMarginToken} />
            <TyonantajakulutForm />
            <Block mt={verticalMarginToken} />
            <VelanhoitomenotForm />
            <Block mt={verticalMarginToken} />
            <EdunvalvonnanKulutForm />
            <Block mt={verticalMarginToken} />
            <MuutMenotForm />
          </section>
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    )
  }
)

export default TulotMenotPage
