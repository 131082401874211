import keyMirror from 'keymirror'

export const AsiointiLupaType = keyMirror({
  // MYYNTI
  ASUNNON_MYYNTI: null,
  ASUMISOIKEUDEN_MYYNTI: null,
  KIINTEISTON_MYYNTI: null,

  // MUU
  ASUNNON_HANKKIMINEN: null,
  KIINTEISTON_HANKKIMINEN: null,
  ASUMISOIKEUDEN_HANKKIMINEN: null,
  KIINTEISTON_VUOKRAAMINEN: null,
  ASUNNON_VUOKRAAMINEN: null,
  ASUMISOIKEUDEN_VUOKRAAMINEN: null,
  LAINAN_OTTAMINEN: null,
  PANTTAUS: null,
  TAKAUS: null,
  ELINKEINON_HARJOITTAMINEN: null,
  YHTIOMIEHEKSI_RYHTYMINEN: null,
  LAINAN_ANTAMINEN: null,
  SIJOITUSKOHTEIDEN_OSTAMINEN: null,
  METSAN_TAI_MAA_AINEKSEN_MYYNTI: null,
  PERINNOSTA_LUOPUMINEN: null,
  PERINTOOSUUDEN_LUOVUTUS: null,
  SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA: null,
  AVIOERO_OSITUS_TAI_EROTTELU: null,

  // PERINNONJAKO
  PERINNONJAKO: null,
})
export type AsiointiLupaTypeId =
  (typeof AsiointiLupaType)[keyof typeof AsiointiLupaType]

export const ExternalLupaType = keyMirror({
  // Muualla haettava
  OMAISUUDENHOITOSUUNNITELMA: null,
  VAJAAVALTAISEN_TYOANSIO: null,
  ESTOTILI: null,
})
export type ExternalLupaTypeId =
  (typeof ExternalLupaType)[keyof typeof ExternalLupaType]

export const AsiointiLupaKeyword = keyMirror({
  MUUTTO: null,
  MYYNTI: null,
  OSTO: null,
  PERINTO: null,
  PANTTAUS: null,
  KUOLINPESA: null,
  LAINAT: null,
  KIINTEISTOT: null,
  ASUNNOT: null,
  ASUMISOIKEUS: null,
  VUOKRAUS: null,
  METSA_TAI_MAA_AINES: null,
  SIJOITTAMINEN: null,
  YRITYSTOIMINTA: null,
  AVIOERO: null,
  OMAISUUDENHOITO: null,
})
export type AsiointiLupaKeywordId =
  (typeof AsiointiLupaKeyword)[keyof typeof AsiointiLupaKeyword]
