import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  AsiointiType,
  TiliData,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getMuutMerkittavatToimet = (
  account: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const { isMuutMerkittavatToimet, muutMerkittavatToimet } = account
  const isOmaisuusluettelo = account.asiaType === AsiointiType.OMAISUUSLUETTELO

  const content: YhteenvetoGroupProps[] = [
    {
      heading: t(
        isOmaisuusluettelo
          ? 'onkoTulossaMuitaMerkittaviaToimia'
          : 'onkoMuitaMerkittaviaToimia'
      ),
      rows: [
        isMuutMerkittavatToimet !== undefined
          ? isMuutMerkittavatToimet
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
    },
  ]

  if (isMuutMerkittavatToimet) {
    content.push({
      heading: t('mitaMuitaMerkittaviaToimia'),
      rows: [muutMerkittavatToimet],
    })
  }

  return content
}

const MuutMerkittavatToimet: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section
      data-test-id={mkYhteenvetoTestId('muut-merkittavat-toimet-container')}
    >
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('muutMerkittavatToimet')}
      </Heading>
      <YhteenvetoGroups groups={getMuutMerkittavatToimet(tili, t)} />
    </section>
  )
})

export default MuutMerkittavatToimet
