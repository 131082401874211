import keyMirror from 'keymirror'
import _ from 'lodash'

export const Viranomainen = keyMirror({
  DVV: null,
  ITA_SUOMEN_AVI: null,
})

export type ViranomainenId = (typeof Viranomainen)[keyof typeof Viranomainen]
export const allViranomainenIds = _.keys(Viranomainen) as ViranomainenId[]

export interface AsiaViranomainen {
  viranomainenId: ViranomainenId
  yTunnus: string
}

export type ViranomainenMetadata = {
  [key in ViranomainenId]: AsiaViranomainen
}

export const viranomainenMetadata: ViranomainenMetadata = {
  [Viranomainen.DVV]: {
    viranomainenId: Viranomainen.DVV,
    yTunnus: '0245437-2',
  },
  [Viranomainen.ITA_SUOMEN_AVI]: {
    viranomainenId: Viranomainen.ITA_SUOMEN_AVI,
    yTunnus: '1094822-5',
  },
}
