import React from 'react'
import {
  IconDoc,
  IconPdf,
  IconPpt,
  IconXls,
  IconXml,
  IconGenericFile,
} from 'suomifi-ui-components'
import { MimeType } from 'common/src/vtj/MimeType.enum'

export const FileIcon: React.FC<{ mimeType: MimeType; className?: string }> = ({
  mimeType,
  className,
}) => {
  switch (mimeType) {
    case MimeType.DOC:
    case MimeType.DOCX:
      return <IconDoc className={className} />
    case MimeType.PDF:
      return <IconPdf className={className} />
    case MimeType.PPT:
    case MimeType.PPTX:
      return <IconPpt className={className} />
    case MimeType.XLS:
    case MimeType.XLSX:
      return <IconXls className={className} />
    case MimeType.XML:
      return <IconXml className={className} />
    default:
      return <IconGenericFile className={className} />
  }
}
