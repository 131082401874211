import bezier, { EasingFunction } from 'bezier-easing'
import { to } from 'common/src/vtj/to-typed'

export const POLL_TIMEOUT_CURVE_LINEAR = 0
export const POLL_TIMEOUT_CURVE_DEFAULT = 0.5
export const POLL_TIMEOUT_CURVE_STEEP = 1

export const pollUntilTrue = async (
  conditionalFn: () => Promise<boolean> | boolean,
  maxIter?: number,
  maxTotalTimeoutMs?: number,
  timeoutCurve?: number
): Promise<boolean> => {
  let unexpectedError: Error | null = null

  const [pollError] = await to(
    pollUntilPasses(
      async () => {
        const [err, isDone] = await to(conditionalFn() as Promise<boolean>)
        if (err) {
          // errors are not expected on conditionalFn, but need to catch them
          // to stop polling here.
          unexpectedError = err
        } else if (!isDone) {
          throw new Error('Not done yet')
        }
      },
      maxIter,
      maxTotalTimeoutMs,
      timeoutCurve
    )
  )

  if (unexpectedError) {
    throw unexpectedError
  }

  return !pollError
}

export const pollUntilPasses = <T>(
  iterFn: () => Promise<T> | T,
  maxIter = 10,
  /*
   * Maximun total time of polling would be calculated by this plus what ever it
   * takes for maxIter x iterFn to complete
   */
  maxTotalTimeoutMs = maxIter * 2000,
  /*
   * Adjust the curve of polling by choosing a number within a range of 0 and 1.
   * 0 would mean fixed timeouts for the whole duration of polling and 1 would
   * mean very rapid iterations at the beginning and when slowing down greatly
   * as total duration of all timeouts closes to maxTotalTimeoutMs.
   */
  timeoutCurve = POLL_TIMEOUT_CURVE_DEFAULT
): Promise<T> => {
  if (timeoutCurve < 0 || timeoutCurve > 1) {
    throw new Error(`timeoutCurve must be a number within a range of 0 and 1. Was ${timeoutCurve}.`)
  }
  return doPollUntilPasses(
    iterFn,
    maxIter,
    1,
    maxTotalTimeoutMs,
    0,
    // see https://cubic-bezier.com/#.5,0,1,1
    bezier(timeoutCurve, 0, 1, 1)
  )
}

const doPollUntilPasses = async <T>(
  iterFn: () => Promise<T> | T,
  maxIter: number,
  currentIter: number,
  maxTotalTimeoutMs: number,
  usedTimeoutMs: number,
  easing: EasingFunction
): Promise<T> => {
  const [err, result] = await to(iterFn() as Promise<T>)
  if (!err) {
    return result as T
  } else if (currentIter === maxIter) {
    throw err
  } else {
    const nextTimeoutMs = maxTotalTimeoutMs * easing(currentIter / (maxIter - 1))
    const timeoutDuration = nextTimeoutMs - usedTimeoutMs
    await new Promise((resolve) => setTimeout(resolve, timeoutDuration))
    return doPollUntilPasses(iterFn, maxIter, currentIter + 1, maxTotalTimeoutMs, nextTimeoutMs, easing)
  }
}
