/* eslint-disable @typescript-eslint/no-explicit-any */

export interface Actions {
  handleSessionExpiration(): void
}

export interface ActionsSingleton extends Actions {
  setAll(newActions: Actions): void
}

const noop = () => {
  // noop
}

const defaultActions = {
  handleSessionExpiration: noop,
}

let actions: any = { ...defaultActions }

const proxy: ActionsSingleton = new Proxy(
  {},
  {
    get: (obj: any, prop: string): ((newActions: Actions) => void | undefined) => {
      if (prop === 'setAll') {
        return (newActions: Actions): void => {
          actions = { ...defaultActions, ...newActions }
        }
      }
      return actions[prop]
    },
  }
)

export default proxy
