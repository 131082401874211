import { runAsiointiStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import { AsiointiLupaTypeId } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import { validateBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/validation-actions'
import {
  AsiointiLupaEstateSpecification,
  asiointiLupaInfos,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import { ContainsEstate } from 'lupa-backend/src/vtj/elsa/lupa/lupa-enums'

export const toggleSelectedLupaType = (
  lupaTypeId: AsiointiLupaTypeId,
  selected: boolean
): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    if (selected) {
      const application = {
        typeId: lupaTypeId,
        containsEstate:
          asiointiLupaInfos[lupaTypeId].containsEstate ===
          AsiointiLupaEstateSpecification.ALWAYS
            ? ContainsEstate.LIITTYY_KUOLINPESA
            : ContainsEstate.EI_KUOLINPESAA,
        attachments: [],
        validationErrors: [],
      }
      store.batch.applications.push(application)
    } else {
      const i = store.batch.applications.findIndex(
        (a) => a.typeId === lupaTypeId
      )
      if (i > -1) {
        store.batch.applications.splice(i, 1)
      }
    }
    yield validateBatch()
  })
