import fp from 'lodash/fp'
import keyMirror from 'keymirror'
import { MimeType } from 'common/src/vtj/MimeType.enum'
import {
  AsiakirjaOriginType,
  AsiakirjaType,
} from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  AsiakirjaMetadata,
  asiakirjaMetadataDeprecated,
} from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { AsiaServiceArea } from 'asia-common/src/vtj/koodisto/asia-enums'
import { asiaTypeMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AccountAsiaTypeId } from 'tilintarkastus-common/src/vtj/tili-account-enums'

export type TiliAsiakirjaDeliveryMethodIdNullable =
  TiliAsiakirjaDeliveryMethodId | null

export const TiliAsiakirjaType = fp.pick(
  [
    'ARVIO_OMAISUUDESTA',
    'LAAKARINLAUSUNTO',
    'MUU_LIITE',
    'MUU_VIRASTON_LAATIMA_ASIAKIRJA',
    'OIKEUSOHJEET',
    'PAATOSTILIN_TIEDOKSIANTO_LIITTEINEEN',
    'PAATOSTILITULOSTE',
    'PERUKIRJA',
    'SALDOTODISTUS_TAI_OMAISUUSOTE',
    'SELVITYS_OMAISUUDEN_MYYNNISTA',
    'SOPIMUS',
    'TESTAMENTTI_JA_TIEDOKSIANTOTODISTUS',
    'TILIOTE',
    'EDUNVALVONTASUUNNITELMA',
    'OMAISUUSLUETTELO',
    'PAATOSTILI_PAAKIRJA_PALKKIOLASKELMA',
    'PALKKIO_JA_KULULASKELMA',
    'TARKASTETTU_PAATOSTILI_PAAKIRJA_JA_PALKKIOLASKELMA_TILIN_TARKASTUSMERKINNAT',
    'TARKASTETTU_VUOSITILI_JA_PALKKIOLASKELMA_TILIN_TARKASTUSMERKINNAT',
    'TAYDENNYSPYYNTO',
    'TOSITE',
    'TULOSTE',
    'VAATIMUS_VIRHEEN_KORJAAMISESTA',
    'VALTAKIRJA',
    'VASTAUS_TAYDENNYS_TAI_SELVITYSPYYNTOON',
    'VUOSITILI_PAAKIRJA_PALKKIOLASKELMA',
  ],
  AsiakirjaType
)
export type TiliAsiakirjaTypeId =
  (typeof TiliAsiakirjaType)[keyof typeof TiliAsiakirjaType]

export const NoticeAsiakirjaType = fp.pick(
  ['OIKEUSOHJEET', 'PAATOSTILIN_TIEDOKSIANTO_LIITTEINEEN', 'PAATOSTILITULOSTE'],
  TiliAsiakirjaType
)

export const noticeAsiakirjaTypeSet = new Set<TiliAsiakirjaTypeId>(
  Object.values(NoticeAsiakirjaType)
)

// FIXME: Use asiaTypeMetadata from asia-common `asia-type-metadata.ts`
// Asia types can have different asiakirja metadata and using old service-area mapping can lead to errors in asianhallinta sync
export const tiliAsiakirjaMetadata = asiakirjaMetadataDeprecated[
  AsiaServiceArea.TILINTARKASTUS
].filter((a) => {
  return fp.contains(a.asiakirjaTypeId, fp.keys(TiliAsiakirjaType))
})

export const getElsaTiliAsiakirjaMetadata = (
  asiaTypeId: AccountAsiaTypeId
): AsiakirjaMetadata[] =>
  asiaTypeMetadata[asiaTypeId].asiakirjaMetadata.filter((a) => {
    return !fp.contains(a.asiakirjaTypeId, fp.keys(NoticeAsiakirjaType))
  })

export const getAllElsaTiliAsiakirjaMetadata = (
  asiaTypeId: AccountAsiaTypeId
): AsiakirjaMetadata[] => asiaTypeMetadata[asiaTypeId].asiakirjaMetadata

export const getTiliAsiakirjaMetadataByAsiaType = (
  asiakirjaTypeId: TiliAsiakirjaTypeId,
  tiliAsiaType: AccountAsiaTypeId
): AsiakirjaMetadata =>
  getAllElsaTiliAsiakirjaMetadata(tiliAsiaType).find(
    (a) => a.asiakirjaTypeId === asiakirjaTypeId
  ) as AsiakirjaMetadata

export const TiliAsiakirjaMimeType = {
  PDF: MimeType.PDF,
  ODT: MimeType.ODT,
  DOCX: MimeType.DOCX,
  XLSX: MimeType.XLSX,
  PPTX: MimeType.PPTX,
  JPEG: MimeType.JPEG,
  PNG: MimeType.PNG,
  HEIC: MimeType.HEIC,
  HEIF: MimeType.HEIF,
}
export type TiliAsiakirjaMimeTypeId =
  (typeof TiliAsiakirjaMimeType)[keyof typeof TiliAsiakirjaMimeType]
export const allowedTiliAsiakirjaMimeTypes: TiliAsiakirjaMimeTypeId[] =
  Object.values(TiliAsiakirjaMimeType)

export const TiliAsiakirjaDeliveryMethod = keyMirror({
  VERKKOSOVELLUS: null,
  INTEGRAATIO: null,
  KIRJE: null,
  SAHKOPOSTI: null,
  PUHELIN: null,
})
export type TiliAsiakirjaDeliveryMethodId =
  (typeof TiliAsiakirjaDeliveryMethod)[keyof typeof TiliAsiakirjaDeliveryMethod]

export const TiliAsiakirjaOriginType = fp.pick(
  ['SAAPUNUT', 'LAADITTU'],
  AsiakirjaOriginType
)
export type TiliAsiakirjaOriginTypeId =
  (typeof TiliAsiakirjaOriginType)[keyof typeof TiliAsiakirjaOriginType]

export const TiliAsiakirjaPublicityClass = keyMirror({
  JULKINEN: null,
  OSITTAIN_SALASSAPIDETTAVA: null,
  SALAINEN: null,
})
export type TiliAsiakirjaPublicityClassId =
  (typeof TiliAsiakirjaPublicityClass)[keyof typeof TiliAsiakirjaPublicityClass]
