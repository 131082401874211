export type ChildTestId = string | number

export const mkTestId = (...ids: ChildTestId[]): string => {
  return `${['asiointi', ...ids].join('-')}`
}

export const mkErrorPageTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('error', ...ids)
}

export const mkHeaderTestId = (...ids: ChildTestId[]): string =>
  mkTestId('header', ...ids)

export const mkLandingPageTestId = (...ids: ChildTestId[]): string =>
  mkTestId('landing-page', ...ids)

export const mkPaamiesFrontPageTestId = (...ids: ChildTestId[]): string =>
  mkTestId('paamies-front-page', ...ids)

export const mkSessionTestId = (...ids: ChildTestId[]): string =>
  mkTestId('istunto', ...ids)

export const mkOmaisuudenHallintaTestId = (...ids: ChildTestId[]): string =>
  mkTestId('omaisuuden-hallinta', ...ids)

export const mkPaamiehenValintaTestId = (...ids: ChildTestId[]): string =>
  mkTestId('puolesta-asiointi', ...ids)
