import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import { runAsiointiStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { z, ZodLiteral } from 'zod'
import { AsiointiLupaType } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import {
  LupaAsiointiAsiakirjaType,
  LupaAsiointiAsiakirjaTypeId,
} from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import {
  LupaApplicationRole,
  OpinionType,
} from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { MimeType } from 'common/src/vtj/MimeType.enum'
import {
  AsiakirjaValidationError,
  AsiointiApplication,
  AsiointiBatch,
  AsiointiPerson,
  FieldValidationError,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { ZodIssue } from 'zod/lib/ZodError'
import {
  getExpectedAsiakirjaTypes,
  getRequiredAsiakirjaTypes,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import {
  validateEmail,
  validatePhoneNumber,
} from 'common/src/vtj/validation/validators'
import { validateHetu } from 'common/src/vtj/validation/validators-typed'
import { findPotentialXssCharacters } from 'common/src/vtj/dom-purify-xss.util'
import localization from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/localization/localization_af.json'
import { RefinementCtx } from 'zod/lib/types'
import { ContainsEstate } from 'lupa-backend/src/vtj/elsa/lupa/lupa-enums'
import { LupaApplicationStep } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'

const superRefineForXssString = (
  value: string | null | undefined,
  ctx: RefinementCtx
) => {
  const removed = value ? findPotentialXssCharacters(value) : []
  if (removed.length) {
    ctx.addIssue({
      code: 'custom',
      message: localization.virheTekstissaOnKiellettyjaMerkkeja,
      params: { translationParams: { invalidCharacters: removed.join(' ') } },
    })
  }
}

const superRefineOpinionArgumentsOrAttachments = (
  data: { attachments: Array<object>; opinionArguments: string | null },
  ctx: RefinementCtx
) => {
  if (data.attachments.length || data.opinionArguments?.length) return

  ctx.addIssue({
    code: 'custom',
    message: localization.virheLiiteOnPakollinenTieto,
    path: ['attachments'],
  })
  ctx.addIssue({
    code: 'custom',
    message: localization.virheLiiteOnPakollinenTieto,
    path: ['opinionArguments'],
  })
}

const zodCustomPersonName = z
  .string({
    errorMap: () => ({ message: localization.virhePakollinenTietoPuuttuu }),
  })
  .min(1, { message: localization.virhePakollinenTietoPuuttuu })
  .refine((value) => !value || value.length <= 100, {
    message: localization.virheTekstinPituusYlittaaMerkkimaaran,
    params: {
      translationParams: {
        maxLength: 100,
      },
    },
  })
  .superRefine(superRefineForXssString)

const zodCustomNonEmptyAddressString = z
  .string({
    errorMap: () => ({ message: localization.virhePakollinenTietoPuuttuu }),
  })
  .min(1, { message: localization.virhePakollinenTietoPuuttuu })
  .refine((value) => !value || (value.length >= 2 && value.length <= 35), {
    message: localization.virheTekstinPituusEiSallituissaRajoissa,
    params: {
      translationParams: {
        minLength: 2,
        maxLength: 35,
      },
    },
  })
  .superRefine(superRefineForXssString)

const zodCustomOptionalAddressString = z
  .string()
  .nullable()
  .refine((value) => !value || (value.length >= 2 && value.length <= 35), {
    message: localization.virheTekstinPituusEiSallituissaRajoissa,
    params: {
      translationParams: {
        minLength: 2,
        maxLength: 35,
      },
    },
  })
  .superRefine(superRefineForXssString)

const zodCustomNonXssNonEmptyString = z
  .string({
    errorMap: () => ({ message: localization.virhePakollinenTietoPuuttuu }),
  })
  .min(1, { message: localization.virhePakollinenTietoPuuttuu })
  .superRefine(superRefineForXssString)

const zodCustomEmail = z
  .string()
  .refine((value) => (!value && value?.length === 0) || validateEmail(value), {
    message: localization.virheVirheellinenEmail,
  })
  .superRefine(superRefineForXssString)
  .optional()
  .or(z.null())

const zodCustomPhone = z
  .string()
  .refine(
    (value) => (!value && value?.length === 0) || validatePhoneNumber(value),
    {
      message: localization.virheVirheellinenPuhelin,
    }
  )
  .superRefine(superRefineForXssString)
  .optional()
  .or(z.null())

const zodCustomHetu = z
  .string({
    errorMap: () => ({ message: localization.virhePakollinenTietoPuuttuu }),
  })
  .min(1, { message: localization.virhePakollinenTietoPuuttuu })
  .refine((value) => value.length === 0 || validateHetu(value), {
    message: localization.virheVirheellinenHetu,
  })
  .superRefine(superRefineForXssString)

const convertObjectKeysToZodLiterals = (o: object) =>
  Object.keys(o).map((key) => z.literal(key)) as unknown as [
    ZodLiteral<string>,
    ZodLiteral<string>,
    ...ZodLiteral<string>[]
  ]

const AsiointiLupaTypeLiterals =
  convertObjectKeysToZodLiterals(AsiointiLupaType)

const LupaAsiointiAsiakirjaTypeLiterals = convertObjectKeysToZodLiterals(
  LupaAsiointiAsiakirjaType
)

const AsiointiAttachmentValidationSchema = z.object(
  {
    sourceFileId: zodCustomNonXssNonEmptyString,
    asiakirjaTypeId: z.union(LupaAsiointiAsiakirjaTypeLiterals),
    filename: zodCustomNonXssNonEmptyString,
    sizeBytes: z.number(),
    mimeType: z.nativeEnum(MimeType),
    status: z.literal('success', {
      errorMap: () => ({ message: localization.virheLiiteEiOleValmis }),
    }),
  },
  {
    errorMap: () => ({ message: localization.virheVirheellinenSyote }),
  }
)

const EmailValidationSchema = z
  .object({
    email: zodCustomEmail,
    emailRepeated: zodCustomEmail,
  })
  .refine(
    (data) =>
      (!data.email?.length && !data.emailRepeated?.length) ||
      data.email === data.emailRepeated,
    {
      path: ['emailRepeated'],
      message: localization.virheAnnaSamaEmailUudelleen,
    }
  )

const AsiontiPersonCommonValidationSchema = z
  .object({
    firstnames: zodCustomPersonName,
    lastname: zodCustomPersonName,
    hetu: zodCustomHetu,
    streetAddress: zodCustomNonEmptyAddressString,
    streetAddressExtra: zodCustomOptionalAddressString,
    postalCode: zodCustomNonEmptyAddressString,
    postOffice: zodCustomNonEmptyAddressString,
    countryId: zodCustomNonXssNonEmptyString,
    phone: zodCustomPhone,
  })
  .and(EmailValidationSchema)

const AsiointiHakijaWithRequiredValtakirjaValidationSchema = z.object({
  opinionTypeId: z.literal(OpinionType.EI_TIEDOSSA),
  attachments: z
    .array(
      AsiointiAttachmentValidationSchema.extend({
        asiakirjaTypeId: z.literal(LupaAsiointiAsiakirjaType.VALTAKIRJA),
      })
    )
    .min(1, { message: localization.virheLiiteOnPakollinenTieto }),
})

const AsiointiAuthenticatedHakijaValidationSchema = z.object({
  opinionTypeId: z.literal(OpinionType.EI_TIEDOSSA),
  attachments: z.array(AsiointiAttachmentValidationSchema).length(0),
})

const AsiointiPaamiesWithOpinionPrincipalOpinionValidationSchema = z.object({
  opinionTypeId: z.literal(
    OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN,
    {
      message: localization.virhePakollinenTietoPuuttuu,
    }
  ),
  attachments: z
    .array(
      AsiointiAttachmentValidationSchema.extend({
        asiakirjaTypeId: z.literal(
          LupaAsiointiAsiakirjaType.PAAMIEHEN_LAUSUNTO,
          {
            message: localization.virheLiiteOnPakollinenTieto,
          }
        ),
      })
    )
    .min(1, { message: localization.virheLiiteOnPakollinenTieto }),
})

const AsiointiPaamiesWithOpinionOtherValidationSchema = z
  .object({
    opinionTypeId: z.literal(
      OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN,
      {
        message: localization.virhePakollinenTietoPuuttuu,
      }
    ),
    attachments: z.array(
      AsiointiAttachmentValidationSchema.extend({
        asiakirjaTypeId: z.literal(
          LupaAsiointiAsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA,
          {
            message: localization.virheLiiteOnPakollinenTieto,
          }
        ),
      })
    ),
    opinionArguments: z
      .string()
      .nullable()
      .superRefine(superRefineForXssString),
  })
  .superRefine(superRefineOpinionArgumentsOrAttachments)

const AsiointiPaamiesWithoutOpinionMedicalCertificateValidationSchema =
  z.object({
    opinionTypeId: z.literal(OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN, {
      message: localization.virhePakollinenTietoPuuttuu,
    }),
    attachments: z
      .array(
        AsiointiAttachmentValidationSchema.extend({
          asiakirjaTypeId: z.literal(
            LupaAsiointiAsiakirjaType.LAAKARINLAUSUNTO,
            {
              message: localization.virheLiiteOnPakollinenTieto,
            }
          ),
        })
      )
      .min(1, { message: localization.virheLiiteOnPakollinenTieto }),
  })

const AsiointiPaamiesWithoutOpinionOtherValidationSchema = z
  .object({
    opinionTypeId: z.literal(OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN, {
      message: localization.virhePakollinenTietoPuuttuu,
    }),
    attachments: z.array(
      AsiointiAttachmentValidationSchema.extend({
        asiakirjaTypeId: z.literal(
          LupaAsiointiAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA,
          {
            message: localization.virheLiiteOnPakollinenTieto,
          }
        ),
      })
    ),
    opinionArguments: z
      .string()
      .nullable()
      .superRefine(superRefineForXssString),
  })
  .superRefine(superRefineOpinionArgumentsOrAttachments)

const AsiointiPaamiesOpinionNotValidValidationSchema = z.object({
  opinionTypeId: z.literal(
    OpinionType.PAAMIES_ALLE_15V_MIELIPIDETTA_EI_SELVITETTY,
    { errorMap: () => ({ message: localization.virhePakollinenTietoPuuttuu }) }
  ),
  // To allow better UI experience when switching opinion (no need to discard attachments)
  attachments: z.array(z.any()).min(0),
})

const AsiointiApplicationValidationSchema = z
  .object({
    typeId: z.union(AsiointiLupaTypeLiterals, {
      errorMap: () => ({ message: localization.virheVirheellinenSyote }),
    }),
    containsEstate: z.enum(
      [ContainsEstate.LIITTYY_KUOLINPESA, ContainsEstate.EI_KUOLINPESAA],
      {
        errorMap: () => ({ message: localization.virhePakollinenTietoPuuttuu }),
      }
    ),
    // Validate AsiointiAttachmentValidationSchema separately, this just a type hint for superRefine
    attachments: z.array(
      AsiointiAttachmentValidationSchema.pick({ asiakirjaTypeId: true })
    ),
  })
  .superRefine((data, ctx) => {
    const requiredAsiakirjaTypes = getRequiredAsiakirjaTypes(
      data as Pick<AsiointiApplication, 'typeId' | 'containsEstate'>
    )
    const actualAsiakirjaTypes = data.attachments.map((a) => a.asiakirjaTypeId)
    const missingAsiakirjaTypes = requiredAsiakirjaTypes.filter(
      (asiakirjaType) => actualAsiakirjaTypes.indexOf(asiakirjaType) < 0
    )
    missingAsiakirjaTypes.forEach((asiakirjaType) =>
      ctx.addIssue({
        code: 'custom',
        message: localization.virheLiiteOnPakollinenTieto,
        path: ['attachments'],
        params: { asiakirjaTypeId: asiakirjaType },
      })
    )
  })

export const AsiointiBatchValidationSchema = z.object({
  description: zodCustomNonXssNonEmptyString,
  arguments: zodCustomNonXssNonEmptyString,
  applications: z
    .array(z.object({ typeId: z.union(AsiointiLupaTypeLiterals) }))
    .min(1, { message: localization.virheLupaAsiaaEiOleValittu }),
})

const isLupaAsiointiAsiakirjaTypeId = (
  value: string | null | undefined
): value is LupaAsiointiAsiakirjaTypeId =>
  !!value && Object.keys(LupaAsiointiAsiakirjaType).indexOf(value) >= 0

const resolveIssueFieldByPath = (issue: ZodIssue): string => {
  const field = issue.path?.[0]?.toString()
  if (!field) throw new Error('cannot resolve issue path')
  return field
}

const issueToFieldValidationError = (
  field: string,
  issue: ZodIssue
): FieldValidationError => ({
  field,
  ...(issue.code === 'custom' && issue.params?.translationParams
    ? { translationParams: issue.params.translationParams }
    : {}),
  translationKey: issue.message,
})

const removeDuplicatesByField = <T extends object>(
  errors: T[],
  field: keyof T
): T[] =>
  Object.values(
    errors.reduce((acc, error) => {
      acc[error[field] as string] = error
      return acc
    }, {} as Record<string, T>)
  )

const resolvePersonErrors = async (
  person: AsiointiPerson
): Promise<FieldValidationError[]> => {
  const attachmentsSchema = (() => {
    if (person.applicationRoleId === LupaApplicationRole.HAKIJA) {
      if (person.isAuthenticatedUser) {
        return AsiointiAuthenticatedHakijaValidationSchema
      } else {
        return AsiointiHakijaWithRequiredValtakirjaValidationSchema
      }
    } else {
      if (
        person.opinionTypeId === OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN
      ) {
        if (
          person.opinionAttachmentAsiakirjaTypeId ===
          LupaAsiointiAsiakirjaType.LAAKARINLAUSUNTO
        ) {
          return AsiointiPaamiesWithoutOpinionMedicalCertificateValidationSchema
        } else {
          return AsiointiPaamiesWithoutOpinionOtherValidationSchema
        }
      } else if (
        person.opinionTypeId ===
        OpinionType.HAKIJA_ON_SELVITTANYT_PAAMIEHEN_MIELIPITEEN
      ) {
        if (
          person.opinionAttachmentAsiakirjaTypeId ===
          LupaAsiointiAsiakirjaType.PAAMIEHEN_LAUSUNTO
        ) {
          return AsiointiPaamiesWithOpinionPrincipalOpinionValidationSchema
        } else {
          return AsiointiPaamiesWithOpinionOtherValidationSchema
        }
      } else {
        return AsiointiPaamiesOpinionNotValidValidationSchema
      }
    }
  })()

  const validationErrors: FieldValidationError[] = []
  const commonResult = await AsiontiPersonCommonValidationSchema.safeParseAsync(
    person
  )
  if (!commonResult.success) {
    validationErrors.push(
      ...commonResult.error.issues.map((issue) =>
        issueToFieldValidationError(resolveIssueFieldByPath(issue), issue)
      )
    )
  }

  const attachmentsResult = await attachmentsSchema.safeParseAsync(person)
  if (!attachmentsResult.success) {
    validationErrors.push(
      ...attachmentsResult.error.issues.map((issue) =>
        issueToFieldValidationError(resolveIssueFieldByPath(issue), issue)
      )
    )
  }
  return removeDuplicatesByField(validationErrors, 'field')
}

const resolveApplicationErrors = async (
  application: AsiointiApplication
): Promise<AsiakirjaValidationError[]> => {
  const validationErrors: AsiakirjaValidationError[] = []
  const applicationResult =
    await AsiointiApplicationValidationSchema.safeParseAsync(application)
  if (!applicationResult.success) {
    applicationResult.error.issues.forEach((issue) => {
      if (issue.code === 'custom') {
        const asiakirjaTypeId = issue.params?.asiakirjaTypeId
        if (isLupaAsiointiAsiakirjaTypeId(asiakirjaTypeId)) {
          validationErrors.push({
            translationKey: issue.message,
            asiakirjaTypeId,
          })
        }
      }
    })
  }
  const expectedAsiakirjaTypes = new Set(getExpectedAsiakirjaTypes(application))
  for (const attachment of application.attachments.filter((att) =>
    expectedAsiakirjaTypes.has(att.asiakirjaTypeId)
  )) {
    const attachmentResult =
      await AsiointiAttachmentValidationSchema.safeParseAsync(attachment)
    if (!attachmentResult.success) {
      attachmentResult.error.issues.forEach((issue) => {
        const { asiakirjaTypeId } = attachment
        validationErrors.push({
          translationKey: issue.message,
          asiakirjaTypeId,
        })
      })
    }
  }
  return removeDuplicatesByField(validationErrors, 'asiakirjaTypeId')
}

const resolveBatchErrors = async (
  batch: AsiointiBatch
): Promise<FieldValidationError[]> => {
  const batchResult = await AsiointiBatchValidationSchema.safeParseAsync(batch)
  const validationErrors: FieldValidationError[] = []
  if (!batchResult.success) {
    validationErrors.push(
      ...batchResult.error.issues.map((issue) =>
        issueToFieldValidationError(resolveIssueFieldByPath(issue), issue)
      )
    )
  }
  return removeDuplicatesByField(validationErrors, 'field')
}

export const validateBatch = (): Promise<boolean> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    const { batch } = store
    const batchValidationErrors = yield resolveBatchErrors(batch)
    batch.validationErrors.splice(0, batch.validationErrors.length)
    store.validationFailedForSteps.clear()
    if (batchValidationErrors.length) {
      batch.validationErrors.push(...batchValidationErrors)
    }
    for (const error of batch.validationErrors) {
      if (error.field === 'applications') {
        store.validationFailedForSteps.add(LupaApplicationStep.LUPA_TYPE_SELECT)
      } else {
        store.validationFailedForSteps.add(LupaApplicationStep.INPUT_INFO)
      }
    }

    for (const person of batch.persons) {
      const personValidationErrors = yield resolvePersonErrors(person)
      person.validationErrors.splice(0, person.validationErrors.length)
      const duplicateHetu = !!batch.persons.find(
        ({ hetu, personId }) =>
          !person.isAuthenticatedUser &&
          hetu &&
          hetu === person.hetu &&
          personId !== person.personId
      )
      if (duplicateHetu) {
        person.validationErrors.push({
          field: 'hetu',
          translationKey: 'virheSamaHetuMontaKertaa',
        })
      }
      if (personValidationErrors.length) {
        person.validationErrors.push(...personValidationErrors)
        store.validationFailedForSteps.add(LupaApplicationStep.INPUT_PERSONS)
      }
    }

    for (const application of batch.applications) {
      const applicationValidationErrors = yield resolveApplicationErrors(
        application
      )
      application.validationErrors.splice(
        0,
        application.validationErrors.length
      )
      if (applicationValidationErrors.length) {
        application.validationErrors.push(...applicationValidationErrors)
        store.validationFailedForSteps.add(LupaApplicationStep.INPUT_INFO)
      }
    }
    return !store.validationFailedForSteps.size
  })
