import { executeBackendClient } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { AsiointiProductCatalog } from 'lupa-backend/src/vtj/asiointi/product-catalog/asiointi-product-catalog-api.type'

export const getCurrentProductCatalog =
  async (): Promise<AsiointiProductCatalog> => {
    const apiResponse = await executeBackendClient<AsiointiProductCatalog>(
      (client) => client.get(`/luvat/asiointi/api/v1/product-catalog/current`)
    )
    if (apiResponse.isOk) {
      return apiResponse.data
    } else {
      throw new Error(
        'Failed loading product catalog: ' +
          JSON.stringify(apiResponse.errorData)
      )
    }
  }
