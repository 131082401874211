import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'

import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import {
  OMAISUUSLUETTELO_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import PankkilainatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/velat/PankkilainatForm'
import TakauksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/velat/TakauksetForm'
import MuutVelatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/velat/MuutVelatForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const VelatPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading heading={t('velat')} steps={OMAISUUSLUETTELO_STEPS} />
        <FormErrorSummary ref={onErrorRef} />

        <Paragraph>{t('velatInfoText1')}</Paragraph>
        <Paragraph mt="s">{t('velatInfoText2')}</Paragraph>

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <PankkilainatForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <TakauksetForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <MuutVelatForm />
        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default VelatPage
