import { Paragraph } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { mkKayttovaratTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  OMAISUUSLUETTELO_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import MuutVaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/MuutVaratForm'
import PankkitilitForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/OmassaKaytossaPankkitilitForm'
import KayttovaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/KayttovaratForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const OmaisuusluetteloKayttovaratPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const onErrorRef = useRef<HTMLDivElement>(null)
  const { isPrincipalUnderaged } = getTilintarkastusStore()

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock
        data-test-id={mkKayttovaratTestId('omaisuusluettelo-page')}
      >
        <PageStepHeading
          heading={t('kayttovarat')}
          steps={OMAISUUSLUETTELO_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <Paragraph mb={verticalMarginToken}>
          {t('ohjeKayttovaratInfo1')}
        </Paragraph>
        {!isPrincipalUnderaged && (
          <>
            <Paragraph mb={verticalMarginToken}>
              {t('ohjeKayttovaratInfo2')}
            </Paragraph>
            <Paragraph mb={verticalMarginToken}>
              {t('ohjeKayttovaratInfo3')}
            </Paragraph>
          </>
        )}

        <DividerLine mb={verticalMarginToken} />
        {!isPrincipalUnderaged && (
          <>
            <KayttovaratForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          </>
        )}

        <PankkitilitForm />

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <MuutVaratForm />

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default OmaisuusluetteloKayttovaratPage
