import { AsiaServiceAreaId, AsiaServiceArea, ServiceAreaAsiaTypes } from 'asia-common/src/vtj/koodisto/asia-enums'
import { AsiakirjaTypeId, AsiakirjaOriginTypeId } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { asiaTypeMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { MarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import { RetentionId, RetentionMetadata, RetentionReasonId } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClassId,
  PublicityClassId,
  RestrictionMetadata,
  SecurityClassId,
  SecurityPeriodId,
  SecurityReasonId,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { ViranomainenId } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

export interface AsiakirjaMetadata {
  asiakirjaTypeId: AsiakirjaTypeId
  restrictionMetadata: RestrictionMetadata
  retentionMetadata: RetentionMetadata
  defaultOriginTypeId: AsiakirjaOriginTypeId
  originTypeIds: AsiakirjaOriginTypeId[]
  toivoAsiakirjaTypeIds?: string[]
  defaultToivoAsiakirjaTypeId?: string
  markingMetadata?: MarkingMetadata[]
}

export type DefaultAsiakirjaTypeMetadata = {
  originTypeId: AsiakirjaOriginTypeId
  viranomainenId: ViranomainenId
  securityClassId: SecurityClassId
  publicityClassId: PublicityClassId
  personalDataClassId: PersonalDataClassId
  securityPeriodId: SecurityPeriodId | null
  securityReasonIds: SecurityReasonId[]
  retentionId: RetentionId
  retentionReasonId: RetentionReasonId
  toivoAsiakirjaTypeId: string | null
}

const getDistinctAsiaTypeAsiakirjaTypes = (serviceAreaId: AsiaServiceAreaId) =>
  ServiceAreaAsiaTypes[serviceAreaId]
    .map((at) => asiaTypeMetadata[at].asiakirjaMetadata)
    .flat()
    .reduce((a, b) => {
      if (!a.map((at) => at.asiakirjaTypeId).includes(b.asiakirjaTypeId)) {
        a.push(b)
      }
      return a
    }, [] as AsiakirjaMetadata[])

// Service areas should use asiaTypeMetadata from `asia-type-metadata.ts`
// Asia types can have different metadata and using this old service area metadata
// can lead to errors in asianhallinta sync
export const asiakirjaMetadataDeprecated: {
  [k in AsiaServiceAreaId]: AsiakirjaMetadata[]
} = {
  [AsiaServiceArea.TODISTUKSET]: getDistinctAsiaTypeAsiakirjaTypes(AsiaServiceArea.TODISTUKSET),
  [AsiaServiceArea.NIMIASIAT]: getDistinctAsiaTypeAsiakirjaTypes(AsiaServiceArea.NIMIASIAT),
  [AsiaServiceArea.LUVAT]: getDistinctAsiaTypeAsiakirjaTypes(AsiaServiceArea.LUVAT),
  [AsiaServiceArea.TILINTARKASTUS]: getDistinctAsiaTypeAsiakirjaTypes(AsiaServiceArea.TILINTARKASTUS),
  [AsiaServiceArea.VANHEMMUUDEN_VAHVISTAMINEN]: getDistinctAsiaTypeAsiakirjaTypes(
    AsiaServiceArea.VANHEMMUUDEN_VAHVISTAMINEN
  ),
  [AsiaServiceArea.ULKOMAALAISEN_REKISTEROINTI]: getDistinctAsiaTypeAsiakirjaTypes(
    AsiaServiceArea.ULKOMAALAISEN_REKISTEROINTI
  ),
  [AsiaServiceArea.EDUNVALVONTAVALTUUTUS_JA_EDUNVALVONNAN_ALKAMINEN]: getDistinctAsiaTypeAsiakirjaTypes(
    AsiaServiceArea.EDUNVALVONTAVALTUUTUS_JA_EDUNVALVONNAN_ALKAMINEN
  ),
  [AsiaServiceArea.ELAMANTAPAHTUMIEN_REKISTEROINTI]: getDistinctAsiaTypeAsiakirjaTypes(
    AsiaServiceArea.ELAMANTAPAHTUMIEN_REKISTEROINTI
  ),
  [AsiaServiceArea.MUUTOT]: getDistinctAsiaTypeAsiakirjaTypes(AsiaServiceArea.MUUTOT),
  [AsiaServiceArea.MUUT_ASIAT]: getDistinctAsiaTypeAsiakirjaTypes(AsiaServiceArea.MUUT_ASIAT),
}
