import {
  AsiointiHenkilo,
  AsiointiSeurantaAsiavaatimus,
  LongIsoDateString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv/evtv-api.type'
import { flow, observable, runInAction } from 'mobx'
import * as evtvApi from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/evtv-api-client'
import * as DateFns from 'date-fns/fp'

export interface EvtvStore {
  vaatimukset: AsiointiSeurantaAsiavaatimus[]
  henkilot: AsiointiHenkilo[]
  valittuVaatimus?: AsiointiSeurantaAsiavaatimus
  valittuHenkilo?: AsiointiHenkilo
  isLoading: boolean
  isInitialized: boolean
  isError: boolean
}

let evtvStore: EvtvStore = observable({
  vaatimukset: [],
  henkilot: [],
  valittuVaatimus: undefined,
  valittuHenkilo: undefined,
  isLoading: false,
  isInitialized: false,
  isError: false,
})

export function createEvtvStore(data: EvtvStore): EvtvStore {
  evtvStore = observable(data)
  return evtvStore
}

export const useVaatimukset = (): AsiointiSeurantaAsiavaatimus[] => {
  return evtvStore.vaatimukset
}

export const useHenkilot = (): AsiointiHenkilo[] => {
  return evtvStore.henkilot
}

export const useEvtvStore = (): EvtvStore => {
  return evtvStore
}

export const getValittuHenkilo = (): AsiointiHenkilo | undefined => {
  return evtvStore.valittuHenkilo
}

export const useValittuHenkilo = (): AsiointiHenkilo => {
  const paamies = evtvStore.valittuHenkilo
  if (!paamies) {
    throw new Error('evtvStore.valittuHenkilo not initialized')
  }
  return paamies
}

export const clearValittuPaamies = (): void => {
  runInAction(() => {
    evtvStore.valittuHenkilo = undefined
  })
}

export const useValittuEvtvVaatimus = (): AsiointiSeurantaAsiavaatimus => {
  const vaatimus = evtvStore.valittuVaatimus
  if (!vaatimus) {
    throw new Error('evtvStore.valittuVaatimus not initialized')
  }
  return vaatimus
}

export const valitseVaatimus = (
  seurantaAsiaVaatimusId: string
): AsiointiSeurantaAsiavaatimus => {
  const vaatimus = evtvStore.vaatimukset?.find(
    (vaatimus) => vaatimus.seurantaAsiavaatimusId === seurantaAsiaVaatimusId
  )
  if (!vaatimus) {
    throw new Error(`Missing evtv vaatimus ${seurantaAsiaVaatimusId}`)
  }
  runInAction(() => {
    evtvStore.valittuVaatimus = vaatimus
  })
  return vaatimus
}

export const initializeEvtvStore = async (): Promise<void> => {
  if (!evtvStore.isInitialized) {
    await fetchEvtvDataIntoStore()
  }
}

export const fetchEvtvDataIntoStore = flow(function* () {
  evtvStore.isLoading = true
  evtvStore.isError = false
  const { fetchSuccess, vaatimukset, henkilot } = yield evtvApi.getEvtvData()
  evtvStore.vaatimukset.splice(0, evtvStore.vaatimukset.length)
  evtvStore.vaatimukset.push(...vaatimukset)
  evtvStore.henkilot.splice(0, evtvStore.henkilot.length)
  evtvStore.henkilot.push(...henkilot)
  evtvStore.valittuVaatimus = undefined
  evtvStore.isError = !fetchSuccess
  evtvStore.isLoading = false
  evtvStore.isInitialized = true
  return evtvStore
})

export const valitseHenkiloByPersonId = (
  personId: string
): AsiointiHenkilo | undefined => {
  if (evtvStore.henkilot?.length) {
    const henkilo = evtvStore.henkilot.find(
      (henkilo) => henkilo.personId === personId
    )
    if (henkilo) {
      runInAction(() => {
        evtvStore.valittuHenkilo = henkilo
      })
      return evtvStore.valittuHenkilo
    }
  }
}

// TODO refactor these date utils here below do not belong here
//  -> move somewhere else
export const IsoDatetimeToUiDate = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    const parsedDate = DateFns.parseISO(date)
    return DateFns.format('d.M.yyyy', parsedDate)
  }
  return ''
}

export const IsoDatetimeToUiDatetime = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    const parsedDate = DateFns.parseISO(date)
    return DateFns.format('d.M.yyyy HH:mm', parsedDate)
  }
  return ''
}

export const displayDateToIsoString = (
  value: string | null
): LongIsoDateString => {
  const date = DateFns.parse(new Date(), 'd.M.yyyy', value)
  return date.toISOString() as LongIsoDateString
}
