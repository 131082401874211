import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import {
  InternalToimenpideType,
  ToimenpideHuomioPhase,
  ToimenpideType,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import {
  lupa10VuottaRetention,
  lupa50VuottaRetention,
  lupaJulkinenEiHenkilotietojaRestriction,
  lupaJulkinenHenkilotietojaRestriction,
  lupaOsittainSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
  lupaPysyvaRetention,
  lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  lupaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  lupaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
  lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/luvat/common-lupa-metadata'

export const perinnonjakoMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ARVIO_OMAISUUDESTA,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['09be6cc7-5ef0-4063-8a56-323ea66114b8', '0d159a82-1d85-4a90-b2d5-57956561d17d'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.AVIOEHTOSOPIMUS,
      restrictionMetadata: lupaOsittainSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ESISOPIMUS,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ESITTELIJAN_ERIAVA_MIELIPIDE,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: lupaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_ASIAN_RAUKEAMISESTA,
      restrictionMetadata: lupaJulkinenHenkilotietojaRestriction,
      retentionMetadata: lupaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['40bb8731-8991-4afc-b949-842641f00fda'],
      defaultToivoAsiakirjaTypeId: '40bb8731-8991-4afc-b949-842641f00fda',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ISANNOITSIJANTODISTUS,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['51fd09e3-986a-4ad1-9840-023a637b2030'],
      defaultToivoAsiakirjaTypeId: '51fd09e3-986a-4ad1-9840-023a637b2030',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KIINTEISTOREKISTERIN_OTE,
      restrictionMetadata: lupaJulkinenHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a84621ae-3b97-44cb-801a-b40e9b79a9f6'],
      defaultToivoAsiakirjaTypeId: 'a84621ae-3b97-44cb-801a-b40e9b79a9f6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['7a08c02b-6ea5-420d-9993-c4b9e963e40f'],
      defaultToivoAsiakirjaTypeId: '7a08c02b-6ea5-420d-9993-c4b9e963e40f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAINHUUTOTODISTUS,
      restrictionMetadata: lupaJulkinenHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['7dd12156-59cb-4d1b-a2e9-b242cfe8c0c1'],
      defaultToivoAsiakirjaTypeId: '7dd12156-59cb-4d1b-a2e9-b242cfe8c0c1',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LUPAHAKEMUS,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['d6e63651-146e-4b55-ac4d-c9ae9014f911'],
      defaultToivoAsiakirjaTypeId: 'd6e63651-146e-4b55-ac4d-c9ae9014f911',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: [
        'e9df1bad-e4a8-48e7-8d6c-a646d1a975b4',
        '85c16bc5-d9f8-4ede-8fcf-e4dff98c9d59',
        '1f7ca3dd-d2da-4dae-8cd7-9efd1da8b661',
        'f9e72eb2-3773-46c0-b98f-96dd0f606cb6',
        '99d3ce3a-0fbd-42d8-9510-52f8f117d4d6',
        '0afe7ff0-f837-45bd-91c7-4acf49c41d9c',
        '89dad047-08fa-4a4d-9960-254846480830',
        '121c1ddd-c03c-4d15-be14-f6ab1af79aa0',
        '8bc3dcf2-4b6b-423f-8089-e1cf02793320',
        'a8d9b866-4b43-4e90-aeb5-1c8e63ee2deb',
        'd90268d4-89ba-40a6-b560-099a5fdce9a4',
        '689a3310-c059-482b-a9a8-cdfe468009f8',
        '29292c0e-76ae-4e67-b1d4-8226b5a0d30a',
        '46d3ec4b-7740-4ac3-a92f-4de46d9603e4',
        '0f33ccf1-21bc-4b92-900c-81b1a872dacc',
        '7a57d87a-81bc-4c07-ae19-611762172769',
        'ee8c9e53-5005-4dfe-a805-4ce5309a3677',
        'd49d5f2d-88d6-4991-9984-18b1c5885094',
        'dce9ba7b-5053-4ad3-83ec-2eb5d7089873',
        'a2285953-50e2-4a6c-93d5-d9fc534f5b45',
        '93c1fa71-7b00-4bc8-aa6a-b1507fe3d7be',
        'a716a2be-b545-4835-b1bf-c13e0f7153d1',
        'c48d0418-3021-4470-932c-4b517d747847',
        'ca916acf-0e29-4357-8453-ca78d1ac49ba',
        '317d0d25-84c4-4f88-a983-34b986433c72',
        'd30e96df-b185-4de8-a4b9-ad9fc2d67042',
        'df496da0-8b7e-4018-ac92-8f1aa5b41032',
        '0a1bfcea-ae26-4f8b-bb48-04d97f969cdc',
        '704fac47-119a-402f-a7ac-1a92ae4f769f',
        'dda7c1be-f231-461b-8dbb-08adf458965f',
        '9abb90d5-1e00-4e2a-9210-7c1b0d83e221',
        'e1ebff63-dd83-40b3-bada-4de6ca275cd7',
      ],
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_SELVITYS_PAAMIEHEN_MIELIPITEEN_SELVITTAMISESTA,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAAMIEHEN_LAUSUNTO,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['284b1734-f075-419b-be22-9c0a8068adbb', '84de23c1-ffc9-43f3-8415-b4a60bb4aa8f'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: lupaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      defaultToivoAsiakirjaTypeId: 'f56204ed-91e3-4967-a572-683a7ae388e9',
      toivoAsiakirjaTypeIds: ['f56204ed-91e3-4967-a572-683a7ae388e9'],
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['42112502-e659-44ee-83f3-b784c3434d2d'],
      defaultToivoAsiakirjaTypeId: '42112502-e659-44ee-83f3-b784c3434d2d',
    },
    {
      asiakirjaTypeId: AsiakirjaType.RASITUSTODISTUS,
      restrictionMetadata: lupaJulkinenHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['eddff561-c25f-42c2-963a-e58b416900cc'],
      defaultToivoAsiakirjaTypeId: 'eddff561-c25f-42c2-963a-e58b416900cc',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS_OMAISUUDEN_MYYNNISTA,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SOPIMUS,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: [
        '73da2348-e2eb-4f52-8626-ed5969fee31b',
        'fb918b20-5c90-4215-8eb2-a232b885c499',
        '0f0abc7d-9b66-4058-8ed1-72a940cc0d6b',
      ],
    },
    {
      asiakirjaTypeId: AsiakirjaType.SOPIMUSLUONNOS,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['47b0f685-ad7b-4097-9158-f46b14c567f2'],
      defaultToivoAsiakirjaTypeId: '47b0f685-ad7b-4097-9158-f46b14c567f2',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    },
    {
      asiakirjaTypeId: AsiakirjaType.TESTAMENTTI,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6350636a-06cf-4593-a42e-4e21e9e42250'],
      defaultToivoAsiakirjaTypeId: '6350636a-06cf-4593-a42e-4e21e9e42250',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILITAPAHTUMAT,
      restrictionMetadata: lupaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['41bc29ae-0cf9-4d43-98a4-fd32b75e6140'],
      defaultToivoAsiakirjaTypeId: '41bc29ae-0cf9-4d43-98a4-fd32b75e6140',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALITUSOSOITUS,
      restrictionMetadata: lupaJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: lupa10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VASTAUS_KUULEMISEEN,
      restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VUOKRAOIKEUSTODISTUS,
      restrictionMetadata: lupaJulkinenHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALTAKIRJA,
      restrictionMetadata: lupaJulkinenHenkilotietojaRestriction,
      retentionMetadata: lupa50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA,
      postDueDateHuomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.HAKIJA],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN, AsiaPersonType.ORGANISAATIO],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.MYONTEINEN_PAATOS,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.TUTKIMATTAJATTAMISPAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.YHDISTETTY_TOISEEN_ASIAAN,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [SpecifierType.KV_ASIA, SpecifierType.EI_LUVANVARAINEN_LUPA_ASIA],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.EDVARD, ViestintaType.MANUAALINEN, ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: {
        [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
        [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
        [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
        showInHuomioFilter: true,
      },
    },
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ITSEPALVELU, Vireilletulokanava.ELSA, Vireilletulokanava.EDVARD],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.VALMISTELTAVANA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMISTELTAVANA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.ESITTELIJA,
      defaultStartableStatusId: AsiaStatus.RATKAISUEHDOTUS_ANNETTU,
      defaultMovableStatusId: AsiaStatus.RATKAISTAVANA,
    },
    {
      asiaStatusId: AsiaStatus.RATKAISUEHDOTUS_ANNETTU,
      toimenpideTypeId: InternalToimenpideType.PAATOKSENTEKO,
      handlerRoleId: AsiaHandlerRole.ESITTELIJA,
      defaultStartableStatusId: AsiaStatus.RATKAISTAVANA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.RATKAISTAVANA,
      toimenpideTypeId: InternalToimenpideType.PAATOKSENTEKO,
      handlerRoleId: AsiaHandlerRole.RATKAISIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.VALMISTELTAVANA,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
  restrictionMetadata: lupaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  retentionMetadata: lupaPysyvaRetention,
}
