export const TimeoutErrors = {
  // default error code
  ECONNABORTED: 'ECONNABORTED',
  // axios will throw this error code instead if request is configured with `transitional.clarifyTimeoutError: true`
  ETIMEDOUT: 'ETIMEDOUT',
}

export const isTimeoutErrorCode = (
  code: string | null | undefined
): boolean => {
  if (!code) {
    return false
  }

  return Object.values(TimeoutErrors).some(
    (timeoutErrorCode) => code === timeoutErrorCode
  )
}
