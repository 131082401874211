import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  getHuoneistotContent,
  getIrtaimistoContent,
  getKiinteistotContent,
  getKuolinpesatContent,
  getMuutVaratContent,
  getOsuudetHenkiloyhtioissaContent,
  getPankkitalletuksetContent,
  getSaatavatVaratContent,
  getVaratContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/varat.util'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const Varat: React.FC = observer(() => {
  const [t] = useTranslation()
  const { tili, lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('varat-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('varat')}
      </Heading>
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('talletuksetHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['talletukset']
            ? getPankkitalletuksetContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('arvopaperitHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['arvopaperit']
            ? getVaratContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('osuudetYhtioissaYrityksissaHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['osuudetYhtioissaYrityksissa']
            ? getOsuudetHenkiloyhtioissaContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('huoneistotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['huoneistot']
            ? getHuoneistotContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('kiinteistotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['kiinteistot']
            ? getKiinteistotContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('irtaimistotHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['irtaimistot']
            ? getIrtaimistoContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('saatavatHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['saatavat']
            ? getSaatavatVaratContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('muutVaratHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['muutVarat']
            ? getMuutVaratContent(tili, t)
            : []
        }
      />
      <Heading variant="h4" mb={verticalMarginToken}>
        {t('osakkuudetKuolinpesissa')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['kuolinpesaOsakkuudet']
            ? getKuolinpesatContent(tili, t)
            : []
        }
      />
    </section>
  )
})

export default Varat
