import styled from 'styled-components'
import { focusOutline } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Block, Heading, suomifiDesignTokens } from 'suomifi-ui-components'

export const ValmisBlock = styled(Block)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: ${suomifiDesignTokens.spacing.insetL};
`

export const FocusableHeading = styled(Heading)`
  ${focusOutline};
`

export const ButtonBlock = styled(Block)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${suomifiDesignTokens.spacing.xs};
`

export const ErrorsBulletedList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
`
