import { runTilintarkastusStoreAction } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { FrontCaretaker } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import { getCountryStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/country.store'

export const getOmatTiedot = (): FrontCaretaker =>
  runTilintarkastusStoreAction(({ lomake }) => {
    return lomake.caretaker
  })

export const updateOmatTiedot = (
  values: Partial<Omit<FrontCaretaker, 'firstnames' | 'lastname'>>
): void =>
  runTilintarkastusStoreAction(({ lomake }) => {
    Object.assign(lomake.caretaker, values)
  })

export const getOmatTiedotCountryShortName = (
  lang: HolhousAsiointiLanguageCode
): string => {
  const countryId = getOmatTiedot().address.countryId || COUNTRY_CODE_FINLAND
  const found = getCountryStore().countries.find(
    (c) => c.countryId === countryId
  )?.shortName[lang]

  if (!found) {
    throw new Error('Unrecognized country ' + countryId)
  }
  return found
}
