export type ChildTestId = string | number

export const mkTestId = (...ids: ChildTestId[]): string => {
  return `${['tili-asiointi', ...ids].join('-')}`
}

export const mkAloitussivuTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('aloitus', ...ids)
}

export const mkKeskeytaHakemusTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('keskeyta-modal', ...ids)
}

export const mkTallennaModalTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('tallenna-modal', ...ids)
}

export const mkLahetaTiliTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('laheta-tili', ...ids)
}

export const mkEdunvalvontaValtakirjaTestId = (
  ...ids: ChildTestId[]
): string => {
  return mkTestId('edunvalvonta-valtakirja', ...ids)
}

export const mkYleiskatsausTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('yleiskatsaus', ...ids)
}

export const mkTiedoksiantoTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('tiedoksianto', ...ids)
}

export const mkOmatTiedotTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('omat-tiedot', ...ids)
}

export const mkPalkkioTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('palkkio', ...ids)
}

export const mkOmaisuusTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('omaisuus', ...ids)
}

export const mkYhteystiedotTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('yhteystiedot', ...ids)
}

export const mkTulotMenotTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('tulotMenot', ...ids)
}

export const mkYhteenvetoTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('yhteenveto', ...ids)
}

export const mkValmisTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('valmis', ...ids)
}

export const mkAlasivuNavigationTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('alasivu-navigation', ...ids)
}

export const mkToimintakykyAsuminenTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('toimintakyky-asuminen', ...ids)
}

export const mkToimintakykyTestTd = (...ids: ChildTestId[]): string => {
  return mkTestId('toimintakyky', ...ids)
}

export const mkHoitosuunnitelmaTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('hoitosuunnitelma', ...ids)
}

export const mkAsuminenFormTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('asuminen', ...ids)
}

export const mkAlaikainenAsuminenFormTestId = (
  ...ids: ChildTestId[]
): string => {
  return mkTestId('asuminen-alaikainen', ...ids)
}

export const mkAsuinpaikkaTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('asuinpaikka', ...ids)
}

export const mkVarat = (...ids: ChildTestId[]): string => {
  return mkTestId('varat', ...ids)
}

export const mkElakkeetEtuudetTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('elakkeetEtuudet', ...ids)
}

export const mkKayttovaratTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('kayttovarat', ...ids)
}

export const mkMuutVaratTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('muut-varat', ...ids)
}

export const mkSopimuksetVakuutuksetTestId = (
  ...ids: ChildTestId[]
): string => {
  return mkTestId('sopimukset-ja-vakuutukset', ...ids)
}
export const mkMuutMerkittavatToimet = (...ids: ChildTestId[]): string => {
  return mkTestId('muut-merkittavat-toimet', ...ids)
}

export const mkTestamenttiTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('testamentti', ...ids)
}

export const mkVirheyhteenvetoTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('virheyhteenveto', ...ids)
}

export const mkPageStepHeadingTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('page-step-heading', ...ids)
}

export const mkUncompletedStepsSummaryTestId = (
  ...ids: ChildTestId[]
): string => {
  return mkTestId('uncompleted-steps-summary', ...ids)
}

export const mkWizardNavigationTestId = (...ids: ChildTestId[]): string => {
  return mkTestId('wizard-navigation', ...ids)
}
