import React, { useRef } from 'react'
import { observer } from 'mobx-react'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FormProvider } from 'react-hook-form'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { Block, Heading, Text } from 'suomifi-ui-components'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import { useTranslation } from 'react-i18next'
import {
  PAATOSTILI_STEPS,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import PankkiTalletuksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/PankkiTalletuksetForm'
import ArvopaperitForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/ArvopaperitForm'
import OsuudetYhtioissaYrityksissaForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/OsuudetYhtioissaYrityksissaForm'
import HuoneistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/HuoneistotForm'
import KiinteistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/KiinteistotForm'
import IrtaimistotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/IrtaimistotForm'
import SaatavatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/SaatavatForm'
import MuutVaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/MuutVaratForm'
import KuolinpesaOsakkuudetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/varat/KuolinpesaOsakkuudetForm'
import MuutVelatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/velat/MuutVelatForm'
import PankkilainatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/velat/PankkilainatForm'
import TakauksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/velat/TakauksetForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'

const PaatostiliOmaisuusPage: React.FC<{ form: TilintarkastusForm }> = observer(
  ({ form }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'
    const onErrorRef = useRef<HTMLDivElement>(null)

    return (
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkOmaisuusTestId('container')}
        >
          <PageStepHeading
            heading={t('omaisuusJaVelat')}
            steps={PAATOSTILI_STEPS}
          />
          <FormErrorSummary ref={onErrorRef} />
          <Text>{t('omaisuusInfoText')}</Text>
          <Block mt={verticalMarginToken} />
          <DividerLine mt={verticalMarginToken} />
          <Block mt={verticalMarginToken} />
          <section data-test-id={mkOmaisuusTestId('varat')}>
            <Block mt={verticalMarginToken} />
            <Heading variant="h2">{t('varat')}</Heading>
            <Block mt={verticalMarginToken} />
            <PankkiTalletuksetForm />
            <Block mt={verticalMarginToken} />
            <ArvopaperitForm />
            <Block mt={verticalMarginToken} />
            <OsuudetYhtioissaYrityksissaForm />
            <Block mt={verticalMarginToken} />
            <HuoneistotForm />
            <Block mt={verticalMarginToken} />
            <KiinteistotForm />
            <Block mt={verticalMarginToken} />
            <IrtaimistotForm />
            <Block mt={verticalMarginToken} />
            <SaatavatForm />
            <Block mt={verticalMarginToken} />
            <MuutVaratForm />
            <Block mt={verticalMarginToken} />
            <KuolinpesaOsakkuudetForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          </section>
          <section data-test-id={mkOmaisuusTestId('velat')}>
            <Block mt={verticalMarginToken} />
            <Heading variant="h2">{t('velat')}</Heading>
            <Block mt={verticalMarginToken} />
            <PankkilainatForm />
            <Block mt={verticalMarginToken} />
            <TakauksetForm />
            <Block mt={verticalMarginToken} />
            <MuutVelatForm />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          </section>
          <FormNavigationBar onErrorRef={onErrorRef} />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    )
  }
)

export default PaatostiliOmaisuusPage
