import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTili,
  getTilirivit,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  AsiointiType,
  FrontKuolinpesaOsakkuus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonDateValidator,
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import {
  formatDateAsLocal,
  formatNumberWithComma,
  parseLocalDate,
  parseStringAsNumberWithDigits,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormDateInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDateInput'
import { AsiointiLedgerAccountCategory } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import {
  IsoDatetimeToUiDate,
  useValittuEvtvVaatimus,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const KuolinpesaOsakkuudetForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const omaisuus = getTilirivit()
  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  const mkNewEntry = (): FrontKuolinpesaOsakkuus =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: AsiointiLedgerAccountAssetsType.OSUUDET_KUOLINPESISSA,
      vainajanEtunimi: '',
      vainajanSukunimi: '',
      vainajanKuolinpaiva: undefined,
      osakkuudenOsuus: '',
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('kuolinpesaOsakkuudet-form')}>
      <Block mt={verticalMarginToken} />
      <Heading variant="h3">{t('kuolinpesaOsakkuudetHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaKuolinpesaOsakkuudet')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={KuolinpesaOsakkuudetListItem}
        ModalContentComponent={KuolinpesaOsakkuudetModal}
        state={omaisuus.varat.osuudetKuolinpesissa}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.VARAT}
        dataTestIdPrefix={mkOmaisuusTestId('kuolinpesaOsakkuudet-form')}
        newEntry={mkNewEntry}
        translationPrefix="kuolinpesaOsakkuudet"
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
}

const KuolinpesaOsakkuudetModal: FC<FormModalProps<FrontKuolinpesaOsakkuus>> =
  observer(({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const { asiaType } = getTili()
    const evtv = useValittuEvtvVaatimus()
    const alkupvm = IsoDatetimeToUiDate(evtv.accountingPeriodStartDate)
    const isOmaisuusluettelo = asiaType === AsiointiType.OMAISUUSLUETTELO
    const translations =
      translationsByType[
        asiaType === 'OMAISUUSLUETTELO' ? 'OMAISUUSLUETTELO' : 'TILI'
      ]
    return (
      <div>
        <FormTextInput
          data-test-id={createDataTestId('modal-vainajanEtunimi')}
          labelText={t('vainajanEtunimi')}
          value={entry.vainajanEtunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, vainajanEtunimi: value })
          }
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-vainajanSukunimi')}
          labelText={t('vainajanSukunimi')}
          value={entry.vainajanSukunimi}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({ ...entry, vainajanSukunimi: value })
          }
        />

        <FormDateInput
          labelText={t('vainajanKuolinpaiva')}
          data-test-id={createDataTestId('modal-vainajanKuolinpaiva')}
          value={
            entry.vainajanKuolinpaiva
              ? formatDateAsLocal(entry.vainajanKuolinpaiva)
              : entry.vainajanKuolinpaiva
          }
          updateValue={(value) => {
            setEntry({
              ...entry,
              vainajanKuolinpaiva: value ? parseLocalDate(value) : value,
            })
          }}
          validate={commonDateValidator(t)}
        />

        <FormTextInput
          data-test-id={createDataTestId('modal-osakkuudenOsuus')}
          labelText={t('henkilonOsuusKuolinpesa')}
          hintText={t('ilmoitaProsenttiTaiJakoOsuus')}
          required
          value={entry.osakkuudenOsuus}
          validate={commonXssValidator(t)}
          updateValue={(value) =>
            setEntry({
              ...entry,
              osakkuudenOsuus: value,
            })
          }
        />

        {!isOmaisuusluettelo && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            hintText={
              isOmaisuusluettelo
                ? t('ilmoitaArvoTehtavanAlussa', { alkupvm })
                : undefined
            }
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLopussa)}
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={
            asiaType === 'OMAISUUSLUETTELO'
              ? TiliAsiakirjaType.PERUKIRJA
              : TiliAsiakirjaType.MUU_LIITE
          }
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t(translations.asiakirjatInfo)}
          title={t('asiakirjat')}
        />
      </div>
    )
  })

const KuolinpesaOsakkuudetListItem: FC<
  FormElementProps<FrontKuolinpesaOsakkuus>
> = observer(({ createDataTestId, entry, onEdit, onRemove }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const isOmaisuusluettelo =
    getTili().asiaType === AsiointiType.OMAISUUSLUETTELO

  const henkilonProsenttiosuus = parseStringAsNumberWithDigits(
    entry.osakkuudenOsuus,
    'two'
  )
  const osuusKuolinpesasta = henkilonProsenttiosuus
    ? `${formatNumberWithComma(henkilonProsenttiosuus)} %`
    : entry.osakkuudenOsuus

  const arvoLopussaEur = entry.arvoTilikaudenLopussaEur
    ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
    : ''

  const arvoAlussaEur = entry.arvoTilikaudenAlussaEur
    ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
    : ''

  const kuolinpaiva = entry.vainajanKuolinpaiva
    ? formatDateAsLocal(entry.vainajanKuolinpaiva)
    : t('vainajanKuolinpaivaEiTiedossa')

  return (
    <FormListInputExpander
      heading={`${entry.vainajanSukunimi}, ${entry.vainajanEtunimi}`}
      headingRight={arvoLopussaEur}
      createDataTestId={createDataTestId}
      onEdit={onEdit}
      onRemove={onRemove}
      showSubHeadingWhileOpen
    >
      <Heading variant="h5">{t('vainajanKuolinpaiva')}</Heading>
      <Text>{kuolinpaiva}</Text>
      <Block mt={verticalMarginToken} />

      <Heading variant="h5">{t('osakkuudenOsuus')}</Heading>
      <Text>{osuusKuolinpesasta}</Text>
      <Block mt={verticalMarginToken} />

      {!isOmaisuusluettelo && (
        <>
          <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
          <Text>{t(arvoAlussaEur)}</Text>
          <Block mt={verticalMarginToken} />
        </>
      )}

      {entry.asiakirjat.length > 0 && (
        <>
          <Block mt={verticalMarginToken} />
          <Heading variant="h5">{t('asiakirjat')}</Heading>
          <AttachmentList attachments={entry.asiakirjat} />
          <Block mt={verticalMarginToken} />
        </>
      )}
      <Block mt={verticalMarginToken} />
    </FormListInputExpander>
  )
})

export default observer(KuolinpesaOsakkuudetForm)

const translationsByType = {
  OMAISUUSLUETTELO: {
    asiakirjatInfo: 'asiakirjaKuolinpesa',
    arvoLopussa: 'arvoEuroina',
  },
  TILI: {
    asiakirjatInfo: 'kuolinpesaOsakkuudetAsiakirjaInfo',
    arvoLopussa: 'arvoTilikaudenLopussaEuroina',
  },
}
