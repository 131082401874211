import { runAsiointiStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import {
  AsiointiPerson,
  TemporaryId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import {
  LupaApplicationRole,
  OpinionType,
  OpinionTypeId,
} from 'lupa-backend/src/vtj/elsa/person/person-enums'
import { validateBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/validation-actions'
import {
  LupaAsiointiAsiakirjaType,
  LupaAsiointiAsiakirjaTypeId,
} from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'

const DEFAULT_COUNTRY_ID = '246' // Finland

export const setPersonValues = (
  personId: TemporaryId,
  values: Omit<
    Partial<AsiointiPerson>,
    'personId' | 'opinionId' | 'attachments'
  >
): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    store.batch.persons.forEach((p) => {
      if (p.personId === personId) {
        Object.assign(p, { ...values, personId: p.personId })
      }
    })
    yield validateBatch()
  })

export const addPerson = (
  props: Partial<AsiointiPerson> & Pick<AsiointiPerson, 'applicationRoleId'>
): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    const { countries } = store
    const country = countries.find((c) => c.countryId === DEFAULT_COUNTRY_ID)
    store.batch.persons.push({
      validationErrors: [],
      attachments: [],
      email: null,
      emailRepeated: null,
      opinionTypeId:
        props.applicationRoleId === LupaApplicationRole.PAAMIES
          ? OpinionType.PAAMIES_EI_VOI_ANTAA_MIELIPIDETTAAN
          : OpinionType.EI_TIEDOSSA,
      opinionArguments: null,
      opinionAttachmentAsiakirjaTypeId:
        props.applicationRoleId === LupaApplicationRole.PAAMIES
          ? LupaAsiointiAsiakirjaType.MUU_SELVITYS_PERUSTEESTA_OLLA_KUULEMATTA_PAAMIESTA
          : null,
      phone: null,
      postOffice: null,
      postalCode: null,
      streetAddress: null,
      streetAddressExtra: null,
      firstnames: null,
      lastname: null,
      hetu: null,
      personId: crypto.randomUUID(),
      isAuthenticatedUser: false,
      ...props,
      // and then overriding some props ->
      countryId: props.countryId || country?.countryId || null,
    })
    yield validateBatch()
  })

export const removePerson = (removePersonId: TemporaryId): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    const index = store.batch.persons.findIndex(
      ({ personId }) => personId === removePersonId
    )
    if (index > -1) {
      store.batch.persons.splice(index, 1)
      yield validateBatch()
    }
  })

export const setPaamiesOpinion = (
  personId: AsiointiPerson['personId'],
  opinionId: OpinionTypeId,
  asiakirjaTypeId: LupaAsiointiAsiakirjaTypeId | null
): Promise<void> =>
  runAsiointiStoreFlow(function* (store) {
    store.batch.persons.forEach((person) => {
      if (
        personId === person.personId &&
        person.applicationRoleId === LupaApplicationRole.PAAMIES
      ) {
        person.opinionTypeId = opinionId
        person.opinionAttachmentAsiakirjaTypeId = asiakirjaTypeId
        // Do not remove attachments for missing asiakirjaTypeId
        // for better UI experience when switching between values
        if (asiakirjaTypeId) {
          person.attachments.forEach((attachment) => {
            attachment.asiakirjaTypeId = asiakirjaTypeId
          })
        }
      }
    })
    return yield validateBatch()
  })

export const setPaamiesOpinionArguments = (
  personId: AsiointiPerson['personId'],
  opinionArguments: string
): Promise<void> =>
  runAsiointiStoreFlow(function* (store) {
    store.batch.persons.forEach((person) => {
      if (
        personId === person.personId &&
        person.applicationRoleId === LupaApplicationRole.PAAMIES
      ) {
        person.opinionArguments = opinionArguments
      }
    })
    return yield validateBatch()
  })
