import {
  AsiointiLedgerAccounts,
  PartialAsiointiLedgerAccounts,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.type'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
  AsiointiLedgerAccountType,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import {
  asiointiLedgerAccountRange,
  makeAsiointiLedgerAccountsMap,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.util'

const partialAsiointiLedgerAccountsAssetMap: Record<
  AsiointiLedgerAccountAssetsType,
  PartialAsiointiLedgerAccounts
> = {
  [AsiointiLedgerAccountType.EDUNVALVONTATILI]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN,
    accounts: ['100000'],
  },
  [AsiointiLedgerAccountType.KAYTTOTILI]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN,
    accounts: asiointiLedgerAccountRange(101000, 101099),
  },
  [AsiointiLedgerAccountType.VUOKRAVAKUUSTILI]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN,
    accounts: asiointiLedgerAccountRange(102000, 102099),
  },
  [AsiointiLedgerAccountType.OMAISUUDEN_HOITOTILI]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN,
    accounts: asiointiLedgerAccountRange(103000, 103099),
  },
  [AsiointiLedgerAccountType.SIJOITUSTILI]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN,
    accounts: asiointiLedgerAccountRange(104000, 104099),
  },
  [AsiointiLedgerAccountType.ERAANTYVA_SIJOITUSTILI]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN,
    accounts: asiointiLedgerAccountRange(105000, 105099),
  },
  [AsiointiLedgerAccountType.VAKUUTUSSAASTOTILI]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.PANKKITALLETUKSET_JA_VAKUUTUSSAASTAMINEN,
    accounts: asiointiLedgerAccountRange(106000, 106099),
  },
  [AsiointiLedgerAccountType.OSAKKEET]: {
    subCategory: AsiointiLedgerAccountSubCategory.ARVOPAPERIT,
    accounts: asiointiLedgerAccountRange(110000, 110099),
  },
  [AsiointiLedgerAccountType.OSUUDET]: {
    subCategory: AsiointiLedgerAccountSubCategory.ARVOPAPERIT,
    accounts: asiointiLedgerAccountRange(111000, 111099),
  },
  [AsiointiLedgerAccountType.JOUKKOVELKAKIRJALAINAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.ARVOPAPERIT,
    accounts: asiointiLedgerAccountRange(112000, 112099),
  },
  [AsiointiLedgerAccountType.RAHASTO_OSUUDET]: {
    subCategory: AsiointiLedgerAccountSubCategory.RAHASTO_OSUUDET,
    accounts: asiointiLedgerAccountRange(120000, 120099),
  },
  [AsiointiLedgerAccountType.ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.ASUNTO_OSAKKEET_JA_MUUT_HUONEISTOT,
    accounts: asiointiLedgerAccountRange(130000, 130099),
  },
  [AsiointiLedgerAccountType.KIINTEISTOT]: {
    subCategory: AsiointiLedgerAccountSubCategory.KIINTEISTOT,
    accounts: asiointiLedgerAccountRange(140100, 140199),
  },
  [AsiointiLedgerAccountType.OSUUDET_KUOLINPESISSA]: {
    subCategory: AsiointiLedgerAccountSubCategory.OSUUDET_KUOLINPESISSA,
    accounts: asiointiLedgerAccountRange(150000, 150010),
  },
  [AsiointiLedgerAccountType.SAATAVAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.SAATAVAT,
    accounts: asiointiLedgerAccountRange(160000, 160099),
  },
  [AsiointiLedgerAccountType.IRTAIMISTO]: {
    subCategory: AsiointiLedgerAccountSubCategory.IRTAIMISTO,
    accounts: asiointiLedgerAccountRange(170000, 170099),
  },
  [AsiointiLedgerAccountType.MUUT_VARAT]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_VARAT,
    accounts: asiointiLedgerAccountRange(180000, 180099),
  },
}

export const asiointiLedgerAccountsAssetsMap: Record<
  AsiointiLedgerAccountAssetsType,
  AsiointiLedgerAccounts
> = makeAsiointiLedgerAccountsMap(AsiointiLedgerAccountCategory.VARAT)(
  partialAsiointiLedgerAccountsAssetMap
)
