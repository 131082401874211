import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliData } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getPaivamaaraString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/yhteenveto.util'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Voimassaoloaika } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'

const getSopimukset = (
  { sopimukset }: TiliData,
  t: TFunction
): YhteenvetoGroupProps[] => {
  return sopimukset.map((s) => ({
    heading: `${s.sopimustyyppi}`,
    rows: [
      [
        s.toimittaja,
        s.voimassaoloaika === Voimassaoloaika.MAARA_AIKAINEN
          ? t('maaraaikainen')
          : s.voimassaoloaika === Voimassaoloaika.TOISTAISEKSI_VOIMASSA_OLEVA
          ? t('toistaiseksiVoimassaOleva')
          : undefined,
        getPaivamaaraString(t, s.beginDate, s.endDate),
      ]
        .filter((s) => s)
        .join(', '),
    ],
    attachments: s.asiakirjat,
  }))
}

const Sopimukset: React.FC = observer(() => {
  const [t] = useTranslation()
  const { tili, lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('sopimukset-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('sopimuksetHeading')}
      </Heading>
      <YhteenvetoGroups
        groups={
          lomakkeelleValitutTiedot['sopimukset'] ? getSopimukset(tili, t) : []
        }
      />
    </section>
  )
})

export default Sopimukset
