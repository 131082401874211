import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { FrontPrivatePerson } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getAddressString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/yhteenveto.util'
import { getTiedoksiantoForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tiedoksianto.store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getTiedoksiantoContent = (
  tiedoksianto: FrontPrivatePerson,
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const { hetu, lastname, firstnames, address, email, phone } = tiedoksianto

  return [
    {
      heading: t('nimi'),
      rows: [firstnames || lastname ? `${firstnames} ${lastname}` : undefined],
    },
    {
      heading: t('henkilotunnus'),
      rows: [hetu],
    },
    {
      heading: t('puhelinnumero'),
      rows: [phone],
    },
    {
      heading: t('sahkoposti'),
      rows: [email],
    },
    {
      heading: t('katuosoite'),
      rows: [getAddressString(address, lang)],
    },
  ]
}

const Tiedoksianto: React.FC = observer(() => {
  const [t] = useTranslation()
  const tiedoksianto = getTiedoksiantoForm()
  const lang = useAsiointiUserStore().lang

  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <section data-test-id={mkYhteenvetoTestId('tiedoksianto-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('tiedoksianto')}
      </Heading>
      <YhteenvetoGroups
        groups={getTiedoksiantoContent(tiedoksianto, t, lang)}
      />
    </section>
  )
})

export default Tiedoksianto
