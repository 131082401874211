import { AsiointiLedgerAccountDebtType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-enum'
import {
  AsiointiLedgerAccounts,
  PartialAsiointiLedgerAccounts,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.type'
import {
  asiointiLedgerAccountRange,
  makeAsiointiLedgerAccountsMap,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.util'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

const partialAsiointiLedgerAccountsDebtMap: Record<
  AsiointiLedgerAccountDebtType,
  PartialAsiointiLedgerAccounts
> = {
  [AsiointiLedgerAccountDebtType.ASUNTOLAINA]: {
    subCategory: AsiointiLedgerAccountSubCategory.PANKKILAINAT,
    accounts: asiointiLedgerAccountRange(200000, 200019),
  },
  [AsiointiLedgerAccountDebtType.OPINTOLAINA]: {
    subCategory: AsiointiLedgerAccountSubCategory.PANKKILAINAT,
    accounts: asiointiLedgerAccountRange(201020, 201029),
  },
  [AsiointiLedgerAccountDebtType.MUU_LAINA]: {
    subCategory: AsiointiLedgerAccountSubCategory.PANKKILAINAT,
    accounts: asiointiLedgerAccountRange(202030, 202099),
  },
  [AsiointiLedgerAccountDebtType.ELATUSAPUVELKA]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_VELAT,
    accounts: asiointiLedgerAccountRange(210100, 210199),
  },
  [AsiointiLedgerAccountDebtType.VELKAJARJESTELYVELKA]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_VELAT,
    accounts: asiointiLedgerAccountRange(211200, 211299),
  },
  [AsiointiLedgerAccountDebtType.MUU_VELKA]: {
    subCategory: AsiointiLedgerAccountSubCategory.MUUT_VELAT,
    accounts: asiointiLedgerAccountRange(212300, 212399),
  },
  [AsiointiLedgerAccountDebtType.TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA]: {
    subCategory:
      AsiointiLedgerAccountSubCategory.TAKAUKSET_JA_VASTUUT_MUIDEN_VELOISTA,
    accounts: asiointiLedgerAccountRange(220400, 220419),
  },
}

export const asiointiLedgerAccountsDebtMap: Record<
  AsiointiLedgerAccountDebtType,
  AsiointiLedgerAccounts
> = makeAsiointiLedgerAccountsMap(AsiointiLedgerAccountCategory.VELAT)(
  partialAsiointiLedgerAccountsDebtMap
)
