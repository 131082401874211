import { AsiointiBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { runAsiointiStoreFlow } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import { ApiResponse } from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { sendAsiointiBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/asiointi-batch-api'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { validateBatch } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/validation-actions'

export const setBatchValues = (
  values: Pick<Partial<AsiointiBatch>, 'arguments' | 'description'>
): Promise<void> =>
  runAsiointiStoreFlow(function* (store: AsiointiStore) {
    Object.assign(store.batch, { ...values })
    yield validateBatch()
  })

export const sendBatch = (
  languageCode: HolhousAsiointiLanguageCode
): Promise<ApiResponse<void>> =>
  runAsiointiStoreFlow(function* (store) {
    store.batch.submitStatus = 'in-progress'
    store.batch.submitError = undefined
    const response: ApiResponse<void> = yield sendAsiointiBatch(
      store.batch,
      languageCode
    )
    if (response.isOk) {
      store.batch.submitStatus = 'submitted'
    } else {
      store.batch.submitStatus = 'none'
      store.batch.submitError = response.errorData ?? {} // On network error there is no error data
    }
    return response
  })
