import {
  OmaisuusluetteloFormStates,
  PaatostiliFormStates,
  TilintarkastusForm,
  TilintarkastusRoutePath,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const resolveRouteErrors = (
  [key, { isComplete }]: [
    TilintarkastusRoutePath,
    (
      | PaatostiliFormStates[keyof PaatostiliFormStates]
      | OmaisuusluetteloFormStates[keyof OmaisuusluetteloFormStates]
    )
  ],
  mapping: Partial<Record<TilintarkastusRoutePath, string>>
): string | undefined => {
  if (isComplete) {
    return undefined
  } else {
    const virhe = mapping[key]
    if (virhe) {
      return virhe
    } else {
      return 'virhe' // form is bad but no mapping exists
    }
  }
}

export const resolveFormErrors = (
  forms: Partial<PaatostiliFormStates | OmaisuusluetteloFormStates>,
  errorToTranslationKeysMapping: Partial<
    Record<TilintarkastusRoutePath, string>
  >
): Record<TilintarkastusRoutePath, string> => {
  const { excludeSteps } = getTilintarkastusStore()
  const errors = Object.fromEntries(
    Object.entries(forms)
      .filter(([key]) => !excludeSteps.has(key))
      .map(([k, v]) => [
        k,
        resolveRouteErrors(
          [k as TilintarkastusRoutePath, v],
          errorToTranslationKeysMapping
        ),
      ])
      .filter(([, v]) => v)
  )
  return errors
}

export const isComplete = ({ formState }: TilintarkastusForm): boolean => {
  return (
    formState.isSubmitted &&
    // form with 0 registered inputs is invalid, thus check for errors
    (formState.isValid || Object.entries(formState.errors).length === 0)
  )
}

export const hasErrors = ({ formState }: TilintarkastusForm): boolean => {
  return (
    formState.isSubmitted &&
    !formState.isValid &&
    // form with 0 registered inputs is invalid, thus check for errors
    Object.entries(formState.errors).length > 0
  )
}
