import { createContext, useContext } from 'react'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'

export type Devices = Record<keyof typeof device, boolean>

export const initialDeviceValues: Readonly<Devices> = {
  tablet: true,
  desktop: true,
}

export const DeviceContext = createContext<Devices>(initialDeviceValues)

export const useDeviceContext = (): Devices => {
  return useContext(DeviceContext)
}
