import keyMirror from 'keymirror'
import _ from 'lodash'

export const SpecifierType = keyMirror({
  ASUMISOIKEUDEN_HANKKIMINEN: null,
  ASUMISOIKEUDEN_VUOKRAAMINEN: null,
  ASUNNON_HANKKIMINEN: null,
  ASUNNON_VUOKRAAMINEN: null,
  AVIOERO_OSITUS_TAI_EROTTELU: null,
  ELINKEINON_HARJOITTAMINEN: null,
  KIINTEISTON_HANKKIMINEN: null,
  KIINTEISTON_VUOKRAAMINEN: null,
  LAINAN_ANTAMINEN: null,
  LAINAN_OTTAMINEN: null,
  METSAN_TAI_MAA_AINEKSEN_MYYNTI: null,
  PANTTAUS: null,
  PERINNOSTA_LUOPUMINEN: null,
  PERINTOOSUUDEN_LUOVUTUS: null,
  SIJOITUSKOHTEIDEN_OSTAMINEN: null,
  SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA: null,
  TAKAUS: null,
  YHTIOMIEHEKSI_RYHTYMINEN: null,
  TOISSIJAINEN_VALTUUTETTU: null,
  AVIOERO: null,
  AVIOLIITTO: null,
  REKISTEROIDUN_PARISUHTEEN_PURKAMINEN: null,
  REKISTEROIDUN_PARISUHTEEN_ILMOITTAMINEN: null,
  A_TAI_P_OLESKELULUPA: null,
  EU_REKISTEROINTI_ALLE_12_KK_SITTEN: null,
  EU_REKISTEROINTI_YLI_12_KK_SITTEN: null,
  TILAPAISEN_SUOJELUN_OLESKELULUPA: null,
  VIRANOMAISALOITTEINEN: null,
  VALTUUTETTU_KUOLLUT: null,
  VALTUUTETUN_LUOPUMINEN: null,
  B_OLESKELULUPA_TAI_PERHESUHDEPERUSTE: null,
  POHJOISMAAT_TAI_VIRO: null,
  KUOLEMA: null,
  KUOLLEEKSI_JULISTAMINEN: null,
  EIDAS: null,
  HETU_NORMAALI_KASITTELY: null,
  HETU_NOPEUTETTU_KASITTELY: null,
  KV_ASIA: null,
  APOSTILLE: null,
  KUOLINTIEDON_KORJAUS: null,
  KUOLLEEKSIJULISTAMISTIEDON_KORJAUS: null,
  VAKINAINEN_MUUTTO: null,
  TILAPAINEN_MUUTTO: null,
  PUOLESTA_ILMOITETTU_MUUTTO: null,
  POSTIOSOITTEEN_MUUTOS: null,
  HENKILOTUNNUKSEN_ANTAMINEN: null,
  LAPSEN_NIMI_AIDINKIELI_JA_KANSALAISUUS: null,
  KANSALAISUUDET: null,
  ULKOMAINEN_SYNTYMAPAIKKA: null,
  EI_LUVANVARAINEN_LUPA_ASIA: null,
  EU_KAANNOSLOMAKE: null,
  TILI_SAAPUMATTA: null,
  TILIKAUSIEN_YHDISTAMINEN: null,
})
export type SpecifierTypeId = (typeof SpecifierType)[keyof typeof SpecifierType]
export const allSpecifierTypeIds = _.keys(SpecifierType) as SpecifierTypeId[]

export type SpecifierMetadata = {
  multipleSpecifiers: boolean
  mandatorySpecifier: boolean
  specifierTypeIds: SpecifierTypeId[]
}

export const prioritizedSpecifiers: SpecifierTypeId[] = [
  SpecifierType.APOSTILLE,
  SpecifierType.EIDAS,
  SpecifierType.KV_ASIA,
]
