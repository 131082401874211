import { RefObject, useRef, useEffect } from 'react'
import {
  getStore,
  runAsiointiStoreAction,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'

export const useValidationErrorFocus = <T extends HTMLElement>(
  focusId: string
): RefObject<T> => {
  const ref = useRef<T>(null)
  const { validationErrorFocus } = getStore()
  useEffect(() => {
    if (validationErrorFocus === focusId) {
      runAsiointiStoreAction((store) => {
        store.validationErrorFocus = null
      })
      ref.current && ref.current?.focus()
    }
  }, [validationErrorFocus, focusId])
  return ref
}
