import React from 'react'
import { observer } from 'mobx-react'
import {
  mkHakemusTestId,
  mkLupaAsiointiPersonActionTestId,
  mkLupaAsiointiPersonHeadingTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { LupaApplicationStep } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { Block, Button, Heading, Paragraph } from 'suomifi-ui-components'
import { IconPlus } from 'suomifi-icons'
import { getStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import AsiointiPersonForm from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/persons/AsiointiPersonForm'
import { addPerson } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/person-actions'
import { useTranslation } from 'react-i18next'
import { LupaApplicationRole } from 'lupa-backend/src/vtj/elsa/person/person-enums'
import CurrentStepDisplay from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/common/CurrentStepDisplay'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import {
  BlueBorderedContentBlock,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import ValidationErrorsSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/ValidationErrorsSummary'
import { AsiointiPerson } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'

const PersonInputPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const store = getStore()

  const {
    countries,
    batch,
    validationFailedForSteps,
    showValidationErrorsForSteps,
  } = store
  const { persons } = batch
  const hakijat = persons.filter(
    (person) => person.applicationRoleId === LupaApplicationRole.HAKIJA
  )
  const paamiehet = persons.filter(
    (person) => person.applicationRoleId === LupaApplicationRole.PAAMIES
  )

  const showErrors =
    validationFailedForSteps.has(LupaApplicationStep.INPUT_PERSONS) &&
    showValidationErrorsForSteps.has(LupaApplicationStep.INPUT_PERSONS)

  const handleAddPersonClick = async (
    applicationRoleId: AsiointiPerson['applicationRoleId']
  ) => {
    await addPerson({ applicationRoleId })
    setTimeout(() => {
      const index =
        store.batch.persons.filter(
          (p) => p.applicationRoleId === applicationRoleId
        ).length - 1
      const personHeading = document.getElementById(
        mkLupaAsiointiPersonHeadingTestId(applicationRoleId, index)
      )
      if (personHeading) {
        personHeading.focus()
      }
    })
  }

  return (
    <section data-test-id={mkHakemusTestId(LupaApplicationStep.INPUT_PERSONS)}>
      <ResponsiveBorderedContentBlock>
        <CurrentStepDisplay heading={t('hakijatJaPaamiehet')} />
        <Block padding="xs" />
        <Heading variant="h3">{t('hakijat')}</Heading>
        <Block mt="m" />
        <Paragraph>{t('lisaaKaikkienHakijoidenTiedot')}</Paragraph>
        <Block mt="m" />
        {showErrors && (
          <>
            <ValidationErrorsSummary batch={{ persons }} />
            <Block mt="m" />
          </>
        )}
        <BlueBorderedContentBlock padding="s">
          <Block>
            {hakijat.map((person, index) => (
              <React.Fragment key={person.personId}>
                <AsiointiPersonForm
                  asiointiPerson={person}
                  index={index}
                  countries={countries}
                />
                <DividerLine mt="xs" mb="xs" />
              </React.Fragment>
            ))}
            <Button
              id={mkLupaAsiointiPersonActionTestId(
                LupaApplicationRole.HAKIJA,
                'addPerson'
              )}
              data-test-id={mkLupaAsiointiPersonActionTestId(
                LupaApplicationRole.HAKIJA,
                'addPerson'
              )}
              variant="secondary"
              icon={<IconPlus />}
              onClick={() => handleAddPersonClick(LupaApplicationRole.HAKIJA)}
            >
              {t('lisaaUusiHakija')}
            </Button>
          </Block>
        </BlueBorderedContentBlock>
        <Block padding="xs" />
        <Heading variant="h3">{t('paamiehet')}</Heading>
        <Block mt="m" />
        <Paragraph mb="m">{t('lisaaKaikkienPaamiehienTiedot')}</Paragraph>
        <BlueBorderedContentBlock padding="s">
          <Block>
            {paamiehet.map((person, index) => (
              <React.Fragment key={person.personId}>
                <AsiointiPersonForm
                  asiointiPerson={person}
                  index={index}
                  countries={countries}
                />
                <DividerLine mt="xs" mb="xs" />
              </React.Fragment>
            ))}

            <Button
              id={mkLupaAsiointiPersonActionTestId(
                LupaApplicationRole.PAAMIES,
                'addPerson'
              )}
              data-test-id={mkLupaAsiointiPersonActionTestId(
                LupaApplicationRole.PAAMIES,
                'addPerson'
              )}
              variant="secondary"
              icon={<IconPlus />}
              onClick={() => handleAddPersonClick(LupaApplicationRole.PAAMIES)}
            >
              {t('lisaaUusiPaamies')}
            </Button>
          </Block>
        </BlueBorderedContentBlock>
        <Block padding="xs" />
      </ResponsiveBorderedContentBlock>
    </section>
  )
})

export default PersonInputPage
