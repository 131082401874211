import { AsiointiLedgerAccountType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { TFunction } from 'react-i18next'

export interface AssetTypeItem {
  name: string
  value: AsiointiLedgerAccountType
}

export const translateAssetType =
  (t: TFunction) =>
  ({ name, value }: AssetTypeItem): AssetTypeItem => {
    return { name: t(name), value }
  }
